<template>
  <div class="m-teaBan">
    <div class="m-teaBan-left">
      <div class="m-teaBan-left-logo"></div>
      <div class="m-teaBan-left-ico"></div>
    </div>
    <div class="m-teaBan-right">
      <div class="login-right-cap">登录</div>
      <div class="login-box">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          class="login-form"
          label-position="top"
          label-width="100px"
        >
          <el-form-item label="账号" prop="phone">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input v-model="form.password" type="password"></el-input>
          </el-form-item>
        </el-form>
        <div class="login-btn m-h" @click="submitForm">登 录</div>
      </div>
    </div>
    <div class="copyright">
      <a href="https://beian.miit.gov.cn/" target="_blank">
        浙ICP备12019612号-9
      </a>
      © 2019-2021 浙江华博特教育科技有限公司
    </div>
  </div>
</template>

<script>
import md5 from "md5";
// import Cookies from "js-cookie";
import store from "../store";
export default {
  data() {
    return {
      query: this.$route.query,
      form: {
        phone: "",
        password: "",
      },
      rules: {
        phone: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      saveAccountStatus: false,
    };
  },
  mounted() {},
  methods: {
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          const form = { ...this.form };
          form.password = md5(form.password);
          this.$api.ums.user.user_signIn
            .post({
              ...form,
              platform: "华博特园丁",
            })
            .then(async (res) => {
              await store.dispatch("userInfoUpdate", res.data);
              this.$router.replace("/");
              console.log(res);
              // service.handleLoginSuccess(res.data);
            });
          // this.$api.main
          //   .signIn({
          //     ...this.form,
          //     password: md5(this.form.password)
          //   })
          //   .then(res => {
          //     if (this.saveAccountStatus) {
          //       localStorage.setItem("saveAccount", this.form.phone);
          //     } else {
          //       localStorage.setItem("saveAccount", "");
          //     }
          //     // 页面逻辑处理
          //     switch (res.data.auditStatus) {
          //       case 1: // 审核中
          //         this.$message.warning("您的账户还在审核中，请耐心等待");
          //         break;
          //       case 2: // 通过
          //         Cookies.set("teacher_userInfo", JSON.stringify(res.data));
          //         this.$store.dispatch("userInfoSet", res.data);
          //         this.$router.replace("/");
          //         break;
          //       case 3: // 不通过
          //         this.$message.error("您的申请已不通过，请修改资料");
          //         this.$router.replace("/teacher_apply?type=edit");
          //         break;
          //     }
          //   });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less">
.copyright {
  position: absolute;
  bottom: 0;
  color: #fff;
  width: 100%;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  background: #474747;
  text-align: center;
}
.copyright a {
  color: #fff;
}
.login-right-cap {
  color: #282828;
  font-size: 48px;
  text-align: center;
  font-weight: bold;
  margin-top: 169px;
}
.login-box {
  width: 520px;
  margin: 100px auto 0 auto;
}
.login-form {
  .el-form-item__label {
    color: #606060;
    font-size: 24px;
    line-height: 1;
    &:before {
      display: none;
    }
  }
  .el-form-item {
    margin-bottom: 32px;
    &.is-error {
      .el-input__inner {
        border-color: #f56c6c;
      }
    }
  }
  .el-input {
    .el-input__inner {
      height: 60px;
      color: #282828;
      font-size: 20px;
      background-color: #f7f7f7;
      border-radius: 8px;
      border-color: #f7f7f7;
      &:focus {
        box-shadow: 0 0 10px 0 rgba(56, 123, 224, 1);
        border-color: rgba(217, 217, 217, 1);
      }
    }
  }
  .el-form-item__error {
    display: none;
  }
}
.login-sys {
  display: flex;
  align-items: center;
}
.login-sys-link {
  font-size: 20px;
  color: #3c80e6;
  font-weight: bold;
}
.login-sys-link-tit {
  color: #979797;
  font-size: 16px;
}
.login-sys-save {
  .el-checkbox__label {
    color: #979797;
    font-size: 16px;
    line-height: 16px;
  }
  .el-checkbox__input {
    &.is-checked {
      & + .el-checkbox__label {
        color: #979797;
      }
    }
  }
}
.login-btn {
  height: 60px;
  line-height: 60px;
  text-align: center;
  background: rgba(60, 128, 230, 1);
  border-radius: 8px;
  margin-top: 64px;
  color: #ffffff;
  font-size: 20px;
}
</style>
