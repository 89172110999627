import bms from "./bms";
import ums from "./ums";
import cms from "./cms";
import sms from "./sms";
import ems from "./ems";

export default {
  // 基础微服务
  bms,
  // 用户微服务
  ums,
  // 内容微服务
  cms,
  // 学习微服务
  sms,
  // 学历微服务
  ems,
};
