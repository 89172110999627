import { $ } from "@/common/Axios";

export default {
  /**
   * bi部门
   */
  biDepartment: {
    /*
     * 添加
     * businessFlag 是否业务部门 (string query )
     * id  (integer query )
     * levelFour 四级部门 (string query )
     * levelOne 一级部门 (string query )
     * levelThree 三级部门 (string query )
     * levelTwo 二级部门 (string query )
     * statsDepartment 统计部门 (string query )
     */
    post: (p, c) => $.post("/api/bms/statistics/biDepartment", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/bms/statistics/biDepartment", p, c),
  },
  biDepartment_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/bms/statistics/biDepartment/export", p, c),
  },
  biDepartment_exportSystemDepartment: {
    /*
     * 导出对应系统部门列表
     */
    get: (p, c) =>
      $.get("/api/bms/statistics/biDepartment/exportSystemDepartment", p, c),
  },
  biDepartment_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/bms/statistics/biDepartment/list", p, c),
  },
  biDepartment_listDepartmentId: {
    /*
     * 根据bi部门id获取已配置的部门id
     * id id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/bms/statistics/biDepartment/listDepartmentId", p, c),
  },
  biDepartment_listLevelFour: {
    /*
     * 四级部门列表
     * levelOne 一级部门 (string query required)
     * levelThree 三级部门 (string query required)
     * levelTwo 二级部门 (string query required)
     */
    get: (p, c) =>
      $.get("/api/bms/statistics/biDepartment/listLevelFour", p, c),
  },
  biDepartment_listLevelFourSimple: {
    /*
     * 四级部门列表(带id)
     * levelOne 一级部门 (string query required)
     * levelThree 三级部门 (string query required)
     * levelTwo 二级部门 (string query required)
     */
    get: (p, c) =>
      $.get("/api/bms/statistics/biDepartment/listLevelFourSimple", p, c),
  },
  biDepartment_listLevelOne: {
    /*
     * 一级部门列表
     */
    get: (p, c) => $.get("/api/bms/statistics/biDepartment/listLevelOne", p, c),
  },
  biDepartment_listLevelThree: {
    /*
     * 三级部门列表
     * levelOne 一级部门 (string query required)
     * levelTwo 二级部门 (string query required)
     */
    get: (p, c) =>
      $.get("/api/bms/statistics/biDepartment/listLevelThree", p, c),
  },
  biDepartment_listLevelTwo: {
    /*
     * 二级部门列表
     * levelOne 一级部门 (string query required)
     */
    get: (p, c) => $.get("/api/bms/statistics/biDepartment/listLevelTwo", p, c),
  },
  biDepartment_listStatsDepartment: {
    /*
     * listStatsDepartment
     */
    get: (p, c) =>
      $.get("/api/bms/statistics/biDepartment/listStatsDepartment", p, c),
  },
  biDepartment_updateDepartmentId: {
    /*
     * 更新bi部门对应系统部门
     * departmentIds 系统部门id (array query required)
     * id id (integer query required)
     */
    put: (p, c) =>
      $.put("/api/bms/statistics/biDepartment/updateDepartmentId", p, c),
  },
  biDepartment_upload: {
    /*
     * 导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/bms/statistics/biDepartment/upload", p, c),
  },
  biDepartment_uploadSystemDepartment: {
    /*
     * 导入对应系统部门列表
     * excelFile excelFile (file formData required)
     */
    post: (p, c) =>
      $.post("/api/bms/statistics/biDepartment/uploadSystemDepartment", p, c),
  },

  /**
   * bi页面
   */
  biPage: {
    /*
     * 添加
     * ableStatus 启用状态 (string query )
     * id  (integer query )
     * maintenanceId 维护者id (integer query )
     * name 名称 (string query )
     * thumb 缩略图 (string query )
     * url bi页面地址 (string query )
     */
    post: (p, c) => $.post("/api/bms/statistics/biPage", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/bms/statistics/biPage", p, c),
  },
  biPage_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/bms/statistics/biPage/list", p, c),
  },
  biPage_listAdminIds: {
    /*
     * 可查看负责人列表
     * pageId 页面id (integer query required)
     */
    get: (p, c) => $.get("/api/bms/statistics/biPage/listAdminIds", p, c),
  },
  biPage_listPageForAdmin: {
    /*
     * 负责人可查看BI页面列表
     * name 页面名字 (string query )
     */
    get: (p, c) => $.get("/api/bms/statistics/biPage/listPageForAdmin", p, c),
  },
  biPage_updateAdmin: {
    /*
     * 编辑可查看管理员
     * adminIds 管理员id (array query required)
     * pageId 页面id (integer query required)
     */
    put: (p, c) => $.put("/api/bms/statistics/biPage/updateAdmin", p, c),
  },
  biPage_updateSort: {
    /*
     * 编辑排序
     * sortText 排序json (string query required)
     */
    put: (p, c) => $.put("/api/bms/statistics/biPage/updateSort", p, c),
  },

  /**
   * 播报海报
   */
  broadcast_test: {
    /*
     * 添加
     */
    post: (p, c) => $.post("/api/bms/statistics/broadcast/test", p, c),
  },

  /**
   * 播报配置
   */
  broadcastConfig: {
    /*
     * 添加
     * fontColor 个人播报字体颜色 (string query )
     * id  (integer query )
     * name 名称 (string query )
     * personalPoster 个人播报海报 (string query )
     * personalPosterHash 个人播报海报文件hash值 (string query )
     * personalPosterTitle 个人海报标题 (string query )
     * personalText 个人播报文本 (string query )
     * personalType 个人播报类型：部门、学校 (string query )
     * robotToken 机器人token (string query )
     * siteType 学校类型 (string query )
     * timingPoster 定时播报海报 (string query )
     * timingPosterHash 定时播报海报文件hash值 (string query )
     * timingType 定时播报类型：部门、学校 (string query )
     */
    post: (p, c) => $.post("/api/bms/statistics/broadcastConfig", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/bms/statistics/broadcastConfig", p, c),
  },
  broadcastConfig_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/bms/statistics/broadcastConfig/list", p, c),
  },

  /**
   * 催费类型
   */
  chargeType: {
    /*
     * 添加
     * ableStatus 启用状态:启用,禁用 (string query )
     * defaultFlag 是否默认 (string query )
     * id  (integer query )
     * name 名字 (string query )
     */
    post: (p, c) => $.post("/api/bms/statistics/chargeType", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/bms/statistics/chargeType", p, c),
  },
  chargeType_getDefaultId: {
    /*
     * 获取默认id
     */
    get: (p, c) => $.get("/api/bms/statistics/chargeType/getDefaultId", p, c),
  },
  chargeType_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/bms/statistics/chargeType/list", p, c),
  },
  chargeType_listSimple: {
    /*
     * 简易列表
     */
    get: (p, c) => $.get("/api/bms/statistics/chargeType/listSimple", p, c),
  },

  /**
   * 催费类型详情
   */
  chargeTypeDetail: {
    /*
     * 添加
     * chargeTypeId 催费类型id (integer query )
     * feeType 缴费类型 (string query )
     * id  (integer query )
     * rollGrade 学籍年级 (string query )
     * siteType 学校类型 (string query )
     */
    post: (p, c) => $.post("/api/bms/statistics/chargeTypeDetail", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/bms/statistics/chargeTypeDetail", p, c),
  },
  chargeTypeDetail_list: {
    /*
     * 列表
     * typeId 类型id (integer query required)
     */
    get: (p, c) => $.get("/api/bms/statistics/chargeTypeDetail/list", p, c),
  },

  /**
   * 线索上报统计
   */
  clueActivate_init: {
    /*
     * 定时任务,临时测试
     * endTime 结束时间 (string query )
     * startTime 开始时间 (string query )
     */
    post: (p, c) => $.post("/api/bms/statistics/clueActivate/init", p, c),
  },
  clueActivate_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/bms/statistics/clueActivate/list", p, c),
  },

  /**
   * 继教院业绩首页
   */
  continue_listContinueHeaderTeacherServiceInfo: {
    /*
     * 继教院服务-班主任带班情况
     * siteId 学校 (integer query )
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/continue/listContinueHeaderTeacherServiceInfo",
        p,
        c
      ),
  },
  continue_listContinueLineChartData: {
    /*
     * 继教院业绩-折线图
     * batch 批次 (string query )
     * end 结束时间 (string query )
     * source 来源详情：线上，线下，同行，非同行，全部 (string query )
     * start 开始时间 (string query )
     * type 类型：全部、1月、2月。。。 (string query required)
     */
    get: (p, c) =>
      $.get("/api/bms/statistics/continue/listContinueLineChartData", p, c),
  },
  continue_listContinueLineChartDataForService: {
    /*
     * 继教院服务-折线图
     * batch 批次 (string query )
     * end 结束时间 (string query )
     * source 来源详情：线上，线下，同行，非同行，全部 (string query )
     * start 开始时间 (string query )
     * type 类型：全部、1月、2月。。。 (string query required)
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/continue/listContinueLineChartDataForService",
        p,
        c
      ),
  },
  continue_listContinueSiteAdmissionsRanking: {
    /*
     * 继教院业绩-学校招生数据饼图
     * end 结束时间 (string query )
     * start 开始时间 (string query )
     * timeType 时间类型 (string query )
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/continue/listContinueSiteAdmissionsRanking",
        p,
        c
      ),
  },
  continue_listContinueSiteServiceData: {
    /*
     * 继教院服务-学校服务数据饼图
     * batch 批次 (string query )
     */
    get: (p, c) =>
      $.get("/api/bms/statistics/continue/listContinueSiteServiceData", p, c),
  },
  continue_listContinueTeacherEnrollData: {
    /*
     * 继教院业绩-学校招生数据(个人排行)
     * end 结束时间 (string query )
     * siteId 院校 (integer query )
     * start 开始时间 (string query )
     * timeType 时间类型 (string query )
     */
    get: (p, c) =>
      $.get("/api/bms/statistics/continue/listContinueTeacherEnrollData", p, c),
  },
  continue_listStatsContinueSiteEnrollData: {
    /*
     * 继教院业绩-学校招生数据
     * batch 批次 (string query )
     * end 结束时间 (string query )
     * source 渠道（线上、线下、同行、非同行 (string query )
     * start 开始时间 (string query )
     * timeType 时间类型 (string query )
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/continue/listStatsContinueSiteEnrollData",
        p,
        c
      ),
  },
  continue_listStatsContinueSiteServiceData: {
    /*
     * 继教院服务-学校服务数据
     * batch 批次 (string query )
     * source 渠道（线上、线下、同行、非同行 (string query )
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/continue/listStatsContinueSiteServiceData",
        p,
        c
      ),
  },

  /**
   * 数据缓存
   */
  dataCache_getUpdateTime: {
    /*
     * 获取最近刷新的时间
     * requestParams 参数(协商好顺序，用逗号隔开，列表是|分割) (string query )
     * requestPath 接口地址，就是最后的方法，前面都不需要 (string query required)
     */
    get: (p, c) => $.get("/api/bms/statistics/dataCache/getUpdateTime", p, c),
  },
  dataCache_refresh: {
    /*
     * 刷新缓存
     * requestParams 参数(协商好顺序，用逗号隔开，列表是|分割) (string query )
     * requestPath 接口地址，就是最后的方法，前面都不需要 (string query required)
     */
    put: (p, c) => $.put("/api/bms/statistics/dataCache/refresh", p, c),
  },

  /**
   * DataEase接口
   */
  dataEase_enrollMonthTargetFinishInfoByProduct: {
    /*
     * 根据产品获取月度目标完成情况
     * product 产品：成教、自考、继教院 (string query required)
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/dataEase/enrollMonthTargetFinishInfoByProduct",
        p,
        c
      ),
  },
  dataEase_listEnrollMonthTargetFinishInfoByProduct: {
    /*
     * 根据产品获取月度目标完成情况
     * product 产品：成教、自考、继教院 (string query required)
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/dataEase/listEnrollMonthTargetFinishInfoByProduct",
        p,
        c
      ),
  },
  dataEase_listEnrollWeekTargetFinishInfoByProduct: {
    /*
     * 根据产品获取周度目标完成情况
     * product 产品：成教、自考、继教院 (string query required)
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/dataEase/listEnrollWeekTargetFinishInfoByProduct",
        p,
        c
      ),
  },
  dataEase_listHumanEffectiveness: {
    /*
     * 月(季)度人效
     * timeType 类型：月度、季度 (string query required)
     */
    get: (p, c) =>
      $.get("/api/bms/statistics/dataEase/listHumanEffectiveness", p, c),
  },

  /**
   * 部门招生目标
   */
  departmentEnrollTarget: {
    /*
     * 添加
     * bigDepartmentId 大部门id (integer query )
     * clueTarget 线索目标 (integer query )
     * countTarget 单量目标 (integer query )
     * departmentId 部门id (integer query )
     * endTime 结束时间 (string query )
     * firstOldCashFlowTarget 首次催费现金流目标 (number query )
     * gmvTarget gmv目标 (number query )
     * id  (integer query )
     * month 月 (string query )
     * newCashFlowTarget 新生现金流目标 (number query )
     * otherOldCashFlowTarget 后续催费现金流目标 (number query )
     * showParent 是否展示上级部门 (string query )
     * siteTypes 学校类型 (string query )
     * startTime 开始时间 (string query )
     * year 年 (string query )
     */
    post: (p, c) => $.post("/api/bms/statistics/departmentEnrollTarget", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) =>
      $.delete("/api/bms/statistics/departmentEnrollTarget", p, c),
  },
  departmentEnrollTarget_export: {
    /*
     * 导出
     */
    get: (p, c) =>
      $.get("/api/bms/statistics/departmentEnrollTarget/export", p, c),
  },
  departmentEnrollTarget_finishDataBySiteTypes: {
    /*
     * 根据学校类型获取统计数据
     * month 月 (string query required)
     * siteTypes 学校类型 (string query required)
     * year 年 (string query required)
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/departmentEnrollTarget/finishDataBySiteTypes",
        p,
        c
      ),
  },
  departmentEnrollTarget_list: {
    /*
     * 列表
     */
    get: (p, c) =>
      $.get("/api/bms/statistics/departmentEnrollTarget/list", p, c),
  },
  departmentEnrollTarget_listSiteTypes: {
    /*
     * 学校类型列表
     */
    get: (p, c) =>
      $.get("/api/bms/statistics/departmentEnrollTarget/listSiteTypes", p, c),
  },
  departmentEnrollTarget_upload: {
    /*
     * 导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) =>
      $.post("/api/bms/statistics/departmentEnrollTarget/upload", p, c),
  },

  /**
   * 员工归档
   */
  employeeFiling: {
    /*
     * 添加
     * adminId 员工id (integer query )
     * biDepartmentId bi部门id (integer query )
     * id  (integer query )
     * month 月份 (string query )
     * quarter 季度 (string query )
     * year 年 (string query )
     */
    post: (p, c) => $.post("/api/bms/statistics/employeeFiling", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/bms/statistics/employeeFiling", p, c),
  },
  employeeFiling_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/bms/statistics/employeeFiling/export", p, c),
  },
  employeeFiling_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/bms/statistics/employeeFiling/list", p, c),
  },
  employeeFiling_listMonth: {
    /*
     * 月列表
     */
    get: (p, c) => $.get("/api/bms/statistics/employeeFiling/listMonth", p, c),
  },
  employeeFiling_listQuarter: {
    /*
     * 季度列表
     */
    get: (p, c) =>
      $.get("/api/bms/statistics/employeeFiling/listQuarter", p, c),
  },
  employeeFiling_listYear: {
    /*
     * 年列表
     */
    get: (p, c) => $.get("/api/bms/statistics/employeeFiling/listYear", p, c),
  },
  employeeFiling_upload: {
    /*
     * 批量导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/bms/statistics/employeeFiling/upload", p, c),
  },

  /**
   * 招生目标
   */
  enrollTarget: {
    /*
     * 编辑
     * clueTarget 线索目标 (integer query )
     * countTarget 单量目标 (integer query )
     * departmentId 部门 (integer query )
     * endTime 结束时间 (string query )
     * firstOldCashFlowTarget 首次催费现金流目标 (number query )
     * gmvTarget gmv目标 (number query )
     * id  (integer query )
     * newCashFlowTarget 新生现金流目标 (number query )
     * otherOldCashFlowTarget 后续催费现金流目标 (number query )
     * saleAmountTarget 销售额目标 (number query )
     * startTime 开始时间 (string query )
     * targetDepartmentId 目标部门 (integer query )
     * timeContent 月、周 (integer query )
     * timeType 月、周 (string query )
     * year 年 (string query )
     */
    put: (p, c) => $.put("/api/bms/statistics/enrollTarget", p, c),
  },
  enrollTarget_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/bms/statistics/enrollTarget/export", p, c),
  },
  enrollTarget_exportSub: {
    /*
     * 子列表导出
     */
    get: (p, c) => $.get("/api/bms/statistics/enrollTarget/exportSub", p, c),
  },
  enrollTarget_init: {
    /*
     * 初始化目标
     * type 类型 (string query required)
     * year 年 (string query required)
     */
    post: (p, c) => $.post("/api/bms/statistics/enrollTarget/init", p, c),
  },
  enrollTarget_initSubForMonth: {
    /*
     * 初始化月子目标
     * month 月 (integer query required)
     * targetDepartmentId 目标部门 (integer query required)
     * year 年 (string query required)
     */
    post: (p, c) =>
      $.post("/api/bms/statistics/enrollTarget/initSubForMonth", p, c),
  },
  enrollTarget_initSubForWeek: {
    /*
     * 初始化周子目标
     * targetDepartmentId 目标部门 (integer query required)
     * weekType 周：上周、本周、下周 (string query required)
     */
    post: (p, c) =>
      $.post("/api/bms/statistics/enrollTarget/initSubForWeek", p, c),
  },
  enrollTarget_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/bms/statistics/enrollTarget/list", p, c),
  },
  enrollTarget_listSub: {
    /*
     * 子列表
     */
    get: (p, c) => $.get("/api/bms/statistics/enrollTarget/listSub", p, c),
  },
  enrollTarget_upload: {
    /*
     * 批量导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/bms/statistics/enrollTarget/upload", p, c),
  },
  enrollTarget_uploadSub: {
    /*
     * 子导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/bms/statistics/enrollTarget/uploadSub", p, c),
  },

  /**
   * 招生目标部门
   */
  enrollTargetDepartment: {
    /*
     * 添加
     * adminId 负责人 (integer query )
     * id  (integer query )
     * name 名称 (string query )
     * sort 排序 (integer query )
     * subName 子部门名称 (string query )
     * type 类型：成教、自考、继教院、大营销中心 (string query )
     */
    post: (p, c) => $.post("/api/bms/statistics/enrollTargetDepartment", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) =>
      $.delete("/api/bms/statistics/enrollTargetDepartment", p, c),
  },
  enrollTargetDepartment_list: {
    /*
     * 列表
     */
    get: (p, c) =>
      $.get("/api/bms/statistics/enrollTargetDepartment/list", p, c),
  },
  enrollTargetDepartment_listName: {
    /*
     * 名称列表
     * type 类型 (string query required)
     */
    get: (p, c) =>
      $.get("/api/bms/statistics/enrollTargetDepartment/listName", p, c),
  },
  enrollTargetDepartment_listNameForDepartmentAdmin: {
    /*
     * 部门负责人的简易列表
     * type 类型 (string query required)
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/enrollTargetDepartment/listNameForDepartmentAdmin",
        p,
        c
      ),
  },
  enrollTargetDepartment_listSelectDepartmentIds: {
    /*
     * 根据统计部门id获取已选择的部门ids
     * id id (integer query required)
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/enrollTargetDepartment/listSelectDepartmentIds",
        p,
        c
      ),
  },
  enrollTargetDepartment_listType: {
    /*
     * 根据管理员获取类型
     */
    get: (p, c) =>
      $.get("/api/bms/statistics/enrollTargetDepartment/listType", p, c),
  },
  enrollTargetDepartment_listTypeForDepartmentAdmin: {
    /*
     * 部门负责人获取类型列表
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/enrollTargetDepartment/listTypeForDepartmentAdmin",
        p,
        c
      ),
  },
  enrollTargetDepartment_refreshOther: {
    /*
     * 更新其他部门
     * type 类型 (string query required)
     */
    put: (p, c) =>
      $.put("/api/bms/statistics/enrollTargetDepartment/refreshOther", p, c),
  },
  enrollTargetDepartment_updateDepartmentIds: {
    /*
     * 更新所属部门id
     * departmentIds 部门id (array query required)
     * id id (integer query required)
     */
    put: (p, c) =>
      $.put(
        "/api/bms/statistics/enrollTargetDepartment/updateDepartmentIds",
        p,
        c
      ),
  },

  /**
   * 人效目标
   */
  humanEffectivenessTarget: {
    /*
     * 添加
     * allAdmin 总人数 (integer query )
     * allIncome 总营收 (number query )
     * businessAdmin 业务人数 (integer query )
     * id  (integer query )
     * month 月份 (string query )
     * netSaleAdmin 网销人数 (integer query )
     * netSaleIncome 网销营收 (number query )
     * phoneSaleAdmin 电销人数 (integer query )
     * phoneSaleIncome 电销营收 (number query )
     * quarter 季度 (string query )
     * selfAdmin 自考人数 (integer query )
     * selfIncome 自考营收 (number query )
     * serviceAdmin 服务人数 (integer query )
     * serviceIncome 服务营收 (number query )
     * year 年 (string query )
     */
    post: (p, c) =>
      $.post("/api/bms/statistics/humanEffectivenessTarget", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) =>
      $.delete("/api/bms/statistics/humanEffectivenessTarget", p, c),
  },
  humanEffectivenessTarget_export: {
    /*
     * 导出
     */
    get: (p, c) =>
      $.get("/api/bms/statistics/humanEffectivenessTarget/export", p, c),
  },
  humanEffectivenessTarget_list: {
    /*
     * 列表
     */
    get: (p, c) =>
      $.get("/api/bms/statistics/humanEffectivenessTarget/list", p, c),
  },
  humanEffectivenessTarget_listMonth: {
    /*
     * 月列表
     */
    get: (p, c) =>
      $.get("/api/bms/statistics/humanEffectivenessTarget/listMonth", p, c),
  },
  humanEffectivenessTarget_listQuarter: {
    /*
     * 季度列表
     */
    get: (p, c) =>
      $.get("/api/bms/statistics/humanEffectivenessTarget/listQuarter", p, c),
  },
  humanEffectivenessTarget_listYear: {
    /*
     * 年列表
     */
    get: (p, c) =>
      $.get("/api/bms/statistics/humanEffectivenessTarget/listYear", p, c),
  },
  humanEffectivenessTarget_upload: {
    /*
     * 批量导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) =>
      $.post("/api/bms/statistics/humanEffectivenessTarget/upload", p, c),
  },

  /**
   * 继教院推广首页
   */
  promote_listGlobalStatsDeptEnrollData: {
    /*
     * 整体数据
     * batch 批次 (string query )
     * classificationId 分类id (integer query required)
     * end 结束时间 (string query )
     * start 开始时间 (string query )
     * timeType 时间类型 (string query )
     */
    get: (p, c) =>
      $.get("/api/bms/statistics/promote/listGlobalStatsDeptEnrollData", p, c),
  },
  promote_listLineChartData: {
    /*
     * 折线图
     * classificationId 分类id (integer query required)
     * monthOrYearSelected 类型：year或month (string query required)
     * province 省份 (string query )
     * searchType 类型：全部、1月、2月。。。 (string query )
     * siteId 学校 (integer query )
     */
    get: (p, c) => $.get("/api/bms/statistics/promote/listLineChartData", p, c),
  },
  promote_listSiteEnrollData: {
    /*
     * 学校招生数据
     * batch 批次 (string query )
     * classificationId 分类id (integer query required)
     * end 结束时间 (string query )
     * province 省份 (string query )
     * start 开始时间 (string query )
     * timeType 时间类型 (string query )
     */
    get: (p, c) =>
      $.get("/api/bms/statistics/promote/listSiteEnrollData", p, c),
  },

  /**
   * 统计分类
   */
  statisticsClassification: {
    /*
     * 添加
     * ableStatus 启用状态 (string query )
     * adminIdList 名单id (string query )
     * hasOther 是否含其他部门 (string query )
     * hasWithoutPeer 是否有不包含同行 (string query )
     * id  (integer query )
     * listType 名单类型：白名单，黑名单 (string query )
     * name 名称 (string query )
     * platform 平台 (string query )
     * showName 显示名称 (string query )
     * showSubName 显示子名称 (string query )
     * siteTypes 学校类型，用逗号隔开 (string query )
     * sort 排序 (integer query )
     * superAdminIdList 可以查看所有数据名单id (string query )
     * type 类型：部门业绩，自定义 (string query )
     */
    post: (p, c) =>
      $.post("/api/bms/statistics/statisticsClassification", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) =>
      $.delete("/api/bms/statistics/statisticsClassification", p, c),
  },
  statisticsClassification_exportUnfinishedFirstFollowFee: {
    /*
     * 统计部门首次催费未收列表
     * adminId 招生老师id (integer query )
     * batch 批次 (string query required)
     * classificationId 分类id (integer query required)
     * deptId 部门id (integer query )
     * siteType 学校类型 (string query required)
     * statsDeptId 统计部门id (integer query )
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/statisticsClassification/exportUnfinishedFirstFollowFee",
        p,
        c
      ),
  },
  statisticsClassification_exportUnfinishedFollowFee: {
    /*
     * 统计部门后续催费未收列表导出
     * adminId 招生老师id (integer query )
     * classificationId 分类id (integer query required)
     * deptId 部门id (integer query )
     * feeType 缴费类型 (string query required)
     * rollGrade 学籍年级 (string query )
     * siteType 学校类型 (string query )
     * statsDeptId 统计部门id (integer query )
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/statisticsClassification/exportUnfinishedFollowFee",
        p,
        c
      ),
  },
  statisticsClassification_getAdminIds: {
    /*
     * 获取名单id
     * id id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/bms/statistics/statisticsClassification/getAdminIds", p, c),
  },
  statisticsClassification_getLeveInfo: {
    /*
     * 获得层级信息
     * id id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/bms/statistics/statisticsClassification/getLeveInfo", p, c),
  },
  statisticsClassification_getSuperAdminIds: {
    /*
     * 获取超级名单id
     * id id (integer query required)
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/statisticsClassification/getSuperAdminIds",
        p,
        c
      ),
  },
  statisticsClassification_list: {
    /*
     * 列表
     */
    get: (p, c) =>
      $.get("/api/bms/statistics/statisticsClassification/list", p, c),
  },
  statisticsClassification_listByAdminId: {
    /*
     * 根据登陆管理员获取可以查看的统计分类
     * platform 平台 (string query )
     * showName 显示名字 (string query )
     */
    get: (p, c) =>
      $.get("/api/bms/statistics/statisticsClassification/listByAdminId", p, c),
  },
  statisticsClassification_listDepartmentAdmissionsRanking: {
    /*
     * 部门排行榜
     * classificationId 分类id (integer query required)
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/statisticsClassification/listDepartmentAdmissionsRanking",
        p,
        c
      ),
  },
  statisticsClassification_listGlobalStatsDeptCashFlow: {
    /*
     * 整体统计部门现金流
     * classificationId 分类id (integer query required)
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/statisticsClassification/listGlobalStatsDeptCashFlow",
        p,
        c
      ),
  },
  statisticsClassification_listGlobalStatsDeptEnrollData: {
    /*
     * 整体统计部门招生数据
     * classificationId 分类id (integer query required)
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/statisticsClassification/listGlobalStatsDeptEnrollData",
        p,
        c
      ),
  },
  statisticsClassification_listGlobalStatsDeptGmv: {
    /*
     * 整体统计部门gmv
     * classificationId 分类id (integer query required)
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/statisticsClassification/listGlobalStatsDeptGmv",
        p,
        c
      ),
  },
  statisticsClassification_listIndividualAdmissionsRanking: {
    /*
     * 个人排行榜
     * classificationId 分类id (integer query required)
     * limitNum 最大显示数量 (integer query required)
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/statisticsClassification/listIndividualAdmissionsRanking",
        p,
        c
      ),
  },
  statisticsClassification_listLineChartData: {
    /*
     * 折线图
     * classificationId 分类id (integer query required)
     * departmentId 部门id (integer query )
     * statsDeptId 统计部门id (integer query )
     * type 类型：全部、1月、2月。。。 (string query required)
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/statisticsClassification/listLineChartData",
        p,
        c
      ),
  },
  statisticsClassification_listLineChartDataGmv: {
    /*
     * 折线图gmv数据
     * classificationId 分类id (integer query required)
     * departmentId 部门id (integer query )
     * statsDeptId 统计部门id (integer query )
     * type 类型：全部、1月、2月。。。 (string query required)
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/statisticsClassification/listLineChartDataGmv",
        p,
        c
      ),
  },
  statisticsClassification_listSiteHeaderTeacher: {
    /*
     * 班主任带班人数
     * rollGrade 学籍年级 (string query )
     * schoolStatuses 学籍状态 (array query )
     * site 学校 (string query required)
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/statisticsClassification/listSiteHeaderTeacher",
        p,
        c
      ),
  },
  statisticsClassification_listSiteStudentForEducation: {
    /*
     * 学校各状态人数(教务)
     * businessId 事业部id (integer query )
     * rollGrade 学籍年级 (string query )
     * schoolStatuses 学籍状态 (array query )
     * siteType 学校类型 (string query )
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/statisticsClassification/listSiteStudentForEducation",
        p,
        c
      ),
  },
  statisticsClassification_listSiteStudentForService: {
    /*
     * 学校各状态人数(服务)
     * businessId 事业部id (integer query )
     * rollGrade 学籍年级 (string query )
     * schoolStatuses 学籍状态 (array query )
     * siteType 学校类型 (string query )
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/statisticsClassification/listSiteStudentForService",
        p,
        c
      ),
  },
  statisticsClassification_listStatsDeptCashFlow: {
    /*
     * 统计部门现金流数据
     * classificationId 分类id (integer query required)
     * end 结束时间 (string query )
     * start 开始时间 (string query )
     * statsDeptId 统计部门id (integer query )
     * timeType 时间类型 (string query )
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/statisticsClassification/listStatsDeptCashFlow",
        p,
        c
      ),
  },
  statisticsClassification_listStatsDeptEnrollData: {
    /*
     * 统计部门招生数据
     * classificationId 分类id (integer query required)
     * end 结束时间 (string query )
     * start 开始时间 (string query )
     * statsDeptId 统计部门id (integer query )
     * timeType 时间类型 (string query )
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/statisticsClassification/listStatsDeptEnrollData",
        p,
        c
      ),
  },
  statisticsClassification_listStatsDeptFirstFollowFee: {
    /*
     * 统计部门首次催费数据
     * batch 批次 (string query required)
     * classificationId 分类id (integer query required)
     * siteType 学校类型 (string query required)
     * statsDeptId 统计部门id (integer query )
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/statisticsClassification/listStatsDeptFirstFollowFee",
        p,
        c
      ),
  },
  statisticsClassification_listStatsDeptFollowFee: {
    /*
     * 统计部门后续催费数据
     * classificationId 分类id (integer query required)
     * feeType 催费类型 (string query required)
     * rollGrade 学籍年级 (string query )
     * siteType 学校类型 (string query )
     * statsDeptId 统计部门id (integer query )
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/statisticsClassification/listStatsDeptFollowFee",
        p,
        c
      ),
  },
  statisticsClassification_listStatsDeptGmv: {
    /*
     * 统计部门gmv数据
     * classificationId 分类id (integer query required)
     * end 结束时间 (string query )
     * start 开始时间 (string query )
     * statsDeptId 统计部门id (integer query )
     * timeType 时间类型 (string query )
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/statisticsClassification/listStatsDeptGmv",
        p,
        c
      ),
  },
  statisticsClassification_listTeacherEnrollData: {
    /*
     * 统计部门招生数据(招生老师)
     * classificationId 分类id (integer query required)
     * departmentId 部门id (integer query )
     * end 结束时间 (string query )
     * start 开始时间 (string query )
     * statsDeptId 统计部门id (integer query )
     * timeType 时间类型 (string query )
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/statisticsClassification/listTeacherEnrollData",
        p,
        c
      ),
  },
  statisticsClassification_listTeacherFirstFollowFee: {
    /*
     * 统计部门首次催费数据(合同负责人)
     * batch 批次 (string query required)
     * classificationId 分类id (integer query required)
     * departmentId 部门id (integer query )
     * siteType 学校类型 (string query required)
     * statsDeptId 统计部门id (integer query )
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/statisticsClassification/listTeacherFirstFollowFee",
        p,
        c
      ),
  },
  statisticsClassification_listTeacherFollowFee: {
    /*
     * 统计部门后续催费数据(班主任)
     * classificationId 分类id (integer query required)
     * departmentId 部门id (integer query )
     * feeType 催费类型 (string query required)
     * rollGrade 学籍年级 (string query )
     * siteType 学籍年级 (string query )
     * statsDeptId 统计部门id (integer query )
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/statisticsClassification/listTeacherFollowFee",
        p,
        c
      ),
  },
  statisticsClassification_listTeacherGmv: {
    /*
     * 统计部门gmv(招生老师)
     * classificationId 分类id (integer query required)
     * departmentId 部门id (integer query )
     * end 结束时间 (string query )
     * start 开始时间 (string query )
     * statsDeptId 统计部门id (integer query )
     * timeType 时间类型 (string query )
     */
    get: (p, c) =>
      $.get(
        "/api/bms/statistics/statisticsClassification/listTeacherGmv",
        p,
        c
      ),
  },
  statisticsClassification_updateAdminIds: {
    /*
     * 名单编辑
     * adminIds 名单id (array query )
     * id id (integer query required)
     */
    put: (p, c) =>
      $.put(
        "/api/bms/statistics/statisticsClassification/updateAdminIds",
        p,
        c
      ),
  },
  statisticsClassification_updateSuperAdminIds: {
    /*
     * 超级名单编辑
     * adminIds 名单id (array query )
     * id id (integer query required)
     */
    put: (p, c) =>
      $.put(
        "/api/bms/statistics/statisticsClassification/updateSuperAdminIds",
        p,
        c
      ),
  },

  /**
   * 统计部门
   */
  statisticsDepartment: {
    /*
     * 添加
     * adminId 负责人 (integer query )
     * backgroundColor 背景颜色 (string query )
     * classificationId 分类id (integer query )
     * departmentIds 部门id (string query )
     * icon 图标 (string query )
     * id  (integer query )
     * name 名称 (string query )
     * shadeColor 阴影颜色 (string query )
     * sort 排序 (integer query )
     */
    post: (p, c) => $.post("/api/bms/statistics/statisticsDepartment", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) =>
      $.delete("/api/bms/statistics/statisticsDepartment", p, c),
  },
  statisticsDepartment_getDepartmentIds: {
    /*
     * 获取部门id
     * id id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/bms/statistics/statisticsDepartment/getDepartmentIds", p, c),
  },
  statisticsDepartment_listByClass: {
    /*
     * 列表
     * classificationId 分类id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/bms/statistics/statisticsDepartment/listByClass", p, c),
  },
  statisticsDepartment_listStatsDept: {
    /*
     * 根据分类获取统计部门列表
     * classificationId 分类id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/bms/statistics/statisticsDepartment/listStatsDept", p, c),
  },
  statisticsDepartment_listSysDept: {
    /*
     * 根据统计部门id获取系统部门列表
     * id 统计部门id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/bms/statistics/statisticsDepartment/listSysDept", p, c),
  },
  statisticsDepartment_updateDepartmentId: {
    /*
     * 名单编辑
     * departmentIds 部门id (array query )
     * id id (integer query required)
     */
    put: (p, c) =>
      $.put(
        "/api/bms/statistics/statisticsDepartment/updateDepartmentId",
        p,
        c
      ),
  },

  /**
   * 统计子类
   */
  statisticsSubclass: {
    /*
     * 添加
     * classificationId 分类id (integer query )
     * id  (integer query )
     * name 名称 (string query )
     * siteTypes 学校类型，用逗号隔开 (string query )
     * sort 排序 (integer query )
     */
    post: (p, c) => $.post("/api/bms/statistics/statisticsSubclass", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/bms/statistics/statisticsSubclass", p, c),
  },
  statisticsSubclass_listByClass: {
    /*
     * 列表
     * classificationId 分类id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/bms/statistics/statisticsSubclass/listByClass", p, c),
  },
};
