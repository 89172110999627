import { $ } from "@/common/Axios";

export default {
  /**
   * 成考学习报告
   */
  adultExamStudyReport_adultExamStudyReport: {
    /*
     * 成考宝典学习报告
     * courseId 课程id (integer query )
     */
    get: (p, c) =>
      $.get("/api/sms/study/adultExamStudyReport/adultExamStudyReport", p, c),
  },
  adultExamStudyReport_refresh: {
    /*
     * 成考宝典学习报告
     */
    post: (p, c) => $.post("/api/sms/study/adultExamStudyReport/refresh", p, c),
  },

  /**
   * 收藏
   */
  collect: {
    /*
     * 收藏|取消收藏
     * contentId 内容id (integer query required)
     * type 类型：知识点，习题 (string query required)
     */
    post: (p, c) => $.post("/api/sms/study/collect", p, c),
  },
  collect_listCollectCourseKnowledge: {
    /*
     * 课程(知识点)收藏列表
     * courseType 课程类型:成教，成考，专升本 (string query required)
     */
    get: (p, c) =>
      $.get("/api/sms/study/collect/listCollectCourseKnowledge", p, c),
  },
  collect_listCollectCourseSubject: {
    /*
     * 课程(习题)收藏列表
     * courseType 课程类型:成教，成考，专升本 (string query required)
     */
    get: (p, c) =>
      $.get("/api/sms/study/collect/listCollectCourseSubject", p, c),
  },
  collect_listCollectKnowledge: {
    /*
     * 知识点收藏列表
     * courseId 课程id (integer query required)
     */
    get: (p, c) => $.get("/api/sms/study/collect/listCollectKnowledge", p, c),
  },
  collect_listCollectPaper: {
    /*
     * 试卷收藏列表
     * courseId 课程id (integer query required)
     * type 类型: 历年真题,模拟测试、顺序练习、顺序练习-组卷 (string query required)
     */
    get: (p, c) => $.get("/api/sms/study/collect/listCollectPaper", p, c),
  },
  collect_listCollectSubject: {
    /*
     * 习题收藏列表
     * belongId 归属id (integer query required)
     * type 类型:直播课课后练习,章节练习,历年真题,模拟测试 (string query required)
     */
    get: (p, c) => $.get("/api/sms/study/collect/listCollectSubject", p, c),
  },
  collect_listKnowledgeForSelf: {
    /*
     * 自考习题题收藏列表（知识点）
     * course 课程 (string query required)
     */
    get: (p, c) => $.get("/api/sms/study/collect/listKnowledgeForSelf", p, c),
  },
  collect_listSubjectForSelf: {
    /*
     * 自考习题收藏列表（习题）
     * knowledgeId 知识点id (integer query required)
     */
    get: (p, c) => $.get("/api/sms/study/collect/listSubjectForSelf", p, c),
  },

  /**
   * 学生课程点击情况
   */
  courseClick: {
    /*
     * 添加
     * courseId 课程id (integer query )
     * id  (integer query )
     * ip ip地址 (string query )
     */
    post: (p, c) => $.post("/api/sms/study/courseClick", p, c),
  },
  courseClick_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/sms/study/courseClick/export", p, c),
  },
  courseClick_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/sms/study/courseClick/list", p, c),
  },

  /**
   * 评价
   */
  discuss_discuss: {
    /*
     * 评价
     * content 评价详情 (string query )
     * contentId 内容id (integer query )
     * id  (integer query )
     * star 星级1-5星 (number query )
     * type 评价内容类型：线上课程，直播课程 (string query )
     * userId 用户id (integer query )
     */
    post: (p, c) => $.post("/api/sms/study/discuss/discuss", p, c),
  },
  discuss_listForClient: {
    /*
     * 评价列表
     * contentId 评价内容id (integer query required)
     * type 评价内容:线上课程，直播课程 (string query required)
     */
    get: (p, c) => $.get("/api/sms/study/discuss/listForClient", p, c),
  },

  /**
   * 入学测试
   */
  entranceTest: {
    /*
     * 添加
     * ableStatus 启用状态 (string query )
     * content 内容 (string query )
     * id  (integer query )
     * level 层次，多个层次逗号隔开 (string query )
     * title 标题 (string query )
     * type 类型:性格测试、逻辑测试 (string query )
     */
    post: (p, c) => $.post("/api/sms/study/entranceTest", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/sms/study/entranceTest", p, c),
  },
  entranceTest_details: {
    /*
     * 详情
     * schoolRollId 合同id (integer query required)
     * testId 测试id (integer query required)
     */
    get: (p, c) => $.get("/api/sms/study/entranceTest/details", p, c),
  },
  entranceTest_list: {
    /*
     * 入学测试列表
     */
    get: (p, c) => $.get("/api/sms/study/entranceTest/list", p, c),
  },
  entranceTest_listForClient: {
    /*
     * 入学测试C端列表
     * level 层次 (string query required)
     * schoolRollId 合同id (integer query required)
     */
    get: (p, c) => $.get("/api/sms/study/entranceTest/listForClient", p, c),
  },
  entranceTest_listSimple: {
    /*
     * 入学测试简易列表
     */
    get: (p, c) => $.get("/api/sms/study/entranceTest/listSimple", p, c),
  },

  /**
   * 入学测试题目
   */
  entranceTestSubject: {
    /*
     * 添加
     * answer 正确答案 (string query )
     * answerA 答案A (string query )
     * answerB 答案B (string query )
     * answerC 答案C (string query )
     * answerD 答案D (string query )
     * answerE 答案E (string query )
     * content 题目 (string query )
     * id  (integer query )
     * score 分值 (integer query )
     * sort 习题序号 (integer query )
     * testId 测试id (integer query )
     */
    post: (p, c) => $.post("/api/sms/study/entranceTestSubject", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/sms/study/entranceTestSubject", p, c),
  },
  entranceTestSubject_export: {
    /*
     * 导出
     * testId 测试id (integer query required)
     */
    get: (p, c) => $.get("/api/sms/study/entranceTestSubject/export", p, c),
  },
  entranceTestSubject_list: {
    /*
     * 列表
     * testId 测试id (integer query required)
     */
    get: (p, c) => $.get("/api/sms/study/entranceTestSubject/list", p, c),
  },
  entranceTestSubject_upload: {
    /*
     * 导入
     * excelFile excelFile (file formData required)
     * testId 测试id (integer query required)
     */
    post: (p, c) => $.post("/api/sms/study/entranceTestSubject/upload", p, c),
  },

  /**
   * 加入
   */
  join: {
    /*
     * 取消加入
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/sms/study/join", p, c),
  },
  join_batchJoinOnlineCourse: {
    /*
     * 批量加入(会删掉之前加入的)
     * contentIds 课程id (array query required)
     * courseType 课程类型：成教，成考，专升本 (string query required)
     */
    post: (p, c) => $.post("/api/sms/study/join/batchJoinOnlineCourse", p, c),
  },
  join_join: {
    /*
     * 加入（免费加入）
     * contentId 内容id (integer query )
     * id  (integer query )
     * type 类型:产品,线上课程,直播课程,线下课程 (string query )
     * userId 用户id (integer query )
     */
    post: (p, c) => $.post("/api/sms/study/join/join", p, c),
  },
  join_listStudentLiveCourseForClient: {
    /*
     * 加入的直播课程列表
     * courseType 课程类型：成教，成考，专升本 (string query required)
     * type 直播课类型：公开课 (string query required)
     */
    get: (p, c) =>
      $.get("/api/sms/study/join/listStudentLiveCourseForClient", p, c),
  },
  join_listStudentOnlineCourseForClient: {
    /*
     * 加入的线上课程列表
     * courseType 课程类型：成教，成考，专升本 (string query required)
     */
    get: (p, c) =>
      $.get("/api/sms/study/join/listStudentOnlineCourseForClient", p, c),
  },

  /**
   * 自考学生答题记录
   */
  selfStudentSubject_handSubject: {
    /*
     * 交卷(返回练习id)
     * answers 答案 (array query required)
     * belongId 试卷或知识点id或直播课章节id (integer query required)
     * courseId 课程id (integer query required)
     * exerciseTime 练习时间（秒） (integer query required)
     * latestExerciseId 只有当继续练习的时候，把这个传给我 (integer query )
     * subjectIds 题目id (array query required)
     * type 类型：自考章节练习 (string query required)
     */
    post: (p, c) =>
      $.post("/api/sms/study/selfStudentSubject/handSubject", p, c),
  },

  /**
   * 入学测试学生答题
   */
  studentEntranceTestSubject_exportForEnroll: {
    /*
     * 入学测试学生答题情况导出(招生端)
     */
    get: (p, c) =>
      $.get("/api/sms/study/studentEntranceTestSubject/exportForEnroll", p, c),
  },
  studentEntranceTestSubject_exportForSite: {
    /*
     * 入学测试学生答题情况导出(后端)
     */
    get: (p, c) =>
      $.get("/api/sms/study/studentEntranceTestSubject/exportForSite", p, c),
  },
  studentEntranceTestSubject_handSubject: {
    /*
     * 交卷
     * answers 答案 (array query required)
     * schoolRollId 合同id (integer query required)
     * subjectIds 题目id (array query required)
     * testId 测试 (integer query required)
     */
    post: (p, c) =>
      $.post("/api/sms/study/studentEntranceTestSubject/handSubject", p, c),
  },
  studentEntranceTestSubject_listForEnroll: {
    /*
     * 入学测试学生答题情况列表(招生端)
     */
    get: (p, c) =>
      $.get("/api/sms/study/studentEntranceTestSubject/listForEnroll", p, c),
  },
  studentEntranceTestSubject_listForSite: {
    /*
     * 入学测试学生答题情况列表(后端)
     */
    get: (p, c) =>
      $.get("/api/sms/study/studentEntranceTestSubject/listForSite", p, c),
  },

  /**
   * 期末考试学生
   */
  studentExam_assignOverAdmin: {
    /*
     * 分配阅卷老师
     * ids 行ID (array query required)
     * overAdminId 阅卷老师ID (integer query required)
     */
    post: (p, c) => $.post("/api/sms/study/studentExam/assignOverAdmin", p, c),
  },
  studentExam_clearExam: {
    /*
     * 打回重考
     * studentExamId 考试成员列表返回的ID (integer query required)
     */
    post: (p, c) => $.post("/api/sms/study/studentExam/clearExam", p, c),
  },
  studentExam_detailsForClient: {
    /*
     * 考生-根据考试列表id获取题目（已考完则获取包含答案）
     * id 考试列表id (integer query required)
     */
    get: (p, c) => $.get("/api/sms/study/studentExam/detailsForClient", p, c),
  },
  studentExam_downloadMould: {
    /*
     * 下载考试学生模版
     */
    get: (p, c) => $.get("/api/sms/study/studentExam/downloadMould", p, c),
  },
  studentExam_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/sms/study/studentExam/export", p, c),
  },
  studentExam_exportDetail: {
    /*
     * 导出学生答题记录
     * courseName 课程名称,下拉 (string query required)
     * majorId 专业ID (integer query )
     */
    get: (p, c) => $.get("/api/sms/study/studentExam/exportDetail", p, c),
  },
  studentExam_exportOverPaper: {
    /*
     * 导出期末考试阅卷
     */
    get: (p, c) => $.get("/api/sms/study/studentExam/exportOverPaper", p, c),
  },
  studentExam_getPaperDetailVO: {
    /*
     * 查看学生试卷详情
     * examId 考试ID (integer query required)
     * schoolRollId 学籍ID (integer query required)
     */
    get: (p, c) => $.get("/api/sms/study/studentExam/getPaperDetailVO", p, c),
  },
  studentExam_list: {
    /*
     * 期末考试学生列表
     */
    get: (p, c) => $.get("/api/sms/study/studentExam/list", p, c),
  },
  studentExam_listCourse: {
    /*
     * 考试课程下拉列表
     */
    get: (p, c) => $.get("/api/sms/study/studentExam/listCourse", p, c),
  },
  studentExam_listExamStudent: {
    /*
     * 查询简单已完成|未完成参考人员
     * examId 考试ID (integer query required)
     * finishStatus 未完成|已完成 (string query required)
     */
    get: (p, c) => $.get("/api/sms/study/studentExam/listExamStudent", p, c),
  },
  studentExam_listForClient: {
    /*
     * C端我的考试列表|考试记录
     * category 考试模式（普通模式|答题卡模式） (string query )
     * finishStatus 完成状态（已完成|未完成） (string query )
     */
    get: (p, c) => $.get("/api/sms/study/studentExam/listForClient", p, c),
  },
  studentExam_listForOverPaper: {
    /*
     * 期末考试阅卷列表
     */
    get: (p, c) => $.get("/api/sms/study/studentExam/listForOverPaper", p, c),
  },
  studentExam_overPaper: {
    /*
     * 阅卷，提交结果
     * id 试卷详情返回的id，studentExamId (integer query required)
     * scores exerciseId和分值的键值对列表[id,name] (string query required)
     */
    post: (p, c) => $.post("/api/sms/study/studentExam/overPaper", p, c),
  },
  studentExam_refreshScore: {
    /*
     * 刷新成绩
     * examId examId (integer query )
     * finishTime 完成时间 (string query )
     */
    post: (p, c) => $.post("/api/sms/study/studentExam/refreshScore", p, c),
  },
  studentExam_refusePaper: {
    /*
     * 打回重新阅卷
     * ids studentExamId (array query required)
     */
    post: (p, c) => $.post("/api/sms/study/studentExam/refusePaper", p, c),
  },
  studentExam_removeExamMember: {
    /*
     * 删除考试成员
     * examId 考试ID (integer query required)
     * schoolRollIds 成员学籍ID列表 (array query required)
     */
    post: (p, c) => $.post("/api/sms/study/studentExam/removeExamMember", p, c),
  },
  studentExam_saveAnswer: {
    /*
     * 考生-提交考试答案
     * answers exerciseId和答案的键值对列表[id,name] (string query required)
     * id 试卷详情返回的id，studentExamId (integer query required)
     */
    post: (p, c) => $.post("/api/sms/study/studentExam/saveAnswer", p, c),
  },
  studentExam_upload: {
    /*
     * 导入考试学生
     * examId 考试ID (integer query required)
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/sms/study/studentExam/upload", p, c),
  },
  studentExam_uploadAssignOverAdmin: {
    /*
     * 导入分配阅卷老师
     * excelFile excelFile (file formData required)
     */
    post: (p, c) =>
      $.post("/api/sms/study/studentExam/uploadAssignOverAdmin", p, c),
  },
  studentExam_uploadExerciseScore: {
    /*
     * 导入更新学生答题记录
     * excelFile excelFile (file formData required)
     */
    post: (p, c) =>
      $.post("/api/sms/study/studentExam/uploadExerciseScore", p, c),
  },

  /**
   * 学生刷题
   */
  studentExercise_detailForClient: {
    /*
     * 答题记录详情
     * id 答题记录id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/sms/study/studentExercise/detailForClient", p, c),
  },
  studentExercise_listAdultExamAssessmentStudentExercise: {
    /*
     * 成考摸底考试阅卷列表
     */
    get: (p, c) =>
      $.get(
        "/api/sms/study/studentExercise/listAdultExamAssessmentStudentExercise",
        p,
        c
      ),
  },
  studentExercise_listLatestStudentExerciseForClient: {
    /*
     * 学习记录
     * courseId 课程id (integer query )
     * type 类型: 历年真题,模拟测试、顺序练习、顺序练习-组卷 (string query required)
     */
    get: (p, c) =>
      $.get(
        "/api/sms/study/studentExercise/listLatestStudentExerciseForClient",
        p,
        c
      ),
  },
  studentExercise_read: {
    /*
     * 阅卷
     * id 学生试卷id (integer query required)
     * scores 分数 (array query required)
     * studentSubjectIds 学生答题id (array query required)
     */
    put: (p, c) => $.put("/api/sms/study/studentExercise/read", p, c),
  },

  /**
   * 学生知识点
   */
  studentKnowledge_lastLiveCourseChapter: {
    /*
     * 最近学习的直播回放章节
     * liveCourseId 直播课id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/sms/study/studentKnowledge/lastLiveCourseChapter", p, c),
  },
  studentKnowledge_latestCourseId: {
    /*
     * 最近学习的课程id
     * courseType 课程类型：成考，成教 (string query required)
     */
    get: (p, c) =>
      $.get("/api/sms/study/studentKnowledge/latestCourseId", p, c),
  },
  studentKnowledge_listAdultExamQualityForClient: {
    /*
     * 最近学习的精品课章节
     */
    get: (p, c) =>
      $.get(
        "/api/sms/study/studentKnowledge/listAdultExamQualityForClient",
        p,
        c
      ),
  },
  studentKnowledge_progress: {
    /*
     * 修改进度，切换视频（新老视频都调用），返回，播放结束，暂停，HOME等情况调用，学习1分钟
     * knowledgeId 知识点id (integer query required)
     * progress 进度 (integer query required)
     * study 0进度1学习 (integer query required)
     * type 类型：录播课，直播回放 (string query )
     */
    put: (p, c) => $.put("/api/sms/study/studentKnowledge/progress", p, c),
  },
  studentKnowledge_progressForSelfTaught: {
    /*
     * 自考修改进度，切换视频（新老视频都调用），返回，播放结束，暂停，HOME, 拖动 等情况调用
     * knowledgeId 知识点id (integer query required)
     * progress 进度 (integer query required)
     * studyEnd 观看结束点（秒） (integer query required)
     * studyStart 观看开始点（秒） (integer query required)
     * type 类型：自考直播回放，自考精品课 (string query )
     */
    put: (p, c) =>
      $.put("/api/sms/study/studentKnowledge/progressForSelfTaught", p, c),
  },
  studentKnowledge_searchRecordById: {
    /*
     * 根据studentKnowledgeId查询观看记录区间
     * studentKnowledgeId studentKnowledgeId (integer query required)
     */
    get: (p, c) =>
      $.get("/api/sms/study/studentKnowledge/searchRecordById", p, c),
  },

  /**
   * 学生直播学习记录
   */
  studentLiveCourseSchedule_exportStudentAdultExamLive: {
    /*
     * 成考学生直播观看情况导出
     * adultExamYear 成考年度 (string query required)
     * end 直播开始时间：结束 (string query )
     * start 直播开始时间：开始 (string query )
     * timeType 直播开始时间类型 (string query )
     */
    get: (p, c) =>
      $.get(
        "/api/sms/study/studentLiveCourseSchedule/exportStudentAdultExamLive",
        p,
        c
      ),
  },
  studentLiveCourseSchedule_listByStudent: {
    /*
     * 学生直播观看情况
     * course 直播课名字 (string query required)
     * level 直播课层次 (string query required)
     * userId 用户id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/sms/study/studentLiveCourseSchedule/listByStudent", p, c),
  },
  studentLiveCourseSchedule_updatePlaybackProgress: {
    /*
     * 修改回看进度，切换视频，返回，播放结束，暂停，HOME等情况调用
     * duration 回放时长 (integer query required)
     * liveCourseScheduleId 直播课表id (integer query required)
     * progress 进度 (integer query required)
     */
    put: (p, c) =>
      $.put(
        "/api/sms/study/studentLiveCourseSchedule/updatePlaybackProgress",
        p,
        c
      ),
  },
  studentLiveCourseSchedule_watchLive: {
    /*
     * 观看直播
     * liveCourseScheduleId 直播课表id (integer query required)
     */
    put: (p, c) =>
      $.put("/api/sms/study/studentLiveCourseSchedule/watchLive", p, c),
  },

  /**
   * 学生答题记录
   */
  studentSubject_handSubject: {
    /*
     * 交卷(返回练习id)
     * answers 答案 (array query required)
     * belongId 试卷或知识点id或直播课章节id (integer query required)
     * courseId 课程id (integer query required)
     * exerciseTime 练习时间（秒） (integer query required)
     * latestExerciseId 只有当继续练习的时候，把这个传给我 (integer query )
     * subjectIds 题目id (array query required)
     * type 类型：历年真题，模拟测试，摸底考试，章节练习，直播课课后练习 (string query required)
     */
    post: (p, c) => $.post("/api/sms/study/studentSubject/handSubject", p, c),
  },
  studentSubject_studentStudyReport: {
    /*
     * 学习报告
     * courseId 课程id (integer query )
     */
    get: (p, c) =>
      $.get("/api/sms/study/studentSubject/studentStudyReport", p, c),
  },

  /**
   * 错题集
   */
  studentWrongSubject: {
    /*
     * 移除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/sms/study/studentWrongSubject", p, c),
  },
  studentWrongSubject_addForSelf: {
    /*
     * 增加自考错题（每日一练）
     * answers 答案 (array query required)
     * subjectIds 习题id (array query required)
     */
    post: (p, c) =>
      $.post("/api/sms/study/studentWrongSubject/addForSelf", p, c),
  },
  studentWrongSubject_listCollectPaper: {
    /*
     * 错题集试卷列表
     * courseId 课程id (integer query required)
     * type 类型:历年真题,模拟测试,顺序练习，顺序练习-组卷 (string query required)
     */
    get: (p, c) =>
      $.get("/api/sms/study/studentWrongSubject/listCollectPaper", p, c),
  },
  studentWrongSubject_listCollectSubject: {
    /*
     * 错题集习题列表
     * belongId 归属id (integer query required)
     * type 类型:历年真题,模拟测试,顺序练习 (string query required)
     */
    get: (p, c) =>
      $.get("/api/sms/study/studentWrongSubject/listCollectSubject", p, c),
  },
  studentWrongSubject_listKnowledgeForSelf: {
    /*
     * 自考错题集列表（知识点）
     * course 课程 (string query required)
     */
    get: (p, c) =>
      $.get("/api/sms/study/studentWrongSubject/listKnowledgeForSelf", p, c),
  },
  studentWrongSubject_listSubjectForSelf: {
    /*
     * 自考错题集列表（习题）
     * knowledgeId 知识点id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/sms/study/studentWrongSubject/listSubjectForSelf", p, c),
  },
  studentWrongSubject_removeByBelongId: {
    /*
     * 移除
     * belongId 归属id (integer query required)
     * type 类型:章节练习,历年真题,模拟测试,直播课课后练习 (string query required)
     */
    delete: (p, c) =>
      $.delete("/api/sms/study/studentWrongSubject/removeByBelongId", p, c),
  },
};
