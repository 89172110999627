import { $ } from "@/common/Axios";

export default {
  /**
   * 招生线索
   */
  admissions_clue_assign: {
    /*
     * 分配
     * adminId 管理员id (integer query required)
     * ids 线索 id列表 (array query required)
     */
    put: (p, c) => $.put("/api/ums/promote/admissions/clue/assign", p, c),
  },
  admissions_clue_assignForDepartment: {
    /*
     * 分配-部门线索
     * adminId 管理员id (integer query required)
     * ids 线索 id列表 (array query required)
     */
    put: (p, c) =>
      $.put("/api/ums/promote/admissions/clue/assignForDepartment", p, c),
  },
  admissions_clue_beforeCall: {
    /*
     * 话务系统拨号前调用
     * clueId Long (integer query required)
     */
    post: (p, c) => $.post("/api/ums/promote/admissions/clue/beforeCall", p, c),
  },
  admissions_clue_callbackContactRY: {
    /*
     * 融营云话务系统回调
     */
    post: (p, c) =>
      $.post("/api/ums/promote/admissions/clue/callbackContactRY", p, c),
  },
  admissions_clue_callbackContactRYGetBySessionId: {
    /*
     * 融营云话务系统回调测试
     * sessionId sessionId (string query required)
     */
    post: (p, c) =>
      $.post(
        "/api/ums/promote/admissions/clue/callbackContactRYGetBySessionId",
        p,
        c
      ),
  },
  admissions_clue_callbackContactRecord: {
    /*
     * 企峰云话务系统回调
     */
    post: (p, c) =>
      $.post("/api/ums/promote/admissions/clue/callbackContactRecord", p, c),
  },
  admissions_clue_downloadMould: {
    /*
     * 下载模版
     */
    get: (p, c) =>
      $.get("/api/ums/promote/admissions/clue/downloadMould", p, c),
  },
  admissions_clue_draw: {
    /*
     * 领取
     * id 线索 id (integer query required)
     */
    post: (p, c) => $.post("/api/ums/promote/admissions/clue/draw", p, c),
  },
  admissions_clue_drawFromSea: {
    /*
     * 公海领取线索
     * assignEndTime 最近分配结束时间 (string query )
     * assignStartTime 最近分配开始时间 (string query )
     * clueStatus 线索状态 (string query )
     * contactEndTime 最近联系结束时间 (string query )
     * contactStartTime 最近联系开始时间 (string query )
     * createEndTime 线索创建结束时间 (string query )
     * createStartTime 线索创建开始时间 (string query )
     * drawCount 领取数量 (integer query required)
     * province 省份 (string query )
     * type 线索类型 (string query )
     */
    put: (p, c) => $.put("/api/ums/promote/admissions/clue/drawFromSea", p, c),
  },
  admissions_clue_exportRecord: {
    /*
     * 职教联系记录明细表或总表导出
     * endTime 联系记录结束时间 (string query )
     * intentionGrades 下拉意向等级，可多选 (array query )
     * startTime 联系记录开始时间 (string query )
     * type 类型(总表或明细表） (string query required)
     */
    get: (p, c) => $.get("/api/ums/promote/admissions/clue/exportRecord", p, c),
  },
  admissions_clue_getCallPhoneType: {
    /*
     * 根据来源学校查询外呼类型,返回1为企峰云， 2为融营云
     * originSchool 来源学校 (string query )
     */
    get: (p, c) =>
      $.get("/api/ums/promote/admissions/clue/getCallPhoneType", p, c),
  },
  admissions_clue_getSeaDrawMainPage: {
    /*
     * 新公海首页
     * timeType 时间， 仅限当日或昨日 (string query )
     */
    get: (p, c) =>
      $.get("/api/ums/promote/admissions/clue/getSeaDrawMainPage", p, c),
  },
  admissions_clue_list: {
    /*
     * 学历线索列表
     */
    get: (p, c) => $.get("/api/ums/promote/admissions/clue/list", p, c),
  },
  admissions_clue_listContactRecord: {
    /*
     * 联系记录通话录音列表
     */
    get: (p, c) =>
      $.get("/api/ums/promote/admissions/clue/listContactRecord", p, c),
  },
  admissions_clue_listForActivate: {
    /*
     * 个人上报线索列表
     */
    get: (p, c) =>
      $.get("/api/ums/promote/admissions/clue/listForActivate", p, c),
  },
  admissions_clue_listForCreate: {
    /*
     * 部门线索列表
     */
    get: (p, c) =>
      $.get("/api/ums/promote/admissions/clue/listForCreate", p, c),
  },
  admissions_clue_listForGlobal: {
    /*
     * 全局搜索线索列表
     * phone 手机号 (string query )
     * qq qq (string query )
     * wechat wechat (string query )
     */
    get: (p, c) =>
      $.get("/api/ums/promote/admissions/clue/listForGlobal", p, c),
  },
  admissions_clue_listFullTimeSeaForPromote: {
    /*
     * 全日制公海列表-推广模板
     */
    get: (p, c) =>
      $.get("/api/ums/promote/admissions/clue/listFullTimeSeaForPromote", p, c),
  },
  admissions_clue_removeContact: {
    /*
     * 删除未创建联系
     * clueContactId Long (integer query required)
     */
    delete: (p, c) =>
      $.delete("/api/ums/promote/admissions/clue/removeContact", p, c),
  },
  admissions_clue_ryCallOut: {
    /*
     * 融云外呼拨号
     * clueId 线索ID (integer query required)
     * phone 被叫号码 (string query required)
     */
    post: (p, c) => $.post("/api/ums/promote/admissions/clue/ryCallOut", p, c),
  },
  admissions_clue_save: {
    /*
     * 添加线索
     * channel 来源渠道 (string query required)
     * contactDuration 如果上传音频，时长 (integer query )
     * contactRecord 附件，可以上传音频，可以上传图片 (string query )
     * contactWay 联系方式(下拉)：电话，微信，电子邮件,qq,上门，其他 (string query )
     * detail 来源详情 (string query required)
     * intentionGrade 意向等级, 线索状态为有意向时必填 (string query )
     * name 名字 (string query required)
     * originSchool 来源学校 (string query )
     * phone 手机号 (string query )
     * preContactTime 预计下次联系时间 (string query )
     * qq qq (string query )
     * remark 备注 (string query )
     * status 线索状态：1待分配6无效资源 (string query )
     * type 业务类型 成人教育 全日制 落户 (string query required)
     * wechat 微信 (string query )
     */
    post: (p, c) => $.post("/api/ums/promote/admissions/clue/save", p, c),
  },
  admissions_clue_saveContact: {
    /*
     * 添加联系,
     * contactDuration 如果上传音频，时长 (integer query )
     * contactRecord 附件，可以上传音频，可以上传图片 (string query )
     * contactWay 联系方式(下拉)：电话，微信，电子邮件,qq,上门，其他 (string query )
     * id 线索id (integer query required)
     * intentionGrade 意向等级 (string query )
     * levelTime 层次时间 (string query )
     * onlineMeetTime 参加解读会时间 (string query )
     * preContactTime 预计下次联系时间 (string query required)
     * preEnrollTime 预报时间 (string query )
     * remark 备注 (string query )
     * status 本次联系线索状态：：key=添加线索状态 (string query required)
     */
    put: (p, c) => $.put("/api/ums/promote/admissions/clue/saveContact", p, c),
  },
  admissions_clue_searchClueReferralUser: {
    /*
     * 根据线索ID或身份证号查询线索推荐人
     * clueId 线索ID, 线索上报时候查询 (integer query )
     * idCardNo 身份证号, 合同代报时候查询 (string query )
     */
    get: (p, c) =>
      $.get("/api/ums/promote/admissions/clue/searchClueReferralUser", p, c),
  },
  admissions_clue_searchDrawCount: {
    /*
     * 查询公海资源当日已领取数量
     */
    get: (p, c) =>
      $.get("/api/ums/promote/admissions/clue/searchDrawCount", p, c),
  },
  admissions_clue_taskCheckClueStatus: {
    /*
     * 定时任务24小时无效资源
     */
    post: (p, c) =>
      $.post("/api/ums/promote/admissions/clue/taskCheckClueStatus", p, c),
  },
  admissions_clue_taskUploadContactRecord: {
    /*
     * 定时任务-同步录音到七牛
     */
    post: (p, c) =>
      $.post("/api/ums/promote/admissions/clue/taskUploadContactRecord", p, c),
  },
  admissions_clue_updateDetail: {
    /*
     * 编辑详情
     * adminRemark 负责人备注 (string query )
     * city 市 (string query )
     * company 公司 (string query )
     * companyAddress 公司地址 (string query )
     * district 县(区) (string query )
     * email email (string query )
     * goal 报读目的 (string query )
     * graduateMajor 毕业专业 (string query )
     * graduateSchool 毕业院校 (string query )
     * id 线索 id (integer query required)
     * industry 行业 (string query )
     * name 名字 (string query required)
     * phone 手机号 (string query )
     * province 省 (string query )
     * qq qq (string query )
     * station 岗位 (string query )
     * telephone 座机 (string query )
     * wantMajor 意向专业 (string query )
     * wantMajorLevel 意向层次 (string query )
     * wantSite 意向学校 (string query )
     * wechat 微信 (string query )
     */
    put: (p, c) => $.put("/api/ums/promote/admissions/clue/updateDetail", p, c),
  },
  admissions_clue_updateUncreatedContact: {
    /*
     * 编辑联系
     * id 线索联系id (integer query required)
     * intentionGrade 意向等级 (string query )
     * levelTime 层次时间 (string query )
     * onlineMeetTime 参加解读会时间 (string query )
     * preContactTime 预计下次联系时间 (string query required)
     * preEnrollTime 预报时间 (string query )
     * remark 备注 (string query )
     * status 目前状态(key=添加线索状态) (string query required)
     */
    put: (p, c) =>
      $.put("/api/ums/promote/admissions/clue/updateUncreatedContact", p, c),
  },
  admissions_clue_upload: {
    /*
     * 批量上传
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ums/promote/admissions/clue/upload", p, c),
  },

  /**
   * 预约到访
   */
  appointment: {
    /*
     * 删除(状态为未接待可以删除)
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/ums/promote/appointment", p, c),
  },
  appointment_apply: {
    /*
     * 申请
     * receptionistId 接待人id (integer query required)
     * userId 用户id (integer query required)
     */
    post: (p, c) => $.post("/api/ums/promote/appointment/apply", p, c),
  },
  appointment_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ums/promote/appointment/export", p, c),
  },
  appointment_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ums/promote/appointment/list", p, c),
  },
  appointment_listForReception: {
    /*
     * 接待列表
     */
    get: (p, c) => $.get("/api/ums/promote/appointment/listForReception", p, c),
  },
  appointment_listSimple: {
    /*
     * 简易列表
     * userId 用户id (integer query required)
     */
    get: (p, c) => $.get("/api/ums/promote/appointment/listSimple", p, c),
  },
  appointment_reception: {
    /*
     * 接待
     * id id (integer query required)
     * receptionContent 接待人情况 (string query required)
     * receptionTime 接待人时间 (string query required)
     */
    put: (p, c) => $.put("/api/ums/promote/appointment/reception", p, c),
  },
  appointment_update: {
    /*
     * 编辑
     * id id (integer query required)
     * receptionistId 接待人id (integer query required)
     */
    put: (p, c) => $.put("/api/ums/promote/appointment/update", p, c),
  },
  appointment_updateAwardFinishStatus: {
    /*
     * 批量更新奖励发放状态
     * awardFinishStatus 奖励是否已发放：是否 (string query required)
     * ids ids (array query required)
     */
    put: (p, c) =>
      $.put("/api/ums/promote/appointment/updateAwardFinishStatus", p, c),
  },

  /**
   * 后台留言
   */
  backgroundMessage_change: {
    /*
     * 转化
     * city 市 (string query )
     * consultWay 咨询方式 (string query required)
     * district 县(区) (string query )
     * id id (integer query required)
     * keyword 关键字 (string query )
     * originDetail 具体来源(来源渠道为网络营销) (string query )
     * province 省 (string query )
     * remark 备注 (string query )
     * type 业务类型 成人教育 全日制 落户 (string query required)
     */
    put: (p, c) => $.put("/api/ums/promote/backgroundMessage/change", p, c),
  },
  backgroundMessage_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ums/promote/backgroundMessage/list", p, c),
  },
  backgroundMessage_messageForClient: {
    /*
     * c端留言
     * address 地址，包含参数 (string query )
     * age 年龄 (string query )
     * channel 渠道，不填则为网络营销，官网留言填运营营销，其他如成考宝典等 (string query )
     * code 公众号code，不用用户确认，用户关注后获取 (string query )
     * currentLevel 当前学历 (string query )
     * name 名字 (string query )
     * phone 手机号 (string query required)
     * wantLevel 报名学历 (string query )
     */
    post: (p, c) =>
      $.post("/api/ums/promote/backgroundMessage/messageForClient", p, c),
  },
  backgroundMessage_sign: {
    /*
     * 标记
     * id id (integer query required)
     */
    put: (p, c) => $.put("/api/ums/promote/backgroundMessage/sign", p, c),
  },

  /**
   * 职教-资源管理
   */
  careerClue_assign: {
    /*
     * 分配
     * ids ids (array query required)
     * newAdminId 新负责人ID (integer query required)
     */
    post: (p, c) => $.post("/api/ums/promote/careerClue/assign", p, c),
  },
  careerClue_detail: {
    /*
     * 详情
     * id id (integer query required)
     */
    get: (p, c) => $.get("/api/ums/promote/careerClue/detail", p, c),
  },
  careerClue_downloadMould: {
    /*
     * 下载模版
     */
    get: (p, c) => $.get("/api/ums/promote/careerClue/downloadMould", p, c),
  },
  careerClue_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ums/promote/careerClue/export", p, c),
  },
  careerClue_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ums/promote/careerClue/list", p, c),
  },
  careerClue_listRecord: {
    /*
     * 联系记录列表
     * id 资源ID (integer query )
     */
    get: (p, c) => $.get("/api/ums/promote/careerClue/listRecord", p, c),
  },
  careerClue_listRelation: {
    /*
     * 学校关系图历史记录列表
     * id id (integer query required)
     */
    get: (p, c) => $.get("/api/ums/promote/careerClue/listRelation", p, c),
  },
  careerClue_remove: {
    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/ums/promote/careerClue/remove", p, c),
  },
  careerClue_removeAdmin: {
    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/ums/promote/careerClue/removeAdmin", p, c),
  },
  careerClue_save: {
    /*
     * 添加
     * careerSchoolId 学校id (integer query required)
     * cooperateCollege 合作院系 (string query )
     * cooperateItem 专业方向 (string query required)
     * cooperateType 合作形式 (string query )
     * source 来源 (string query required)
     * status 资源状态 (string query required)
     */
    post: (p, c) => $.post("/api/ums/promote/careerClue/save", p, c),
  },
  careerClue_saveAdmin: {
    /*
     * 添加负责人
     * adminId 负责人ID (integer query required)
     * id 线索id (integer query required)
     * joinTime 加入项目时间 (string query required)
     * role 负责人角色,可多选 (string query required)
     */
    post: (p, c) => $.post("/api/ums/promote/careerClue/saveAdmin", p, c),
  },
  careerClue_saveAdminTemp: {
    /*
     * 临时方法，迁移数据
     */
    post: (p, c) => $.post("/api/ums/promote/careerClue/saveAdminTemp", p, c),
  },
  careerClue_saveContacts: {
    /*
     * 添加学校联系人
     * careerClueId 资源ID (integer query )
     * email 邮箱 (string query )
     * id 详情学校联系人列表id (integer query )
     * intro 个人情况（履历爱好等） (string query )
     * name 姓名 (string query required)
     * phone 手机 (string query )
     * position 职位 (string query required)
     * status 角色：未知、支持者、中立者、反对者、内线 (string query )
     * telephone 座机 (string query )
     */
    post: (p, c) => $.post("/api/ums/promote/careerClue/saveContacts", p, c),
  },
  careerClue_saveRecord: {
    /*
     * 添加联系记录
     * contactWay 联系方式 (string query )
     * content 内容 (string query required)
     * currentContractTime 本次联系时间 (string query required)
     * extraFile 附件 (string query )
     * id 资源id (integer query required)
     * intentionLevel 意向评级 (string query )
     * nextContractTime 预计下次联系时间 (string query )
     * reception 接待人 (string query )
     * salesPossible 销售成熟度（0-100） (string query )
     * status 资源状态 (string query )
     */
    post: (p, c) => $.post("/api/ums/promote/careerClue/saveRecord", p, c),
  },
  careerClue_saveRelation: {
    /*
     * 添加学校关系图
     * id id (integer query required)
     * imageUrl 关系图附件地址 (string query required)
     */
    post: (p, c) => $.post("/api/ums/promote/careerClue/saveRelation", p, c),
  },
  careerClue_update: {
    /*
     * 编辑
     * actualContractTime 实际成交时间 (string query )
     * actualPayAmount 实际到账金额 (number query required)
     * cooperateCollege 合作院系 (string query required)
     * cooperateType 合作形式 (string query required)
     * estimateContractTime 预计成交时间 (string query )
     * estimateSalesAmount 销售金额预测 (number query required)
     * estimateSalesGist 销售金额预测依据 (string query required)
     * id id (integer query required)
     * intentionLevel 意向评级 (string query required)
     * remark 备注 (string query )
     * salesPossible 销售成熟度（0-100） (string query required)
     * source 来源 (string query required)
     * status 资源状态 (string query required)
     */
    put: (p, c) => $.put("/api/ums/promote/careerClue/update", p, c),
  },
  careerClue_updateAdmin: {
    /*
     * 编辑负责人
     * id 负责人列表id (integer query required)
     * joinTime 加入项目时间 (string query required)
     * role 负责人角色,可多选 (string query required)
     */
    put: (p, c) => $.put("/api/ums/promote/careerClue/updateAdmin", p, c),
  },
  careerClue_upload: {
    /*
     * 导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ums/promote/careerClue/upload", p, c),
  },

  /**
   * 职教-学校管理
   */
  careerSchool_downloadMould: {
    /*
     * 下载模版
     */
    get: (p, c) => $.get("/api/ums/promote/careerSchool/downloadMould", p, c),
  },
  careerSchool_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ums/promote/careerSchool/export", p, c),
  },
  careerSchool_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ums/promote/careerSchool/list", p, c),
  },
  careerSchool_listSimple: {
    /*
     * 简单学校列表含ID
     * city 市 (string query )
     * district 区 (string query )
     * province 省 (string query )
     * type 学校类型 (string query )
     */
    get: (p, c) => $.get("/api/ums/promote/careerSchool/listSimple", p, c),
  },
  careerSchool_listSimpleSchool: {
    /*
     * 简单学校列表
     */
    get: (p, c) =>
      $.get("/api/ums/promote/careerSchool/listSimpleSchool", p, c),
  },
  careerSchool_remove: {
    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/ums/promote/careerSchool/remove", p, c),
  },
  careerSchool_save: {
    /*
     * 添加编辑
     * address 地址 (string query )
     * city 市 (string query )
     * district 区 (string query )
     * id id (integer query )
     * intro 简介 (string query )
     * name 学校名称 (string query required)
     * province 省份 (string query required)
     * remark 备注 (string query )
     * type 类别 (string query required)
     */
    post: (p, c) => $.post("/api/ums/promote/careerSchool/save", p, c),
  },
  careerSchool_upload: {
    /*
     * 导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ums/promote/careerSchool/upload", p, c),
  },

  /**
   * 网络线索
   */
  clue: {
    /*
     * 添加线索
     * channelAddress 网址 (string query )
     * city 市 (string query )
     * consultWay 咨询方式 (string query required)
     * customerRemark 备注 (string query )
     * district 县(区) (string query )
     * intentionGrade 意向等级 (string query )
     * keyword 关键字 (string query )
     * name 名字 (string query required)
     * originDetail 具体来源(来源渠道为运营营销) (string query )
     * originSchool 来源学校 (string query )
     * phone 手机号 (string query )
     * province 省 (string query )
     * qq qq (string query )
     * type 业务类型 成人教育 全日制 落户 (string query required)
     * wechat 微信 (string query )
     */
    post: (p, c) => $.post("/api/ums/promote/clue", p, c),

    /*
     * 编辑线索，只有编辑没有负责人的线索
     * city 市 (string query )
     * consultWay 咨询方式 (string query required)
     * customerRemark 备注 (string query )
     * district 县(区) (string query )
     * id 线索 id (integer query required)
     * keyword 关键字 (string query )
     * name 名字 (string query required)
     * originDetail 具体来源(来源渠道为运营营销) (string query )
     * originSchool 来源学校 (string query )
     * phone 手机号 (string query )
     * province 省 (string query )
     * qq qq (string query )
     * status 线索状态：1待分配6无效资源 (string query required)
     * type 业务类型 成人教育 全日制 落户 (string query required)
     * wechat 微信 (string query )
     */
    put: (p, c) => $.put("/api/ums/promote/clue", p, c),
  },
  clue_assign: {
    /*
     * 分配线索
     * adminId 管理员id (integer query required)
     * id 线索 id (integer query required)
     */
    put: (p, c) => $.put("/api/ums/promote/clue/assign", p, c),
  },
  clue_changeType_batchChange: {
    /*
     * 批量转让线索
     * clueIds 线索IDs (array query required)
     * newType 新业务类型 (string query required)
     * remark 备注 (string query )
     */
    put: (p, c) => $.put("/api/ums/promote/clue/changeType/batchChange", p, c),
  },
  clue_changeType_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ums/promote/clue/changeType/export", p, c),
  },
  clue_changeType_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ums/promote/clue/changeType/list", p, c),
  },
  clue_channelAddress: {
    /*
     * 编辑网址
     * channelAddress 来源网址 (string query required)
     * id 线索 id (integer query required)
     */
    put: (p, c) => $.put("/api/ums/promote/clue/channelAddress", p, c),
  },
  clue_details: {
    /*
     * 详情,只有可编辑的才有详情按钮
     * id 线索id (integer query required)
     */
    get: (p, c) => $.get("/api/ums/promote/clue/details", p, c),
  },
  clue_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ums/promote/clue/export", p, c),
  },
  clue_leaderUpdate: {
    /*
     * 主管编辑
     * channelAddress 来源网址 (string query required)
     * consultWay 咨询方式 (string query required)
     * id 线索 id (integer query required)
     * keyword 关键字 (string query )
     * originDetail 具体来源(来源渠道为运营营销) (string query )
     * type 业务类型 成人教育 全日制 落户 (string query required)
     */
    put: (p, c) => $.put("/api/ums/promote/clue/leaderUpdate", p, c),
  },
  clue_list: {
    /*
     * 线索列表
     */
    get: (p, c) => $.get("/api/ums/promote/clue/list", p, c),
  },
  clue_listContact: {
    /*
     * 线索联系记录
     * id 线索id (integer query required)
     */
    get: (p, c) => $.get("/api/ums/promote/clue/listContact", p, c),
  },
  clue_saveContact: {
    /*
     * 添加联系,添加前先调用获取最后未创建联系接口,如果存在则不调用次此接口,先让管理员编辑未创建联系
     * contactDuration 如果上传音频，时长 (integer query )
     * contactRecord 附件，可以上传音频，可以上传图片 (string query )
     * contactWay 联系方式(下拉)：电话，微信，电子邮件,qq,上门，其他 (string query )
     * id 线索id (integer query required)
     * preContactTime 预计下次联系时间 (string query required)
     * remark 备注 (string query )
     * status 线索状态：1待分配6无效资源 (string query required)
     */
    put: (p, c) => $.put("/api/ums/promote/clue/saveContact", p, c),
  },
  clue_searchByPhone: {
    /*
     * 查重
     * phone 手机号(三选一) (string query )
     * qq qq (string query )
     * wechat 微信 (string query )
     */
    get: (p, c) => $.get("/api/ums/promote/clue/searchByPhone", p, c),
  },
  clue_updateUncreatedContact: {
    /*
     * 编辑未创建联系
     * id 线索联系id (integer query required)
     * preContactTime 预计下次联系时间 (string query required)
     * remark 备注 (string query )
     * status 线索状态：1待分配6无效资源 (string query required)
     */
    put: (p, c) => $.put("/api/ums/promote/clue/updateUncreatedContact", p, c),
  },

  /**
   * 全日制审核
   */
  clueGradeAudit_audit: {
    /*
     * 审核
     * auditStatus 类型：通过|拒绝 (string query required)
     * id id (integer query required)
     * refuseReason 拒绝理由 (string query )
     */
    put: (p, c) => $.put("/api/ums/promote/clueGradeAudit/audit", p, c),
  },
  clueGradeAudit_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ums/promote/clueGradeAudit/list", p, c),
  },
  clueGradeAudit_listByClueContactId: {
    /*
     * 根据线索联系记录ID查询审核记录
     * clueContactId clueContactId (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ums/promote/clueGradeAudit/listByClueContactId", p, c),
  },

  /**
   * 线索来源
   */
  clueOrigin: {
    /*
     * 添加
     * id  (integer query )
     * originChannel 来源渠道 (string query )
     * originChannelSort 来源渠道排序 (integer query )
     * originDetail 具体来源 (string query )
     * originDetailSort 来源详情排序 (integer query )
     */
    post: (p, c) => $.post("/api/ums/promote/clueOrigin", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/ums/promote/clueOrigin", p, c),
  },
  clueOrigin_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ums/promote/clueOrigin/list", p, c),
  },
  clueOrigin_listChannel: {
    /*
     * 渠道列表
     */
    get: (p, c) => $.get("/api/ums/promote/clueOrigin/listChannel", p, c),
  },
  clueOrigin_listDetail: {
    /*
     * 详情列表
     * channel 渠道 (string query required)
     */
    get: (p, c) => $.get("/api/ums/promote/clueOrigin/listDetail", p, c),
  },

  /**
   * 企业线索
   */
  enterpriseClue: {
    /*
     * 添加编辑线索
     * actualOperateAdmin 实际操办人 (string query )
     * actualOperateAdminPhone 实际操办人联系方式 (string query )
     * address 企业地址 (string query )
     * adminId 负责人id (integer query )
     * adminPosition 负责人职位 (string query )
     * assignTime 最近分配时间 (string query )
     * city 所属城市 (string query )
     * cooperateTime 达成合作时间 (string query )
     * corporate 法人代表 (string query )
     * district 所属区县 (string query )
     * enterpriseAdmin 对接负责人 (string query )
     * enterpriseAdminPhone 对接负责人联系方式 (string query )
     * enterpriseType 企业类型 (string query )
     * establishment 成立日期 (string query )
     * id  (integer query )
     * industry 所属行业 (string query )
     * insuredPersonsNumber 参保人数 (string query )
     * lastContactId 最近联系记录 (integer query )
     * lastOfflineTime 最近拜访时间 (string query )
     * linePhone 联系方式 (string query )
     * mobilePhone 移动电话 (string query )
     * name 企业名称 (string query )
     * operateStatus 经营状态 (string query )
     * point 负责人招生点 (string query )
     * province 所属省份 (string query )
     * qq qq (string query )
     * registeredCapital 注册资本 (string query )
     * status 线索状态：有意向，无意向，无效资源，已经约访，已经合作 (string query )
     * type 企业类型 (string query )
     * wechat 微信 (string query )
     */
    post: (p, c) => $.post("/api/ums/promote/enterpriseClue", p, c),
  },
  enterpriseClue_assign: {
    /*
     * 分配
     * adminId 管理员id (integer query required)
     * ids 线索id (array query required)
     */
    put: (p, c) => $.put("/api/ums/promote/enterpriseClue/assign", p, c),
  },
  enterpriseClue_downloadMould: {
    /*
     * 下载模版
     */
    get: (p, c) => $.get("/api/ums/promote/enterpriseClue/downloadMould", p, c),
  },
  enterpriseClue_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ums/promote/enterpriseClue/list", p, c),
  },
  enterpriseClue_listContact: {
    /*
     * 联系记录列表
     * id 线索id (integer query required)
     */
    get: (p, c) => $.get("/api/ums/promote/enterpriseClue/listContact", p, c),
  },
  enterpriseClue_saveContact: {
    /*
     * 添加联系
     * adminId 联系人ｉｄ (integer query )
     * clueId 线索id (integer query )
     * clueStatus 线索状态 (string query )
     * contactWay 联系方式：ｑｑ，微信，电话，线下拜访 (string query )
     * content 最新进展 (string query )
     * cooperateStatus 是否合作 (string query )
     * dispatchStatus 是否发文 (string query )
     * id  (integer query )
     * nextContactTime 预计下次联系时间 (string query )
     */
    put: (p, c) => $.put("/api/ums/promote/enterpriseClue/saveContact", p, c),
  },
  enterpriseClue_upload: {
    /*
     * 导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ums/promote/enterpriseClue/upload", p, c),
  },

  /**
   * 推广现金消费
   */
  feeRecord_downloadMould: {
    /*
     * 下载模版
     */
    get: (p, c) => $.get("/api/ums/promote/feeRecord/downloadMould", p, c),
  },
  feeRecord_downloadMouldForSelfTaught: {
    /*
     * 下载自考成本导入模版
     */
    get: (p, c) =>
      $.get("/api/ums/promote/feeRecord/downloadMouldForSelfTaught", p, c),
  },
  feeRecord_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ums/promote/feeRecord/export", p, c),
  },
  feeRecord_exportForSelfTaught: {
    /*
     * 导出自考成本
     */
    get: (p, c) =>
      $.get("/api/ums/promote/feeRecord/exportForSelfTaught", p, c),
  },
  feeRecord_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ums/promote/feeRecord/list", p, c),
  },
  feeRecord_listForSelfTaught: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ums/promote/feeRecord/listForSelfTaught", p, c),
  },
  feeRecord_remove: {
    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/ums/promote/feeRecord/remove", p, c),
  },
  feeRecord_save: {
    /*
     * 添加
     * adminId  (integer query )
     * amount 消费金额 (number query )
     * channel  (string query )
     * id  (integer query )
     * province 省份 (string query )
     * time 消费时间 (string query )
     * type 推广消费|自考成本 (string query )
     */
    post: (p, c) => $.post("/api/ums/promote/feeRecord/save", p, c),
  },
  feeRecord_upload: {
    /*
     * 导入推广现金消费
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ums/promote/feeRecord/upload", p, c),
  },
  feeRecord_uploadForSelfTaught: {
    /*
     * 导入自考成本
     * excelFile excelFile (file formData required)
     */
    post: (p, c) =>
      $.post("/api/ums/promote/feeRecord/uploadForSelfTaught", p, c),
  },

  /**
   * 运营线索
   */
  operateClue: {
    /*
     * 添加线索
     * channelAddress 网址 (string query )
     * city 市 (string query )
     * consultWay 咨询方式 (string query required)
     * customerRemark 备注 (string query )
     * district 县(区) (string query )
     * intentionGrade 意向等级 (string query )
     * keyword 关键字 (string query )
     * name 名字 (string query required)
     * originDetail 具体来源(来源渠道为运营营销) (string query )
     * originSchool 来源学校 (string query )
     * phone 手机号 (string query )
     * province 省 (string query )
     * qq qq (string query )
     * type 业务类型 成人教育 全日制 落户 (string query required)
     * wechat 微信 (string query )
     */
    post: (p, c) => $.post("/api/ums/promote/operateClue", p, c),

    /*
     * 编辑线索，只有编辑没有负责人的线索
     * city 市 (string query )
     * consultWay 咨询方式 (string query required)
     * customerRemark 备注 (string query )
     * district 县(区) (string query )
     * id 线索 id (integer query required)
     * keyword 关键字 (string query )
     * name 名字 (string query required)
     * originDetail 具体来源(来源渠道为运营营销) (string query )
     * originSchool 来源学校 (string query )
     * phone 手机号 (string query )
     * province 省 (string query )
     * qq qq (string query )
     * status 线索状态：1待分配6无效资源 (string query required)
     * type 业务类型 成人教育 全日制 落户 (string query required)
     * wechat 微信 (string query )
     */
    put: (p, c) => $.put("/api/ums/promote/operateClue", p, c),
  },
  operateClue_assign: {
    /*
     * 分配线索
     * adminId 管理员id (integer query required)
     * id 线索 id (integer query required)
     */
    put: (p, c) => $.put("/api/ums/promote/operateClue/assign", p, c),
  },
  operateClue_channelAddress: {
    /*
     * 编辑网址
     * channelAddress 来源网址 (string query required)
     * id 线索 id (integer query required)
     */
    put: (p, c) => $.put("/api/ums/promote/operateClue/channelAddress", p, c),
  },
  operateClue_details: {
    /*
     * 详情,只有可编辑的才有详情按钮
     * id 线索id (integer query required)
     */
    get: (p, c) => $.get("/api/ums/promote/operateClue/details", p, c),
  },
  operateClue_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ums/promote/operateClue/export", p, c),
  },
  operateClue_list: {
    /*
     * 线索列表
     */
    get: (p, c) => $.get("/api/ums/promote/operateClue/list", p, c),
  },
  operateClue_listContact: {
    /*
     * 线索联系记录
     * id 线索id (integer query required)
     */
    get: (p, c) => $.get("/api/ums/promote/operateClue/listContact", p, c),
  },
  operateClue_saveContact: {
    /*
     * 添加联系,添加前先调用获取最后未创建联系接口,如果存在则不调用次此接口,先让管理员编辑未创建联系
     * contactDuration 如果上传音频，时长 (integer query )
     * contactRecord 附件，可以上传音频，可以上传图片 (string query )
     * contactWay 联系方式(下拉)：电话，微信，电子邮件,qq,上门，其他 (string query )
     * id 线索id (integer query required)
     * preContactTime 预计下次联系时间 (string query required)
     * remark 备注 (string query )
     * status 线索状态：1待分配6无效资源 (string query required)
     */
    put: (p, c) => $.put("/api/ums/promote/operateClue/saveContact", p, c),
  },
  operateClue_searchByPhone: {
    /*
     * 查重
     * phone 手机号(三选一) (string query )
     * qq qq (string query )
     * wechat 微信 (string query )
     */
    get: (p, c) => $.get("/api/ums/promote/operateClue/searchByPhone", p, c),
  },
  operateClue_updateUncreatedContact: {
    /*
     * 编辑未创建联系
     * id 线索联系id (integer query required)
     * preContactTime 预计下次联系时间 (string query required)
     * remark 备注 (string query )
     * status 线索状态：1待分配6无效资源 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ums/promote/operateClue/updateUncreatedContact", p, c),
  },

  /**
   * 1+X渠道
   */
  opxChannel: {
    /*
     * 添加
     * address 企业地址 (string query )
     * adminId 负责人id (integer query )
     * broker 对接人 (string query )
     * brokerPhone 联系电话 (string query )
     * chargeRegion 负责区域 (string query )
     * city 所属城市 (string query )
     * contractContent 联系情况 (string query )
     * district 所属区县 (string query )
     * id  (integer query )
     * legalRepresentative 法人代表 (string query )
     * name 企业名称 (string query )
     * preApplyCount 预计申报人数 (integer query )
     * preExamCount 预计参考人数 (integer query )
     * prePlatformCount 预计平台售卖数量 (integer query )
     * preTeacherTrainCount 预计师培人数 (integer query )
     * province 所属省份 (string query )
     * registeredCapital 注册资本 (string query )
     * setUpTime 成立时间 (string query )
     * signedAgreement 是否签订渠道协议 (string query )
     * type 企业类型 (string query )
     */
    post: (p, c) => $.post("/api/ums/promote/opxChannel", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/ums/promote/opxChannel", p, c),
  },
  opxChannel_downloadMould: {
    /*
     * 下载模版
     */
    get: (p, c) => $.get("/api/ums/promote/opxChannel/downloadMould", p, c),
  },
  opxChannel_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ums/promote/opxChannel/export", p, c),
  },
  opxChannel_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ums/promote/opxChannel/list", p, c),
  },
  opxChannel_upload: {
    /*
     * 导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ums/promote/opxChannel/upload", p, c),
  },

  /**
   * 1+X学校
   */
  opxSite: {
    /*
     * 添加
     * adminId 负责人id (integer query )
     * applyCount 申报人数 (integer query )
     * broker 学校对接人 (string query )
     * brokerDepartment 所属部门 (string query )
     * brokerPhone 联系电话 (string query )
     * brokerPosition 所属岗位 (string query )
     * channel 所属渠道 (string query )
     * city 所属城市 (string query )
     * contractContent 联系情况 (string query )
     * district 所属区县 (string query )
     * examCount 参考人数 (integer query )
     * id  (integer query )
     * name 学校名称 (string query )
     * platformCount 平台购买数量 (integer query )
     * province 所属省份 (string query )
     * teacherTrainCount 师资培训人数 (integer query )
     * type 学校类型（中职、高职、应用型本科） (string query )
     */
    post: (p, c) => $.post("/api/ums/promote/opxSite", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/ums/promote/opxSite", p, c),
  },
  opxSite_downloadMould: {
    /*
     * 下载模版
     */
    get: (p, c) => $.get("/api/ums/promote/opxSite/downloadMould", p, c),
  },
  opxSite_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ums/promote/opxSite/export", p, c),
  },
  opxSite_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ums/promote/opxSite/list", p, c),
  },
  opxSite_listChannel: {
    /*
     * 渠道列表
     */
    get: (p, c) => $.get("/api/ums/promote/opxSite/listChannel", p, c),
  },
  opxSite_upload: {
    /*
     * 导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ums/promote/opxSite/upload", p, c),
  },

  /**
   * 成考宝典注册用户
   */
  platformRegister_change: {
    /*
     * 转化
     * city 市 (string query )
     * district 县(区) (string query )
     * id id (integer query required)
     * keyword 关键字 (string query )
     * province 省 (string query )
     * remark 备注 (string query )
     * type 业务类型 成人教育 全日制 落户 (string query required)
     */
    put: (p, c) => $.put("/api/ums/promote/platformRegister/change", p, c),
  },
  platformRegister_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ums/promote/platformRegister/list", p, c),
  },
  platformRegister_sign: {
    /*
     * 标记
     * id id (integer query required)
     */
    put: (p, c) => $.put("/api/ums/promote/platformRegister/sign", p, c),
  },

  /**
   * 排班管理
   */
  schedule_applyTransfer: {
    /*
     * 上报转化-招生线索用
     * clueId 线索ID (integer query required)
     * referralUserId 推荐人ID, 和合同代报推荐人处理方式相同 (integer query )
     */
    put: (p, c) => $.put("/api/ums/promote/schedule/applyTransfer", p, c),
  },
  schedule_changeFirstActivate: {
    /*
     * 更新线索上报人-招生线索用
     * clueId 线索ID (integer query required)
     * firstActivateAdminId 线索上报人ID (integer query required)
     */
    put: (p, c) => $.put("/api/ums/promote/schedule/changeFirstActivate", p, c),
  },
  schedule_initScheduleAndSort: {
    /*
     * 刷新排班顺序
     */
    put: (p, c) => $.put("/api/ums/promote/schedule/initScheduleAndSort", p, c),
  },
  schedule_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ums/promote/schedule/list", p, c),
  },
  schedule_listAdmin: {
    /*
     * 人员列表
     * id id (integer query )
     */
    get: (p, c) => $.get("/api/ums/promote/schedule/listAdmin", p, c),
  },
  schedule_remove: {
    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/ums/promote/schedule/remove", p, c),
  },
  schedule_removeAdmin: {
    /*
     * 删除排班人员
     * scheduleAdminId 排班人员列表返回的ID (integer query required)
     */
    delete: (p, c) => $.delete("/api/ums/promote/schedule/removeAdmin", p, c),
  },
  schedule_save: {
    /*
     * 添加编辑
     * endTime 排班结束时间 (string query required)
     * id id (integer query )
     * startTime 排班开始时间 (string query required)
     */
    put: (p, c) => $.put("/api/ums/promote/schedule/save", p, c),
  },
  schedule_saveAdmin: {
    /*
     * 添加编辑排班人员
     * adminInfo Json串, adminId, sort (string query required)
     * id id (integer query required)
     */
    put: (p, c) => $.put("/api/ums/promote/schedule/saveAdmin", p, c),
  },
  schedule_scheduleRemind: {
    /*
     * 排班提醒, 定时任务
     */
    put: (p, c) => $.put("/api/ums/promote/schedule/scheduleRemind", p, c),
  },

  /**
   * 军人专项
   */
  soldierInfo_audit: {
    /*
     * 审核
     * auditStatus 类型：通过|拒绝 (string query required)
     * id id (integer query required)
     * reason 拒绝理由 (string query )
     */
    put: (p, c) => $.put("/api/ums/promote/soldierInfo/audit", p, c),
  },
  soldierInfo_details: {
    /*
     * 详情
     * id id (integer query required)
     */
    get: (p, c) => $.get("/api/ums/promote/soldierInfo/details", p, c),
  },
  soldierInfo_enrollConfirm: {
    /*
     * 军人资料-确认报读
     * enrollFlag 报读状态， 已报读|未报读 (string query required)
     * id id (integer query required)
     */
    put: (p, c) => $.put("/api/ums/promote/soldierInfo/enrollConfirm", p, c),
  },
  soldierInfo_export: {
    /*
     * 军人专项导出
     */
    get: (p, c) => $.get("/api/ums/promote/soldierInfo/export", p, c),
  },
  soldierInfo_exportForInside: {
    /*
     * 军人资料-导出
     */
    get: (p, c) => $.get("/api/ums/promote/soldierInfo/exportForInside", p, c),
  },
  soldierInfo_list: {
    /*
     * 军人专项列表
     */
    get: (p, c) => $.get("/api/ums/promote/soldierInfo/list", p, c),
  },
  soldierInfo_listForInside: {
    /*
     * 军人资料-列表
     */
    get: (p, c) => $.get("/api/ums/promote/soldierInfo/listForInside", p, c),
  },
  soldierInfo_remove: {
    /*
     * 军人资料-删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/ums/promote/soldierInfo/remove", p, c),
  },
  soldierInfo_saveForClient: {
    /*
     * 添加
     * armyContactPhone 部队联系电话 (string query )
     * armyMailAddress 部队通讯地址 (string query required)
     * award 奖励情况，无、优秀士兵、嘉奖、荣誉称号、一二三等功 (string query required)
     * cardBackPhoto 身份证背面照 (string query )
     * cardFrontPhoto 身份证正面照 (string query )
     * cardNo 身份证号 (string query required)
     * disabilityLevel 无或实际伤残等级 (string query required)
     * endowmentInsuranceJoinPhoto 养老保险参保缴费凭证 (string query )
     * endowmentInsuranceTransferPhoto 养老保险关系转移接续信息表 (string query )
     * familyAddressArea 家庭住址-区 (string query required)
     * familyAddressCity 家庭住址-市 (string query required)
     * familyAddressDetail 家庭住址-详情地址 (string query required)
     * familyAddressProvince 家庭住址-省 (string query required)
     * familyAddressStreet 家庭住址-街道 (string query )
     * familyMemberName 家庭成员名字 (string query required)
     * familyMemberPhone 家庭成员联系方式 (string query required)
     * familyMemberType 类型 1父亲 2母亲 (string query required)
     * familyMemberWorkCompany 家庭成员工作单位 (string query required)
     * graduateLevel 文化程度 (string query required)
     * hardAreaExperience 是否在4、5、6类艰苦边远地区服役过， 填是否 (string query required)
     * hardAreaExperienceTime 在4、5、6类艰苦边远地区服役时间 (string query )
     * height 身高 (integer query required)
     * joinArmyArea 批准入伍地-区 (string query required)
     * joinArmyAreaType 入伍前户口性质（下拉农村、城镇） (string query required)
     * joinArmyCity 批准入伍地-市 (string query required)
     * joinArmyMonth 入伍月份（2018） (integer query required)
     * joinArmyProvince 批准入伍地-省 (string query required)
     * joinArmyYear 入伍年份（2018） (integer query required)
     * joinPartyTime 入党年月（2018.04） (string query )
     * leaveArmyCardNo 退伍证号 (string query required)
     * leaveArmyCode 退役时部队代号 (string query required)
     * leaveArmyMilitaryRank 退役时军衔 (string query required)
     * leaveArmyMonth 退伍月份 (integer query required)
     * leaveArmyYear 退伍年份 (integer query required)
     * major 部队从事专业或特长 (string query required)
     * medicalInsuranceTransferPhoto 医疗保险转移凭证 (string query )
     * name 姓名 (string query required)
     * nation 民族 (string query required)
     * phone 手机号 (string query required)
     * physicalInfo 身体状况（有何疾病和问题），无则填健康 (string query required)
     * politicalStatus 政治面貌 (string query required)
     * recommendationPhoto 行政介绍信 (string query )
     * reenterSchoolFlag 退伍后是否复学 (string query )
     * remark 备注 (string query )
     * retirementCardPhoto 退役证照片 (string query required)
     * selfPhoto 个人半身照 (string query )
     * sourceArea 来源区 (string query required)
     * tibetSoldierFlag 是否为进藏兵 (string query )
     * tibetSoldierTime 在西藏服役时长 (string query )
     * universityOrWorkContent 在校大学生填写学校名称，已参加工作填写工作单位名称 (string query )
     * universityOrWorkFlag 入伍前是否为在校大学生或已参加工作 (string query )
     * vocationalSkillsLevel 职业技能等级，无或实际等级 (string query required)
     * vocationalSkillsName 职业技能名称，无或实际 (string query required)
     * workPurpose 职业技能教育意向，下拉 (string query )
     */
    put: (p, c) => $.put("/api/ums/promote/soldierInfo/saveForClient", p, c),
  },
  soldierInfo_update: {
    /*
     * 编辑
     * armyContactPhone 部队联系电话 (string query )
     * armyMailAddress 部队通讯地址 (string query required)
     * award 奖励情况，无、优秀士兵、嘉奖、荣誉称号、一二三等功 (string query required)
     * cardBackPhoto 身份证背面照 (string query )
     * cardFrontPhoto 身份证正面照 (string query )
     * cardNo 身份证号 (string query required)
     * disabilityLevel 无或实际伤残等级 (string query required)
     * endowmentInsuranceJoinPhoto 养老保险参保缴费凭证 (string query )
     * endowmentInsuranceTransferPhoto 养老保险关系转移接续信息表 (string query )
     * familyAddressArea 家庭住址-区 (string query required)
     * familyAddressCity 家庭住址-市 (string query required)
     * familyAddressDetail 家庭住址-详情地址 (string query required)
     * familyAddressProvince 家庭住址-省 (string query required)
     * familyAddressStreet 家庭住址-街道 (string query )
     * familyMemberName 家庭成员名字 (string query required)
     * familyMemberPhone 家庭成员联系方式 (string query required)
     * familyMemberType 类型 1父亲 2母亲 (string query required)
     * familyMemberWorkCompany 家庭成员工作单位 (string query required)
     * graduateLevel 文化程度 (string query required)
     * hardAreaExperience 是否在4、5、6类艰苦边远地区服役过， 填是否 (string query required)
     * hardAreaExperienceTime 在4、5、6类艰苦边远地区服役时间 (string query )
     * height 身高 (integer query required)
     * id id (integer query required)
     * joinArmyArea 批准入伍地-区 (string query required)
     * joinArmyAreaType 入伍前户口性质（下拉农村、城镇） (string query required)
     * joinArmyCity 批准入伍地-市 (string query required)
     * joinArmyMonth 入伍月份（2018） (integer query required)
     * joinArmyProvince 批准入伍地-省 (string query required)
     * joinArmyYear 入伍年份（2018） (integer query required)
     * joinPartyTime 入党年月（2018.04） (string query )
     * leaveArmyCardNo 退伍证号 (string query required)
     * leaveArmyCode 退役时部队代号 (string query required)
     * leaveArmyMilitaryRank 退役时军衔 (string query required)
     * leaveArmyMonth 退伍月份 (integer query required)
     * leaveArmyYear 退伍年份 (integer query required)
     * major 部队从事专业或特长 (string query required)
     * medicalInsuranceTransferPhoto 医疗保险转移凭证 (string query )
     * name 姓名 (string query required)
     * nation 民族 (string query required)
     * physicalInfo 身体状况（有何疾病和问题），无则填健康 (string query required)
     * politicalStatus 政治面貌 (string query required)
     * recommendationPhoto 行政介绍信 (string query )
     * reenterSchoolFlag 退伍后是否复学 (string query )
     * remark 备注 (string query )
     * retirementCardPhoto 退役证照片 (string query required)
     * selfPhoto 个人半身照 (string query )
     * tibetSoldierFlag 是否为进藏兵 (string query )
     * tibetSoldierTime 在西藏服役时长 (string query )
     * universityOrWorkContent 在校大学生填写学校名称，已参加工作填写工作单位名称 (string query )
     * universityOrWorkFlag 入伍前是否为在校大学生或已参加工作 (string query )
     * vocationalSkillsLevel 职业技能等级，无或实际等级 (string query required)
     * vocationalSkillsName 职业技能名称，无或实际 (string query required)
     * workPurpose 职业技能教育意向，下拉 (string query )
     */
    put: (p, c) => $.put("/api/ums/promote/soldierInfo/update", p, c),
  },

  /**
   * 天猫活动
   */
  tianmaoActivity: {
    /*
     * 添加
     * ableStatus 启用状态:启用,禁用 (string query )
     * beforeTutorFeePrice 考前辅导费价格 (number query )
     * discountPayment 优惠返款金额 (number query )
     * enrollFeePrice 报名费价格 (number query )
     * goodName 商品名称 (string query )
     * id  (integer query )
     * name 活动名称 (string query )
     * oldContinueDiscount 老生续读额外优惠 (number query )
     * shareOtherDiscount 是否与其他优惠共享 (string query )
     * subName 活动子名称 (string query )
     */
    post: (p, c) => $.post("/api/ums/promote/tianmaoActivity", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/ums/promote/tianmaoActivity", p, c),
  },
  tianmaoActivity_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ums/promote/tianmaoActivity/list", p, c),
  },
  tianmaoActivity_listGoodName: {
    /*
     * 商品名称列表
     */
    get: (p, c) => $.get("/api/ums/promote/tianmaoActivity/listGoodName", p, c),
  },
  tianmaoActivity_listName: {
    /*
     * 活动名称列表
     */
    get: (p, c) => $.get("/api/ums/promote/tianmaoActivity/listName", p, c),
  },

  /**
   * 新媒体线索
   */
  xmtClue: {
    /*
     * 添加线索
     * channelAddress 网址 (string query )
     * city 市 (string query )
     * consultWay 咨询方式 (string query required)
     * customerRemark 备注 (string query )
     * district 县(区) (string query )
     * intentionGrade 意向等级 (string query )
     * keyword 关键字 (string query )
     * name 名字 (string query required)
     * originDetail 具体来源(来源渠道为运营营销) (string query )
     * originSchool 来源学校 (string query )
     * phone 手机号 (string query )
     * province 省 (string query )
     * qq qq (string query )
     * type 业务类型 成人教育 全日制 落户 (string query required)
     * wechat 微信 (string query )
     */
    post: (p, c) => $.post("/api/ums/promote/xmtClue", p, c),

    /*
     * 编辑线索，只有编辑没有负责人的线索
     * city 市 (string query )
     * consultWay 咨询方式 (string query required)
     * customerRemark 备注 (string query )
     * district 县(区) (string query )
     * id 线索 id (integer query required)
     * keyword 关键字 (string query )
     * name 名字 (string query required)
     * originDetail 具体来源(来源渠道为运营营销) (string query )
     * originSchool 来源学校 (string query )
     * phone 手机号 (string query )
     * province 省 (string query )
     * qq qq (string query )
     * status 线索状态：1待分配6无效资源 (string query required)
     * type 业务类型 成人教育 全日制 落户 (string query required)
     * wechat 微信 (string query )
     */
    put: (p, c) => $.put("/api/ums/promote/xmtClue", p, c),
  },
  xmtClue_assign: {
    /*
     * 分配
     * adminId 管理员id (integer query required)
     * id 线索 id (integer query required)
     */
    put: (p, c) => $.put("/api/ums/promote/xmtClue/assign", p, c),
  },
  xmtClue_channelAddress: {
    /*
     * 编辑网址
     * channelAddress 来源网址 (string query required)
     * id 线索 id (integer query required)
     */
    put: (p, c) => $.put("/api/ums/promote/xmtClue/channelAddress", p, c),
  },
  xmtClue_details: {
    /*
     * 详情,只有可编辑的才有详情按钮
     * id 线索id (integer query required)
     */
    get: (p, c) => $.get("/api/ums/promote/xmtClue/details", p, c),
  },
  xmtClue_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ums/promote/xmtClue/export", p, c),
  },
  xmtClue_list: {
    /*
     * 线索列表
     */
    get: (p, c) => $.get("/api/ums/promote/xmtClue/list", p, c),
  },
  xmtClue_listContact: {
    /*
     * 线索联系记录
     * id 线索id (integer query required)
     */
    get: (p, c) => $.get("/api/ums/promote/xmtClue/listContact", p, c),
  },
  xmtClue_saveContact: {
    /*
     * 添加联系,添加前先调用获取最后未创建联系接口,如果存在则不调用次此接口,先让管理员编辑未创建联系
     * contactDuration 如果上传音频，时长 (integer query )
     * contactRecord 附件，可以上传音频，可以上传图片 (string query )
     * contactWay 联系方式(下拉)：电话，微信，电子邮件,qq,上门，其他 (string query )
     * id 线索id (integer query required)
     * preContactTime 预计下次联系时间 (string query required)
     * remark 备注 (string query )
     * status 线索状态：1待分配6无效资源 (string query required)
     */
    post: (p, c) => $.post("/api/ums/promote/xmtClue/saveContact", p, c),
  },
  xmtClue_searchByPhone: {
    /*
     * 查重
     * phone 手机号(三选一) (string query )
     * qq qq (string query )
     * wechat 微信 (string query )
     */
    get: (p, c) => $.get("/api/ums/promote/xmtClue/searchByPhone", p, c),
  },
  xmtClue_updateUncreatedContact: {
    /*
     * 编辑未创建联系
     * id 线索联系id (integer query required)
     * preContactTime 预计下次联系时间 (string query required)
     * remark 备注 (string query )
     * status 线索状态：1待分配6无效资源 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ums/promote/xmtClue/updateUncreatedContact", p, c),
  },
};
