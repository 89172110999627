import { $ } from "@/common/Axios";

export default {
  /**
   * 收货地址
   */
  address_details: {
    /*
     * 地址详情
     * id 行ID (integer query required)
     */
    get: (p, c) => $.get("/api/ums/user/address/details", p, c),
  },
  address_list: {
    /*
     * 我的收获地址列表
     */
    get: (p, c) => $.get("/api/ums/user/address/list", p, c),
  },
  address_save: {
    /*
     * 添加
     * address 地址 (string query )
     * city 市 (string query )
     * district 区 (string query )
     * encryptValue 哈希值 (string query )
     * id  (integer query )
     * phone 手机号 (string query )
     * province 省 (string query )
     * userId 用户id (integer query )
     */
    post: (p, c) => $.post("/api/ums/user/address/save", p, c),
  },

  /**
   * 教师管理
   */
  adultTeacher_detail: {
    /*
     * 教师详情
     * id id (integer query required)
     */
    get: (p, c) => $.get("/api/ums/user/adultTeacher/detail", p, c),
  },
  adultTeacher_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ums/user/adultTeacher/export", p, c),
  },
  adultTeacher_list: {
    /*
     * 教师列表
     */
    get: (p, c) => $.get("/api/ums/user/adultTeacher/list", p, c),
  },
  adultTeacher_listSimple: {
    /*
     * 简单教师列表
     * ableStatus 启用禁用 (string query required)
     * major 专业 (string query required)
     * name 姓名 (string query required)
     */
    get: (p, c) => $.get("/api/ums/user/adultTeacher/listSimple", p, c),
  },
  adultTeacher_save: {
    /*
     * 添加
     * accountNo 银行卡号 (string query )
     * credentialType 资格证书类型( 高校教师资格证、中学教师资格证 、职业资格证书， 其他， 无) (string query )
     * credentialUrl 资格证书地址 (string query )
     * educationCredentialUrl 学历证书地址 (string query )
     * educationLevel 学历（无，专科，本科，硕士，博士） (string query )
     * email 邮箱 (string query )
     * graduatedMajor 毕业专业 (string query )
     * graduatedSchool 毕业院校 (string query )
     * idCardNo 身份证号 (string query )
     * jobTitle 职称（无 专业技术人员 讲师 副教授 教授） (string query )
     * name 姓名 (string query required)
     * openingBank 开户行 (string query )
     * phone 手机号 (string query required)
     * politics 政治面貌 (string query )
     * position 职位（下拉） (string query required)
     * qq qq (string query )
     * resumeInfo 履历信息 (string query )
     * schoolingName 任职院校 (string query )
     * teachCourse 可授课课程名称 (string query )
     * teacherCertificationNo 教师资格证编号 (string query )
     * thesisTeach 是否可以指导论文（是或否） (string query )
     */
    post: (p, c) => $.post("/api/ums/user/adultTeacher/save", p, c),
  },
  adultTeacher_setPayStandard: {
    /*
     * 设置薪酬
     * amount 100元/小时 (integer query )
     * id id (integer query required)
     */
    post: (p, c) => $.post("/api/ums/user/adultTeacher/setPayStandard", p, c),
  },
  adultTeacher_teacherContactInfo: {
    /*
     * 查看老师联系方式
     * teacherId id (integer query required)
     */
    get: (p, c) => $.get("/api/ums/user/adultTeacher/teacherContactInfo", p, c),
  },
  adultTeacher_update: {
    /*
     * 编辑
     * accountNo 银行卡账号 (string query )
     * credentialType 资格证书类型( 高校教师资格证、中学教师资格证 、职业资格证书， 其他， 无) (string query )
     * credentialUrl 资格证书地址 (string query )
     * educationCredentialUrl 毕业证书地址 (string query )
     * educationLevel 学历（无，专科，本科，硕士，博士） (string query )
     * email 邮箱 (string query )
     * graduatedMajor 毕业专业 (string query )
     * graduatedSchool 毕业院校 (string query )
     * id id (integer query required)
     * idCardNo 身份证 (string query )
     * jobTitle 职称（无 专业技术人员 讲师 副教授 教授） (string query )
     * openingBank 开户行 (string query )
     * politics 政治面貌 (string query )
     * qq qq (string query )
     * resumeInfo 履历信息 (string query )
     * schoolingName 任职院校 (string query )
     * teachCourse 可授课课程名称 (string query )
     * teacherCertificationNo 教师资格证编号 (string query )
     * thesisTeach 是否可以指导论文（1是0否） (string query )
     */
    put: (p, c) => $.put("/api/ums/user/adultTeacher/update", p, c),
  },
  adultTeacher_upload: {
    /*
     * 导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ums/user/adultTeacher/upload", p, c),
  },

  /**
   * 军人信息
   */
  armyInfo: {
    /*
     * 获取军人信息
     */
    get: (p, c) => $.get("/api/ums/user/armyInfo", p, c),

    /*
     * 添加
     * birth 出生日期|列表|日期|| (string query )
     * city 安置地(市)|全部||字符串下拉|api/cms/resource/armyCounty/listCity?province=${province} (string query )
     * contactPhone 联系电话|列表||| (string query )
     * district 安置地(区)|全部||字符串下拉|api/cms/resource/armyCounty/listDistrict?province=${province}&city=${city} (string query )
     * educationDegree 文化程度|列表||| (string query )
     * emergencyContactPhone 联系人电话|列表||| (string query )
     * emergencyContactRelationship 联系人关系|列表||| (string query )
     * familyAddress 家庭住址|列表||| (string query )
     * familyCity 家庭住址（市）|列表||| (string query )
     * familyDistrict 家庭住址（区）|列表||| (string query )
     * familyProvince 家庭住址（省）|列表||| (string query )
     * gender 性别|全部||字符串下拉|api/bms/develop/config/listSelect?key=性别 (string query )
     * id 军人信息id|无||| (integer query )
     * idCardNo 身份证号|全部||| (string query )
     * major 专业|列表||| (string query )
     * militaryTime 入伍时间|列表|日期|| (string query )
     * name 姓名|全部||| (string query )
     * phone 注册手机号|全部||| (string query )
     * province 安置地(省)|全部||字符串下拉|api/cms/resource/armyCounty/listProvince (string query )
     * reentry 是否复学|列表||| (string query )
     * site 学校名称|列表||| (string query )
     * submit 是否已提交|全部||字符串下拉|api/bms/develop/config/listSelect?key=是否 (string query )
     * trainingType 培训类别|全部||字符串下拉|api/bms/develop/config/listSelect?key=军人适应性培训类别 (string query )
     * userId 用户id|无||| (integer query )
     * year 年份|全部||字符串下拉|api/cms/resource/armyCounty/listYear (string query )
     */
    post: (p, c) => $.post("/api/ums/user/armyInfo", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/ums/user/armyInfo", p, c),
  },
  armyInfo_batchRemove: {
    /*
     * 批量删除
     * ids id (array query required)
     */
    delete: (p, c) => $.delete("/api/ums/user/armyInfo/batchRemove", p, c),
  },
  armyInfo_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ums/user/armyInfo/export", p, c),
  },
  armyInfo_exportStudyInfo: {
    /*
     * 学习情况列表导出
     */
    get: (p, c) => $.get("/api/ums/user/armyInfo/exportStudyInfo", p, c),
  },
  armyInfo_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ums/user/armyInfo/list", p, c),
  },
  armyInfo_listStudyInfo: {
    /*
     * 学习情况列表
     */
    get: (p, c) => $.get("/api/ums/user/armyInfo/listStudyInfo", p, c),
  },
  armyInfo_submitHomework: {
    /*
     * 提交作业
     * homework 作业 (string query required)
     */
    put: (p, c) => $.put("/api/ums/user/armyInfo/submitHomework", p, c),
  },
  armyInfo_updateSelf: {
    /*
     * 编辑军人信息
     * birth 出生日期 (string query )
     * city 安置地(市) (string query )
     * contactPhone 联系电话 (string query )
     * district 安置地(区) (string query )
     * educationDegree 文化程度 (string query )
     * emergencyContactPhone 联系人电话 (string query )
     * emergencyContactRelationship 联系人关系 (string query )
     * familyAddress 家庭住址 (string query )
     * familyCity 家庭住址（市） (string query )
     * familyDistrict 家庭住址（区） (string query )
     * familyProvince 家庭住址（省） (string query )
     * gender 性别 (string query )
     * major 专业 (string query )
     * militaryTime 入伍时间 (string query )
     * name 姓名 (string query )
     * phone 注册手机号 (string query )
     * province 安置地(省) (string query )
     * reentry 是否复学 (string query )
     * site 学校名称 (string query )
     * submit 是否已提交 (string query )
     * trainingType 培训类别 (string query )
     * year 退役年份 (string query )
     */
    put: (p, c) => $.put("/api/ums/user/armyInfo/updateSelf", p, c),
  },
  armyInfo_upload: {
    /*
     * 导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ums/user/armyInfo/upload", p, c),
  },

  /**
   * 修改手机号
   */
  changePhone: {
    /*
     * 编辑
     * dingDepartmentId 钉钉部门id (integer query required)
     * id id (integer query required)
     * newPhone 新手机号 (string query required)
     * remark 备注 (string query required)
     */
    put: (p, c) => $.put("/api/ums/user/changePhone", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/ums/user/changePhone", p, c),
  },
  changePhone_apply: {
    /*
     * 申请
     * dingDepartmentId 钉钉部门id (integer query required)
     * newPhone 新手机号 (string query required)
     * remark 备注 (string query required)
     * userId 用户id (integer query required)
     */
    post: (p, c) => $.post("/api/ums/user/changePhone/apply", p, c),
  },
  changePhone_checkVerify: {
    /*
     * 验证手机号验证码
     * id id (integer query required)
     * newPhone 新手机号 (string query required)
     * verifyCode 备注 (string query required)
     */
    put: (p, c) => $.put("/api/ums/user/changePhone/checkVerify", p, c),
  },
  changePhone_details: {
    /*
     * 详情
     * id id (integer query required)
     */
    get: (p, c) => $.get("/api/ums/user/changePhone/details", p, c),
  },
  changePhone_listByUserId: {
    /*
     * 列表
     * userId 用户id (integer query required)
     */
    get: (p, c) => $.get("/api/ums/user/changePhone/listByUserId", p, c),
  },
  changePhone_sync: {
    /*
     * 同步
     * id id (integer query required)
     */
    put: (p, c) => $.put("/api/ums/user/changePhone/sync", p, c),
  },

  /**
   * 新生注册
   */
  enrollSelf_listFamilyInfo: {
    /*
     * 查询学生家庭成员信息
     * userId userId (integer query )
     */
    get: (p, c) => $.get("/api/ums/user/enrollSelf/listFamilyInfo", p, c),
  },
  enrollSelf_listFamilyInfoBySchoolRollId: {
    /*
     * 根据合同编号查询家庭成员信息
     * schoolRollId schoolRollId (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ums/user/enrollSelf/listFamilyInfoBySchoolRollId", p, c),
  },
  enrollSelf_listResumeInfo: {
    /*
     * 查询学生履历信息
     * userId userId (integer query )
     */
    get: (p, c) => $.get("/api/ums/user/enrollSelf/listResumeInfo", p, c),
  },
  enrollSelf_listResumeInfoBySchoolRollId: {
    /*
     * 根据合同编号查询履历信息
     * schoolRollId schoolRollId (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ums/user/enrollSelf/listResumeInfoBySchoolRollId", p, c),
  },
  enrollSelf_remove: {
    /*
     * 删除
     * fromSource 类型 1后台编辑 2学生端编辑 (integer query required)
     * id 履历或家庭成员列表返回的ID (integer query required)
     * type 类型 1家庭成员 2履历 (integer query required)
     */
    delete: (p, c) => $.delete("/api/ums/user/enrollSelf/remove", p, c),
  },
  enrollSelf_saveRealNameInfo: {
    /*
     * 编辑身份证照片、民族, 为空方可填入
     * idCardBackPhoto 身份证背面照 (string query )
     * idCardFrontPhoto 身份证正面照 (string query )
     * nation 民族 (string query )
     */
    put: (p, c) => $.put("/api/ums/user/enrollSelf/saveRealNameInfo", p, c),
  },
  enrollSelf_saveSingleFamilyInfo: {
    /*
     * 单条添加编辑家庭成员信息
     * company 工作单位 (string query required)
     * contactPhone 联系电话 (string query required)
     * familyInfoId 记录ID，编辑传入，不传默认为新增 (integer query )
     * name 名字 (string query required)
     * otherInfo 其他信息 (string query required)
     * relation 关系称谓， 父母， 兄弟姐妹等 (string query required)
     * userId userId (integer query )
     */
    put: (p, c) => $.put("/api/ums/user/enrollSelf/saveSingleFamilyInfo", p, c),
  },
  enrollSelf_saveSingleResumeInfo: {
    /*
     * 单条添加编辑履历信息
     * certifier 证明人（建议填写班主任） (string query required)
     * endTime 履历结束时间 (string query required)
     * name 学校名称 (string query required)
     * otherInfo 其他信息 (string query required)
     * resumeInfoId 记录ID，编辑传入，不传默认为新增 (integer query )
     * startTime 履历开始时间 (string query required)
     * userId userId (integer query )
     */
    put: (p, c) => $.put("/api/ums/user/enrollSelf/saveSingleResumeInfo", p, c),
  },

  /**
   * 用户登陆信息
   */
  loginRecord_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ums/user/loginRecord/list", p, c),
  },

  /**
   * 用户实名信息
   */
  realName_getNamePhoneByIdCardNo: {
    /*
     * 根据身份证号码获取姓名和手机号
     * idCardNo 身份证号码 (string query required)
     */
    put: (p, c) => $.put("/api/ums/user/realName/getNamePhoneByIdCardNo", p, c),
  },
  realName_updateCardPhoto: {
    /*
     * 根据身份证号码更新正反面照片和个人照
     * backPhoto 身份证反面照片 (string query )
     * frontPhoto 身份证正面照片 (string query )
     * idCardNo 身份证号码 (string query required)
     * photo 个人照 (string query )
     */
    put: (p, c) => $.put("/api/ums/user/realName/updateCardPhoto", p, c),
  },

  /**
   * 用户学籍信息
   */
  schoolRoll_changeSchoolRoll: {
    /*
     * 切换学籍
     * schoolRollId 学籍ID (integer query required)
     */
    post: (p, c) => $.post("/api/ums/user/schoolRoll/changeSchoolRoll", p, c),
  },
  schoolRoll_searchUserByName: {
    /*
     * 根据推荐人姓名询用户信息
     * name 姓名 (string query required)
     */
    get: (p, c) => $.get("/api/ums/user/schoolRoll/searchUserByName", p, c),
  },

  /**
   * 用户
   */
  user_changePassword: {
    /*
     * 修改密码
     * oldPassword 旧密码 (string query )
     * password 密码 (string query )
     */
    put: (p, c) => $.put("/api/ums/user/user/changePassword", p, c),
  },
  user_changePhone: {
    /*
     * 修改手机号
     * phone 手机号 (string query required)
     * verifyCode 验证码 (string query required)
     */
    put: (p, c) => $.put("/api/ums/user/user/changePhone", p, c),
  },
  user_changeProvince: {
    /*
     * 修改省份
     * province 省份 (string query required)
     */
    put: (p, c) => $.put("/api/ums/user/user/changeProvince", p, c),
  },
  user_findPassword: {
    /*
     * 找回密码
     * password 密码 (string query )
     * phone 手机号 (string query )
     * platform 平台:成考宝典，专升本，学历 (string query )
     * verifyCode 验证码 (string query )
     */
    put: (p, c) => $.put("/api/ums/user/user/findPassword", p, c),
  },
  user_logOut: {
    /*
     * 注销
     */
    delete: (p, c) => $.delete("/api/ums/user/user/logOut", p, c),
  },
  user_resetPassword: {
    /*
     * 重置密码、解冻账号
     * userId userId (integer query required)
     */
    post: (p, c) => $.post("/api/ums/user/user/resetPassword", p, c),
  },
  user_sendVerify: {
    /*
     * 发送验证码
     * phone 手机号 (string query )
     * type 类型：登录，修改手机号，找回密码 (string query )
     */
    post: (p, c) => $.post("/api/ums/user/user/sendVerify", p, c),
  },
  user_signIn: {
    /*
     * 登录
     * idfa IOS 6+的设备id字段，32位 (string query )
     * imei 安卓的设备 ID 的 md5 摘要 (string query )
     * loginToken 一键登录token (string query )
     * oaid 安卓10以上设备 (string query )
     * os 操作系统 (string query )
     * password 密码 (string query )
     * phone 手机号 (string query )
     * platform 平台:成考宝典，专升本，华博特园丁 (string query )
     * signCode 登录码 (string query )
     * source 应用市场名称 (string query )
     * type 类型（微信）：公众号,小程序,APP (string query )
     * verifyCode 验证码 (string query )
     */
    post: (p, c) => $.post("/api/ums/user/user/signIn", p, c),
  },
  user_signInfo: {
    /*
     * 登录信息
     */
    get: (p, c) => $.get("/api/ums/user/user/signInfo", p, c),
  },
  user_signOut: {
    /*
     * 退出登录
     * unbind 是否解除绑定：是，否 (string query )
     */
    delete: (p, c) => $.delete("/api/ums/user/user/signOut", p, c),
  },
  user_updateSelf: {
    /*
     * 编辑个人信息
     * adultExamDiscipline 成考科类 (string query )
     * armyInfo.birth 出生日期 (string query )
     * armyInfo.city 安置地(市) (string query )
     * armyInfo.contactPhone 联系电话 (string query )
     * armyInfo.district 安置地(区) (string query )
     * armyInfo.educationDegree 文化程度 (string query )
     * armyInfo.emergencyContactPhone 联系人电话 (string query )
     * armyInfo.emergencyContactRelationship 联系人关系 (string query )
     * armyInfo.familyAddress 家庭住址 (string query )
     * armyInfo.familyCity 家庭住址（市） (string query )
     * armyInfo.familyDistrict 家庭住址（区） (string query )
     * armyInfo.familyProvince 家庭住址（省） (string query )
     * armyInfo.gender 性别 (string query )
     * armyInfo.major 专业 (string query )
     * armyInfo.militaryTime 入伍时间 (string query )
     * armyInfo.name 姓名 (string query )
     * armyInfo.phone 注册手机号 (string query )
     * armyInfo.province 安置地(省) (string query )
     * armyInfo.reentry 是否复学 (string query )
     * armyInfo.site 学校名称 (string query )
     * armyInfo.submit 是否已提交 (string query )
     * armyInfo.trainingType 培训类别 (string query )
     * armyInfo.year 退役年份 (string query )
     * avatar 头像 (string query )
     * batch 批次 (string query )
     * currentTerm 当前学期 (integer query )
     * degreeApply 学位申请情况 (string query )
     * enrollLevel 层次 (string query )
     * headerTeacher 班主任 (string query )
     * headerTeacherPhone 班主任手机号 (string query )
     * headerTeacherWechat 班主任微信 (string query )
     * id 用户id (integer query )
     * idCardNo 身份证号 (string query )
     * level 当前学历层次 (string query )
     * major 专业 (string query )
     * majorId 专业Id (integer query )
     * name 实名 (string query )
     * nation 民族 (string query )
     * nick 昵称 (string query )
     * password 密码 (string query )
     * phone 手机号 (string query )
     * photo 照片 (string query )
     * position  (string query )
     * province 省份 (string query )
     * rollGrade 学籍年级 (string query )
     * schoolRollId 学籍id (integer query )
     * site 学校 (string query )
     * siteType 学校类型 (string query )
     * studentFlag 是否kf学生 1是0否 (integer query )
     * studentNo 学号 (string query )
     * summary 个人简介 (string query )
     * vipRank vip等级，0为非vip (integer query )
     * wantMajor 意向专业 (string query )
     * wantMajorLevel 意向层次 (string query )
     * wantSite 意向学校 (string query )
     */
    put: (p, c) => $.put("/api/ums/user/user/updateSelf", p, c),
  },
};
