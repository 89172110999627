<template>
  <header class="head">
    <div class="inner">
      <div class="fl fl-row fl-ac" @click="toHome">
        <div>
          <img class="logo" src="@/assets/img/logo.png" alt="" />
        </div>
      </div>
      <nav class="fr">
        <!-- <router-link to="/" class="link" :class="{ active: nave.ind === 0 }">
          <span>首页</span>
        </router-link> -->
        <!-- <router-link
          to="/course"
          class="link"
          :class="{ active: nave.ind === 1 }"
        >
          <span>课程</span>
        </router-link> -->
        <div @click="loginOut" class="log-out">
          <span style="margin-right: 20px"> {{ userInfo.name }} </span> 退出
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import { mapState } from "vuex";
import jsCookie from "js-cookie";
import config from "../../config";
import page_mx from "@/mixin/page_mx";

import store from "../store";
export default {
  mixins: [page_mx],
  data() {
    return {};
  },
  computed: mapState({
    nave: (state) => state.main.nave,
  }),
  methods: {
    toHome() {
      // this.$router.push({
      //   path: "/home",
      // });
    },
    loginOut() {
      this.$Tools.confirm("确定退出重新登录吗？").then(async () => {
        await this.$api.ums.user.user_signOut.delete({
          unbind: "是",
        });
        const temp = JSON.parse(jsCookie.get(`${config.NAME}_userInfo`));
        localStorage.removeItem(`${config.NAME}_userInfo_${temp.id}`);
        await store.dispatch("userInfoUpdate", {});
        this.$router.replace({
          path: "/login",
        });
      });
    },
  },
  created() {
    console.log(this.userInfo);
    console.log(this.nave);
  },
};
</script>
<style lang="less" scope>
.fl {
  float: left;
}

.fr {
  float: right;
}

.head {
  box-shadow: 0px 4px 8px 0px rgba(40, 44, 56, 0.1);
}

.inner {
  width: 1200px;
  height: 60px;
  overflow: hidden;
  line-height: 60px;
  margin-left: auto;
  margin-right: auto;
}

.logo {
  margin-top: 12px;
}

.fr .link {
  color: #666;
  font-size: 18px;
  margin-left: 40px;
}

.fr .link.active {
  position: relative;
  color: #1886ff;
  font-weight: 600;
}

// .fr .link.active::after {
//   position: absolute;
//   content: "";
//   left: 10%;
//   bottom: -10px;
//   height: 2px;
//   width: 80%;
//   background-color: #00b861;
// }

.log-out {
  font-size: 16px;
  color: #323232;
  cursor: pointer;
}
</style>
