export default {
  path: "/moduleMain",
  meta: {
    title: "公共路由",
  },
  component: () => import("../views/common/moduleMain.vue"),
  children: [
    {
      path: "/home",
      meta: {
        title: "首页",
        isTransparent: true,
        naveInd: 0,
      },
      component: () => import("../views/main/home.vue"),
    },
    {
      path: "/down",
      meta: {
        title: "我的",
        naveInd: 1,
      },
      component: () => import("../views/main/downLoad.vue"),
    },
  ],
};
