const packageJson = require("./package.json");

//
const { NODE_ENV } = process.env;
const all = {
  catalog: "api",
  tabCatalog: ["bms", "ums", "cms", "ems", "sms"],
  localhost: {
    webHost: "http://" + (typeof location !== "undefined" ? location.host : ""),
    apiHost: "https://ckbd-wap-test.kefang.net",
    apiDoc: "https://api-test.kefang.net",
  },
  development: {
    webHost: "https://ckbd-wap-test.kefang.net",
    apiHost: "https://ckbd-wap-test.kefang.net",
    apiDoc: "https://api-test.kefang.net",
  },
  production: {
    webHost: "https://m.haike.cc",
    apiHost: "https://m.haike.cc",
    apiDoc: "https://api.kefang.net",
  },
};

module.exports = {
  NAME: packageJson.name,
  ENV: NODE_ENV,
  ENV_IS_PROD: NODE_ENV === "production",
  WEB_HOST: all[NODE_ENV].webHost,
  API_HOST: all[NODE_ENV].apiHost,
  API_DOC: all[NODE_ENV].apiDoc,
  API_CATALOG: all.catalog,
  API_TAB_CATALOG: all.tabCatalog,
  ALL: all,
};
