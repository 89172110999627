import { $ } from "@/common/Axios";

export default {
  /**
   * 提问
   */
  question: {
    /*
     * 添加
     * answer 回复内容 (string query )
     * answerId 回复人 (integer query )
     * answerTime  (string query )
     * belongId 归属id (integer query )
     * content 提问内容 (string query )
     * extraFileUrl  (string query )
     * id  (integer query )
     * status 状态：未解决,已解决 (string query )
     * type 类型：直播,成考练习 (string query )
     * userId 用户id (integer query )
     */
    post: (p, c) => $.post("/api/sms/social/question", p, c),
  },
  question_answer: {
    /*
     * 回复
     * answer 提问内容 (string query required)
     * id id (integer query required)
     * status 解决状态 (string query )
     */
    post: (p, c) => $.post("/api/sms/social/question/answer", p, c),
  },
  question_batchUpdateStatus: {
    /*
     * 批量修改解决状态
     * ids ids (array query required)
     * status 解决状态 (string query required)
     */
    put: (p, c) => $.put("/api/sms/social/question/batchUpdateStatus", p, c),
  },
  question_listAdultExamLiveCourseQuestion: {
    /*
     * 成考直播提问列表
     */
    get: (p, c) =>
      $.get("/api/sms/social/question/listAdultExamLiveCourseQuestion", p, c),
  },
  question_listAdultExamLiveCourseQuestionForClient: {
    /*
     * C端直播提问列表
     * liveCourseChapterId 直播章节id (integer query required)
     */
    get: (p, c) =>
      $.get(
        "/api/sms/social/question/listAdultExamLiveCourseQuestionForClient",
        p,
        c
      ),
  },
  question_listAdultExamSubjectQuestion: {
    /*
     * 成考习题反馈列表
     */
    get: (p, c) =>
      $.get("/api/sms/social/question/listAdultExamSubjectQuestion", p, c),
  },
  question_listSelfTaughtCourseQuestion: {
    /*
     * 自考精品课程学习反馈列表
     */
    get: (p, c) =>
      $.get("/api/sms/social/question/listSelfTaughtCourseQuestion", p, c),
  },
  question_listSelfTaughtLiveCourseQuestion: {
    /*
     * 自考直播回放学习反馈列表
     */
    get: (p, c) =>
      $.get("/api/sms/social/question/listSelfTaughtLiveCourseQuestion", p, c),
  },
  question_listSimpleSelfTaughtCourseQuestionForClient: {
    /*
     * C端自考精品课-我的提问列表
     */
    get: (p, c) =>
      $.get(
        "/api/sms/social/question/listSimpleSelfTaughtCourseQuestionForClient",
        p,
        c
      ),
  },
  question_listSimpleSelfTaughtLiveCourseQuestionForClient: {
    /*
     * C端自考直播回放-我的提问列表
     */
    get: (p, c) =>
      $.get(
        "/api/sms/social/question/listSimpleSelfTaughtLiveCourseQuestionForClient",
        p,
        c
      ),
  },
  question_saveQuestion: {
    /*
     * C端自考提问
     * belongId 提问章节ID (integer query required)
     * content 提问内容 (string query required)
     * extraFileUrl 附件图片, 多个用|隔开 (string query )
     * type 类型:自考精品课|自考直播回放 (string query required)
     */
    post: (p, c) => $.post("/api/sms/social/question/saveQuestion", p, c),
  },
  question_testAdminCreateTime: {
    /*
     * C端直播提问列表
     * id id (integer query required)
     */
    get: (p, c) => $.get("/api/sms/social/question/testAdminCreateTime", p, c),
  },
};
