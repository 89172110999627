import { $ } from "@/common/Axios";

export default {
  /**
   * 电视播报
   */
  tvPage_broadcastInfo: {
    /*
     * 播报信息
     */
    get: (p, c) => $.get("/api/bms/page/tvPage/broadcastInfo", p, c),
  },
  tvPage_doubleElevenActivityBroadcastInfo: {
    /*
     * 2021双十一活动播报信息
     * type 类型：个人|队伍 (string query required)
     */
    get: (p, c) =>
      $.get("/api/bms/page/tvPage/doubleElevenActivityBroadcastInfo", p, c),
  },
};
