import Vue from "vue";
import Vuex from "vuex";

// 公用store
import main from "@/store/main";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    main,
  },
});
