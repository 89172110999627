import social from "./social";
import study from "./study";

/**
 * 学习微服务
 */
export default {
  social,
  study,
};
