import { $ } from "@/common/Axios";

export default {
  /**
   * advert-callback-controller
   */
  advertCallback_data: {
    /*
     * data
     * aid aid (string query )
     * androidid androidid (string query )
     * callback_url callback_url (string query )
     * cid cid (string query )
     * convert_id convert_id (string query )
     * csite csite (string query )
     * ctype ctype (string query )
     * idfa idfa (string query )
     * imei imei (string query )
     * ip ip (string query )
     * mac mac (string query )
     * oaid oaid (string query )
     * os os (string query )
     * ts ts (string query )
     */
    get: (p, c) => $.get("/api/bms/extension/advertCallback/data", p, c),
  },

  /**
   * 签到
   */
  attendance_attendance: {
    /*
     * 签到
     * contentId 内容id (integer query )
     * type 类型：任务要求、任务落地 (string query )
     */
    post: (p, c) => $.post("/api/bms/extension/attendance/attendance", p, c),
  },
  attendance_listDate: {
    /*
     * 列表
     * contentId 内容id (integer query )
     * type 类型：任务要求、任务落地 (string query )
     */
    get: (p, c) => $.get("/api/bms/extension/attendance/listDate", p, c),
  },

  /**
   * 对账单
   */
  bill_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/bms/extension/bill/export", p, c),
  },
  bill_getSummaryType: {
    /*
     * 获取摘要类型
     */
    get: (p, c) => $.get("/api/bms/extension/bill/getSummaryType", p, c),
  },
  bill_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/bms/extension/bill/list", p, c),
  },
  bill_refresh: {
    /*
     * 刷新
     * date 日期：yyyy-MM-dd (string query )
     */
    post: (p, c) => $.post("/api/bms/extension/bill/refresh", p, c),
  },
  bill_refreshByAccount: {
    /*
     * 刷新
     * account 账号 (string query )
     * date 日期：yyyy-MM-dd (string query )
     */
    post: (p, c) => $.post("/api/bms/extension/bill/refreshByAccount", p, c),
  },
  bill_setSummaryType: {
    /*
     * 设置摘要类型
     * summaryTypes 摘要类型json字符串 (string query )
     */
    post: (p, c) => $.post("/api/bms/extension/bill/setSummaryType", p, c),
  },
  bill_updateType: {
    /*
     * 更新类型类型
     * id id (integer query required)
     */
    put: (p, c) => $.put("/api/bms/extension/bill/updateType", p, c),
  },

  /**
   * 分类
   */
  classification: {
    /*
     * 添加
     * firstLevel 一级 (string query )
     * fourthLevel 四级 (string query )
     * id  (integer query )
     * secondLevel 二级 (string query )
     * thirdLevel 三级 (string query )
     * type 分类类型 (string query )
     */
    post: (p, c) => $.post("/api/bms/extension/classification", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/bms/extension/classification", p, c),
  },
  classification_listFirstLevel: {
    /*
     * 一级列表
     * type 分类类型 (string query required)
     */
    get: (p, c) =>
      $.get("/api/bms/extension/classification/listFirstLevel", p, c),
  },
  classification_listForComplaint: {
    /*
     * 投诉分类列表
     */
    get: (p, c) =>
      $.get("/api/bms/extension/classification/listForComplaint", p, c),
  },
  classification_listFourthLevel: {
    /*
     * 四级列表
     * firstLevel 一级类型 (string query required)
     * secondLevel 二级类型 (string query required)
     * thirdLevel 三级类型 (string query required)
     * type 分类类型 (string query required)
     */
    get: (p, c) =>
      $.get("/api/bms/extension/classification/listFourthLevel", p, c),
  },
  classification_listSecondLevel: {
    /*
     * 二级列表
     * firstLevel 一级类型 (string query required)
     * type 分类类型 (string query required)
     */
    get: (p, c) =>
      $.get("/api/bms/extension/classification/listSecondLevel", p, c),
  },
  classification_listThirdLevel: {
    /*
     * 三级列表
     * firstLevel 一级类型 (string query required)
     * secondLevel 一级类型 (string query required)
     * type 分类类型 (string query required)
     */
    get: (p, c) =>
      $.get("/api/bms/extension/classification/listThirdLevel", p, c),
  },

  /**
   * 列表标签
   */
  columnLabel: {
    /*
     * 添加
     * id  (integer query )
     * name 标签名字 (string query )
     * type 标签类型 (string query )
     * url 标签地址 (string query )
     */
    post: (p, c) => $.post("/api/bms/extension/columnLabel", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/bms/extension/columnLabel", p, c),
  },
  columnLabel_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/bms/extension/columnLabel/list", p, c),
  },
  columnLabel_listSimple: {
    /*
     * 简易列表
     * type 类型 (string query required)
     */
    get: (p, c) => $.get("/api/bms/extension/columnLabel/listSimple", p, c),
  },
  columnLabel_listType: {
    /*
     * 类型列表
     */
    get: (p, c) => $.get("/api/bms/extension/columnLabel/listType", p, c),
  },

  /**
   * 电脑设备信息
   */
  computerInfo_checkProd: {
    /*
     * 添加
     */
    get: (p, c) => $.get("/api/bms/extension/computerInfo/checkProd", p, c),
  },
  computerInfo_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/bms/extension/computerInfo/export", p, c),
  },
  computerInfo_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/bms/extension/computerInfo/list", p, c),
  },
  computerInfo_save: {
    /*
     * 添加
     * adminId 使用人 (integer query )
     * adminName 使用人 (string query )
     * cpuMemorySize cpu显存 (number query required)
     * cpuName cpu (string query required)
     * cpuTaskCount cpu线程数 (integer query required)
     * hardDiskId 硬盘编号 (string query required)
     * hardDiskSize 硬盘大小 (number query required)
     * mainBoardId 主板编号 (string query required)
     * memorySize 内存大小 (number query required)
     * systemName 操作系统 (string query required)
     */
    get: (p, c) => $.get("/api/bms/extension/computerInfo/save", p, c),
  },
  computerInfo_updateNo: {
    /*
     * 添加编辑电脑编号
     * displayerNo 显示器编号 (string query )
     * id id (integer query required)
     * mainNo 主机编号 (string query )
     */
    post: (p, c) => $.post("/api/bms/extension/computerInfo/updateNo", p, c),
  },

  /**
   * ding-task-controller
   */
  dingTask_finishTask: {
    /*
     * 完成代办
     * adminId adminId (integer query )
     * contentId 来源ID (integer query )
     */
    put: (p, c) => $.put("/api/bms/extension/dingTask/finishTask", p, c),
  },
  dingTask_removeTask: {
    /*
     * 删除代办
     * adminId adminId (integer query )
     * contentId 来源ID (integer query )
     */
    delete: (p, c) => $.delete("/api/bms/extension/dingTask/removeTask", p, c),
  },
  dingTask_save: {
    /*
     * 新增代办
     * appUrl appUrl (string query )
     * contentId 来源ID (integer query )
     * deadline 截止时间(2022-08-05 23:59:59) (string query )
     * description 代办描述 (string query )
     * pcUrl pcUrl (string query )
     * targetAdminId targetAdminId (integer query )
     * title 代办标题 (string query )
     */
    post: (p, c) => $.post("/api/bms/extension/dingTask/save", p, c),
  },

  /**
   * 钉钉
   */
  dingtalk_createProcessInstance: {
    /*
     * 发起钉钉审批实例
     * adminId 管理员id (integer query required)
     * componentTypes 控件类型 (array query required)
     * dingDepartmentId 钉钉部门id (integer query required)
     * names 表单项名字 (array query required)
     * processCode 审批流的唯一码 (string query required)
     * values 表单项值 (array query required)
     */
    post: (p, c) =>
      $.post("/api/bms/extension/dingtalk/createProcessInstance", p, c),
  },
  dingtalk_getInstanceResp: {
    /*
     * 获取钉钉审批实例
     * instanceId 审批实例id (string query required)
     */
    get: (p, c) => $.get("/api/bms/extension/dingtalk/getInstanceResp", p, c),
  },
  dingtalk_postRequest: {
    /*
     * 钉钉post请求
     * keys 请求参数key (array query )
     * url 请求id (string query required)
     * values 请求参数value (array query )
     */
    post: (p, c) => $.post("/api/bms/extension/dingtalk/postRequest", p, c),
  },
  dingtalk_refreshDingAccessToken: {
    /*
     * 刷新钉钉的AccessToken
     */
    post: (p, c) =>
      $.post("/api/bms/extension/dingtalk/refreshDingAccessToken", p, c),
  },
  dingtalk_sendDingMessage: {
    /*
     * 发送钉钉消息
     * adminId 接收人id (integer query required)
     * message 消息 (string query required)
     */
    post: (p, c) => $.post("/api/bms/extension/dingtalk/sendDingMessage", p, c),
  },
  dingtalk_sendDingOAMessage: {
    /*
     * 发送钉钉消息
     * adminId 接收人id (integer query required)
     * content 内容 (string query required)
     * pcUrl PC端链接 (string query required)
     * title 标题 (string query required)
     * url 钉钉小程序链接 (string query required)
     */
    post: (p, c) =>
      $.post("/api/bms/extension/dingtalk/sendDingOAMessage", p, c),
  },
  dingtalk_syncOrganization: {
    /*
     * 同步部门和账号
     */
    put: (p, c) => $.put("/api/bms/extension/dingtalk/syncOrganization", p, c),
  },

  /**
   * 2021双十一活动
   */
  doubleElevenActivity_broadcast: {
    /*
     * 播报
     */
    get: (p, c) =>
      $.get("/api/bms/extension/doubleElevenActivity/broadcast", p, c),
  },
  doubleElevenActivity_currentCount: {
    /*
     * 总当前量
     */
    get: (p, c) =>
      $.get("/api/bms/extension/doubleElevenActivity/currentCount", p, c),
  },
  doubleElevenActivity_downloadModule: {
    /*
     * 模板下载
     */
    get: (p, c) =>
      $.get("/api/bms/extension/doubleElevenActivity/downloadModule", p, c),
  },
  doubleElevenActivity_listPersonRank: {
    /*
     * 个人排名
     * department 部门名称 (string query required)
     * type 类型 (string query required)
     */
    get: (p, c) =>
      $.get("/api/bms/extension/doubleElevenActivity/listPersonRank", p, c),
  },
  doubleElevenActivity_listTeamRank: {
    /*
     * 队伍排名
     * department 部门名称 (string query required)
     */
    get: (p, c) =>
      $.get("/api/bms/extension/doubleElevenActivity/listTeamRank", p, c),
  },
  doubleElevenActivity_targetCount: {
    /*
     * 总目标量
     */
    get: (p, c) =>
      $.get("/api/bms/extension/doubleElevenActivity/targetCount", p, c),
  },
  doubleElevenActivity_upload: {
    /*
     * 批量导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) =>
      $.post("/api/bms/extension/doubleElevenActivity/upload", p, c),
  },

  /**
   * 文件导入导出
   */
  fileUploadExport_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/bms/extension/fileUploadExport/list", p, c),
  },
  fileUploadExport_saveUpload: {
    /*
     * 添加导入
     * adminId 管理员id (integer query )
     * exportType 导出类型：实时导出、延时导出 (string query )
     * fileUrl 文件地址 (string query )
     * id  (integer query )
     * requestParams 请求参数 (string query )
     * requestPath 请求路径 (string query )
     * type 类型：导入、导出 (string query )
     */
    post: (p, c) =>
      $.post("/api/bms/extension/fileUploadExport/saveUpload", p, c),
  },

  /**
   * 人社证书团购活动
   */
  groupPayActivity_createGroupEnroll: {
    /*
     * 创建新团报
     * currentLevel 当前学历(本科及以上，本科以下） (string query required)
     * enrollTeacherId 招生老师ID (integer query required)
     * idCardNo 身份证 (string query required)
     * majorId 专业ID (integer query required)
     * name 姓名 (string query required)
     * phone 手机号 (string query required)
     * type 类型：团报、单报 (string query required)
     */
    put: (p, c) =>
      $.put("/api/bms/extension/groupPayActivity/createGroupEnroll", p, c),
  },
  groupPayActivity_getActivityTime: {
    /*
     * 查询活动时间
     */
    get: (p, c) =>
      $.get("/api/bms/extension/groupPayActivity/getActivityTime", p, c),
  },
  groupPayActivity_joinGroupEnroll: {
    /*
     * 加入团报
     * currentLevel 当前学历(本科及以上，本科以下） (string query required)
     * groupPayActivityId 学生分享码识别的ID (integer query required)
     * idCardNo 身份证 (string query required)
     * majorId 专业ID (integer query required)
     * name 姓名 (string query required)
     * phone 手机号 (string query required)
     */
    put: (p, c) =>
      $.put("/api/bms/extension/groupPayActivity/joinGroupEnroll", p, c),
  },
  groupPayActivity_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/bms/extension/groupPayActivity/list", p, c),
  },
  groupPayActivity_listActivityMajor: {
    /*
     * 查询活动专业
     */
    get: (p, c) =>
      $.get("/api/bms/extension/groupPayActivity/listActivityMajor", p, c),
  },
  groupPayActivity_listGroupId: {
    /*
     * 查询拼团编号列表
     */
    get: (p, c) =>
      $.get("/api/bms/extension/groupPayActivity/listGroupId", p, c),
  },
  groupPayActivity_scanCode: {
    /*
     * 扫码
     * enrollTeacherId enrollTeacherId (integer query required)
     */
    put: (p, c) => $.put("/api/bms/extension/groupPayActivity/scanCode", p, c),
  },
  groupPayActivity_searchByGroupId: {
    /*
     * 根据GroupId查询参团
     * groupId GroupId (integer query required)
     */
    get: (p, c) =>
      $.get("/api/bms/extension/groupPayActivity/searchByGroupId", p, c),
  },
  groupPayActivity_searchByPhone: {
    /*
     * 根据手机号查询参团列表
     * phone 手机号 (string query required)
     */
    get: (p, c) =>
      $.get("/api/bms/extension/groupPayActivity/searchByPhone", p, c),
  },

  /**
   * 扩展（其他）
   */
  other_broadcastDegreeEnroll: {
    /*
     * 播报学历招生情况
     * type 类型：学历、继教院、自考 (string query required)
     */
    post: (p, c) =>
      $.post("/api/bms/extension/other/broadcastDegreeEnroll", p, c),
  },
  other_cancelTravel: {
    /*
     * 查询转账状态
     * endDate 结束日期 (string query required)
     * endTime 结束时间：显示上午，下午，传 AM，PM (string query required)
     * phone 手机号 (string query required)
     * startDate 开始日期 (string query required)
     * startTime 开始时间：显示上午，下午，传 AM，PM (string query required)
     */
    post: (p, c) => $.post("/api/bms/extension/other/cancelTravel", p, c),
  },
  other_downloadEnrollLetter: {
    /*
     * 下载录取贺函
     * level 层次 (string query required)
     * major 专业 (string query required)
     * name 姓名 (string query required)
     * site 学校 (string query required)
     */
    get: (p, c) => $.get("/api/bms/extension/other/downloadEnrollLetter", p, c),
  },
  other_downloadServerFile: {
    /*
     * 下载服务器文件
     * filePath 文件路径 (string query required)
     */
    get: (p, c) => $.get("/api/bms/extension/other/downloadServerFile", p, c),
  },
  other_getAlipayBillUrl: {
    /*
     * 支付宝对账单
     * billDate 账单日期 (string query required)
     * payAccount 收款账号 (string query required)
     */
    get: (p, c) => $.get("/api/bms/extension/other/getAlipayBillUrl", p, c),
  },
  other_getCache: {
    /*
     * 根据类型获取缓存
     * type 类型 (string query required)
     */
    get: (p, c) => $.get("/api/bms/extension/other/getCache", p, c),
  },
  other_getCacheByAdmin: {
    /*
     * 根据token和类型获取缓存
     * type 类型 (string query required)
     */
    get: (p, c) => $.get("/api/bms/extension/other/getCacheByAdmin", p, c),
  },
  other_getCacheByToken: {
    /*
     * 根据token和类型获取缓存
     * type 类型 (string query required)
     */
    get: (p, c) => $.get("/api/bms/extension/other/getCacheByToken", p, c),
  },
  other_getRedis: {
    /*
     * 获取redis缓存
     * key key (string query required)
     */
    get: (p, c) => $.get("/api/bms/extension/other/getRedis", p, c),
  },
  other_getUpToken: {
    /*
     * 获取上传凭证
     * ext 文件扩展名 (string query required)
     * name 文件名 (string query )
     */
    get: (p, c) => $.get("/api/bms/extension/other/getUpToken", p, c),
  },
  other_getWechatBill: {
    /*
     * 微信对账单
     * billDate 账单日期 (string query required)
     * payAccount 收款账号 (string query required)
     */
    get: (p, c) => $.get("/api/bms/extension/other/getWechatBill", p, c),
  },
  other_htmlToImage: {
    /*
     * html转图片
     * url 地址 (string query required)
     */
    post: (p, c) => $.post("/api/bms/extension/other/htmlToImage", p, c),
  },
  other_parseIdCard: {
    /*
     * 解析身份证
     * cardSide 正面(front)｜反面(back) (string query required)
     * url 图片地址 (string query required)
     */
    post: (p, c) => $.post("/api/bms/extension/other/parseIdCard", p, c),
  },
  other_parseImg: {
    /*
     * 解析图片
     * url 图片地址 (string query required)
     */
    post: (p, c) => $.post("/api/bms/extension/other/parseImg", p, c),
  },
  other_parsePdf: {
    /*
     * 解析pdf
     * pdfFile pdfFile (file formData required)
     */
    post: (p, c) => $.post("/api/bms/extension/other/parsePdf", p, c),
  },
  other_parseWord: {
    /*
     * 解析word（纯文本）
     * wordFile wordFile (file formData required)
     */
    post: (p, c) => $.post("/api/bms/extension/other/parseWord", p, c),
  },
  other_parseWordTable: {
    /*
     * 解析word表格（多个表格以列表形式返回）
     * cellSplit 单元格分隔符 (string query required)
     * rowSplit 行分隔符 (string query required)
     * wordFile wordFile (file formData required)
     */
    post: (p, c) => $.post("/api/bms/extension/other/parseWordTable", p, c),
  },
  other_pay: {
    /*
     * 付款
     * amount 金额 (number query required)
     * payAccount 收款账号 (string query required)
     */
    post: (p, c) => $.post("/api/bms/extension/other/pay", p, c),
  },
  other_putCache: {
    /*
     * 根据类型存入缓存
     * content 内容 (string query required)
     * type 类型 (string query required)
     */
    post: (p, c) => $.post("/api/bms/extension/other/putCache", p, c),
  },
  other_putCacheByAdmin: {
    /*
     * 根据登录信息和类型存入缓存
     * content 内容 (string query required)
     * type 类型 (string query required)
     */
    post: (p, c) => $.post("/api/bms/extension/other/putCacheByAdmin", p, c),
  },
  other_putCacheByToken: {
    /*
     * 根据token和类型存入缓存
     * content 内容 (string query required)
     * type 类型 (string query required)
     */
    post: (p, c) => $.post("/api/bms/extension/other/putCacheByToken", p, c),
  },
  other_removeCache: {
    /*
     * 根据类型删除缓存
     * type 类型 (string query required)
     */
    delete: (p, c) => $.delete("/api/bms/extension/other/removeCache", p, c),
  },
  other_removeCacheByAdmin: {
    /*
     * 根据登录信息和类型删除缓存
     * type 类型 (string query required)
     */
    delete: (p, c) =>
      $.delete("/api/bms/extension/other/removeCacheByAdmin", p, c),
  },
  other_removeCacheByToken: {
    /*
     * 根据token和类型删除缓存
     * type 类型 (string query required)
     */
    delete: (p, c) =>
      $.delete("/api/bms/extension/other/removeCacheByToken", p, c),
  },
  other_searchOrderInfo: {
    /*
     * 查询订单信息
     * orderNo 订单号 (string query )
     * payAccount 收款账号 (string query required)
     */
    get: (p, c) => $.get("/api/bms/extension/other/searchOrderInfo", p, c),
  },
  other_searchTransferResp: {
    /*
     * 查询转账状态
     * outBizNo 订单号 (string query required)
     * payAccount 打款账号：公司支付宝 (string query required)
     */
    get: (p, c) => $.get("/api/bms/extension/other/searchTransferResp", p, c),
  },
  other_sendDingTalkMessage: {
    /*
     * 发送钉钉消息
     * adminId 管理员id (integer query required)
     * message 消息 (string query required)
     */
    post: (p, c) =>
      $.post("/api/bms/extension/other/sendDingTalkMessage", p, c),
  },
  other_testBroadcastPerson: {
    /*
     * 测试个人业绩播报
     * adminId 管理员id (integer query required)
     * clueActivateName 线索提供人 (string query )
     * site 学校 (string query )
     * siteType 学校类型 (string query required)
     */
    post: (p, c) =>
      $.post("/api/bms/extension/other/testBroadcastPerson", p, c),
  },
  other_timestamp: {
    /*
     * 服务器时间戳
     */
    get: (p, c) => $.get("/api/bms/extension/other/timestamp", p, c),
  },
  other_uploadFileToServer: {
    /*
     * 上传文件到服务器
     * file file (file formData required)
     */
    post: (p, c) => $.post("/api/bms/extension/other/uploadFileToServer", p, c),
  },
  other_uploadImageBase64ToServer: {
    /*
     * 上传图片base64到服务器
     * base64 base64 (string query required)
     */
    post: (p, c) =>
      $.post("/api/bms/extension/other/uploadImageBase64ToServer", p, c),
  },

  /**
   * 支付配置
   */
  payConfig: {
    /*
     * 添加
     * apiKey 接口秘钥 (string query )
     * companyBody 公司主体 (string query )
     * id  (integer query )
     * mchId 商户ID (string query )
     * mchPublicKey 商户公钥（支付宝使用） (string query )
     * mchSecret 商户密钥 (string query )
     * mchSn 证书序列号 (string query )
     * notifyUrl 通知地址 (string query )
     * payAccount 收款账号 (string query )
     * payAccountIdentity 收款账号标识 (string query )
     * payType 类型：支付宝，微信 (string query )
     * platform 平台（微信用） (string query )
     * testNotifyUrl 测试环境通知地址 (string query )
     */
    post: (p, c) => $.post("/api/bms/extension/payConfig", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/bms/extension/payConfig", p, c),
  },
  payConfig_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/bms/extension/payConfig/list", p, c),
  },
  payConfig_listAccountByType: {
    /*
     * 根据类型获取账号列表
     * type 类型：支付宝、微信 (string query )
     */
    get: (p, c) =>
      $.get("/api/bms/extension/payConfig/listAccountByType", p, c),
  },

  /**
   * 任务
   */
  task: {
    /*
     * 添加
     * category 类别 (string query )
     * createId 发布人 (integer query )
     * endTime 结束时间 (string query )
     * finishTime 实际完成时间 (string query )
     * id  (integer query )
     * label 标签 (string query )
     * name 任务名称 (string query )
     * publishPlatform 发布平台 (string query )
     * publishStatus 发布状态 (string query )
     * publishTime 发布时间 (string query )
     * remark 备注 (string query )
     * result 结果 (string query )
     * startTime 开始时间 (string query )
     * status 状态：未完成、按时完成、已超期、超期完成 (string query )
     * weight 权重 (integer query )
     */
    post: (p, c) => $.post("/api/bms/extension/task", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/bms/extension/task", p, c),
  },
  task_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/bms/extension/task/export", p, c),
  },
  task_gantt: {
    /*
     * 甘特图
     * endTime 结束时间 (string query required)
     * startTime 开始时间 (string query required)
     */
    get: (p, c) => $.get("/api/bms/extension/task/gantt", p, c),
  },
  task_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/bms/extension/task/list", p, c),
  },
  task_listSimple: {
    /*
     * 简易列表
     */
    get: (p, c) => $.get("/api/bms/extension/task/listSimple", p, c),
  },
  task_listUnFinishByAdmin: {
    /*
     * 未完成简易列表(任务发布人)
     */
    get: (p, c) => $.get("/api/bms/extension/task/listUnFinishByAdmin", p, c),
  },
  task_listUnFinishByRequirementAdmin: {
    /*
     * 未完成简易列表（任务要求负责人）
     */
    get: (p, c) =>
      $.get("/api/bms/extension/task/listUnFinishByRequirementAdmin", p, c),
  },
  task_publish: {
    /*
     * 发布
     * id id (integer query required)
     */
    put: (p, c) => $.put("/api/bms/extension/task/publish", p, c),
  },
  task_updateLabel: {
    /*
     * 编辑标签
     * id id (integer query required)
     * label 标签 (string query required)
     */
    put: (p, c) => $.put("/api/bms/extension/task/updateLabel", p, c),
  },

  /**
   * 任务落地
   */
  taskLanding: {
    /*
     * 添加
     * adminId 执行人id (integer query )
     * adminIds 执行人ids (array query )
     * content 落地内容 (string query required)
     * detail 详情 (string query )
     * endTime 结束时间 (string query required)
     * id id (integer query )
     * requirementId 要求id (integer query required)
     * startTime 开始时间 (string query required)
     * weight 权重 (integer query required)
     */
    post: (p, c) => $.post("/api/bms/extension/taskLanding", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/bms/extension/taskLanding", p, c),
  },
  taskLanding_applyFinish: {
    /*
     * 申请完成
     * attach 数据验证 (string query required)
     * cause 产生原因 (string query required)
     * comment 申请备注 (string query required)
     * countermeasure 对应措施 (string query required)
     * dataVerification 数据验证 (string query required)
     * id id (integer query required)
     */
    post: (p, c) => $.post("/api/bms/extension/taskLanding/applyFinish", p, c),
  },
  taskLanding_auditFinish: {
    /*
     * 审核完成
     * auditStatus 审核状态 (string query required)
     * comment 审核备注 (string query required)
     * finishTime 实际完成时间 (string query )
     * flowId 节点id (integer query required)
     * id id (integer query required)
     * result 结果 (string query )
     */
    post: (p, c) => $.post("/api/bms/extension/taskLanding/auditFinish", p, c),
  },
  taskLanding_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/bms/extension/taskLanding/export", p, c),
  },
  taskLanding_gantt: {
    /*
     * 甘特图
     * endTime 结束时间 (string query required)
     * startTime 开始时间 (string query required)
     */
    get: (p, c) => $.get("/api/bms/extension/taskLanding/gantt", p, c),
  },
  taskLanding_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/bms/extension/taskLanding/list", p, c),
  },
  taskLanding_upload: {
    /*
     * 导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/bms/extension/taskLanding/upload", p, c),
  },

  /**
   * 任务要求
   */
  taskRequirement: {
    /*
     * 添加
     * adminId 负责人 (integer query )
     * approvalId 审批流id (integer query )
     * content 要求内容 (string query )
     * endTime 结束时间 (string query )
     * finishTime 实际完成时间 (string query )
     * id  (integer query )
     * publishStatus 发布状态 (string query )
     * publishTime 发布时间 (string query )
     * result 提交结果 (string query )
     * startTime 开始时间 (string query )
     * status 状态：未完成、按时完成、已超期、超期完成 (string query )
     * taskId 任务id (integer query )
     * weight 权重 (integer query )
     */
    post: (p, c) => $.post("/api/bms/extension/taskRequirement", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/bms/extension/taskRequirement", p, c),
  },
  taskRequirement_applyFinish: {
    /*
     * 申请完成
     * attach 附件 (string query )
     * comment 申请备注 (string query required)
     * id id (integer query required)
     */
    post: (p, c) =>
      $.post("/api/bms/extension/taskRequirement/applyFinish", p, c),
  },
  taskRequirement_auditFinish: {
    /*
     * 审核完成
     * auditStatus 审核状态 (string query required)
     * comment 审核备注 (string query required)
     * finishTime 实际完成时间 (string query )
     * flowId 节点id (integer query required)
     * id id (integer query required)
     * result 结果 (string query )
     * taskResult 任务结果 (string query )
     */
    post: (p, c) =>
      $.post("/api/bms/extension/taskRequirement/auditFinish", p, c),
  },
  taskRequirement_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/bms/extension/taskRequirement/export", p, c),
  },
  taskRequirement_gantt: {
    /*
     * 甘特图
     * endTime 结束时间 (string query required)
     * startTime 开始时间 (string query required)
     */
    get: (p, c) => $.get("/api/bms/extension/taskRequirement/gantt", p, c),
  },
  taskRequirement_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/bms/extension/taskRequirement/list", p, c),
  },
  taskRequirement_listSimple: {
    /*
     * 简易列表
     * taskId 任务id (integer query required)
     */
    get: (p, c) => $.get("/api/bms/extension/taskRequirement/listSimple", p, c),
  },
  taskRequirement_listUnFinishByAdmin: {
    /*
     * 未完成简易列表
     * taskId 任务id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/bms/extension/taskRequirement/listUnFinishByAdmin", p, c),
  },
  taskRequirement_publish: {
    /*
     * 发布
     * id id (integer query required)
     */
    put: (p, c) => $.put("/api/bms/extension/taskRequirement/publish", p, c),
  },
  taskRequirement_upload: {
    /*
     * 导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/bms/extension/taskRequirement/upload", p, c),
  },

  /**
   * 微信
   */
  wechat_checkOpenId: {
    /*
     * 检查openId
     * platform 平台 (string query required)
     * type 类型：小程序，公众号 (string query required)
     */
    get: (p, c) => $.get("/api/bms/extension/wechat/checkOpenId", p, c),
  },
  wechat_decryptMiniProgram: {
    /*
     * 小程序数据解密,解密后数据自动处理
     * encryptedData 加密数据 (string query required)
     * iv iv (string query required)
     * platform 平台 (string query required)
     */
    get: (p, c) => $.get("/api/bms/extension/wechat/decryptMiniProgram", p, c),
  },
  wechat_getAccessToken: {
    /*
     * 获取AccessToken
     * platform 平台 (string query required)
     */
    get: (p, c) => $.get("/api/bms/extension/wechat/getAccessToken", p, c),
  },
  wechat_getRequest: {
    /*
     * 微信get请求
     * platform 平台 (string query required)
     * url url (string query required)
     */
    get: (p, c) => $.get("/api/bms/extension/wechat/getRequest", p, c),
  },
  wechat_getSignature: {
    /*
     * 获取签名
     * platform 平台 (string query required)
     * url url (string query required)
     */
    get: (p, c) => $.get("/api/bms/extension/wechat/getSignature", p, c),
  },
  wechat_postRequest: {
    /*
     * 微信post请求
     * body 请求体 (string query required)
     * platform 平台 (string query required)
     * url url (string query required)
     */
    get: (p, c) => $.get("/api/bms/extension/wechat/postRequest", p, c),
  },

  /**
   * 微信配置
   */
  wechatConfig: {
    /*
     * 添加
     * accountAppId 公众号应用ID (string query )
     * accountSecret 公众号密钥 (string query )
     * appAppId APP应用ID (string query )
     * appSecret APP密钥 (string query )
     * id  (integer query )
     * mpAppId 小程序应用ID (string query )
     * mpSecret 小程序密钥 (string query )
     * platform 平台名称 (string query )
     * platformIdentity 平台标识 (string query )
     * welcome 欢迎词 (string query )
     */
    post: (p, c) => $.post("/api/bms/extension/wechatConfig", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/bms/extension/wechatConfig", p, c),
  },
  wechatConfig_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/bms/extension/wechatConfig/list", p, c),
  },
  wechatConfig_listPlatform: {
    /*
     * 平台列表
     */
    get: (p, c) => $.get("/api/bms/extension/wechatConfig/listPlatform", p, c),
  },
  wechatConfig_refresh: {
    /*
     * 刷新缓存
     */
    put: (p, c) => $.put("/api/bms/extension/wechatConfig/refresh", p, c),
  },

  /**
   * 微信消息
   */
  wechatMessage_resend: {
    /*
     * 重新发送
     * id id (integer query required)
     */
    post: (p, c) => $.post("/api/bms/extension/wechatMessage/resend", p, c),
  },
  wechatMessage_send: {
    /*
     * 发送微信模板消息
     * first 第一个关键字 (string query required)
     * keywords 中间关键字填充，多个用英文逗号隔开 (array query required)
     * miniProgramUrl 小程序跳转地址 (string query )
     * platform 平台 (string query required)
     * remark 最后一个关键字 (string query required)
     * title 标题 (string query required)
     * url 跳转地址 (string query )
     * userId 用户id (integer query required)
     */
    post: (p, c) => $.post("/api/bms/extension/wechatMessage/send", p, c),
  },

  /**
   * 微信模板
   */
  wechatTemplate_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/bms/extension/wechatTemplate/list", p, c),
  },
  wechatTemplate_listTitle: {
    /*
     * 标题列表
     * platform 平台 (string query required)
     */
    get: (p, c) => $.get("/api/bms/extension/wechatTemplate/listTitle", p, c),
  },
  wechatTemplate_listTitleContent: {
    /*
     * 根据平台查询简单模板列表
     * platform 平台 (string query required)
     */
    get: (p, c) =>
      $.get("/api/bms/extension/wechatTemplate/listTitleContent", p, c),
  },
  wechatTemplate_refresh: {
    /*
     * 刷新
     * platform 平台 (string query required)
     */
    put: (p, c) => $.put("/api/bms/extension/wechatTemplate/refresh", p, c),
  },
};
