export default {
  path: "/course",
  meta: {
    title: "新闻中心",
  },
  component: () => import("@/views/common/moduleMain.vue"),
  children: [
    {
      path: "/",
      redirect: "list",
    },
    {
      path: "list",
      meta: {
        title: "列表",
      },
      component: () => import("@/views/course/course.vue"),
    },
    {
      path: "detail",
      meta: {
        title: "详情",
      },
      component: () => import("@/views/course/courseDetail.vue"),
    },
  ],
};
