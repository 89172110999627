import { $ } from "@/common/Axios";

export default {
  /**
   * 资料收集
   */
  record_detail: {
    /*
     * 详情
     * collectionId collectionId (integer query required)
     * commitStatus 提交状态 (string query )
     * headerTeacherId 班主任ID (integer query )
     * idCardNo 身份证 (string query )
     * name 学生名字 (string query )
     * schoolStatus 学籍状态 (string query )
     * siteType 学校类型 (string query )
     * source 1教务模块进入 2服务模块进入 (integer query required)
     * type type (string query required)
     */
    get: (p, c) => $.get("/api/ems/collect/record/detail", p, c),
  },
  record_export: {
    /*
     * 导出
     * collectionId collectionId (integer query required)
     * commitStatus 提交状态 (string query )
     * headerTeacherId 班主任ID (integer query )
     * siteType 学校类型 (string query )
     * source 1教务模块进入 2服务模块进入 (integer query required)
     * type type (string query required)
     */
    get: (p, c) => $.get("/api/ems/collect/record/export", p, c),
  },
  record_listField: {
    /*
     * 采集字段列表
     * id ID (integer query required)
     */
    get: (p, c) => $.get("/api/ems/collect/record/listField", p, c),
  },
  record_listForEdu: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/collect/record/listForEdu", p, c),
  },
  record_listForService: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/collect/record/listForService", p, c),
  },
  record_publish: {
    /*
     * 发布
     * id id (integer query required)
     * publishStatus 发布状态：已发布、未发布 (string query required)
     */
    post: (p, c) => $.post("/api/ems/collect/record/publish", p, c),
  },
  record_remove: {
    /*
     * 删除
     * id ID (integer query )
     */
    delete: (p, c) => $.delete("/api/ems/collect/record/remove", p, c),
  },
  record_removeField: {
    /*
     * 删除采集字段
     * id ID (integer query required)
     */
    delete: (p, c) => $.delete("/api/ems/collect/record/removeField", p, c),
  },
  record_save: {
    /*
     * 添加编辑
     * content 值 (string query required)
     * deadline 截止时间 (string query required)
     * helperFileUrl 操作视频或文档 (string query )
     * id ID (integer query )
     * name 名称 (string query required)
     * type 筛选类型（批次、学籍年级、成考年度三选一） (string query required)
     */
    post: (p, c) => $.post("/api/ems/collect/record/save", p, c),
  },
  record_saveField: {
    /*
     * 添加编辑采集字段
     * collectionId collectionId (integer query required)
     * dataType 数据类型（文本、数字、下拉、日期、图片、文档、附件） (string query required)
     * displayCondition 显示条件 (string query )
     * displayName 显示名称 (string query required)
     * displayStatus 显示状态(总是显示、条件显示） (string query required)
     * dropdownContent 下拉值 (string query )
     * fieldLength 长度限制 (integer query )
     * id id (integer query )
     * requiredFlag 是否必填 (string query required)
     * sorting 顺序 (integer query required)
     */
    post: (p, c) => $.post("/api/ems/collect/record/saveField", p, c),
  },
  record_searchFieldInfo: {
    /*
     * 获取编辑字段信息
     * collectionId collectionId (integer query required)
     * schoolRollId 合同编号 (integer query required)
     */
    get: (p, c) => $.get("/api/ems/collect/record/searchFieldInfo", p, c),
  },
  record_updateContent: {
    /*
     * 提交字段内容
     * fieldInfo 字段提交信息Json (string query )
     */
    put: (p, c) => $.put("/api/ems/collect/record/updateContent", p, c),
  },

  /**
   * 学生资料收集
   */
  student_commitFieldContent: {
    /*
     * 提交字段内容
     * fieldInfo 字段提交信息Json (string query )
     */
    put: (p, c) => $.put("/api/ems/collect/student/commitFieldContent", p, c),
  },
  student_searchByIdCardNo: {
    /*
     * 根据身份号查询信息
     * collectionId collectionId (integer query required)
     * idCardNo 身份证 (string query required)
     */
    get: (p, c) => $.get("/api/ems/collect/student/searchByIdCardNo", p, c),
  },
};
