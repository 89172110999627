import content from "./content";
import resource from "./resource";
import web from "./web";

/**
 * 内容微服务
 */
export default {
  content,
  resource,
  web,
};
