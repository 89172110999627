<template>
  <div id="app" class="g-app">
    <router-view />
  </div>
</template>

<script>
import "element-ui/lib/theme-chalk/index.css";
import "swiper/swiper.less";
import jsCookie from "js-cookie";
import config from "../config";
import store from "./store";
export default {
  name: "app",
  data() {
    return {};
  },
  async beforeCreate() {
    try {
      let userInfoStr;
      const temp = JSON.parse(jsCookie.get(`${config.NAME}_userInfo`));
      userInfoStr = localStorage.getItem(`${config.NAME}_userInfo_${temp.id}`);
      await store.dispatch("userInfoUpdate", JSON.parse(userInfoStr));
    } catch (e) {
      //
    }
  },
  mounted() {},
};
</script>
<style lang="less">
@import "assets/css/reset";
@import "assets/css/style";
@import "assets/css/media";
</style>
