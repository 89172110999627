<template>
  <div class="g-main">
    <!--顶部11-->
    <kf-header />

    <!--内容-->
    <router-view />

    <!--底部-->
    <kf-footer />
  </div>
</template>

<script>
import page_mx from "@/mixin/page_mx";
import kfHeader from "@/components/kf-header";
import kfFooter from "@/components/kf-footer";

export default {
  mixins: [page_mx],
  data() {
    return {};
  },
  components: { kfHeader, kfFooter },
  mounted() {},
  methods: {},
};
</script>
