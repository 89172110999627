import { $ } from "@/common/Axios";

export default {
  /**
   * ＡＰＰ版本管理
   */
  appVersion: {
    /*
     * 添加
     * channel 渠道 (string query )
     * content 版本更新消息 (string query )
     * deviceType 设备类型：安卓，苹果 (string query )
     * download 新版apk或补丁下载地址 (string query )
     * forceCheck 是否强制更新：是，否 (string query )
     * id  (integer query )
     * name APP名称 (string query )
     * title 更新标题 (string query )
     * versionCode 版本号 (integer query )
     * versionName 版本名称 (string query )
     */
    post: (p, c) => $.post("/api/bms/develop/appVersion", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/bms/develop/appVersion", p, c),
  },
  appVersion_getAppVersion: {
    /*
     * 获取Ａｐｐ版本
     * channel 渠道 (string query )
     * deviceType 设备 (string query required)
     * name App名字 (string query required)
     */
    get: (p, c) => $.get("/api/bms/develop/appVersion/getAppVersion", p, c),
  },
  appVersion_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/bms/develop/appVersion/list", p, c),
  },

  /**
   * 回调管理
   */
  callback_handle: {
    /*
     * 处理
     * id id (integer query required)
     */
    put: (p, c) => $.put("/api/bms/develop/callback/handle", p, c),
  },
  callback_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/bms/develop/callback/list", p, c),
  },

  /**
   * 系统配置
   */
  config: {
    /*
     * 添加
     * configKey 键名 (string query )
     * configValue 键值 (string query )
     * id  (integer query )
     * remark 备注 (string query )
     * type 类型 (string query )
     */
    post: (p, c) => $.post("/api/bms/develop/config", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/bms/develop/config", p, c),
  },
  config_getConfigByKey: {
    /*
     * 获取字符串类型的值
     * key 键 (string query required)
     */
    get: (p, c) => $.get("/api/bms/develop/config/getConfigByKey", p, c),
  },
  config_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/bms/develop/config/list", p, c),
  },
  config_listSelect: {
    /*
     * 选项列表
     * ableStatus 启用状态 (string query )
     * key 键 (string query )
     * parent 父键 (string query )
     */
    get: (p, c) => $.get("/api/bms/develop/config/listSelect", p, c),
  },

  /**
   * 易快报单据
   */
  ekuaibaoDoc_listAdmin: {
    /*
     * 管理员列表
     */
    get: (p, c) => $.get("/api/bms/develop/ekuaibaoDoc/listAdmin", p, c),
  },
  ekuaibaoDoc_sync: {
    /*
     * 同步
     * index 索引 (integer query required)
     */
    put: (p, c) => $.put("/api/bms/develop/ekuaibaoDoc/sync", p, c),
  },

  /**
   * 意见反馈
   */
  feedback: {
    /*
     * 添加
     * appName 应用名称 (string query )
     * content 内容 (string query )
     * id  (integer query )
     * imageOne 图片1 (string query )
     * imageThree 图片3 (string query )
     * imageTwo 图片2 (string query )
     * userId 用户id (integer query )
     */
    post: (p, c) => $.post("/api/bms/develop/feedback", p, c),
  },
  feedback_temp: {
    /*
     * 测试获取工资配置
     */
    get: (p, c) => $.get("/api/bms/develop/feedback/temp", p, c),
  },

  /**
   * 需求管理
   */
  requirement_audit: {
    /*
     * 审核
     * flowId id (integer query required)
     * id id (integer query required)
     * periodDays 开发周期(技术审核传入) (number query )
     * refuseReason 拒绝理由 (string query )
     * type 操作类型：通过|拒绝 (string query required)
     */
    put: (p, c) => $.put("/api/bms/develop/requirement/audit", p, c),
  },
  requirement_detail: {
    /*
     * 详情
     * id id (integer query )
     */
    get: (p, c) => $.get("/api/bms/develop/requirement/detail", p, c),
  },
  requirement_export: {
    /*
     * 导出(开发管理-需求管理)
     */
    get: (p, c) => $.get("/api/bms/develop/requirement/export", p, c),
  },
  requirement_leaderUpdate: {
    /*
     * 编辑（项目负责人）
     * actualOnlineTime 实际上线时间 (string query )
     * docQiniuUrl 使用文档地址 (string query )
     * id id (integer query required)
     * preOnlineTime 预计交付时间 (string query )
     * preStartTime 预计开发开始时间 (string query )
     * remark 项目负责人备注 (string query )
     * step 阶段 (string query required)
     * urgencyLevel 紧急程度 (string query )
     */
    put: (p, c) => $.put("/api/bms/develop/requirement/leaderUpdate", p, c),
  },
  requirement_list: {
    /*
     * 列表(开发管理-需求管理)
     */
    get: (p, c) => $.get("/api/bms/develop/requirement/list", p, c),
  },
  requirement_listApplyDepartment: {
    /*
     * 登录人可添加需求部门列表， 为空则无添加权限
     */
    get: (p, c) =>
      $.get("/api/bms/develop/requirement/listApplyDepartment", p, c),
  },
  requirement_listForApply: {
    /*
     * 列表(内部管理-需求开发)
     */
    get: (p, c) => $.get("/api/bms/develop/requirement/listForApply", p, c),
  },
  requirement_listPartnerDepartment: {
    /*
     * 配合部门列表
     */
    get: (p, c) =>
      $.get("/api/bms/develop/requirement/listPartnerDepartment", p, c),
  },
  requirement_listRequirementDepartment: {
    /*
     * 简单需求部门列表
     */
    get: (p, c) =>
      $.get("/api/bms/develop/requirement/listRequirementDepartment", p, c),
  },
  requirement_remove: {
    /*
     * 删除-撤回和拒绝状态下
     * id ID (integer query )
     */
    delete: (p, c) => $.delete("/api/bms/develop/requirement/remove", p, c),
  },
  requirement_save: {
    /*
     * 添加
     * department 需求部门 (string query required)
     * description 具体开发内容 (string query required)
     * extraQiniuUrl 需求文档 (string query )
     * name 需求名称 (string query required)
     * partnerDepartments 其他配合部门，多选逗号隔开 (string query )
     * purpose 开发目的 (string query required)
     */
    post: (p, c) => $.post("/api/bms/develop/requirement/save", p, c),
  },
  requirement_update: {
    /*
     * 编辑
     * description 具体开发内容 (string query required)
     * extraQiniuUrl 需求文档 (string query )
     * id id (integer query )
     * name 需求名称 (string query required)
     * partnerDepartments 其他配合部门，多选逗号隔开 (string query )
     * purpose 开发目的 (string query required)
     */
    post: (p, c) => $.post("/api/bms/develop/requirement/update", p, c),
  },
  requirement_withDraw: {
    /*
     * 撤回
     * id id (integer query required)
     */
    put: (p, c) => $.put("/api/bms/develop/requirement/withDraw", p, c),
  },

  /**
   * 需求部门配置
   */
  requirementConfig_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/bms/develop/requirementConfig/list", p, c),
  },
  requirementConfig_listCopyReceiver: {
    /*
     * 抄送人列表
     * id id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/bms/develop/requirementConfig/listCopyReceiver", p, c),
  },
  requirementConfig_save: {
    /*
     * 添加
     * applyId 需求申请人 (integer query required)
     * auditId 需求审核人 (integer query required)
     * copyReceiverIds 抄送人ID列表,逗号隔开 (array query )
     * department 部门名称 (string query required)
     * id id (integer query )
     */
    post: (p, c) => $.post("/api/bms/develop/requirementConfig/save", p, c),
  },
};
