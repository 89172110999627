import { $ } from "@/common/Axios";

export default {
  /**
   * 成考考区
   */
  adultExamArea: {
    /*
     * 添加
     * area 成考考区 (string query )
     * city 成考县级市 (string query )
     * enrollPoint 报名点 (string query )
     * id  (integer query )
     * province 省份 (string query )
     */
    post: (p, c) => $.post("/api/ems/basic/adultExamArea", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/ems/basic/adultExamArea", p, c),
  },
  adultExamArea_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ems/basic/adultExamArea/export", p, c),
  },
  adultExamArea_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/basic/adultExamArea/list", p, c),
  },
  adultExamArea_listArea: {
    /*
     * 考区列表
     * province 省份 (string query )
     */
    get: (p, c) => $.get("/api/ems/basic/adultExamArea/listArea", p, c),
  },
  adultExamArea_listCity: {
    /*
     * 县级市列表
     * area 考区 (string query )
     * province 省份 (string query )
     */
    get: (p, c) => $.get("/api/ems/basic/adultExamArea/listCity", p, c),
  },
  adultExamArea_upload: {
    /*
     * 导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ems/basic/adultExamArea/upload", p, c),
  },

  /**
   * 文件政策管理
   */
  basicEduFile_list: {
    /*
     * 学历教育平台-文件政策列表
     */
    get: (p, c) => $.get("/api/ems/basic/basicEduFile/list", p, c),
  },
  basicEduFile_listForBasic: {
    /*
     * 基础平台-文件政策列表
     */
    get: (p, c) => $.get("/api/ems/basic/basicEduFile/listForBasic", p, c),
  },
  basicEduFile_listForFin: {
    /*
     * 财务平台-文件政策列表
     */
    get: (p, c) => $.get("/api/ems/basic/basicEduFile/listForFin", p, c),
  },
  basicEduFile_listForPersonnel: {
    /*
     * 人事平台-文件政策列表
     */
    get: (p, c) => $.get("/api/ems/basic/basicEduFile/listForPersonnel", p, c),
  },
  basicEduFile_listRecord: {
    /*
     * 历史版本列表
     * id 行ID (integer query required)
     */
    get: (p, c) => $.get("/api/ems/basic/basicEduFile/listRecord", p, c),
  },
  basicEduFile_remove: {
    /*
     * 删除
     * id ID (integer query )
     */
    delete: (p, c) => $.delete("/api/ems/basic/basicEduFile/remove", p, c),
  },
  basicEduFile_save: {
    /*
     * 添加-学历教育平台
     * category 分类 (string query required)
     * extraUrl 附件地址 (string query required)
     * name 名称 (string query required)
     * platform 平台 (string query required)
     * type 类型：政策文件、学位文件等 (string query required)
     */
    post: (p, c) => $.post("/api/ems/basic/basicEduFile/save", p, c),
  },
  basicEduFile_update: {
    /*
     * 编辑
     * category 分类 (string query required)
     * extraUrl 附件地址 (string query required)
     * id id (integer query required)
     * name 名称 (string query required)
     * type 类型：政策文件、学位文件等 (string query required)
     */
    post: (p, c) => $.post("/api/ems/basic/basicEduFile/update", p, c),
  },

  /**
   * 期末考区
   */
  campus: {
    /*
     * 添加
     * ableStatus 启用状态 (string query )
     * id  (integer query )
     * name 期末考区名字 (string query )
     * siteId 学校id (integer query )
     * type 类型：期末考区或企业学生期末考点 (string query )
     */
    post: (p, c) => $.post("/api/ems/basic/campus", p, c),
  },
  campus_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ems/basic/campus/export", p, c),
  },
  campus_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/basic/campus/list", p, c),
  },
  campus_listAllCentre: {
    /*
     * 获取所有线下考点列表
     */
    get: (p, c) => $.get("/api/ems/basic/campus/listAllCentre", p, c),
  },
  campus_listCentre: {
    /*
     * 根据考区ID获取线下考点列表
     * id 行ID (integer query )
     */
    get: (p, c) => $.get("/api/ems/basic/campus/listCentre", p, c),
  },
  campus_listEnterpriseExamPoint: {
    /*
     * 企业学生期末考点列表
     */
    get: (p, c) => $.get("/api/ems/basic/campus/listEnterpriseExamPoint", p, c),
  },
  campus_listName: {
    /*
     * 考区名字
     * ableStatus 启用禁用 (string query )
     * siteId 学校id (integer query )
     */
    get: (p, c) => $.get("/api/ems/basic/campus/listName", p, c),
  },
  campus_saveCentre: {
    /*
     * 编辑考点
     * centreIds 线下考点IDS (array query required)
     * id 行ID (integer query required)
     */
    post: (p, c) => $.post("/api/ems/basic/campus/saveCentre", p, c),
  },
  campus_upload: {
    /*
     * 导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ems/basic/campus/upload", p, c),
  },

  /**
   * 和同方
   */
  contractParty: {
    /*
     * 添加
     * id  (integer query )
     * name 合同方名称 (string query )
     * seal 合同章 (string query )
     */
    post: (p, c) => $.post("/api/ems/basic/contractParty", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/ems/basic/contractParty", p, c),
  },
  contractParty_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/basic/contractParty/list", p, c),
  },
  contractParty_listSimple: {
    /*
     * 简易和同方列表
     */
    get: (p, c) => $.get("/api/ems/basic/contractParty/listSimple", p, c),
  },

  /**
   * 和同方学校
   */
  contractPartySite: {
    /*
     * 添加
     * attachment 附件 (string query )
     * contractId 合同方id (integer query )
     * endTime 结束时间 (string query )
     * id  (integer query )
     * siteId 学校id (integer query )
     * startTime 开始时间 (string query )
     */
    post: (p, c) => $.post("/api/ems/basic/contractPartySite", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/ems/basic/contractPartySite", p, c),
  },
  contractPartySite_list: {
    /*
     * 列表
     * id 和同方id (integer query required)
     */
    get: (p, c) => $.get("/api/ems/basic/contractPartySite/list", p, c),
  },

  /**
   * 成考科目
   */
  disciplineSubjects_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/basic/disciplineSubjects/list", p, c),
  },
  disciplineSubjects_remove: {
    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) =>
      $.delete("/api/ems/basic/disciplineSubjects/remove", p, c),
  },
  disciplineSubjects_save: {
    /*
     * 添加编辑
     * discipline 科类 (string query )
     * id  (integer query )
     * level 层次 (string query )
     * subjects 入学考试科目 (string query )
     */
    post: (p, c) => $.post("/api/ems/basic/disciplineSubjects/save", p, c),
  },

  /**
   * 文件政策配置
   */
  eduFileConfig_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/basic/eduFileConfig/list", p, c),
  },
  eduFileConfig_listCategories: {
    /*
     * 简易分类列表
     * platform 平台 (string query )
     */
    get: (p, c) => $.get("/api/ems/basic/eduFileConfig/listCategories", p, c),
  },
  eduFileConfig_listTypes: {
    /*
     * 简易具体类型列表
     * category 分类 (string query )
     * platform 平台 (string query )
     */
    get: (p, c) => $.get("/api/ems/basic/eduFileConfig/listTypes", p, c),
  },
  eduFileConfig_save: {
    /*
     * 添加编辑
     * category 文件分类 (string query )
     * creatorId  (integer query )
     * id  (integer query )
     * platform 平台 (string query )
     * type 具体类型 (string query )
     */
    post: (p, c) => $.post("/api/ems/basic/eduFileConfig/save", p, c),
  },

  /**
   * 教务word模板
   */
  educationWordModel: {
    /*
     * 添加
     * filePath 模板本地地址 (string query )
     * id  (integer query )
     * name 模板名字 (string query )
     * siteName 学校名字 (string query )
     * siteType 学校类型 (string query )
     * type 模板类型 (string query )
     */
    post: (p, c) => $.post("/api/ems/basic/educationWordModel", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/ems/basic/educationWordModel", p, c),
  },
  educationWordModel_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/basic/educationWordModel/list", p, c),
  },
  educationWordModel_listForSelfTaught: {
    /*
     * 列表-自考
     */
    get: (p, c) =>
      $.get("/api/ems/basic/educationWordModel/listForSelfTaught", p, c),
  },

  /**
   * 招生计划
   */
  enrollPlan: {
    /*
     * 添加
     * adultExamYear 成考年度 (string query )
     * id  (integer query )
     * majorId 专业id (integer query )
     * planCount 计划人数 (integer query )
     * siteId 学校id (integer query )
     */
    post: (p, c) => $.post("/api/ems/basic/enrollPlan", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/ems/basic/enrollPlan", p, c),
  },
  enrollPlan_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ems/basic/enrollPlan/export", p, c),
  },
  enrollPlan_exportForDetail: {
    /*
     * 导出详情
     * id id (integer query required)
     */
    get: (p, c) => $.get("/api/ems/basic/enrollPlan/exportForDetail", p, c),
  },
  enrollPlan_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/basic/enrollPlan/list", p, c),
  },
  enrollPlan_listForDetail: {
    /*
     * 列表
     * id id (integer query required)
     */
    get: (p, c) => $.get("/api/ems/basic/enrollPlan/listForDetail", p, c),
  },
  enrollPlan_upload: {
    /*
     * 导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ems/basic/enrollPlan/upload", p, c),
  },
  enrollPlan_uploadForDetail: {
    /*
     * 导入详情
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ems/basic/enrollPlan/uploadForDetail", p, c),
  },

  /**
   * 招生简章
   */
  enrollRecruitmentBrochure_export: {
    /*
     * 导出
     */
    get: (p, c) =>
      $.get("/api/ems/basic/enrollRecruitmentBrochure/export", p, c),
  },
  enrollRecruitmentBrochure_getBySiteId: {
    /*
     * 根据学校ID查询招生简章（H5网页版）
     * siteId siteId (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/basic/enrollRecruitmentBrochure/getBySiteId", p, c),
  },
  enrollRecruitmentBrochure_getBySiteIdForPicture: {
    /*
     * 根据学校ID查询招生简章（图片版）
     * siteId siteId (integer query required)
     */
    get: (p, c) =>
      $.get(
        "/api/ems/basic/enrollRecruitmentBrochure/getBySiteIdForPicture",
        p,
        c
      ),
  },
  enrollRecruitmentBrochure_getByTypeAndLevel: {
    /*
     * 根据层次查询科类和专业列表
     * level 层次 (string query required)
     * siteType 学校类型:只允许成教 (string query required)
     */
    get: (p, c) =>
      $.get("/api/ems/basic/enrollRecruitmentBrochure/getByTypeAndLevel", p, c),
  },
  enrollRecruitmentBrochure_list: {
    /*
     * 招生简章列表
     */
    get: (p, c) => $.get("/api/ems/basic/enrollRecruitmentBrochure/list", p, c),
  },
  enrollRecruitmentBrochure_listRecord: {
    /*
     * 历史版本列表
     */
    get: (p, c) =>
      $.get("/api/ems/basic/enrollRecruitmentBrochure/listRecord", p, c),
  },
  enrollRecruitmentBrochure_listSimpleSite: {
    /*
     * 简单学校列表
     */
    get: (p, c) =>
      $.get("/api/ems/basic/enrollRecruitmentBrochure/listSimpleSite", p, c),
  },
  enrollRecruitmentBrochure_remove: {
    /*
     * 批量删除
     * ids 行ID (array query required)
     */
    delete: (p, c) =>
      $.delete("/api/ems/basic/enrollRecruitmentBrochure/remove", p, c),
  },

  /**
   * 线下考点
   */
  examinationCentre_ableClassroom: {
    /*
     * 启用禁用教室
     * ableStatus 启用禁用 (string query required)
     * id id (integer query )
     */
    post: (p, c) =>
      $.post("/api/ems/basic/examinationCentre/ableClassroom", p, c),
  },
  examinationCentre_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/basic/examinationCentre/list", p, c),
  },
  examinationCentre_listClassroom: {
    /*
     * 教室列表
     * id 行ID (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/basic/examinationCentre/listClassroom", p, c),
  },
  examinationCentre_listSimpleClassroom: {
    /*
     * 简单教室列表
     */
    get: (p, c) =>
      $.get("/api/ems/basic/examinationCentre/listSimpleClassroom", p, c),
  },
  examinationCentre_save: {
    /*
     * 添加编辑
     * address 具体地址 (string query required)
     * id id (integer query )
     * name 名称 (string query required)
     */
    post: (p, c) => $.post("/api/ems/basic/examinationCentre/save", p, c),
  },
  examinationCentre_saveClassroom: {
    /*
     * 添加|编辑教室
     * ableStatus 启用禁用 (string query required)
     * capacity 容量 (integer query required)
     * centreId 考点列表ID (integer query required)
     * classroom 教室 (string query required)
     * id 教室列表id (integer query )
     */
    post: (p, c) =>
      $.post("/api/ems/basic/examinationCentre/saveClassroom", p, c),
  },

  /**
   * 行业合同
   */
  industryContract: {
    /*
     * 添加
     * batch 批次 (string query )
     * contractAdminId 甲方负责人id (integer query )
     * contractId 甲方 (integer query )
     * cooperationId 行业合作id (integer query )
     * id  (integer query )
     * overDue 是否超期 (string query )
     * protocol 协议 (string query )
     * signPeriod 签署期限 (string query )
     * signTime 签署时间 (string query )
     * signYear 签署年份 (string query )
     */
    post: (p, c) => $.post("/api/ems/basic/industryContract", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/ems/basic/industryContract", p, c),
  },
  industryContract_export: {
    /*
     * 导出
     * id 行业合作id (integer query required)
     */
    get: (p, c) => $.get("/api/ems/basic/industryContract/export", p, c),
  },
  industryContract_list: {
    /*
     * 列表
     * id 行业合作id (integer query required)
     */
    get: (p, c) => $.get("/api/ems/basic/industryContract/list", p, c),
  },

  /**
   * 行业合作
   */
  industryCooperation: {
    /*
     * 添加
     * adminId 管理员id (integer query )
     * id  (integer query )
     * name 名称 (string query )
     * principal 负责人 (string query )
     */
    post: (p, c) => $.post("/api/ems/basic/industryCooperation", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/ems/basic/industryCooperation", p, c),
  },
  industryCooperation_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ems/basic/industryCooperation/export", p, c),
  },
  industryCooperation_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/basic/industryCooperation/list", p, c),
  },
  industryCooperation_resetPassword: {
    /*
     * 重置密码
     * id id (integer query required)
     */
    put: (p, c) =>
      $.put("/api/ems/basic/industryCooperation/resetPassword", p, c),
  },

  /**
   * 专业
   */
  major: {
    /*
     * 添加
     * ableStatus 启用状态:启用,禁用 (string query )
     * beforeTutorFee 考前辅导费 (number query )
     * collectingBeforeTutorFee 代收考前辅导费（上交学校部分） (number query )
     * collectingTutorFee 代收辅导费（上交学校部分） (number query )
     * discipline 科类 (string query )
     * fifthFee 第五次学费 (number query )
     * fifthTutorFee 第五次辅导费 (number query )
     * firstFee 第一次学费 (number query )
     * firstTutorFee 第一次辅导费 (number query )
     * fourthFee 第四次学费 (number query )
     * fourthTutorFee 第四次辅导费 (number query )
     * headerTeacherId 班主任id (integer query )
     * id  (integer query )
     * level 层次:高起专,专升本,高起本 (string query )
     * name 专业名字 (string query )
     * period  (integer query )
     * remark  (string query )
     * schoolingLength 学制 (number query )
     * secondFee 第二次学费 (number query )
     * secondTutorFee 第二次辅导费 (number query )
     * siteId 学校id (integer query )
     * studyType 学习类型:函授,业余,网络教育 (string query )
     * thirdFee 第三次学费 (number query )
     * thirdTutorFee 第三次辅导费 (number query )
     * tutorFee 辅导费 (number query )
     */
    post: (p, c) => $.post("/api/ems/basic/major", p, c),
  },
  major_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ems/basic/major/export", p, c),
  },
  major_exportForContinueEducation: {
    /*
     * 导出继教院专业
     */
    get: (p, c) =>
      $.get("/api/ems/basic/major/exportForContinueEducation", p, c),
  },
  major_exportForSelfTaught: {
    /*
     * 导出自考专业
     */
    get: (p, c) => $.get("/api/ems/basic/major/exportForSelfTaught", p, c),
  },
  major_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/basic/major/list", p, c),
  },
  major_listForContinueEducation: {
    /*
     * 继教院专业列表
     */
    get: (p, c) => $.get("/api/ems/basic/major/listForContinueEducation", p, c),
  },
  major_listForSelfTaught: {
    /*
     * 自考专业列表
     */
    get: (p, c) => $.get("/api/ems/basic/major/listForSelfTaught", p, c),
  },
  major_listLevel: {
    /*
     * listLevel
     * siteId 学校id (integer query )
     */
    get: (p, c) => $.get("/api/ems/basic/major/listLevel", p, c),
  },
  major_listName: {
    /*
     * 名字列表
     * ableStatus 启用状态 (string query )
     * level 层次:高起专，专升本，高起本 (string query )
     * site 学校 (string query )
     */
    get: (p, c) => $.get("/api/ems/basic/major/listName", p, c),
  },
  major_listSimple: {
    /*
     * 简易列表
     * ableStatus 启用状态 (string query )
     * discipline 科类 (string query )
     * level 层次:高起专，专升本，高起本 (string query )
     * siteId 学校id (integer query )
     */
    get: (p, c) => $.get("/api/ems/basic/major/listSimple", p, c),
  },
  major_publishBrochure: {
    /*
     * 发布招生简章
     * content 更新说明 (string query )
     * sendDingFlag 是否发送ding通知 (string query )
     * siteTypes 学校类型，多选 (array query )
     */
    put: (p, c) => $.put("/api/ems/basic/major/publishBrochure", p, c),
  },
  major_upload: {
    /*
     * 导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ems/basic/major/upload", p, c),
  },
  major_uploadForContinueEducation: {
    /*
     * 导入继教院专业
     * excelFile excelFile (file formData required)
     */
    post: (p, c) =>
      $.post("/api/ems/basic/major/uploadForContinueEducation", p, c),
  },
  major_uploadForSelfTaught: {
    /*
     * 导入自考专业
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ems/basic/major/uploadForSelfTaught", p, c),
  },

  /**
   * 自考专业课程配置
   */
  majorCourse_downloadMould: {
    /*
     * 下载模板
     */
    get: (p, c) => $.get("/api/ems/basic/majorCourse/downloadMould", p, c),
  },
  majorCourse_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ems/basic/majorCourse/export", p, c),
  },
  majorCourse_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/basic/majorCourse/list", p, c),
  },
  majorCourse_listCourseForClient: {
    /*
     * C端自考课程列表
     */
    get: (p, c) =>
      $.get("/api/ems/basic/majorCourse/listCourseForClient", p, c),
  },
  majorCourse_listSimpleCourse: {
    /*
     * 自考课程简易列表
     * ableStatus 启用状态 (string query )
     * majorId 专业id (integer query )
     */
    get: (p, c) => $.get("/api/ems/basic/majorCourse/listSimpleCourse", p, c),
  },
  majorCourse_repair: {
    /*
     * 临时修复
     */
    post: (p, c) => $.post("/api/ems/basic/majorCourse/repair", p, c),
  },
  majorCourse_save: {
    /*
     * 添加编辑
     * addCourseIds 新增courseIds (array query )
     * majorId 专业ID (integer query required)
     * removeCourseIds 删除courseIds (array query )
     */
    post: (p, c) => $.post("/api/ems/basic/majorCourse/save", p, c),
  },
  majorCourse_upload: {
    /*
     * 导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ems/basic/majorCourse/upload", p, c),
  },

  /**
   * 快捷入口
   */
  roleShortcut_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/basic/roleShortcut/list", p, c),
  },
  roleShortcut_listMyShortcut: {
    /*
     * 我的快捷入口
     */
    get: (p, c) => $.get("/api/ems/basic/roleShortcut/listMyShortcut", p, c),
  },
  roleShortcut_remove: {
    /*
     * 删除
     * id ID (integer query )
     */
    delete: (p, c) => $.delete("/api/ems/basic/roleShortcut/remove", p, c),
  },
  roleShortcut_save: {
    /*
     * 添加
     * module 模块 (string query required)
     * path 路径，自存自用 (string query )
     * platform 平台 (string query required)
     * positions 职位 (array query required)
     * submodule 子模块 (string query required)
     */
    post: (p, c) => $.post("/api/ems/basic/roleShortcut/save", p, c),
  },
  roleShortcut_update: {
    /*
     * 编辑
     * id id (integer query )
     * module 模块 (string query required)
     * path 路径，自存自用 (string query )
     * platform 平台 (string query required)
     * positions 职位 (array query required)
     * submodule 子模块 (string query required)
     */
    post: (p, c) => $.post("/api/ems/basic/roleShortcut/update", p, c),
  },

  /**
   * 学校
   */
  site: {
    /*
     * 添加
     * ableStatus 启用状态:启用,禁用 (string query )
     * businessType 业务类型:自营,代理,托管 (string query )
     * checkRecordTeacherId 教学检查老师id (integer query )
     * degreeCertificateUrl 学位证书模板 (string query )
     * eduTeacherId 招办老师id (integer query )
     * examCode 线下排考编码 (string query )
     * examTeacherId 考务老师id (integer query )
     * firstSchoolPlatformAccount 管理平台地址1-账号 (string query )
     * firstSchoolPlatformPassword 管理平台地址1-密码 (string query )
     * firstSchoolPlatformUrl 管理平台地址1 (string query )
     * graduationCertificateUrl 毕业证书模板 (string query )
     * id  (integer query )
     * intro 学校介绍 (string query )
     * logo  (string query )
     * name 学校名字 (string query )
     * nameEn  (string query )
     * photo 学校照片 (string query )
     * platformUrl 学习平台地址 (string query )
     * province 省 (string query )
     * schoolReceptionTeacherId 高校对接人id (integer query )
     * schoolRollReservedYears 学籍保留年份 (number query )
     * schoolRollTeacherId 学籍老师id (integer query )
     * secondSchoolPlatformAccount 管理平台地址2-账号 (string query )
     * secondSchoolPlatformPassword 管理平台地址2-密码 (string query )
     * secondSchoolPlatformUrl 管理平台地址2 (string query )
     * simpleName 学校简称 (string query )
     * studyTeacherId 教学老师id (integer query )
     * thesisDuplicateCheckingSource 论文查重库 (string query )
     * thesisExtraFile 毕业论文模板附件 (string query )
     * thesisIntroUrl 论文操作视频 (string query )
     * thesisNeedTitleReport 论文选题是否提交开题报告 (string query )
     * thesisPassedDuplicatePer 论文查重率通过线(需要学位） (number query )
     * thesisWithoutDegreePassedDuplicatePer 论文查重率通过线（无需学位） (number query )
     * thirdSchoolPlatformAccount 管理平台地址3-账号 (string query )
     * thirdSchoolPlatformPassword 管理平台地址3-密码 (string query )
     * thirdSchoolPlatformUrl 管理平台地址3 (string query )
     * type 类型:成教,远程,国开,全日制,自考 (string query )
     */
    post: (p, c) => $.post("/api/ems/basic/site", p, c),
  },
  site_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ems/basic/site/export", p, c),
  },
  site_exportForContinueEducation: {
    /*
     * 导出继教院学校列表
     */
    get: (p, c) =>
      $.get("/api/ems/basic/site/exportForContinueEducation", p, c),
  },
  site_exportForSelfTaught: {
    /*
     * 导出自考学校列表
     */
    get: (p, c) => $.get("/api/ems/basic/site/exportForSelfTaught", p, c),
  },
  site_getTeacherBySite: {
    /*
     * 根据学校和角色查询对应老师
     * role 角色(招办老师|学籍老师|考务老师|教学老师) (string query )
     * siteId 学校ID (integer query required)
     */
    get: (p, c) => $.get("/api/ems/basic/site/getTeacherBySite", p, c),
  },
  site_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/basic/site/list", p, c),
  },
  site_listContinueEducationName: {
    /*
     * 学校名字列表
     * province 省份 (string query )
     */
    get: (p, c) => $.get("/api/ems/basic/site/listContinueEducationName", p, c),
  },
  site_listForContinueEducation: {
    /*
     * 继教院学校列表
     */
    get: (p, c) => $.get("/api/ems/basic/site/listForContinueEducation", p, c),
  },
  site_listForSelfTaught: {
    /*
     * 自考学校列表
     */
    get: (p, c) => $.get("/api/ems/basic/site/listForSelfTaught", p, c),
  },
  site_listName: {
    /*
     * 学校名字列表
     * province 省份 (string query )
     * type 类型 (string query )
     */
    get: (p, c) => $.get("/api/ems/basic/site/listName", p, c),
  },
  site_listNameByReceptionTeacher: {
    /*
     * 高校对接人学校名称列表
     */
    get: (p, c) =>
      $.get("/api/ems/basic/site/listNameByReceptionTeacher", p, c),
  },
  site_listProvince: {
    /*
     * 学校省份列表
     * ableStatus 启用状态 (string query )
     * type 学校类型 (string query )
     */
    get: (p, c) => $.get("/api/ems/basic/site/listProvince", p, c),
  },
  site_listSimple: {
    /*
     * 简易列表
     * ableStatus 启用状态 (string query )
     * province 省份 (string query )
     * type 类型:成教,远程,国开,全日制 (string query )
     */
    get: (p, c) => $.get("/api/ems/basic/site/listSimple", p, c),
  },
  site_transferTeacher: {
    /*
     * 高校老师移交
     * originTeacherId 类型：原老师id (integer query required)
     * teacherId 类型：现老师id (integer query required)
     * type 类型：通过系统配置-学校老师类型获取 (string query required)
     */
    put: (p, c) => $.put("/api/ems/basic/site/transferTeacher", p, c),
  },
  site_upload: {
    /*
     * 导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ems/basic/site/upload", p, c),
  },
  site_uploadForContinueEducation: {
    /*
     * 导入继教院学校
     * excelFile excelFile (file formData required)
     */
    post: (p, c) =>
      $.post("/api/ems/basic/site/uploadForContinueEducation", p, c),
  },
  site_uploadForSelfTaught: {
    /*
     * 导入自考学校
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ems/basic/site/uploadForSelfTaught", p, c),
  },

  /**
   * 军人培训状态
   */
  soldierTrainingStatus: {
    /*
     * 添加
     * driverStatus 驾驶证培训状态 (string query )
     * id  (integer query )
     * majorId 专业id (integer query )
     * repairStatus 汽修培训状态 (string query )
     * status 培训状态 (string query )
     */
    post: (p, c) => $.post("/api/ems/basic/soldierTrainingStatus", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/ems/basic/soldierTrainingStatus", p, c),
  },
  soldierTrainingStatus_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ems/basic/soldierTrainingStatus/export", p, c),
  },
  soldierTrainingStatus_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/basic/soldierTrainingStatus/list", p, c),
  },
  soldierTrainingStatus_listDriverStatus: {
    /*
     * 驾驶证状态列表
     * majorId 专业id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/basic/soldierTrainingStatus/listDriverStatus", p, c),
  },
  soldierTrainingStatus_listRepairStatus: {
    /*
     * 汽修培训状态列表
     * majorId 专业id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/basic/soldierTrainingStatus/listRepairStatus", p, c),
  },
  soldierTrainingStatus_listStatus: {
    /*
     * 状态列表
     * majorId 专业id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/basic/soldierTrainingStatus/listStatus", p, c),
  },
  soldierTrainingStatus_upload: {
    /*
     * 导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ems/basic/soldierTrainingStatus/upload", p, c),
  },

  /**
   * 异动配置
   */
  transactionConfig: {
    /*
     * 添加
     * batch 批次 (string query )
     * endTime 开始时间 (string query )
     * id  (integer query )
     * siteId 学校 (integer query )
     * startTime 开始时间 (string query )
     * tradeType 异动类型：不读，转校转专业，转批次，休学，退学，转专业，复学 (string query )
     * type 异动分类：招办异动，教务异动 (string query )
     */
    post: (p, c) => $.post("/api/ems/basic/transactionConfig", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/ems/basic/transactionConfig", p, c),
  },
  transactionConfig_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ems/basic/transactionConfig/export", p, c),
  },
  transactionConfig_exportForSelfTaught: {
    /*
     * 异动配置列表导出-自考
     */
    get: (p, c) =>
      $.get("/api/ems/basic/transactionConfig/exportForSelfTaught", p, c),
  },
  transactionConfig_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/basic/transactionConfig/list", p, c),
  },
  transactionConfig_listForSelfTaught: {
    /*
     * 自考异动配置列表
     */
    get: (p, c) =>
      $.get("/api/ems/basic/transactionConfig/listForSelfTaught", p, c),
  },
  transactionConfig_upload: {
    /*
     * 导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ems/basic/transactionConfig/upload", p, c),
  },
  transactionConfig_uploadForSelfTaught: {
    /*
     * 导入-自考
     * excelFile excelFile (file formData required)
     */
    post: (p, c) =>
      $.post("/api/ems/basic/transactionConfig/uploadForSelfTaught", p, c),
  },

  /**
   * 异动申请表
   */
  transactionFile_getBySiteAndType: {
    /*
     * 根据学校和异动类型查询申请表
     * siteId 学校ID (integer query required)
     * tradeType 具体类型 (string query required)
     * type 异动类型 (string query required)
     */
    get: (p, c) =>
      $.get("/api/ems/basic/transactionFile/getBySiteAndType", p, c),
  },
  transactionFile_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/basic/transactionFile/list", p, c),
  },
  transactionFile_remove: {
    /*
     * 删除
     * id 列表ID (integer query required)
     */
    delete: (p, c) => $.delete("/api/ems/basic/transactionFile/remove", p, c),
  },
  transactionFile_save: {
    /*
     * 添加编辑
     * fileUrl 申请表地址 (string query )
     * id  (integer query )
     * siteId 学校 (integer query )
     * tradeType 具体异动类型：不读，转校转专业，转批次，休学，退学，转专业，复学 (string query )
     * type 异动类型：招办异动，教务异动 (string query )
     */
    post: (p, c) => $.post("/api/ems/basic/transactionFile/save", p, c),
  },
};
