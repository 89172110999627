import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Menu from "../common/Menu";

/**
 * 公用页面
 */
import main from "@/views/main";
import notFound from "@/views/notFound";
import login from "@/views/login";

/**
 * 模块路由
 */
import router_main from "@/router/router_main";
import router_course from "@/router/router_course";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: main,
    redirect: "/home",
    children: [router_main, router_course],
  },
  {
    path: "*",
    component: notFound,
  },
  {
    path: "/login",
    component: login,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// 全局前置守卫
router.beforeEach(async (to, from, next) => {
  const { meta } = to;

  // 设置菜单状态
  store.commit("headMenuSet", {
    show: true,
    isTransparent: !!meta.isTransparent,
  });
  if (from.path !== "/") {
    window.scrollTo(0, 0);
  }

  // 设置菜单样式
  Menu.routerChange(to);

  next();
});

export default router;
