import { $ } from "@/common/Axios";

export default {
  /**
   * 成考空报
   */
  adultExamEmpty: {
    /*
     * 添加
     * address 通知书邮寄地址 (string query )
     * discipline 科类 (string query )
     * enrollId 报名人id (integer query )
     * id  (integer query )
     * idCardNo 身份证号码 (string query )
     * level 层次 (string query )
     * major 专业 (string query )
     * majorCode 专业代码 (string query )
     * name 姓名 (string query )
     * phone 手机号 (string query )
     * remark 备注 (string query )
     * site 学校 (string query )
     * siteCode 学校代码 (string query )
     * supply 提供人 (string query )
     * year 成考年度 (string query )
     */
    post: (p, c) => $.post("/api/ems/adultexam/adultExamEmpty", p, c),
  },
  adultExamEmpty_assignEnroll: {
    /*
     * 分配报名人
     * enrollId 报名人id (integer query required)
     * ids 空报id (array query required)
     */
    put: (p, c) =>
      $.put("/api/ems/adultexam/adultExamEmpty/assignEnroll", p, c),
  },
  adultExamEmpty_changeToNormal: {
    /*
     * 转为正常学生
     * id 空报id (integer query required)
     * idCardNo 正常学生身份证 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/adultexam/adultExamEmpty/changeToNormal", p, c),
  },
  adultExamEmpty_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ems/adultexam/adultExamEmpty/export", p, c),
  },
  adultExamEmpty_getApplyInfo: {
    /*
     * 获取报名信息（报名辅助页面）
     * phone 手机号 (string query required)
     * year 成考年度 (string query required)
     */
    get: (p, c) =>
      $.get("/api/ems/adultexam/adultExamEmpty/getApplyInfo", p, c),
  },
  adultExamEmpty_getCompareApplyInfo: {
    /*
     * 获取对比的报名信息
     * idCardNo 身份证号码 (string query required)
     * phone 手机号 (string query required)
     */
    get: (p, c) =>
      $.get("/api/ems/adultexam/adultExamEmpty/getCompareApplyInfo", p, c),
  },
  adultExamEmpty_list: {
    /*
     * 空报列表
     */
    get: (p, c) => $.get("/api/ems/adultexam/adultExamEmpty/list", p, c),
  },
  adultExamEmpty_remark: {
    /*
     * 备注
     * id 空报id (integer query required)
     * remark 备注 (string query required)
     */
    put: (p, c) => $.put("/api/ems/adultexam/adultExamEmpty/remark", p, c),
  },
  adultExamEmpty_upload: {
    /*
     * 导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ems/adultexam/adultExamEmpty/upload", p, c),
  },

  /**
   * 成考缴费
   */
  adultExamFee_feeSuccess: {
    /*
     * 缴费成功
     * feeNo 缴款单号 (string query required)
     * idCardNo 身份证 (string query required)
     * serialNo 交易流水号 (string query required)
     * year 成考年度 (string query required)
     */
    put: (p, c) => $.put("/api/ems/adultexam/adultExamFee/feeSuccess", p, c),
  },

  /**
   * 成考专业代码
   */
  adultExamMajorCode: {
    /*
     * 添加
     * area 成考考区 (string query )
     * discipline 科类 (string query )
     * id  (integer query )
     * majorCode 专业代码 (string query )
     * majorId 专业id (integer query )
     * siteCode 院校代码 (string query )
     * year 成考年度 (string query )
     */
    post: (p, c) => $.post("/api/ems/adultexam/adultExamMajorCode", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/ems/adultexam/adultExamMajorCode", p, c),
  },
  adultExamMajorCode_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ems/adultexam/adultExamMajorCode/export", p, c),
  },
  adultExamMajorCode_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/adultexam/adultExamMajorCode/list", p, c),
  },
  adultExamMajorCode_upload: {
    /*
     * 导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) =>
      $.post("/api/ems/adultexam/adultExamMajorCode/upload", p, c),
  },

  /**
   * 分数线
   */
  scoreLine: {
    /*
     * 添加
     * discipline 科类 (string query )
     * id  (integer query )
     * level 层次 (string query )
     * score 分数线 (integer query )
     * year 成考年度 (string query )
     */
    post: (p, c) => $.post("/api/ems/adultexam/scoreLine", p, c),
  },

  /**
   * 成考信息
   */
  studentAdultExamInfo_assignAdminTeacher: {
    /*
     * 分配合同负责人
     * newAdminId 负责人 (integer query required)
     * schoolRollId 合同编号 (integer query required)
     */
    put: (p, c) =>
      $.put("/api/ems/adultexam/studentAdultExamInfo/assignAdminTeacher", p, c),
  },
  studentAdultExamInfo_confirmInfo: {
    /*
     * 确认学生资料
     * id 学生资料id (integer query required)
     * status 通过1,拒绝0 (integer query required)
     */
    put: (p, c) =>
      $.put("/api/ems/adultexam/studentAdultExamInfo/confirmInfo", p, c),
  },
  studentAdultExamInfo_detail: {
    /*
     * 学生资料详情
     * id id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/adultexam/studentAdultExamInfo/detail", p, c),
  },
  studentAdultExamInfo_enrollHelperDetail: {
    /*
     * 报名辅助页面
     * id id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/adultexam/studentAdultExamInfo/enrollHelperDetail", p, c),
  },
  studentAdultExamInfo_export: {
    /*
     * 学历教育-成考管理-学生资料导出
     */
    get: (p, c) =>
      $.get("/api/ems/adultexam/studentAdultExamInfo/export", p, c),
  },
  studentAdultExamInfo_exportForEnrollTeacher: {
    /*
     * 招生管理-成考学生（招生老师角度）-导出
     */
    get: (p, c) =>
      $.get(
        "/api/ems/adultexam/studentAdultExamInfo/exportForEnrollTeacher",
        p,
        c
      ),
  },
  studentAdultExamInfo_getCompareApplyInfo: {
    /*
     * 获取对比的报名信息
     * idCardNo 身份证号码 (string query required)
     * phone 手机号 (string query required)
     */
    get: (p, c) =>
      $.get(
        "/api/ems/adultexam/studentAdultExamInfo/getCompareApplyInfo",
        p,
        c
      ),
  },
  studentAdultExamInfo_list: {
    /*
     * 学历教育-成考管理-学生资料列表
     */
    get: (p, c) => $.get("/api/ems/adultexam/studentAdultExamInfo/list", p, c),
  },
  studentAdultExamInfo_listForEnrollTeacher: {
    /*
     * 招生管理-成考学生（招生老师角度）
     */
    get: (p, c) =>
      $.get(
        "/api/ems/adultexam/studentAdultExamInfo/listForEnrollTeacher",
        p,
        c
      ),
  },
  studentAdultExamInfo_listForRedeem: {
    /*
     * 待挽回列表
     */
    get: (p, c) =>
      $.get("/api/ems/adultexam/studentAdultExamInfo/listForRedeem", p, c),
  },
  studentAdultExamInfo_listStatics: {
    /*
     * 成考统计-列表顶部统计
     */
    get: (p, c) =>
      $.get("/api/ems/adultexam/studentAdultExamInfo/listStatics", p, c),
  },
  studentAdultExamInfo_listTotalData: {
    /*
     * 成考统计-列表
     * area 成考考区 (string query )
     * auditStep 审核状态 (string query )
     * businessDepartmentId 事业部id (integer query )
     * city 地级市 (string query )
     * departmentId 部门id (integer query )
     * enrollEnd 报名成功结束时间 (string query )
     * enrollStart 报名成功开始时间 (string query )
     * level 层次:1高起专2专升本3高起本 (string query )
     * point 招生点 (string query )
     * province 学校省份 (string query )
     * year 成考年度（列表选择） (string query required)
     */
    get: (p, c) =>
      $.get("/api/ems/adultexam/studentAdultExamInfo/listTotalData", p, c),
  },
  studentAdultExamInfo_updateInfo: {
    /*
     * 编辑学生资料
     * addScoreItem 加分类型 (string query )
     * addScoreItemContent 加分附件 (string query )
     * area 成考考区 (string query )
     * city 地级市 (string query )
     * id 列表id (integer query required)
     * localContent 成考外地凭证内容，类型为本地时不填，为社保、居住证时候上传截图，三无时填写备注 (string query )
     * localType 成考所在地类型:未知、本地、社保、居住证、三无 (string query )
     * notNeedExamItem 免试类型 (string query )
     * notNeedExamItemContent 免试附件 (string query )
     * remark 成考备注 (string query )
     * shippingAddress 收件地址 (string query )
     */
    put: (p, c) =>
      $.put("/api/ems/adultexam/studentAdultExamInfo/updateInfo", p, c),
  },
  studentAdultExamInfo_uploadStudentInfo: {
    /*
     * 导入学生资料
     * excelFile excelFile (file formData required)
     */
    post: (p, c) =>
      $.post("/api/ems/adultexam/studentAdultExamInfo/uploadStudentInfo", p, c),
  },

  /**
   * 学生分数
   */
  studentOfficeScore_list: {
    /*
     * 成绩列表
     * idCardNo 身份证 (string query required)
     * year 成考年度 (string query required)
     */
    get: (p, c) => $.get("/api/ems/adultexam/studentOfficeScore/list", p, c),
  },

  /**
   * 成考考试院信息
   */
  studentOfficialInfo_applySuccess: {
    /*
     * 提交报名成功
     * phone 手机号 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/adultexam/studentOfficialInfo/applySuccess", p, c),
  },
  studentOfficialInfo_batchSyncApplyInfo: {
    /*
     * 批量同步考试院报名信息
     */
    put: (p, c) =>
      $.put("/api/ems/adultexam/studentOfficialInfo/batchSyncApplyInfo", p, c),
  },
  studentOfficialInfo_batchSyncEnrollInfo: {
    /*
     * 批量同步考试院录取信息
     * level 层次 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/adultexam/studentOfficialInfo/batchSyncEnrollInfo", p, c),
  },
  studentOfficialInfo_batchSyncExamCardInfo: {
    /*
     * 批量同步考试院准考证信息
     */
    put: (p, c) =>
      $.put(
        "/api/ems/adultexam/studentOfficialInfo/batchSyncExamCardInfo",
        p,
        c
      ),
  },
  studentOfficialInfo_batchSyncFeeInfo: {
    /*
     * 批量同步缴费信息
     */
    put: (p, c) =>
      $.put("/api/ems/adultexam/studentOfficialInfo/batchSyncFeeInfo", p, c),
  },
  studentOfficialInfo_batchSyncNoTrialInfo: {
    /*
     * 批量同步考试院免试信息
     */
    put: (p, c) =>
      $.put(
        "/api/ems/adultexam/studentOfficialInfo/batchSyncNoTrialInfo",
        p,
        c
      ),
  },
  studentOfficialInfo_batchSyncScoreInfo: {
    /*
     * 批量同步考试院成绩信息
     */
    put: (p, c) =>
      $.put("/api/ems/adultexam/studentOfficialInfo/batchSyncScoreInfo", p, c),
  },
  studentOfficialInfo_batchSyncSiteAndMajor: {
    /*
     * 批量同步考试院学校专业信息
     */
    put: (p, c) =>
      $.put(
        "/api/ems/adultexam/studentOfficialInfo/batchSyncSiteAndMajor",
        p,
        c
      ),
  },
  studentOfficialInfo_changeSiteAndMajor: {
    /*
     * 志愿调剂
     * ccdm 层次代码 (undefined body required)
     * idCardNo 身份证 (string query required)
     * kldm 科类代码 (undefined body required)
     * pc pc (undefined body required)
     * year 成考年度 (string query required)
     * yxdm 院校代码 (undefined body required)
     * zydm1 专业代码 (undefined body required)
     */
    put: (p, c) =>
      $.put("/api/ems/adultexam/studentOfficialInfo/changeSiteAndMajor", p, c),
  },
  studentOfficialInfo_checkScanningDevice: {
    /*
     * 查看扫码设备
     * idCardNo 身份证 (string query )
     * phone 手机号 (string query )
     * year 成考年度 (string query )
     */
    get: (p, c) =>
      $.get("/api/ems/adultexam/studentOfficialInfo/checkScanningDevice", p, c),
  },
  studentOfficialInfo_exportNoTrial: {
    /*
     * 免试信息导出
     */
    get: (p, c) =>
      $.get("/api/ems/adultexam/studentOfficialInfo/exportNoTrial", p, c),
  },
  studentOfficialInfo_getOfficeInfo: {
    /*
     * 获取考试院信息
     * idCardNo 身份证 (string query required)
     * url 请求地地址 (string query required)
     * year 成考年度 (string query required)
     */
    get: (p, c) =>
      $.get("/api/ems/adultexam/studentOfficialInfo/getOfficeInfo", p, c),
  },
  studentOfficialInfo_saveSignInfo: {
    /*
     * 保存考试院登录资料信息
     * ddOpenId 钉钉openId (string query )
     * phone 手机号 (string query required)
     * type 类型 (string query )
     * wxOpenId 微信openId (string query )
     * wxUnionId 微信unionId (string query )
     */
    post: (p, c) =>
      $.post("/api/ems/adultexam/studentOfficialInfo/saveSignInfo", p, c),
  },
  studentOfficialInfo_scanCodeSuccess: {
    /*
     * 扫码成功
     * ddOpenId 钉钉openId (string query )
     * nick 昵称 (string query required)
     * type 类型 (string query )
     * wxOpenId 微信openId (string query )
     * wxUnionId 微信unionId (string query )
     */
    post: (p, c) =>
      $.post("/api/ems/adultexam/studentOfficialInfo/scanCodeSuccess", p, c),
  },
  studentOfficialInfo_syncApplyInfo: {
    /*
     * 单个同步考试院报名信息
     * idCardNo 身份证 (string query required)
     * year 成考年度 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/adultexam/studentOfficialInfo/syncApplyInfo", p, c),
  },
  studentOfficialInfo_syncEnrollInfo: {
    /*
     * 单个同步考试院录取信息
     * idCardNo 身份证 (string query required)
     * year 成考年度 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/adultexam/studentOfficialInfo/syncEnrollInfo", p, c),
  },
  studentOfficialInfo_syncExamCardInfo: {
    /*
     * 单个同步考试院准考证信息
     * idCardNo 身份证 (string query required)
     * year 成考年度 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/adultexam/studentOfficialInfo/syncExamCardInfo", p, c),
  },
  studentOfficialInfo_syncFeeInfo: {
    /*
     * 同步缴费信息，返回支付地址
     * idCardNo 身份证 (string query required)
     * year 成考年度 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/adultexam/studentOfficialInfo/syncFeeInfo", p, c),
  },
  studentOfficialInfo_syncNoTrialInfo: {
    /*
     * 单个同步考试院免试信息
     * idCardNo 身份证 (string query required)
     * year 成考年度 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/adultexam/studentOfficialInfo/syncNoTrialInfo", p, c),
  },
  studentOfficialInfo_syncScoreInfo: {
    /*
     * 单个同步考试院成绩信息
     * idCardNo 身份证 (string query required)
     * year 成考年度 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/adultexam/studentOfficialInfo/syncScoreInfo", p, c),
  },

  /**
   * 志愿调整
   */
  volunteerTransfer: {
    /*
     * 添加
     * agreeType 是否愿意接受调剂 (string query )
     * id  (integer query )
     * idCardNo 身份证 (string query )
     * level 层次 (string query )
     * major 专业 (string query )
     * majorCode 专业代码 (string query )
     * officeMajor 考试院专业 (string query )
     * officeMajorCode 考试院专业代码 (string query )
     * officeSite 考试院院学校 (string query )
     * officeSiteCode 考试院学校代码 (string query )
     * remark 备注 (string query )
     * site 学校 (string query )
     * siteCode 学校代码 (string query )
     * status 状态：调剂中，调剂错误，已调剂 (string query )
     * type 类型 (string query )
     * year 成考年度 (string query )
     */
    post: (p, c) => $.post("/api/ems/adultexam/volunteerTransfer", p, c),
  },
  volunteerTransfer_agree: {
    /*
     * 是否同意
     * agreeType 是否统一 (string query required)
     * id id (integer query required)
     * remark 备注 (string query )
     */
    put: (p, c) => $.put("/api/ems/adultexam/volunteerTransfer/agree", p, c),
  },
  volunteerTransfer_batchSync: {
    /*
     * 批量同步
     * type 类型：调剂、补报 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/adultexam/volunteerTransfer/batchSync", p, c),
  },
  volunteerTransfer_batchSyncVolunteer: {
    /*
     * 批量同步考试院志愿信息
     */
    put: (p, c) =>
      $.put("/api/ems/adultexam/volunteerTransfer/batchSyncVolunteer", p, c),
  },
  volunteerTransfer_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ems/adultexam/volunteerTransfer/export", p, c),
  },
  volunteerTransfer_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/adultexam/volunteerTransfer/list", p, c),
  },
  volunteerTransfer_listColumn: {
    /*
     * 字段列表
     * key key (string query required)
     * type 类型：调剂、补报 (string query )
     */
    get: (p, c) =>
      $.get("/api/ems/adultexam/volunteerTransfer/listColumn", p, c),
  },
  volunteerTransfer_sync: {
    /*
     * 单个同步
     * idCardNo 身份证 (string query required)
     * type 类型 (string query required)
     * year 成考年度 (string query required)
     */
    put: (p, c) => $.put("/api/ems/adultexam/volunteerTransfer/sync", p, c),
  },
  volunteerTransfer_syncVolunteer: {
    /*
     * 单个同步考试志愿信息
     * idCardNo 身份证 (string query required)
     * year 成考年度 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/adultexam/volunteerTransfer/syncVolunteer", p, c),
  },
  volunteerTransfer_upload: {
    /*
     * 导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ems/adultexam/volunteerTransfer/upload", p, c),
  },
};
