import { $ } from "@/common/Axios";

export default {
  /**
   * c端论文学生
   */
  client_commitFile: {
    /*
     * 提交论文初稿、定稿、修改定稿
     * docName 文件名称 (string query )
     * extraFileUrl 查重报告地址 (string query )
     * fileUrl 文件地址 (string query required)
     * id 行ID (integer query required)
     * needDegree 是否申请学位（提交定稿时传入） (string query )
     * type type 1初稿 2定稿 3答辩后修改稿 (integer query required)
     */
    put: (p, c) => $.put("/api/ems/thesis/client/commitFile", p, c),
  },
  client_commitTitle: {
    /*
     * 提交论文选题
     * id 行ID (integer query required)
     * title 论题 (string query required)
     * titleIntro 论题简介 (string query required)
     * titleReportUrl 开题报告 (string query )
     */
    put: (p, c) => $.put("/api/ems/thesis/client/commitTitle", p, c),
  },
  client_getGradeInfo: {
    /*
     * 成绩信息
     * id 行ID (integer query required)
     */
    get: (p, c) => $.get("/api/ems/thesis/client/getGradeInfo", p, c),
  },
  client_getReplyInfo: {
    /*
     * 查询答辩安排信息
     * id 行ID (integer query required)
     */
    get: (p, c) => $.get("/api/ems/thesis/client/getReplyInfo", p, c),
  },
  client_list: {
    /*
     * 我的论文列表
     */
    get: (p, c) => $.get("/api/ems/thesis/client/list", p, c),
  },
  client_listRecord: {
    /*
     * 各阶段提交记录
     * id 行ID (integer query required)
     * step 阶段 1选题 2初稿 3定稿 5答辩后修改稿 (string query required)
     */
    get: (p, c) => $.get("/api/ems/thesis/client/listRecord", p, c),
  },

  /**
   * 实习报告
   */
  internshipReport: {
    /*
     * 添加
     * endTime 结束时间 (string query )
     * id  (integer query )
     * rollGrade 学籍年级 (string query )
     * siteId 学校id (integer query )
     * startTime 开始时间 (string query )
     * type 类型 (string query )
     */
    post: (p, c) => $.post("/api/ems/thesis/internshipReport", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/ems/thesis/internshipReport", p, c),
  },
  internshipReport_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/thesis/internshipReport/list", p, c),
  },
  internshipReport_listRollGrade: {
    /*
     * 学籍年级列表
     */
    get: (p, c) =>
      $.get("/api/ems/thesis/internshipReport/listRollGrade", p, c),
  },

  /**
   * 实习报告学生
   */
  internshipReportStudent: {
    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/ems/thesis/internshipReportStudent", p, c),
  },
  internshipReportStudent_applyInfo: {
    /*
     * 资料提交
     * id id (integer query required)
     * report 报告 (string query required)
     * topic 选题 (string query required)
     */
    post: (p, c) =>
      $.post("/api/ems/thesis/internshipReportStudent/applyInfo", p, c),
  },
  internshipReportStudent_auditByEducation: {
    /*
     * 教务审核
     * auditStatus 审核状态：通过、拒绝 (string query required)
     * comments 评语 (string query required)
     * id id (integer query required)
     */
    put: (p, c) =>
      $.put("/api/ems/thesis/internshipReportStudent/auditByEducation", p, c),
  },
  internshipReportStudent_auditByInstructor: {
    /*
     * 指导老师审核
     * auditStatus 审核状态：通过、拒绝 (string query required)
     * comments 评语 (string query required)
     * id id (integer query required)
     * score 评语 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/thesis/internshipReportStudent/auditByInstructor", p, c),
  },
  internshipReportStudent_changeInstructor: {
    /*
     * 导师移交
     * instructorId 原指导老师 (integer query required)
     * newInstructorId 新指导老师 (integer query required)
     */
    put: (p, c) =>
      $.put("/api/ems/thesis/internshipReportStudent/changeInstructor", p, c),
  },
  internshipReportStudent_downloadMould: {
    /*
     * 学生导入模版
     */
    get: (p, c) =>
      $.get("/api/ems/thesis/internshipReportStudent/downloadMould", p, c),
  },
  internshipReportStudent_export: {
    /*
     * 导出
     */
    get: (p, c) =>
      $.get("/api/ems/thesis/internshipReportStudent/export", p, c),
  },
  internshipReportStudent_exportForInstructor: {
    /*
     * 导出（指导老师）
     */
    get: (p, c) =>
      $.get(
        "/api/ems/thesis/internshipReportStudent/exportForInstructor",
        p,
        c
      ),
  },
  internshipReportStudent_infoForClient: {
    /*
     * 获取实习报告信息（C端）
     */
    get: (p, c) =>
      $.get("/api/ems/thesis/internshipReportStudent/infoForClient", p, c),
  },
  internshipReportStudent_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/thesis/internshipReportStudent/list", p, c),
  },
  internshipReportStudent_listForInstructor: {
    /*
     * 列表（指导老师）
     */
    get: (p, c) =>
      $.get("/api/ems/thesis/internshipReportStudent/listForInstructor", p, c),
  },
  internshipReportStudent_listSimple: {
    /*
     * 简易列表
     * name 姓名 (string query )
     * reportId 报告id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/thesis/internshipReportStudent/listSimple", p, c),
  },
  internshipReportStudent_upload: {
    /*
     * 导入
     * excelFile excelFile (file formData required)
     * reportId 报告id (integer query required)
     */
    post: (p, c) =>
      $.post("/api/ems/thesis/internshipReportStudent/upload", p, c),
  },

  /**
   * 实习报告学生审批记录
   */
  internshipReportStudentFlow_listByStudentId: {
    /*
     * 根据报告学生获取审批记录列表
     * studentId 报告学生id (integer query required)
     */
    get: (p, c) =>
      $.get(
        "/api/ems/thesis/internshipReportStudentFlow/listByStudentId",
        p,
        c
      ),
  },

  /**
   * 论文审核
   */
  teacher_audit: {
    /*
     * 审核
     * auditStatus 2通过 3拒绝 (string query required)
     * earlyComment 评语 (string query )
     * earlyGrade 初评分 不合格，合格，中等，良好，优秀 (string query )
     * id 详情记录ID (integer query required)
     * refuseFile 审核附件 (string query )
     * refuseReason 审核备注 (string query )
     * step 论文阶段，1论文选题 2论文初稿 3论文定稿  5论文终稿 (string query )
     */
    post: (p, c) => $.post("/api/ems/thesis/teacher/audit", p, c),
  },
  teacher_details: {
    /*
     * 详情
     * id ID (integer query required)
     */
    get: (p, c) => $.get("/api/ems/thesis/teacher/details", p, c),
  },
  teacher_feedback: {
    /*
     * 上报班主任
     * content 反馈内容 (string query )
     * id 行ID (integer query required)
     * step 论文阶段，1论文选题 2论文初稿 3论文定稿  5论文终稿 (string query )
     */
    post: (p, c) => $.post("/api/ems/thesis/teacher/feedback", p, c),
  },
  teacher_list: {
    /*
     * 所有学生列表
     */
    get: (p, c) => $.get("/api/ems/thesis/teacher/list", p, c),
  },
  teacher_listForEarlyDoc: {
    /*
     * 初稿列表
     */
    get: (p, c) => $.get("/api/ems/thesis/teacher/listForEarlyDoc", p, c),
  },
  teacher_listForFinalDoc: {
    /*
     * 定稿列表
     */
    get: (p, c) => $.get("/api/ems/thesis/teacher/listForFinalDoc", p, c),
  },
  teacher_listForModifyFinalDoc: {
    /*
     * 修改定稿列表
     */
    get: (p, c) => $.get("/api/ems/thesis/teacher/listForModifyFinalDoc", p, c),
  },
  teacher_listForTitle: {
    /*
     * 选题列表
     */
    get: (p, c) => $.get("/api/ems/thesis/teacher/listForTitle", p, c),
  },

  /**
   * 论文批次
   */
  thesisBatch_downloadMould: {
    /*
     * 下载导入学生模版
     */
    get: (p, c) => $.get("/api/ems/thesis/thesisBatch/downloadMould", p, c),
  },
  thesisBatch_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/thesis/thesisBatch/list", p, c),
  },
  thesisBatch_listSimple: {
    /*
     * 简单批次列表
     */
    get: (p, c) => $.get("/api/ems/thesis/thesisBatch/listSimple", p, c),
  },
  thesisBatch_listSimpleStudent: {
    /*
     * 学生列表
     * id id (integer query required)
     * name 姓名 (string query )
     * step 阶段 (string query )
     */
    get: (p, c) => $.get("/api/ems/thesis/thesisBatch/listSimpleStudent", p, c),
  },
  thesisBatch_remove: {
    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/ems/thesis/thesisBatch/remove", p, c),
  },
  thesisBatch_removeStudent: {
    /*
     * 删除单个学生
     * id 学生列表返回的id (integer query required)
     */
    delete: (p, c) =>
      $.delete("/api/ems/thesis/thesisBatch/removeStudent", p, c),
  },
  thesisBatch_save: {
    /*
     * 添加
     * batchTime 批次时间（格式202007、202008） (string query required)
     * chooseTitleEndTime 选题结束时间 (string query required)
     * chooseTitleStartTime 选题开始时间 (string query required)
     * earlyDocEndTime 初稿结束时间 (string query required)
     * earlyDocStartTime 初稿开始时间 (string query required)
     * finalDocEndTime 定稿结束时间 (string query required)
     * finalDocStartTime 定稿开始时间 (string query required)
     * id id， 编辑时候传入 (integer query )
     * modifyFinalDocEndTime 修改定稿结束时间 (string query required)
     * modifyFinalDocStartTime 修改定稿开始时间 (string query required)
     * replyEndTime 答辩结束时间 (string query required)
     * replyStartTime 答辩开始时间 (string query required)
     * siteId 学校ID (integer query required)
     */
    post: (p, c) => $.post("/api/ems/thesis/thesisBatch/save", p, c),
  },
  thesisBatch_statisticsStepForMainPage: {
    /*
     * 论文首页统计
     * auditStatus 审核状态 (string query )
     * batchTime 论文批次 (string query )
     * siteId 学校ID (integer query )
     * step 论文阶段 (string query )
     */
    get: (p, c) =>
      $.get("/api/ems/thesis/thesisBatch/statisticsStepForMainPage", p, c),
  },
  thesisBatch_upload: {
    /*
     * 导入学生
     * excelFile excelFile (file formData required)
     * id 学生列表返回的id (integer query required)
     */
    post: (p, c) => $.post("/api/ems/thesis/thesisBatch/upload", p, c),
  },

  /**
   * 论文答辩
   */
  thesisReply_downloadMouldForPlan: {
    /*
     * 下载答辩安排模板
     */
    get: (p, c) =>
      $.get("/api/ems/thesis/thesisReply/downloadMouldForPlan", p, c),
  },
  thesisReply_downloadMouldForScore: {
    /*
     * 下载答辩成绩模板
     */
    get: (p, c) =>
      $.get("/api/ems/thesis/thesisReply/downloadMouldForScore", p, c),
  },
  thesisReply_export: {
    /*
     * 导出答辩学生
     */
    get: (p, c) => $.get("/api/ems/thesis/thesisReply/export", p, c),
  },
  thesisReply_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/thesis/thesisReply/list", p, c),
  },
  thesisReply_remove: {
    /*
     * 删除答辩安排
     * id 行ID (integer query required)
     */
    delete: (p, c) => $.delete("/api/ems/thesis/thesisReply/remove", p, c),
  },
  thesisReply_save: {
    /*
     * 添加答辩安排
     * address 答辩地址 (string query required)
     * classroomNumber 答辩教室号 (string query required)
     * endTime 答辩结束时间 (string query required)
     * groupNumber 答辩组 (string query required)
     * ids thesisStudentId多选 (array query required)
     * recordAdminId 记录员 (integer query required)
     * startTime 答辩开始时间 (string query required)
     */
    post: (p, c) => $.post("/api/ems/thesis/thesisReply/save", p, c),
  },
  thesisReply_saveContent: {
    /*
     * 添加记录内容
     * content 记录内容 (string query required)
     * grade 答辩成绩（限未参加 不合格 合格 中等 良好 优秀 待定 二辩 ） (string query required)
     * id 行ID (integer query required)
     */
    post: (p, c) => $.post("/api/ems/thesis/thesisReply/saveContent", p, c),
  },
  thesisReply_update: {
    /*
     * 编辑答辩安排
     * address 答辩地址 (string query required)
     * classroomNumber 答辩教室号 (string query required)
     * endTime 答辩结束时间 (string query required)
     * groupNumber 答辩组 (string query required)
     * id 行ID (integer query required)
     * recordAdminId 记录员 (integer query required)
     * startTime 答辩开始时间 (string query required)
     */
    put: (p, c) => $.put("/api/ems/thesis/thesisReply/update", p, c),
  },
  thesisReply_uploadGrade: {
    /*
     * 导入答辩成绩
     * excelFile excelFile (file formData required)
     * type 类型 1一辩成绩 2二辩成绩 (integer query required)
     */
    post: (p, c) => $.post("/api/ems/thesis/thesisReply/uploadGrade", p, c),
  },
  thesisReply_uploadReplyPlan: {
    /*
     * 导入答辩安排
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ems/thesis/thesisReply/uploadReplyPlan", p, c),
  },

  /**
   * 论文学生
   */
  thesisStudent_allotTeacher: {
    /*
     * 分配导师
     * ids 学生列表 (array query required)
     * teacherId 指导老师ID (integer query required)
     */
    post: (p, c) => $.post("/api/ems/thesis/thesisStudent/allotTeacher", p, c),
  },
  thesisStudent_batchReplyGrade: {
    /*
     * 批量修改答辩成绩
     * grade 答辩成绩（从配置取 ） (string query required)
     * ids 行ID (array query required)
     * type 1一辩成绩， 2 二辩成绩 (integer query required)
     */
    put: (p, c) => $.put("/api/ems/thesis/thesisStudent/batchReplyGrade", p, c),
  },
  thesisStudent_changeTeacher: {
    /*
     * 更换导师
     * id ID (integer query required)
     * teacherId 指导老师ID (integer query required)
     */
    post: (p, c) => $.post("/api/ems/thesis/thesisStudent/changeTeacher", p, c),
  },
  thesisStudent_details: {
    /*
     * 详情
     * id 行ID (integer query required)
     */
    get: (p, c) => $.get("/api/ems/thesis/thesisStudent/details", p, c),
  },
  thesisStudent_downloadAssignTeacher: {
    /*
     * 下载导入分配导师模板
     */
    get: (p, c) =>
      $.get("/api/ems/thesis/thesisStudent/downloadAssignTeacher", p, c),
  },
  thesisStudent_downloadMouldFinalScore: {
    /*
     * 下载总评成绩模版
     */
    get: (p, c) =>
      $.get("/api/ems/thesis/thesisStudent/downloadMouldFinalScore", p, c),
  },
  thesisStudent_duplicateCheckRecord: {
    /*
     * 论文查重记录
     * id 列表ID (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/thesis/thesisStudent/duplicateCheckRecord", p, c),
  },
  thesisStudent_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ems/thesis/thesisStudent/export", p, c),
  },
  thesisStudent_exportComment: {
    /*
     * 评阅记录表导出
     * batchTime 论文批次(202007) (string query required)
     * siteId 学校ID (integer query required)
     */
    get: (p, c) => $.get("/api/ems/thesis/thesisStudent/exportComment", p, c),
  },
  thesisStudent_feedbackList: {
    /*
     * 查看导师反馈记录
     * id ID (integer query required)
     */
    get: (p, c) => $.get("/api/ems/thesis/thesisStudent/feedbackList", p, c),
  },
  thesisStudent_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/thesis/thesisStudent/list", p, c),
  },
  thesisStudent_listSimpleBySchoolRollId: {
    /*
     * 根据学籍Id查询学生记录
     * schoolRollId 学籍ID (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/thesis/thesisStudent/listSimpleBySchoolRollId", p, c),
  },
  thesisStudent_modifyPhasesTime: {
    /*
     * 编辑学生论文各阶段时间
     * chooseTitleEndTime 选题结束时间 (string query required)
     * chooseTitleStartTime 选题开始时间 (string query required)
     * earlyDocEndTime 初稿结束时间 (string query required)
     * earlyDocStartTime 初稿开始时间 (string query required)
     * finalDocEndTime 定稿结束时间 (string query required)
     * finalDocStartTime 定稿开始时间 (string query required)
     * ids id列表 (array query )
     * modifyFinalDocEndTime 修改定稿结束时间 (string query required)
     * modifyFinalDocStartTime 修改定稿开始时间 (string query required)
     * replyEndTime 答辩结束时间 (string query required)
     * replyStartTime 答辩开始时间 (string query required)
     */
    post: (p, c) =>
      $.post("/api/ems/thesis/thesisStudent/modifyPhasesTime", p, c),
  },
  thesisStudent_publishGrade: {
    /*
     * 发布论文成绩
     * ids 学生列表 (array query required)
     * publishStatus 1发布 0取消发布 (string query required)
     */
    post: (p, c) => $.post("/api/ems/thesis/thesisStudent/publishGrade", p, c),
  },
  thesisStudent_rollbackLatestStep: {
    /*
     * 返回上一阶段
     * id ID (integer query required)
     */
    post: (p, c) =>
      $.post("/api/ems/thesis/thesisStudent/rollbackLatestStep", p, c),
  },
  thesisStudent_saveDuplicateFile: {
    /*
     * 手动提交论文查重
     * recordId 论文记录ID (integer query required)
     */
    post: (p, c) =>
      $.post("/api/ems/thesis/thesisStudent/saveDuplicateFile", p, c),
  },
  thesisStudent_thesisDuplicateCheckAutoChecking: {
    /*
     * 临时方法查重
     */
    post: (p, c) =>
      $.post(
        "/api/ems/thesis/thesisStudent/thesisDuplicateCheckAutoChecking",
        p,
        c
      ),
  },
  thesisStudent_thesisExport: {
    /*
     * 批量导出毕业论文
     * batchTime 论文批次(202007) (string query required)
     * needReport 是否下载查重报告, 1是0否 (integer query required)
     * siteId 学校ID (integer query required)
     */
    get: (p, c) => $.get("/api/ems/thesis/thesisStudent/thesisExport", p, c),
  },
  thesisStudent_transferStep: {
    /*
     * 变更阶段
     * auditStatus 审核状态(仅限待申请或审核中） (string query required)
     * id ID (integer query required)
     * step 目标阶段 (string query required)
     */
    post: (p, c) => $.post("/api/ems/thesis/thesisStudent/transferStep", p, c),
  },
  thesisStudent_uploadAssignTeacher: {
    /*
     * 导入分配导师
     * excelFile excelFile (file formData required)
     */
    post: (p, c) =>
      $.post("/api/ems/thesis/thesisStudent/uploadAssignTeacher", p, c),
  },
  thesisStudent_uploadFinalGrade: {
    /*
     * 导入总评成绩
     * excelFile excelFile (file formData required)
     */
    post: (p, c) =>
      $.post("/api/ems/thesis/thesisStudent/uploadFinalGrade", p, c),
  },
};
