import axios from "axios";
import { stringify } from "qs";
import { Message, Loading } from "element-ui";
import Tools from "@/common/Tools";

/**
 * 请求
 * method 请求方式
 * url 请求链接
 * params 请求参数
 * conf 请求配置
 *    lock 请求时候锁定页面
 */
const ajax_main = (
  method,
  url = "",
  params,
  { lock, addPlatform = false, msgShow = true } = {}
) => {
  return new Promise((resolve, reject) => {
    const notBodyMethod = ["GET", "DELETE"];
    let configObj = {
      method,
      url: url,
      withCredentials: true, //跨域携带凭证
    };
    let loading;
    let _lock = method !== "GET";

    if (lock !== undefined && lock !== null) {
      _lock = lock;
    }

    // 添加平台
    if (addPlatform) {
      if (!params) params = {};
      params["platform"] = Tools.getDomain().platform;
    }

    //序列化url参数
    if (notBodyMethod.indexOf(method) > -1) {
      const paramsStr = stringify(params);
      configObj.url = configObj.url + (paramsStr ? "?" + paramsStr : "");
    } else {
      configObj.data = params;
    }
    configObj.transformRequest = (res) => stringify(res);

    if (_lock) {
      loading = Loading.service({
        background: "rgba(0, 0, 0, 0.1)",
      });
    }

    axios(configObj)
      .then((res) => {
        if (res.data.code === 0) {
          //成功
          resolve(res.data);
        } else if (res.data.code === 100000004) {
          //未登录
          reject(res.data.message);
        } else {
          if (msgShow) {
            Message.error(res.data.message);
          }
          reject(res.data.message);
        }
        if (loading) loading.close();
      })
      .catch((err) => {
        console.log(err.response);
        let errStr = "";
        if (err.response) {
          switch (err.response.status) {
            case 502:
              errStr = "服务器正在部署中，请稍后尝试...";
              break;
            default:
              if (err.response.data) {
                errStr =
                  typeof err.response.data === "object"
                    ? err.response.data.message
                    : err.response.data;
              } else {
                errStr = err.response.statusText + " " + err.response.status;
              }
              break;
          }
        } else {
          errStr = err.message;
        }
        if (msgShow) {
          Message.error(errStr ? errStr : err.response.statusText);
        }
        reject(errStr ? errStr : err.response.statusText);
        if (loading) loading.close();
      });
  });
};

const $ = {
  get: (url, params, conf) => ajax_main("GET", url, params, conf),
  delete: (url, params, conf) => ajax_main("DELETE", url, params, conf),
  post: (url, params, conf) => ajax_main("POST", url, params, conf),
  put: (url, params, conf) => ajax_main("PUT", url, params, conf),
};

export { $ };
