import adultexam from "./adultexam";
import army from "./army";
import basic from "./basic";
import collect from "./collect";
import education from "./education";
import examination from "./examination";
import server from "./server";
import siteplatform from "./siteplatform";
import thesis from "./thesis";

/**
 * 学历微服务
 */
export default {
  adultexam,
  army,
  basic,
  collect,
  education,
  examination,
  server,
  siteplatform,
  thesis,
};
