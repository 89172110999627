import { $ } from "@/common/Axios";

export default {
  /**
   * 军人专项申请报名表
   */
  soldierApplyInfo_details: {
    /*
     * 详情
     * id id (integer query required)
     */
    get: (p, c) => $.get("/api/ems/army/soldierApplyInfo/details", p, c),
  },
  soldierApplyInfo_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ems/army/soldierApplyInfo/export", p, c),
  },
  soldierApplyInfo_exportWord: {
    /*
     * 导出word
     * ids ids (array query required)
     */
    get: (p, c) => $.get("/api/ems/army/soldierApplyInfo/exportWord", p, c),
  },
  soldierApplyInfo_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/army/soldierApplyInfo/list", p, c),
  },
  soldierApplyInfo_remove: {
    /*
     * 删除
     * id id (integer query )
     */
    delete: (p, c) => $.delete("/api/ems/army/soldierApplyInfo/remove", p, c),
  },
  soldierApplyInfo_save: {
    /*
     * 添加编辑
     * award 立功奖励情况|列表||| (string query )
     * birth 出生年月|全部|时间|时间 (string query )
     * company 工作单位 (string query )
     * createTime 签名时间|全部|时间|时间| (string query )
     * diplomaPhoto 毕业证书照片|列表|图片|| (string query )
     * domicileArea 安置（户籍）地|全部||| (string query )
     * familyAddress 家庭住址|列表||| (string query )
     * familyContactInfo 家庭联系人及电话|列表||| (string query )
     * farea 承诺书里的区|无||| (string query )
     * gender 性别|全部||| (string query )
     * graduateLevel 文化程度|全部||字符串下拉|api/bms/develop/config/listSelect?key=学历 (string query )
     * health 健康状况|全部||字符串下拉|api/bms/develop/config/listSelect?key=健康状况 (string query )
     * id id|无||| (integer query )
     * idCardBackPhoto 身份证背面|列表|图片|| (string query )
     * idCardFrontPhoto 身份证正面|列表|图片|| (string query )
     * idCardNo 身份证|全部||| (string query )
     * joinArmyTime 入伍年月|全部|时间|时间| (string query )
     * leaveArmyTime 退役年月|全部|时间|时间| (string query )
     * leaveArmyType 退役方式|全部||字符串下拉|api/bms/develop/config/listSelect?key=退役方式 (string query )
     * name 姓名|全部||| (string query )
     * nonlocalFlag 是否异地培训|列表||| (string query )
     * phone 手机号|全部||| (string query )
     * politicalStatus 政治面貌|全部||字符串下拉|api/bms/develop/config/listSelect?key=政治面貌 (string query )
     * remark 备注|全部||| (string query )
     * retirementCardPhoto 退役证照片|列表|图片|| (string query )
     * schoolRollId 职业技能合同编号|全部||| (integer query )
     * selfPhotoUrl 个人照|列表|图片|| (string query )
     * selfSignUrl 个人签名|列表|图片|| (string query )
     * street 街道|无||| (string query )
     * trainDuration 培训期限|列表||| (string query )
     * trainSite 申请承训机构|全部||字符串下拉|api/bms/develop/config/listSelect?key=军人申请承训机构 (string query )
     * trainSkill 技能项目|全部||字符串下拉|api/bms/develop/config/listSelect?key=军人培训技能项目 (string query )
     * trainType 申请培训类型|全部||字符串下拉|api/bms/develop/config/listSelect?key=军人申请培训类型 (string query )
     * userId userId|无||| (integer query )
     * vocationalSkillsInfo 就业技能及等级|列表||| (string query )
     */
    put: (p, c) => $.put("/api/ems/army/soldierApplyInfo/save", p, c),
  },
  soldierApplyInfo_saveForClient: {
    /*
     * 小程序端添加
     * award 立功奖励情况|列表||| (string query )
     * birth 出生年月|全部|时间|时间 (string query )
     * company 工作单位 (string query )
     * createTime 签名时间|全部|时间|时间| (string query )
     * diplomaPhoto 毕业证书照片|列表|图片|| (string query )
     * domicileArea 安置（户籍）地|全部||| (string query )
     * familyAddress 家庭住址|列表||| (string query )
     * familyContactInfo 家庭联系人及电话|列表||| (string query )
     * farea 承诺书里的区|无||| (string query )
     * gender 性别|全部||| (string query )
     * graduateLevel 文化程度|全部||字符串下拉|api/bms/develop/config/listSelect?key=学历 (string query )
     * health 健康状况|全部||字符串下拉|api/bms/develop/config/listSelect?key=健康状况 (string query )
     * id id|无||| (integer query )
     * idCardBackPhoto 身份证背面|列表|图片|| (string query )
     * idCardFrontPhoto 身份证正面|列表|图片|| (string query )
     * idCardNo 身份证|全部||| (string query )
     * joinArmyTime 入伍年月|全部|时间|时间| (string query )
     * leaveArmyTime 退役年月|全部|时间|时间| (string query )
     * leaveArmyType 退役方式|全部||字符串下拉|api/bms/develop/config/listSelect?key=退役方式 (string query )
     * name 姓名|全部||| (string query )
     * nonlocalFlag 是否异地培训|列表||| (string query )
     * phone 手机号|全部||| (string query )
     * politicalStatus 政治面貌|全部||字符串下拉|api/bms/develop/config/listSelect?key=政治面貌 (string query )
     * remark 备注|全部||| (string query )
     * retirementCardPhoto 退役证照片|列表|图片|| (string query )
     * schoolRollId 职业技能合同编号|全部||| (integer query )
     * selfPhotoUrl 个人照|列表|图片|| (string query )
     * selfSignUrl 个人签名|列表|图片|| (string query )
     * street 街道|无||| (string query )
     * trainDuration 培训期限|列表||| (string query )
     * trainSite 申请承训机构|全部||字符串下拉|api/bms/develop/config/listSelect?key=军人申请承训机构 (string query )
     * trainSkill 技能项目|全部||字符串下拉|api/bms/develop/config/listSelect?key=军人培训技能项目 (string query )
     * trainType 申请培训类型|全部||字符串下拉|api/bms/develop/config/listSelect?key=军人申请培训类型 (string query )
     * userId userId|无||| (integer query )
     * vocationalSkillsInfo 就业技能及等级|列表||| (string query )
     */
    post: (p, c) =>
      $.post("/api/ems/army/soldierApplyInfo/saveForClient", p, c),
  },
  soldierApplyInfo_searchByIdCardNo: {
    /*
     * 根据身份证查询
     * idCardNo 身份证号 (string query required)
     */
    get: (p, c) =>
      $.get("/api/ems/army/soldierApplyInfo/searchByIdCardNo", p, c),
  },

  /**
   * 军人信息
   */
  soldierInfo_downloadMould: {
    /*
     * 下载模版
     */
    get: (p, c) => $.get("/api/ems/army/soldierInfo/downloadMould", p, c),
  },
  soldierInfo_updatePhoto: {
    /*
     * 图片更新
     * driverCertificate  (string query )
     * qualifiedCertificate 合格证书 (string query )
     * repairCertificate 汽修培训证书 (string query )
     * schoolRollId 合同编号 (integer query )
     */
    put: (p, c) => $.put("/api/ems/army/soldierInfo/updatePhoto", p, c),
  },
  soldierInfo_upload: {
    /*
     * 导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ems/army/soldierInfo/upload", p, c),
  },
};
