import { $ } from "@/common/Axios";

export default {
  /**
   * 投诉
   */
  complaint: {
    /*
     * 添加
     * appeal 诉求 (string query )
     * attach 附件 (string query )
     * contactPhone 联系手机号 (string query )
     * content 投诉内容 (string query )
     * createId 创建人 (integer query )
     * filingTime 归档时间 (string query )
     * firstCommunicationTime 首次沟通时间 (string query )
     * handleAdminId 处理人 (integer query )
     * handleTime 处理时间 (string query )
     * id  (integer query )
     * module 模块 (string query )
     * result 处理结果：已撤销、未撤销 (string query )
     * schoolRollId 合同id (integer query )
     * status 状态：处理中，已处理，已归档 (string query )
     * subType 子分类 (string query )
     * targetId 投诉对象 (integer query )
     * type 分类 (string query )
     */
    post: (p, c) => $.post("/api/ems/server/complaint", p, c),
  },
  complaint_changeHandleAdmin: {
    /*
     * 更换处理人
     * adminId 处理人 (integer query required)
     * id id (integer query required)
     */
    put: (p, c) => $.put("/api/ems/server/complaint/changeHandleAdmin", p, c),
  },
  complaint_filing: {
    /*
     * 归档
     * id id (integer query required)
     * result 结果 (string query required)
     */
    put: (p, c) => $.put("/api/ems/server/complaint/filing", p, c),
  },
  complaint_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/server/complaint/list", p, c),
  },
  complaint_listModule: {
    /*
     * 模块列表
     */
    get: (p, c) => $.get("/api/ems/server/complaint/listModule", p, c),
  },
  complaint_listSubType: {
    /*
     * 子类列表
     * type 分类 (string query required)
     */
    get: (p, c) => $.get("/api/ems/server/complaint/listSubType", p, c),
  },
  complaint_listType: {
    /*
     * 分类列表
     */
    get: (p, c) => $.get("/api/ems/server/complaint/listType", p, c),
  },
  complaint_transferHandleAdmin: {
    /*
     * 移交处理人
     * adminId 原处理人 (integer query required)
     * newAdminId 新处理人 (integer query required)
     */
    put: (p, c) => $.put("/api/ems/server/complaint/transferHandleAdmin", p, c),
  },

  /**
   * 投诉处理
   */
  complaintHandle_hand: {
    /*
     * 处理
     * attach 附件 (string query )
     * communication 沟通情况 (string query required)
     * complaintId 投诉id (integer query required)
     * status 是否已处理：是|否 (string query required)
     */
    post: (p, c) => $.post("/api/ems/server/complaintHandle/hand", p, c),
  },
  complaintHandle_listByComplaintId: {
    /*
     * 根据投诉id获取处理列表
     * complaintId 投诉id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/server/complaintHandle/listByComplaintId", p, c),
  },

  /**
   * 寄书管理
   */
  mailBook_getFrontAgreementInfo: {
    /*
     * C端获取寄书确认内容
     * id id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/server/mailBook/getFrontAgreementInfo", p, c),
  },
  mailBook_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/server/mailBook/list", p, c),
  },
  mailBook_listCourse: {
    /*
     * 书本列表
     * schoolRollId 合同编号 (integer query required)
     * type 类型: 已寄|未寄 (string query required)
     */
    get: (p, c) => $.get("/api/ems/server/mailBook/listCourse", p, c),
  },
  mailBook_listForDetail: {
    /*
     * 学生详情页面展示的列表
     * schoolRollId 合同编号 (integer query required)
     */
    get: (p, c) => $.get("/api/ems/server/mailBook/listForDetail", p, c),
  },
  mailBook_remove: {
    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/ems/server/mailBook/remove", p, c),
  },
  mailBook_save: {
    /*
     * 添加编辑
     * courseIds 选择的书本列表 (array query required)
     * mailNo 快递单号 (string query )
     * name 邮寄名称 (string query required)
     * postTime 邮寄时间 (string query required)
     * schoolRollId 合同编号 (integer query required)
     */
    post: (p, c) => $.post("/api/ems/server/mailBook/save", p, c),
  },
  mailBook_searchById: {
    /*
     * 书本列表
     * id 行ID (integer query required)
     */
    get: (p, c) => $.get("/api/ems/server/mailBook/searchById", p, c),
  },
  mailBook_updateMailNo: {
    /*
     * 编辑快递单号
     * id id (integer query required)
     * mailNo 快递单号 (string query required)
     * name 邮寄名称 (string query required)
     * postTime 邮寄时间 (string query required)
     */
    put: (p, c) => $.put("/api/ems/server/mailBook/updateMailNo", p, c),
  },
  mailBook_uploadAgreementForClient: {
    /*
     * C端上传寄书确认协议（学生签名）-自考
     * id 邮寄详情ID (integer query required)
     * signData 签名图片，png文件，base64值 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/server/mailBook/uploadAgreementForClient", p, c),
  },

  /**
   * 邮寄表
   */
  mailService: {
    /*
     * 添加
     * allCount 总人数 (integer query )
     * deadline 截止时间 (string query )
     * examBatch 寄书批次-自考考期 (string query )
     * id  (integer query )
     * name 名称 (string query )
     * rollGrade 学籍年级 (string query )
     * siteType 学校类型 (string query )
     */
    post: (p, c) => $.post("/api/ems/server/mailService", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/ems/server/mailService", p, c),
  },
  mailService_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/server/mailService/list", p, c),
  },
  mailService_listForClient: {
    /*
     * C端列表
     */
    get: (p, c) => $.get("/api/ems/server/mailService/listForClient", p, c),
  },
  mailService_listName: {
    /*
     * 名字列表
     */
    get: (p, c) => $.get("/api/ems/server/mailService/listName", p, c),
  },
  mailService_listSimple: {
    /*
     * 简易列表
     */
    get: (p, c) => $.get("/api/ems/server/mailService/listSimple", p, c),
  },
  mailService_searchLatestForClient: {
    /*
     * C端查询最后的一次邮寄记录, 取地址用
     */
    get: (p, c) =>
      $.get("/api/ems/server/mailService/searchLatestForClient", p, c),
  },
  mailService_updateDeadline: {
    /*
     * 编辑截止时间
     * deadline 截止时间 (string query required)
     * id id (integer query required)
     */
    put: (p, c) => $.put("/api/ems/server/mailService/updateDeadline", p, c),
  },

  /**
   * 邮寄表详情
   */
  mailServiceRecord: {
    /*
     * 添加
     * address 具体地址 (string query )
     * city 市 (string query )
     * district 区 (string query )
     * id  (integer query )
     * mailNo 快递单号 (string query )
     * mailServiceId 邮寄表ID (integer query )
     * phone 手机号 (string query )
     * province 省 (string query )
     * schoolRollId 学籍id (integer query )
     */
    post: (p, c) => $.post("/api/ems/server/mailServiceRecord", p, c),
  },
  mailServiceRecord_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ems/server/mailServiceRecord/export", p, c),
  },
  mailServiceRecord_exportForService: {
    /*
     * 导出（服务）
     */
    get: (p, c) =>
      $.get("/api/ems/server/mailServiceRecord/exportForService", p, c),
  },
  mailServiceRecord_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/server/mailServiceRecord/list", p, c),
  },
  mailServiceRecord_listForAdultMail: {
    /*
     * 成考教材邮寄列表
     */
    get: (p, c) =>
      $.get("/api/ems/server/mailServiceRecord/listForAdultMail", p, c),
  },
  mailServiceRecord_listForService: {
    /*
     * 列表（服务）
     */
    get: (p, c) =>
      $.get("/api/ems/server/mailServiceRecord/listForService", p, c),
  },
  mailServiceRecord_saveForClient: {
    /*
     * 成考教材邮寄-c端提交
     * address 具体地区 (string query required)
     * city 市 (string query required)
     * district 区 (string query required)
     * phone 手机 (string query required)
     * province 省 (string query required)
     * schoolRollId 合同编号 (integer query required)
     */
    put: (p, c) =>
      $.put("/api/ems/server/mailServiceRecord/saveForClient", p, c),
  },
  mailServiceRecord_searchForClient: {
    /*
     * 成考教材邮寄-c端查询
     * batch 批次 (string query )
     * idCardNo 身份证 (string query required)
     */
    get: (p, c) =>
      $.get("/api/ems/server/mailServiceRecord/searchForClient", p, c),
  },
  mailServiceRecord_updateMailNo: {
    /*
     * 编辑快递单号
     * id id (integer query required)
     * mailNo 快递单号 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/server/mailServiceRecord/updateMailNo", p, c),
  },
  mailServiceRecord_uploadMailNo: {
    /*
     * 导入快递单号
     * excelFile excelFile (file formData required)
     * mailServiceId id (integer query required)
     */
    post: (p, c) =>
      $.post("/api/ems/server/mailServiceRecord/uploadMailNo", p, c),
  },

  /**
   * 班主任评分
   */
  schoolRollAppraise_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/server/schoolRollAppraise/list", p, c),
  },
  schoolRollAppraise_listBySchoolRollId: {
    /*
     * 根据合同编号查询评价记录
     * schoolRollId 学籍id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/server/schoolRollAppraise/listBySchoolRollId", p, c),
  },
  schoolRollAppraise_save: {
    /*
     * 添加
     * appraise 评语 (string query )
     * grade  (string query )
     * headerTeacherId  (integer query )
     * id  (integer query )
     * operatorId 点评人 (integer query )
     * schoolRollId 学籍ID (integer query )
     */
    post: (p, c) => $.post("/api/ems/server/schoolRollAppraise/save", p, c),
  },

  /**
   * 服务打回
   */
  serviceBack_apply: {
    /*
     * 申请
     * remark 备注 (string query )
     * schoolRollId 学籍id (integer query required)
     */
    post: (p, c) => $.post("/api/ems/server/serviceBack/apply", p, c),
  },
  serviceBack_audit: {
    /*
     * 审核
     * auditStatus 审核状态 (string query required)
     * flowId 节点id (integer query required)
     * headerTeacherId 班主任id (integer query )
     * id id (integer query required)
     * remark 备注 (string query )
     */
    put: (p, c) => $.put("/api/ems/server/serviceBack/audit", p, c),
  },
  serviceBack_details: {
    /*
     * 详情
     * id id (integer query required)
     */
    get: (p, c) => $.get("/api/ems/server/serviceBack/details", p, c),
  },
  serviceBack_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/server/serviceBack/list", p, c),
  },
  serviceBack_listForAdminTeacher: {
    /*
     * 招生管理列表
     */
    get: (p, c) =>
      $.get("/api/ems/server/serviceBack/listForAdminTeacher", p, c),
  },
  serviceBack_listSimple: {
    /*
     * 简易列表
     * schoolRollId 学籍id (integer query required)
     */
    get: (p, c) => $.get("/api/ems/server/serviceBack/listSimple", p, c),
  },

  /**
   * 服务联系记录
   */
  serviceContact: {
    /*
     * 添加
     * attach 附件 (string query )
     * content 内容 (string query )
     * createId 发起人 (integer query )
     * endTime  (string query )
     * id  (integer query )
     * preContactTime 预计下次联系时间 (string query )
     * recordDetail  (string query )
     * schoolRollId 学籍id (integer query )
     * startTime  (string query )
     * type 类型：0普通 1聊天记录 (integer query )
     */
    post: (p, c) => $.post("/api/ems/server/serviceContact", p, c),
  },
  serviceContact_getDetail: {
    /*
     * 聊天记录详情，type=1时取
     * id id (integer query required)
     */
    get: (p, c) => $.get("/api/ems/server/serviceContact/getDetail", p, c),
  },
  serviceContact_listSimple: {
    /*
     * 简易列表
     * schoolRollId 合同id (integer query required)
     */
    get: (p, c) => $.get("/api/ems/server/serviceContact/listSimple", p, c),
  },

  /**
   * 学历服务信息
   */
  serviceSchoolRoll_assign: {
    /*
     * 分配班主任
     * headerTeacherId 班主任id (integer query required)
     * ids 服务信息id (array query required)
     */
    put: (p, c) => $.put("/api/ems/server/serviceSchoolRoll/assign", p, c),
  },
  serviceSchoolRoll_assignForContinue: {
    /*
     * 分配班主任-继教院
     * headerTeacherId 班主任id (integer query required)
     * ids 服务信息id (array query required)
     * originSchoolRollIds 行里的eduSchoolRollId，为null传0 (array query required)
     */
    put: (p, c) =>
      $.put("/api/ems/server/serviceSchoolRoll/assignForContinue", p, c),
  },
  serviceSchoolRoll_assignForSelfTaught: {
    /*
     * 分配班主任-自考
     * headerTeacherId 班主任id (integer query required)
     * ids 服务信息id (array query required)
     */
    put: (p, c) =>
      $.put("/api/ems/server/serviceSchoolRoll/assignForSelfTaught", p, c),
  },
  serviceSchoolRoll_batchChangeHeaderTeacher: {
    /*
     * 批量更换班主任
     * headerTeacherId 班主任id (integer query required)
     * ids 服务id (array query required)
     */
    put: (p, c) =>
      $.put("/api/ems/server/serviceSchoolRoll/batchChangeHeaderTeacher", p, c),
  },
  serviceSchoolRoll_batchChangeHeaderTeacherForContinue: {
    /*
     * 批量更换班主任-继教院
     * headerTeacherId 班主任id (integer query required)
     * ids 服务id (array query required)
     * originSchoolRollIds 行里的eduSchoolRollId，为null传0 (array query required)
     */
    put: (p, c) =>
      $.put(
        "/api/ems/server/serviceSchoolRoll/batchChangeHeaderTeacherForContinue",
        p,
        c
      ),
  },
  serviceSchoolRoll_batchChangeHeaderTeacherForSelfTaught: {
    /*
     * 批量更换班主任-自考
     * headerTeacherId 班主任id (integer query required)
     * ids 服务id (array query required)
     */
    put: (p, c) =>
      $.put(
        "/api/ems/server/serviceSchoolRoll/batchChangeHeaderTeacherForSelfTaught",
        p,
        c
      ),
  },
  serviceSchoolRoll_birthdayToday: {
    /*
     * 当天生日学生数量
     */
    get: (p, c) =>
      $.get("/api/ems/server/serviceSchoolRoll/birthdayToday", p, c),
  },
  serviceSchoolRoll_changeHeaderTeacher: {
    /*
     * 更换班主任
     * headerTeacherId 班主任id (integer query required)
     * originHeaderTeacherId 原班主任id (integer query required)
     */
    put: (p, c) =>
      $.put("/api/ems/server/serviceSchoolRoll/changeHeaderTeacher", p, c),
  },
  serviceSchoolRoll_countContactByDays: {
    /*
     * 距离上次联系时间天数
     * days 天数 (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/server/serviceSchoolRoll/countContactByDays", p, c),
  },
  serviceSchoolRoll_countOverdueContact: {
    /*
     * 首次联系逾期学生数量
     */
    get: (p, c) =>
      $.get("/api/ems/server/serviceSchoolRoll/countOverdueContact", p, c),
  },
  serviceSchoolRoll_countUnContact: {
    /*
     * 未联系学生数量
     */
    get: (p, c) =>
      $.get("/api/ems/server/serviceSchoolRoll/countUnContact", p, c),
  },
  serviceSchoolRoll_exportStudent: {
    /*
     * 服务学生列表导出
     */
    get: (p, c) =>
      $.get("/api/ems/server/serviceSchoolRoll/exportStudent", p, c),
  },
  serviceSchoolRoll_exportStudentForSelfTaught: {
    /*
     * 服务学生列表（自考）导出
     */
    get: (p, c) =>
      $.get(
        "/api/ems/server/serviceSchoolRoll/exportStudentForSelfTaught",
        p,
        c
      ),
  },
  serviceSchoolRoll_initFirstContact: {
    /*
     * 初始化首次联系记录
     */
    post: (p, c) =>
      $.post("/api/ems/server/serviceSchoolRoll/initFirstContact", p, c),
  },
  serviceSchoolRoll_listSimpleStudent: {
    /*
     * 简单服务学生列表
     * batch 批次 (string query )
     * level 层次 (string query )
     * majorId 专业 (integer query )
     * siteId 专业 (integer query )
     */
    get: (p, c) =>
      $.get("/api/ems/server/serviceSchoolRoll/listSimpleStudent", p, c),
  },
  serviceSchoolRoll_listStudent: {
    /*
     * 服务学生列表
     */
    get: (p, c) => $.get("/api/ems/server/serviceSchoolRoll/listStudent", p, c),
  },
  serviceSchoolRoll_listStudentByReceptionTeacher: {
    /*
     * 高校学生列表
     */
    get: (p, c) =>
      $.get(
        "/api/ems/server/serviceSchoolRoll/listStudentByReceptionTeacher",
        p,
        c
      ),
  },
  serviceSchoolRoll_listStudentForContinue: {
    /*
     * 服务学生列表（继教院）
     */
    get: (p, c) =>
      $.get("/api/ems/server/serviceSchoolRoll/listStudentForContinue", p, c),
  },
  serviceSchoolRoll_listStudentForSelfTaught: {
    /*
     * 服务学生列表（自考）
     */
    get: (p, c) =>
      $.get("/api/ems/server/serviceSchoolRoll/listStudentForSelfTaught", p, c),
  },
  serviceSchoolRoll_listUnAssign: {
    /*
     * 未分配学生列表
     */
    get: (p, c) =>
      $.get("/api/ems/server/serviceSchoolRoll/listUnAssign", p, c),
  },
  serviceSchoolRoll_listUnAssignForContinue: {
    /*
     * 未分配学生列表(继教院)
     */
    get: (p, c) =>
      $.get("/api/ems/server/serviceSchoolRoll/listUnAssignForContinue", p, c),
  },
  serviceSchoolRoll_listUnAssignForSelfTaught: {
    /*
     * 未分配学生列表(自考)
     */
    get: (p, c) =>
      $.get(
        "/api/ems/server/serviceSchoolRoll/listUnAssignForSelfTaught",
        p,
        c
      ),
  },
  serviceSchoolRoll_remark: {
    /*
     * 备注
     * id id (integer query required)
     * remark 标签 (string query required)
     */
    put: (p, c) => $.put("/api/ems/server/serviceSchoolRoll/remark", p, c),
  },
  serviceSchoolRoll_saveAppraise: {
    /*
     * 添加班主任评语
     * appraise 评语 (string query required)
     * grade 评语 (string query required)
     * headerTeacherId 班主任id (integer query required)
     * operatorId 评价人ID (integer query required)
     * schoolRollId 学籍ID (integer query required)
     */
    put: (p, c) =>
      $.put("/api/ems/server/serviceSchoolRoll/saveAppraise", p, c),
  },
  serviceSchoolRoll_sendUnAssignNoticeToAdminTeacher: {
    /*
     * 发送未分配班主任钉钉通知到招生老师
     */
    post: (p, c) =>
      $.post(
        "/api/ems/server/serviceSchoolRoll/sendUnAssignNoticeToAdminTeacher",
        p,
        c
      ),
  },
  serviceSchoolRoll_updateDegreeApply: {
    /*
     * 修改学位申请情况
     * apply 是否申请学位 (boolean query required)
     */
    put: (p, c) =>
      $.put("/api/ems/server/serviceSchoolRoll/updateDegreeApply", p, c),
  },
  serviceSchoolRoll_uploadChangeHeaderTeacher: {
    /*
     * 导入更换班主任
     * excelFile excelFile (file formData required)
     */
    post: (p, c) =>
      $.post(
        "/api/ems/server/serviceSchoolRoll/uploadChangeHeaderTeacher",
        p,
        c
      ),
  },
  serviceSchoolRoll_uploadPhotoCollection: {
    /*
     * 导入毕业照片采集地
     * excelFile excelFile (file formData required)
     */
    post: (p, c) =>
      $.post("/api/ems/server/serviceSchoolRoll/uploadPhotoCollection", p, c),
  },

  /**
   * 学生通知
   */
  studentMessage_detail: {
    /*
     * 通知内容详情
     * id id (integer query required)
     */
    get: (p, c) => $.get("/api/ems/server/studentMessage/detail", p, c),
  },
  studentMessage_detailForClient: {
    /*
     * c端-我的通知详情
     * id id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/server/studentMessage/detailForClient", p, c),
  },
  studentMessage_downloadMould: {
    /*
     * 下载模版
     */
    get: (p, c) => $.get("/api/ems/server/studentMessage/downloadMould", p, c),
  },
  studentMessage_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/server/studentMessage/list", p, c),
  },
  studentMessage_listForClient: {
    /*
     * c端-我的通知列表
     */
    get: (p, c) => $.get("/api/ems/server/studentMessage/listForClient", p, c),
  },
  studentMessage_listStudentDetail: {
    /*
     * 已读|未读学生列表
     * id id (integer query required)
     * name 学生姓名 (string query )
     * readStatus 已读状态 (string query )
     * wechatFollowStatus 公众号关注状态 (string query )
     */
    get: (p, c) =>
      $.get("/api/ems/server/studentMessage/listStudentDetail", p, c),
  },
  studentMessage_publish: {
    /*
     * 发布
     * id id (integer query required)
     */
    put: (p, c) => $.put("/api/ems/server/studentMessage/publish", p, c),
  },
  studentMessage_resend: {
    /*
     * 重新发送
     * id 已读|未读学生列表返回的id (integer query required)
     */
    put: (p, c) => $.put("/api/ems/server/studentMessage/resend", p, c),
  },
  studentMessage_save: {
    /*
     * 添加编辑
     * content 内容 (string query )
     * extraUrl 附件地址 (string query )
     * id ID编辑传入 (integer query )
     * keyword1 关键字1 (string query )
     * keyword2 关键字2 (string query )
     * keyword3 关键字3 (string query )
     * keyword4 关键字4 (string query )
     * keyword5 关键字5 (string query )
     * templateId 模板id (integer query )
     * title 标题 (string query )
     */
    post: (p, c) => $.post("/api/ems/server/studentMessage/save", p, c),
  },
  studentMessage_uploadReceiver: {
    /*
     * 导入收件人
     * excelFile excelFile (file formData required)
     * studentMessageId 通知ID (integer query required)
     */
    post: (p, c) =>
      $.post("/api/ems/server/studentMessage/uploadReceiver", p, c),
  },
};
