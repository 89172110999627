import { $ } from "@/common/Axios";

export default {
  /**
   * 线下考试
   */
  offline_arrangeExam: {
    /*
     * 排考
     * centreClassroomIds 选择的考点教室ID列表 (array query required)
     * id ID (integer query required)
     */
    put: (p, c) => $.put("/api/ems/examination/offline/arrangeExam", p, c),
  },
  offline_clearExam: {
    /*
     * 清空排考
     * id ID (integer query required)
     */
    delete: (p, c) => $.delete("/api/ems/examination/offline/clearExam", p, c),
  },
  offline_exportCovers: {
    /*
     * 导出试卷袋封面数据
     * examId 考试ID (integer query required)
     */
    get: (p, c) => $.get("/api/ems/examination/offline/exportCovers", p, c),
  },
  offline_exportExamCards: {
    /*
     * 获取准考证、桌贴
     * examId 考试ID (integer query required)
     */
    get: (p, c) => $.get("/api/ems/examination/offline/exportExamCards", p, c),
  },
  offline_exportPrintData: {
    /*
     * 导出打印数据
     * examId 考试ID (integer query required)
     */
    get: (p, c) => $.get("/api/ems/examination/offline/exportPrintData", p, c),
  },
  offline_exportSignInData: {
    /*
     * 获取签到表数据
     * examId 考试ID (integer query required)
     */
    get: (p, c) => $.get("/api/ems/examination/offline/exportSignInData", p, c),
  },
  offline_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/examination/offline/list", p, c),
  },
  offline_listCentreClassroom: {
    /*
     * 查询可分配的考点教室列表
     * examId 考试ID (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/examination/offline/listCentreClassroom", p, c),
  },
  offline_refreshStudent: {
    /*
     * 刷新学生数据
     * id ID (integer query required)
     */
    put: (p, c) => $.put("/api/ems/examination/offline/refreshStudent", p, c),
  },
  offline_remove: {
    /*
     * 删除考试
     * id ID (integer query required)
     */
    delete: (p, c) => $.delete("/api/ems/examination/offline/remove", p, c),
  },
  offline_save: {
    /*
     * 添加
     * examDate 考试日期 (string query required)
     * id ID (integer query )
     * name 名称 (string query required)
     * rollGradesAndTerms 学籍年级和学期,多组 (string query required)
     * siteId 学校ID (integer query required)
     */
    post: (p, c) => $.post("/api/ems/examination/offline/save", p, c),
  },
  offline_saveExamTimes: {
    /*
     * 添加|编辑考试场次
     * examTimes 例9:00-10:00,11:00-12:00 (array query required)
     * id ID (integer query required)
     */
    put: (p, c) => $.put("/api/ems/examination/offline/saveExamTimes", p, c),
  },

  /**
   * 线下考试学生
   */
  offlineStudent_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ems/examination/offlineStudent/export", p, c),
  },
  offlineStudent_list: {
    /*
     * 考务管理-列表
     */
    get: (p, c) => $.get("/api/ems/examination/offlineStudent/list", p, c),
  },

  /**
   * 线上考试
   */
  online_answerForClient: {
    /*
     * C端-提交考试大难
     * beforeExamCheckingPhoto 考前身份核验照片 (string query )
     * firstAnswerPhoto 答卷照片1 (string query required)
     * handlePaperPhoto 手持答题卷照片 (string query )
     * id 我的考试列表ID (integer query required)
     * secondAnswerPhoto 答卷照片2 (string query )
     * thirdAnswerPhoto 答卷照片3 (string query )
     */
    put: (p, c) => $.put("/api/ems/examination/online/answerForClient", p, c),
  },
  online_batchAssign: {
    /*
     * 批量分配监考老师
     * ids 列表IDs (array query required)
     * invigilatorId 监考老师ID (integer query required)
     */
    put: (p, c) => $.put("/api/ems/examination/online/batchAssign", p, c),
  },
  online_clearExam: {
    /*
     * 打回重考
     * id id (integer query required)
     */
    put: (p, c) => $.put("/api/ems/examination/online/clearExam", p, c),
  },
  online_detail: {
    /*
     * 详情
     * id 列表ID (integer query required)
     */
    get: (p, c) => $.get("/api/ems/examination/online/detail", p, c),
  },
  online_downloadMould: {
    /*
     * 下载模版
     */
    get: (p, c) => $.get("/api/ems/examination/online/downloadMould", p, c),
  },
  online_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ems/examination/online/export", p, c),
  },
  online_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/examination/online/list", p, c),
  },
  online_listForClient: {
    /*
     * C端-我的考试列表
     * finishStatus 完成状态（已完成|未完成） (string query required)
     */
    get: (p, c) => $.get("/api/ems/examination/online/listForClient", p, c),
  },
  online_listInvigilator: {
    /*
     * 简单监考老师列表
     */
    get: (p, c) => $.get("/api/ems/examination/online/listInvigilator", p, c),
  },
  online_remove: {
    /*
     * 删除
     * id 列表ID (integer query required)
     */
    delete: (p, c) => $.delete("/api/ems/examination/online/remove", p, c),
  },
  online_update: {
    /*
     * 编辑
     * beforeExamCheckingPhoto 考前身份核验照片 (string query )
     * dingGroupNo 所在钉钉群 (string query required)
     * endTime 考试结束时间 (string query required)
     * firstAnswerPhoto 答卷照片1 (string query required)
     * handlePaperPhoto 手持答题卷照片 (string query )
     * id 行ID (integer query required)
     * onlyAnswerFlag 是否只需要提交答卷照片 (string query )
     * secondAnswerPhoto 答卷照片2 (string query )
     * startTime 考试开始时间 (string query required)
     * thirdAnswerPhoto 答卷照片3 (string query )
     */
    put: (p, c) => $.put("/api/ems/examination/online/update", p, c),
  },
  online_upload: {
    /*
     * 批量导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ems/examination/online/upload", p, c),
  },

  /**
   * 自考成绩
   */
  selfTaughtScore_downloadMould: {
    /*
     * 下载模版
     */
    get: (p, c) =>
      $.get("/api/ems/examination/selfTaughtScore/downloadMould", p, c),
  },
  selfTaughtScore_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ems/examination/selfTaughtScore/export", p, c),
  },
  selfTaughtScore_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/examination/selfTaughtScore/list", p, c),
  },
  selfTaughtScore_listScore: {
    /*
     * 根据合同编号查询成绩
     * examBatch 考期 (string query )
     * schoolRollId 合同编号 (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/examination/selfTaughtScore/listScore", p, c),
  },
  selfTaughtScore_remove: {
    /*
     * 删除
     * id 列表ID (integer query required)
     */
    delete: (p, c) =>
      $.delete("/api/ems/examination/selfTaughtScore/remove", p, c),
  },
  selfTaughtScore_update: {
    /*
     * 编辑
     * examBatch 考期, 下拉(key=自考考期) (string query )
     * examCardNo 准考证号 (string query )
     * id 行ID (integer query required)
     * score 成绩 (string query )
     */
    put: (p, c) => $.put("/api/ems/examination/selfTaughtScore/update", p, c),
  },
  selfTaughtScore_upload: {
    /*
     * 导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ems/examination/selfTaughtScore/upload", p, c),
  },

  /**
   * 成绩管理
   */
  studentScore_applyChecking: {
    /*
     * 申请复查
     * id 列表ID (integer query required)
     * reason 复查原因 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/examination/studentScore/applyChecking", p, c),
  },
  studentScore_auditChecking: {
    /*
     * 处理复查
     * id 复查列表ID (integer query required)
     * offlineExamScore 修改后考试成绩 (number query )
     * resitExamScore 修改后补考成绩 (number query )
     * result 处理结果 (string query required)
     * totalScore 修改后总成绩 (number query )
     */
    put: (p, c) =>
      $.put("/api/ems/examination/studentScore/auditChecking", p, c),
  },
  studentScore_batchPublish: {
    /*
     * 批量发布
     * ids 列表ID (array query required)
     * publishStatus 发布状态（已发布未发布） (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/examination/studentScore/batchPublish", p, c),
  },
  studentScore_downloadMould: {
    /*
     * 下载模版
     */
    get: (p, c) =>
      $.get("/api/ems/examination/studentScore/downloadMould", p, c),
  },
  studentScore_exportForEdu: {
    /*
     * 考务管理-成绩管理列表导出
     */
    get: (p, c) =>
      $.get("/api/ems/examination/studentScore/exportForEdu", p, c),
  },
  studentScore_exportForService: {
    /*
     * 服务管理-成绩管理列表导出
     */
    get: (p, c) =>
      $.get("/api/ems/examination/studentScore/exportForService", p, c),
  },
  studentScore_getBySchoolRollId: {
    /*
     * 根据合同编号查询成绩管理列表
     * schoolRollId 合同编号 (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/examination/studentScore/getBySchoolRollId", p, c),
  },
  studentScore_listCheckingRecord: {
    /*
     * 成绩复查记录列表
     * id 列表ID (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/examination/studentScore/listCheckingRecord", p, c),
  },
  studentScore_listForEdu: {
    /*
     * 考务管理-成绩管理列表
     */
    get: (p, c) => $.get("/api/ems/examination/studentScore/listForEdu", p, c),
  },
  studentScore_listForService: {
    /*
     * 服务管理-成绩管理列表
     */
    get: (p, c) =>
      $.get("/api/ems/examination/studentScore/listForService", p, c),
  },
  studentScore_listStudentScoreForExport: {
    /*
     * 导出成绩档案-杭商院
     * majorId 专业ID (integer query )
     * rollGrade 学习年级 (string query )
     * siteId 学校ID (integer query required)
     * term 学期 (integer query )
     */
    get: (p, c) =>
      $.get(
        "/api/ems/examination/studentScore/listStudentScoreForExport",
        p,
        c
      ),
  },
  studentScore_publish: {
    /*
     * 发布
     * id 列表ID (integer query required)
     * publishStatus 发布状态（已发布未发布） (string query required)
     */
    put: (p, c) => $.put("/api/ems/examination/studentScore/publish", p, c),
  },
  studentScore_remove: {
    /*
     * 删除
     * id 列表ID (integer query required)
     */
    delete: (p, c) =>
      $.delete("/api/ems/examination/studentScore/remove", p, c),
  },
  studentScore_schoolStudentScoreExport: {
    /*
     * 导出高校成绩单
     * checkAdmin 复核人（学校温职需要） (string query )
     * commitAdmin 登记人（学校温职需要, 学校湖师,默认传登录人名字） (string query )
     * examTime 考试时间（年月日，学校温职需要） (string query )
     * majorId 专业ID (integer query )
     * rollGrade 学习年级 (string query required)
     * siteId 学校ID (integer query required)
     * siteName 学校 (string query required)
     * studyEnd 学习结束时间（年月日，学校湖师需要） (string query )
     * studyStart 学习开始时间（年月日，学校湖师需要） (string query )
     * term 学期 (integer query )
     */
    get: (p, c) =>
      $.get("/api/ems/examination/studentScore/schoolStudentScoreExport", p, c),
  },
  studentScore_searchForClient: {
    /*
     * C端我的期末考试成绩列表
     */
    get: (p, c) =>
      $.get("/api/ems/examination/studentScore/searchForClient", p, c),
  },
  studentScore_update: {
    /*
     * 编辑
     * id 列表ID (integer query required)
     * offlineExamScore 考试成绩 (number query )
     * resitExamScore 补考成绩 (number query )
     * totalScore 总成绩 (number query )
     * usualScore 总成绩 (number query )
     * workScore 总成绩 (number query )
     */
    put: (p, c) => $.put("/api/ems/examination/studentScore/update", p, c),
  },
  studentScore_upload: {
    /*
     * 批量上传
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ems/examination/studentScore/upload", p, c),
  },

  /**
   * 学生签到
   */
  studentSignature_batchClear: {
    /*
     * 批量清空签到
     * ids ID (array query )
     */
    put: (p, c) =>
      $.put("/api/ems/examination/studentSignature/batchClear", p, c),
  },
  studentSignature_delay: {
    /*
     * 批量延期
     * endTime 签到结束时间 (string query required)
     * siteId 学校 (integer query required)
     */
    put: (p, c) => $.put("/api/ems/examination/studentSignature/delay", p, c),
  },
  studentSignature_downloadMould: {
    /*
     * 下载模版
     */
    get: (p, c) =>
      $.get("/api/ems/examination/studentSignature/downloadMould", p, c),
  },
  studentSignature_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ems/examination/studentSignature/export", p, c),
  },
  studentSignature_exportExcel: {
    /*
     * 导出签到表
     * headerTeacherId 班主任 (integer query )
     * majorIds 专业ID,多选 (array query )
     * rollGrade 学籍年级 (string query )
     * siteId 学校ID (integer query required)
     * term 学期 (integer query )
     */
    get: (p, c) =>
      $.get("/api/ems/examination/studentSignature/exportExcel", p, c),
  },
  studentSignature_getForClient: {
    /*
     * 学生端-我的列表
     */
    get: (p, c) =>
      $.get("/api/ems/examination/studentSignature/getForClient", p, c),
  },
  studentSignature_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/examination/studentSignature/list", p, c),
  },
  studentSignature_listSimpleSite: {
    /*
     * 简单学校列表
     */
    get: (p, c) =>
      $.get("/api/ems/examination/studentSignature/listSimpleSite", p, c),
  },
  studentSignature_remove: {
    /*
     * 删除
     * ids ID (array query )
     */
    delete: (p, c) =>
      $.delete("/api/ems/examination/studentSignature/remove", p, c),
  },
  studentSignature_signatureForClient: {
    /*
     * 学生端-学生签到
     * id detail列表ID (integer query required)
     * signatureUrl 签到地址 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/examination/studentSignature/signatureForClient", p, c),
  },
  studentSignature_update: {
    /*
     * 编辑
     * address 上课地点 (string query required)
     * endTime 签到结束时间 (string query required)
     * id 行ID (integer query required)
     * roomNo 教室号 (string query required)
     * startTime 签到开始时间 (string query required)
     * status 学生签到状态 (string query required)
     * studyDate 上课日期（仅年月日） (string query required)
     * studyTime 具体时间（上午或下午） (string query required)
     * teacherName 任课老师 (string query required)
     */
    put: (p, c) => $.put("/api/ems/examination/studentSignature/update", p, c),
  },
  studentSignature_upload: {
    /*
     * 批量导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) =>
      $.post("/api/ems/examination/studentSignature/upload", p, c),
  },
};
