/**
 * 公用store
 */
import jsCookie from "js-cookie";
import config from "../../config";

export default {
  state: {
    /**
     * 顶部菜单
     */
    headMenu: {
      show: true,
      isTransparent: false,
    },
    nave: {
      ind: 0,
      sideInd: 0,
    },
    menu: {
      ind: "",
      subInd: "",
      title: "",
      subTitle: "",
      subOpen: true,
      list: [],
    },
    /**
     * 当前用户信息
     */
    userInfo: {},
  },
  mutations: {
    /**
     * 顶部菜单
     */
    headMenuSet(state, res) {
      return (state.headMenu = {
        ...state.headMenu,
        ...res,
      });
    },
    menuSet(state, res) {
      return (state.menu = {
        ...state.menu,
        ...res,
      });
    },
    naveSet(state, res = {}) {
      return (state.nave = res);
    },
    userInfoSet(state, res) {
      return (state.userInfo = res);
    },
  },
  actions: {
    // 修改导航索引
    naveIndSet({ state, commit }, res) {
      commit("naveSet", {
        ...state.nave,
        ind: res,
      });
    },
    userInfoUpdate({ state, commit }, res = {}) {
      let data = {
        ...state.userInfo,
        ...res,
      };
      jsCookie.set(
        `${config.NAME}_userInfo`,
        JSON.stringify({
          id: data.id,
        })
      );
      localStorage.setItem(
        `${config.NAME}_userInfo_${data.id}`,
        JSON.stringify(data)
      );
      commit("userInfoSet", data);
    },
  },
  getters: {},
};
