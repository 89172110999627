import { mapState } from "vuex";
import { stringify } from "qs";
import store from "../store";
export default {
  data() {
    return {
      query: this.$route.query,
      params: this.$route.params,
      //分页——start
      pageNum: 1,
      pageSize: 15,
      total: 0,
      //分页——end
      info: {},
      list: [],
      labels: [],
      articleList: [],
      details: {},
      isUserLogin: true,
    };
  },
  computed: mapState({
    menu: (state) => state.main.menu,
    userInfo: (state) => state.main.userInfo,
  }),

  async mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll();
    await this.login(this.isUserLogin);
    if (typeof this.init === "function") this.init();
  },
  methods: {
    async login(isUserLogin = true) {
      if (isUserLogin) {
        await this.userLogin();
      }
    },
    userLogin() {
      return new Promise((resolve, reject) => {
        const userInfo = store.state.main.userInfo;
        if (userInfo.id) {
          resolve();
        } else {
          // 没有登录
          this.$router.push({
            path: "/login",
          });
          reject();
        }
      });
    },
    // 判断是否用户已登录
    async isUserLoginFlg() {
      const userInfo = store.state.main.userInfo;
      if (userInfo.id) {
        // 没有登录
        return true;
      } else {
        return false;
      }
    },
    getMenuList({
      pageNum = this.pageNum,
      pageSize = this.pageSize,
      menuId = this.params.menuId,
    } = {}) {
      return new Promise((resolve) => {
        this.$api.cms.web.siteContent_listForClient
          .get({
            pageNum,
            pageSize,
            menuId,
          })
          .then((res) => {
            this.total = Number(res.data.total);
            resolve(res.data.pageList);
          });
      });
    },
    getLabels() {
      return new Promise((resolve) => {
        this.$api.cms.content.publicity_listLabel
          .get({
            platform: "成考宝典",
            type: "成考资讯",
          })
          .then((res) => {
            this.labels = res.data;
            resolve(res.data);
          });
      });
    },
    getLists(province, label) {
      return new Promise((resolve) => {
        this.$api.cms.content.publicity_listPublicityForClient
          .get({
            platform: "成考宝典",
            type: "成考资讯",
            province,
            label,
            showSide: "移动端",
          })
          .then((res) => {
            this.articleList = res.data.pageList;
            this.total = Number(res.data.total);
            resolve(res.data);
          });
      });
    },

    async getInfo() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {
        let contList = [];
        let info = {};

        if (this.query.pType === "one") {
          contList = await this.getMenuList();
          if (contList && contList.length) {
            info = await this.getAjaxInfo(contList[0].id);
          }
        } else {
          info = await this.getAjaxInfo(this.params.menuId);
        }

        this.info = info;
        resolve(info);
      });
    },
    getArticleDetail(id) {
      return new Promise((resolve) => {
        this.$api.cms.content.publicity_detailsForClient
          .get({
            id,
          })
          .then((res) => {
            resolve(res.data);
            this.details = res.data;
          });
      });
    },
    getAjaxInfo(id) {
      return new Promise((resolve) => {
        this.$api.cms.web.siteContent_detailsForClient
          .get({
            id,
          })
          .then((res) => {
            resolve(res.data);
          });
      });
    },
    async getList() {
      this.list = await this.getMenuList();
    },
    async getListAndInfo() {
      let list = await this.getMenuList();
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        const info = await this.getAjaxInfo(item.id);
        if (info) {
          list[i] = {
            ...item,
            ...info,
          };
        }
      }
      this.list = list;
    },
    onCurrentChange(val) {
      console.log(val);
      console.log(this.pageSize, this.total);
      this.pageNum = val;
      if (typeof this.getAjax === "function") {
        this.getAjax();
      }
    },
    getMenu(title) {
      for (let item of this.menu.list) {
        for (let item2 of item.subMenus) {
          if (item2.name === title) {
            return item2;
          }
        }
      }
    },
    getMenuPath(title) {
      const menu = this.getMenu(title);
      if (menu) {
        return menu.path;
      }
    },
    getMenuId(title) {
      const menu = this.getMenu(title);
      if (menu) {
        return menu.id;
      }
    },
    // 获取配置项
    async getMenuConfig(title, key) {
      const menu = this.getMenu(title);
      if (menu) {
        const ids = await this.getMenuList({
          menuId: menu.id,
        });
        if (ids.length) {
          const res = await this.getAjaxInfo(ids[0].id);
          return res[key];
        }
      }
    },
    stringify,
    // 滚动监听
    handleScroll() {
      const { scrollY, innerHeight } = window;
      const elList = document.querySelectorAll(".kf-animate");
      const maxTop = scrollY + innerHeight * 0.9;

      for (const el of elList) {
        if (
          maxTop > el.offsetTop + el.clientHeight / 2 &&
          el.offsetTop + el.clientHeight / 2 > scrollY
        ) {
          el.classList.add("animate-on");
        }
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    next((vm) => {
      vm.init();
    });
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
