import { $ } from "@/common/Axios";

export default {
  /**
   * 北开学生
   */
  beijingOpen_batchSync: {
    /*
     * 批量同步考勤和成绩
     * batch 批次 (string query required)
     */
    post: (p, c) => $.post("/api/ems/siteplatform/beijingOpen/batchSync", p, c),
  },
  beijingOpen_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ems/siteplatform/beijingOpen/export", p, c),
  },
  beijingOpen_list: {
    /*
     * 北开学生列表
     */
    get: (p, c) => $.get("/api/ems/siteplatform/beijingOpen/list", p, c),
  },
  beijingOpen_listCourse: {
    /*
     * 课程列表
     */
    get: (p, c) => $.get("/api/ems/siteplatform/beijingOpen/listCourse", p, c),
  },
  beijingOpen_sync: {
    /*
     * 同步单个考勤和成绩
     * name 姓名 (string query required)
     * schoolAccount 学校账号 (string query required)
     * schoolPassword 学校密码 (string query required)
     * schoolRollId 学籍id (integer query required)
     */
    post: (p, c) => $.post("/api/ems/siteplatform/beijingOpen/sync", p, c),
  },

  /**
   * 北开学生考勤
   */
  beijingOpenAttendance_list: {
    /*
     * 考勤列表
     * beijingOpenId 北开学生id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/siteplatform/beijingOpenAttendance/list", p, c),
  },

  /**
   * 北开专业
   */
  beijingOpenMajor: {
    /*
     * 添加
     * account 账号 (string query )
     * batch 批次 (string query )
     * id  (integer query )
     * major 专业 (string query )
     * name 姓名 (string query )
     * password 密码 (string query )
     */
    post: (p, c) => $.post("/api/ems/siteplatform/beijingOpenMajor", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/ems/siteplatform/beijingOpenMajor", p, c),
  },
  beijingOpenMajor_createWorkUrl: {
    /*
     * 生成作业
     * batch 批次 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/siteplatform/beijingOpenMajor/createWorkUrl", p, c),
  },
  beijingOpenMajor_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ems/siteplatform/beijingOpenMajor/export", p, c),
  },
  beijingOpenMajor_getWorkUrl: {
    /*
     * 获取作业下载地址
     * batch 批次 (string query required)
     */
    get: (p, c) =>
      $.get("/api/ems/siteplatform/beijingOpenMajor/getWorkUrl", p, c),
  },
  beijingOpenMajor_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/siteplatform/beijingOpenMajor/list", p, c),
  },
  beijingOpenMajor_listBatch: {
    /*
     * 批次列表
     */
    get: (p, c) =>
      $.get("/api/ems/siteplatform/beijingOpenMajor/listBatch", p, c),
  },
  beijingOpenMajor_listMajor: {
    /*
     * 批次列表
     */
    get: (p, c) =>
      $.get("/api/ems/siteplatform/beijingOpenMajor/listMajor", p, c),
  },
  beijingOpenMajor_upload: {
    /*
     * 导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) =>
      $.post("/api/ems/siteplatform/beijingOpenMajor/upload", p, c),
  },

  /**
   * 北开学生成绩
   */
  beijingOpenScore_list: {
    /*
     * 考勤列表
     * beijingOpenId 北开学生id (integer query required)
     */
    get: (p, c) => $.get("/api/ems/siteplatform/beijingOpenScore/list", p, c),
  },

  /**
   * 工商课程
   */
  gongshangCourse_listCourse: {
    /*
     * 课程列表
     */
    get: (p, c) =>
      $.get("/api/ems/siteplatform/gongshangCourse/listCourse", p, c),
  },
  gongshangCourse_sync: {
    /*
     * 同步
     */
    post: (p, c) => $.post("/api/ems/siteplatform/gongshangCourse/sync", p, c),
  },

  /**
   * 工商成绩
   */
  gongshangScore: {
    /*
     * 添加
     * batch 批次 (string query )
     * id  (integer query )
     * level 层次 (string query )
     * major 专业 (string query )
     * size 学生数量 (integer query )
     * url 请求地址 (string query )
     */
    post: (p, c) => $.post("/api/ems/siteplatform/gongshangScore", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/ems/siteplatform/gongshangScore", p, c),
  },
  gongshangScore_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ems/siteplatform/gongshangScore/export", p, c),
  },
  gongshangScore_exportBadScore: {
    /*
     * 导出问题成绩
     * batch 批次 (string query required)
     */
    get: (p, c) =>
      $.get("/api/ems/siteplatform/gongshangScore/exportBadScore", p, c),
  },
  gongshangScore_getScoreInfo: {
    /*
     * 获取成绩信息
     * url 批次 (string query required)
     */
    get: (p, c) =>
      $.get("/api/ems/siteplatform/gongshangScore/getScoreInfo", p, c),
  },
  gongshangScore_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/siteplatform/gongshangScore/list", p, c),
  },
  gongshangScore_savePhoto: {
    /*
     * 保存照片
     * idCardNos 身份证 (array query required)
     */
    post: (p, c) =>
      $.post("/api/ems/siteplatform/gongshangScore/savePhoto", p, c),
  },
  gongshangScore_upload: {
    /*
     * 导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ems/siteplatform/gongshangScore/upload", p, c),
  },

  /**
   * 工商学生成绩
   */
  gongshangStudentScore_continueSync: {
    /*
     * 继续上次同步
     */
    post: (p, c) =>
      $.post("/api/ems/siteplatform/gongshangStudentScore/continueSync", p, c),
  },
  gongshangStudentScore_export: {
    /*
     * 导出
     */
    get: (p, c) =>
      $.get("/api/ems/siteplatform/gongshangStudentScore/export", p, c),
  },
  gongshangStudentScore_list: {
    /*
     * 列表
     */
    get: (p, c) =>
      $.get("/api/ems/siteplatform/gongshangStudentScore/list", p, c),
  },
  gongshangStudentScore_reSync: {
    /*
     * 重新同步
     */
    post: (p, c) =>
      $.post("/api/ems/siteplatform/gongshangStudentScore/reSync", p, c),
  },
  gongshangStudentScore_syncByStudentNo: {
    /*
     * 根据学号同步
     * schoolRollId 合同编号 (integer query required)
     * studentNo 学号 (string query required)
     */
    post: (p, c) =>
      $.post(
        "/api/ems/siteplatform/gongshangStudentScore/syncByStudentNo",
        p,
        c
      ),
  },

  /**
   * 学校平台数据处理
   */
  sitePlatformDataHandler_exportGongshangGradeInput: {
    /*
     * 成绩输入数据导出
     * classId 班级id (integer query required)
     * courseId 课程id (integer query required)
     */
    get: (p, c) =>
      $.get(
        "/api/ems/siteplatform/sitePlatformDataHandler/exportGongshangGradeInput",
        p,
        c
      ),
  },
  sitePlatformDataHandler_handler: {
    /*
     * 处理
     * site 学校 (string query required)
     * type 类型 (string query required)
     */
    post: (p, c) =>
      $.post("/api/ems/siteplatform/sitePlatformDataHandler/handler", p, c),
  },
  sitePlatformDataHandler_listGongshangClass: {
    /*
     * 工商班级列表
     */
    get: (p, c) =>
      $.get(
        "/api/ems/siteplatform/sitePlatformDataHandler/listGongshangClass",
        p,
        c
      ),
  },
  sitePlatformDataHandler_listGongshangCourse: {
    /*
     * 课程列表
     * classId 班级id (integer query required)
     * grade 年级 (integer query )
     * learnNum 学期 (integer query )
     */
    get: (p, c) =>
      $.get(
        "/api/ems/siteplatform/sitePlatformDataHandler/listGongshangCourse",
        p,
        c
      ),
  },
  sitePlatformDataHandler_resetGongshangPasswordBySignInInfo: {
    /*
     * 处理
     * account 账号 (string query required)
     * password 密码 (string query required)
     * schoolRollId 合同id (integer query required)
     */
    post: (p, c) =>
      $.post(
        "/api/ems/siteplatform/sitePlatformDataHandler/resetGongshangPasswordBySignInInfo",
        p,
        c
      ),
  },
  sitePlatformDataHandler_uploadGongshangGrade: {
    /*
     * 成绩导入
     * classId 班级id (integer query required)
     * courseId 课程id (integer query required)
     * excelFile excelFile (file formData required)
     */
    post: (p, c) =>
      $.post(
        "/api/ems/siteplatform/sitePlatformDataHandler/uploadGongshangGrade",
        p,
        c
      ),
  },
};
