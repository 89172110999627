import { $ } from "@/common/Axios";

export default {
  /**
   * 修改转介绍信息
   */
  changeReferral_apply: {
    /*
     * 申请
     * dingDepartmentId 钉钉部门id (integer query required)
     * referralUserId 推荐人id (integer query required)
     * remark 备注 (string query required)
     * schoolRollId 学籍id (integer query required)
     */
    post: (p, c) => $.post("/api/ems/education/changeReferral/apply", p, c),
  },
  changeReferral_listBySchoolRollId: {
    /*
     * 当前合同下的修改转介绍记录列表
     * schoolRollId 学籍id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/education/changeReferral/listBySchoolRollId", p, c),
  },
  changeReferral_sync: {
    /*
     * 同步
     * id id (integer query required)
     */
    put: (p, c) => $.put("/api/ems/education/changeReferral/sync", p, c),
  },

  /**
   * 教务通知
   */
  educationNotice_addReceiver: {
    /*
     * 添加收件人
     * id id (integer query required)
     * receiverIds 纯新增的收件人列表 (array query required)
     * removeIds 删除的收件人列表 (array query required)
     */
    post: (p, c) =>
      $.post("/api/ems/education/educationNotice/addReceiver", p, c),
  },
  educationNotice_countUnFinishedFromSource: {
    /*
     * 首页通知未完成数量
     * source 来源：通知管理、通知详情 (string query required)
     */
    get: (p, c) =>
      $.get(
        "/api/ems/education/educationNotice/countUnFinishedFromSource",
        p,
        c
      ),
  },
  educationNotice_delay: {
    /*
     * 延长截止时间
     * deadline 截止日期 (string query required)
     * id id (integer query required)
     */
    put: (p, c) => $.put("/api/ems/education/educationNotice/delay", p, c),
  },
  educationNotice_detail: {
    /*
     * 详情
     * fromService 来源：1通知详情 2通知管理 (integer query required)
     * id id (integer query required)
     */
    get: (p, c) => $.get("/api/ems/education/educationNotice/detail", p, c),
  },
  educationNotice_downloadMould: {
    /*
     * 下载模板
     */
    get: (p, c) =>
      $.get("/api/ems/education/educationNotice/downloadMould", p, c),
  },
  educationNotice_finish: {
    /*
     * 标记完成
     * id id (integer query required)
     */
    put: (p, c) => $.put("/api/ems/education/educationNotice/finish", p, c),
  },
  educationNotice_headerTeacherConfirm: {
    /*
     * 班主任确认通知
     * extraUrl 附件地址 (string query )
     * recordId 详情返回的recordId, 没有则不需要确认 (integer query required)
     * remark 备注 (string query )
     */
    put: (p, c) =>
      $.put("/api/ems/education/educationNotice/headerTeacherConfirm", p, c),
  },
  educationNotice_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/education/educationNotice/list", p, c),
  },
  educationNotice_listConfirm: {
    /*
     * 班主任确认记录
     * recordId 详情返回的recordId (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/education/educationNotice/listConfirm", p, c),
  },
  educationNotice_listForCreator: {
    /*
     * APP端-创建人登陆获取列表
     */
    get: (p, c) =>
      $.get("/api/ems/education/educationNotice/listForCreator", p, c),
  },
  educationNotice_listForCreatorReceiver: {
    /*
     * 创建通知时查询收件人列表
     * info Json串，可多个 (string query )
     */
    get: (p, c) =>
      $.get("/api/ems/education/educationNotice/listForCreatorReceiver", p, c),
  },
  educationNotice_listForServer: {
    /*
     * 教务管理-通知详情-列表
     */
    get: (p, c) =>
      $.get("/api/ems/education/educationNotice/listForServer", p, c),
  },
  educationNotice_listReceiver: {
    /*
     * 详情里的收件人列表
     * departmentName 部门 (string query )
     * noticeId 通知id (integer query required)
     * receiverName 姓名 (string query )
     * roleType 角色：收件人|抄送人 (string query )
     * status 状态：未查看|已查看|完成 (string query )
     */
    get: (p, c) =>
      $.get("/api/ems/education/educationNotice/listReceiver", p, c),
  },
  educationNotice_listSimpleCreator: {
    /*
     * 简单通知创建人列表
     */
    get: (p, c) =>
      $.get("/api/ems/education/educationNotice/listSimpleCreator", p, c),
  },
  educationNotice_listSimpleReceiver: {
    /*
     * 简单通知抄送人|接收人列表
     * noticeId 通知id， 查询发件人不传 (integer query )
     * roleType 收件人|抄送人 (string query )
     */
    get: (p, c) =>
      $.get("/api/ems/education/educationNotice/listSimpleReceiver", p, c),
  },
  educationNotice_publish: {
    /*
     * 发布通知
     * id id (integer query required)
     */
    put: (p, c) => $.put("/api/ems/education/educationNotice/publish", p, c),
  },
  educationNotice_remove: {
    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) =>
      $.delete("/api/ems/education/educationNotice/remove", p, c),
  },
  educationNotice_save: {
    /*
     * 添加
     * attachmentUrl 附件地址，可多个 (string query )
     * category 类别：政策公告(直接确认即)、事务通知(需完成) (string query required)
     * classify 分类：招办、学籍、考务、教学，其他可多选 (string query required)
     * content 内容 (string query required)
     * deadline 截止日期 (string query required)
     * remindTime 截止日期前未完成钉钉提醒时间 (string query required)
     * siteInfo 学校，多选 (string query )
     * title 标题 (string query required)
     */
    post: (p, c) => $.post("/api/ems/education/educationNotice/save", p, c),
  },
  educationNotice_sendDingRemind: {
    /*
     * 发送钉钉提醒
     * id 列表ID (integer query required)
     * source 来源：收件人列表、上级列表、主列表 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/educationNotice/sendDingRemind", p, c),
  },
  educationNotice_update: {
    /*
     * 编辑
     * attachmentUrl 附件地址，可多个 (string query )
     * category 类别：政策公告(直接确认即)、事务通知(需完成) (string query required)
     * classify 分类：招办、学籍、考务、教学，其他可多选 (string query required)
     * content 内容 (string query required)
     * deadline 截止日期 (string query required)
     * id id (integer query required)
     * remindTime 截止日期前未完成钉钉提醒时间 (string query required)
     * siteInfo 学校，多选 (string query )
     * title 标题 (string query required)
     */
    put: (p, c) => $.put("/api/ems/education/educationNotice/update", p, c),
  },
  educationNotice_upload: {
    /*
     * 导入筛选收件人
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ems/education/educationNotice/upload", p, c),
  },

  /**
   * 学生报名信息
   */
  peer_analysisLearnForm: {
    /*
     * 解析学习形式
     * adultExamYear 成考年度 (string query )
     * batch 批次 (string query )
     * level 专业层次 (string query )
     * major 专业 (string query )
     * rollGrade 学籍年级 (string query )
     * schoolStatuses 学籍状态 (array query )
     * site 学校 (string query )
     * siteType 学校类型 (string query )
     */
    get: (p, c) => $.get("/api/ems/education/peer/analysisLearnForm", p, c),
  },
  peer_details: {
    /*
     * 前端学籍合同详情（报名确认、招生统计、招办审核）
     * id id (integer query )
     */
    get: (p, c) => $.get("/api/ems/education/peer/details", p, c),
  },
  peer_downloadPeerStudentMould: {
    /*
     * 批量代报模版
     */
    get: (p, c) =>
      $.get("/api/ems/education/peer/downloadPeerStudentMould", p, c),
  },
  peer_enroll: {
    /*
     * 报名
     * batch 批次 (string query required)
     * enrollLevel 报名层次（包含专升本） (string query required)
     * idCardNo 身份证 (string query required)
     * majorId 专业id (integer query required)
     * name 真实姓名 (string query required)
     * phone 手机号 (string query required)
     */
    post: (p, c) => $.post("/api/ems/education/peer/enroll", p, c),
  },
  peer_listForEnrollConfirm: {
    /*
     * 报名确认列表
     */
    get: (p, c) => $.get("/api/ems/education/peer/listForEnrollConfirm", p, c),
  },
  peer_pass: {
    /*
     * 招生老师通过
     * id id (integer query required)
     */
    put: (p, c) => $.put("/api/ems/education/peer/pass", p, c),
  },
  peer_updateForFront: {
    /*
     * 招生老师编辑
     * addScoreItem 加分项 (string query )
     * address 身份证地址 (string query )
     * adultExamArea 成考考区 (string query )
     * adultExamCity 成考地级市 (string query )
     * birth 出生日期 (string query )
     * brushedCard 是否刷身份证 (string query )
     * campus 期末考区 (string query )
     * certificatePhoto 特殊证件 (string query )
     * contactPhone 联系电话 (string query )
     * diplomaNo 毕业证书编号 (string query )
     * diplomaPhoto 毕业证照片 (string query )
     * educationLevel 毕业层次 (string query )
     * educationNetCertificateInfo 学信网零散查询 (string query )
     * educationNetCertificateNo 备案表在线验证码 (string query )
     * emergencyContactName 紧急联系人 (string query )
     * emergencyContactPhone 紧急联系电话 (string query )
     * gender 性别 (string query )
     * graduationMajor 毕业专业 (string query )
     * graduationSchool 毕业院校 (string query )
     * graduationTime 毕业时间 (string query )
     * id 学籍信息id (integer query )
     * idCardBackPhoto 身份证反面 (string query )
     * idCardFrontPhoto 身份证正面 (string query )
     * idCardNo 身份证号 (string query )
     * localType 成考所在地类型 (string query )
     * name 姓名 (string query )
     * nation 民族 (string query )
     * natives 是否省内（继教院隐藏） (string query )
     * notNeedExamItem 免试项 (string query )
     * photo 个人照 (string query )
     */
    put: (p, c) => $.put("/api/ems/education/peer/updateForFront", p, c),
  },
  peer_uploadPeerStudent: {
    /*
     * 批量代报
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ems/education/peer/uploadPeerStudent", p, c),
  },

  /**
   * 照片采集
   */
  photoCollection: {
    /*
     * 添加编辑
     * areaNo 地区码|列表||| (string query )
     * auditRemark 审核备注|全部||| (string query )
     * auditStatus 审核状态|全部||字符串下拉|api/bms/develop/config/listSelect?key=毕业照片审核状态 (string query )
     * batch 拍摄批次|全部||字符串下拉|api/ems/education/photoCollection/listColumn?column=batch (string query )
     * chsiCollectCode 学信网采集码|列表|图片|| (string query )
     * chsiPhotoUrl 学信网截图|列表|图片|| (string query )
     * classRoom 教室|全部||| (string query )
     * eduRemark 教务备注|全部||| (string query )
     * examNo 考生号|全部||| (string query )
     * headerTeacher 班主任|列表||| (string query )
     * headerTeacherId 班主任id|筛选||ID下拉|api/bms/system/admin/listAdmin (integer query )
     * headerTeacherRemark 班主任备注|全部||| (string query )
     * id id|无||| (integer query )
     * idCardNo 身份证号|全部||| (string query )
     * level 层次|全部||字符串下拉|api/ems/education/photoCollection/listColumn?column=level (string query )
     * major 专业|列表||| (string query )
     * name 姓名|全部||| (string query )
     * orderNo 订单号|全部||| (string query )
     * payAccount 收款方式|列表||| (string query )
     * payAmount 收款金额|列表||| (number query )
     * payTime 收款时间|全部|时间|时间| (string query )
     * photoAddress 拍摄具体地址|全部||| (string query )
     * photoCampus 拍摄校区|全部||字符串下拉|api/ems/education/photoCollection/listColumn?column=photo_campus (string query )
     * photoChecked 身份核验|全部||字符串下拉|api/bms/develop/config/listSelect?key=是否 (string query )
     * photoDate 拍摄日期|全部|时间|时间| (string query )
     * photoGroup 拍摄分组|全部||| (string query )
     * photoTimePeriod 拍摄具体时间|全部||| (string query )
     * photoUpdateTime 最近更新时间|全部|时间|时间| (string query )
     * photoUrl 照片|列表|图片|| (string query )
     * rollGrade 学籍年级|全部||| (string query )
     * schoolRollId 合同编号|全部||| (string query )
     * schoolStatus 学籍状态|全部||字符串下拉|api/bms/develop/config/listSelect?key=学籍状态 (string query )
     * sequenceNo 拍摄序号|无||| (integer query )
     * sequenceNoText 拍摄序号|列表||| (string query )
     * sex 性别|全部||字符串下拉|api/bms/develop/config/listSelect?key=性别 (string query )
     * siteName 学校|全部||字符串下拉|api/ems/education/photoCollection/listColumn?column=site_name (string query )
     * siteNo 院校代码|列表||| (string query )
     * siteType 所在校别|全部||字符串下拉|api/ems/education/photoCollection/listColumn?column=site_type (string query )
     * sourceDetails 来源详情|全部||字符串下拉|api/ems/education/photoCollection/listColumn?column=source_details (string query )
     * studentNo 学号|全部||| (string query )
     * syncRemark 同步备注|全部||字符串下拉|api/ems/education/photoCollection/listColumn?column=sync_remark (string query )
     * syncStatus 合同同步状态|全部||字符串下拉|api/bms/develop/config/listSelect?key=同步状态 (string query )
     */
    post: (p, c) => $.post("/api/ems/education/photoCollection", p, c),
  },
  photoCollection_auditByEducation: {
    /*
     * 教务审核
     * auditStatus 审核状态 (string query required)
     * id 行id (integer query required)
     * remark 审核备注 (string query )
     */
    put: (p, c) =>
      $.put("/api/ems/education/photoCollection/auditByEducation", p, c),
  },
  photoCollection_auditByHeaderTeacher: {
    /*
     * 班主任审核
     * auditStatus 审核状态 (string query required)
     * id 行id (integer query required)
     * remark 审核备注 (string query )
     */
    put: (p, c) =>
      $.put("/api/ems/education/photoCollection/auditByHeaderTeacher", p, c),
  },
  photoCollection_batchRemove: {
    /*
     * 批量删除
     * ids 行id (array query required)
     */
    delete: (p, c) =>
      $.delete("/api/ems/education/photoCollection/batchRemove", p, c),
  },
  photoCollection_exportForEducation: {
    /*
     * 导出
     */
    get: (p, c) =>
      $.get("/api/ems/education/photoCollection/exportForEducation", p, c),
  },
  photoCollection_getAbleBatch: {
    /*
     * 获取启用批次
     */
    get: (p, c) =>
      $.get("/api/ems/education/photoCollection/getAbleBatch", p, c),
  },
  photoCollection_getId: {
    /*
     * 根据身份证和拍摄批次获取学生拍照信息
     * batch 拍照批次 (string query required)
     * idCardNo 身份证 (string query required)
     * photoCampus 拍摄小区 (string query required)
     */
    get: (p, c) => $.get("/api/ems/education/photoCollection/getId", p, c),
  },
  photoCollection_getNext: {
    /*
     * 下个学生拍摄信息
     * batch 拍摄批次 (string query required)
     * classRoom 拍摄教室 (string query required)
     * photoCampus 拍摄校区 (string query required)
     * sequenceNo 序号，不传则取序号1的 (integer query )
     */
    get: (p, c) => $.get("/api/ems/education/photoCollection/getNext", p, c),
  },
  photoCollection_getSimple: {
    /*
     * 根据身份证和拍摄批次获取学生拍照信息
     * batch 拍照批次 (string query required)
     * idCardNo 身份证 (string query required)
     * photoCampus 拍摄小区 (string query required)
     */
    get: (p, c) => $.get("/api/ems/education/photoCollection/getSimple", p, c),
  },
  photoCollection_listColumn: {
    /*
     * 字段列表
     * column 列名称 (string query required)
     */
    get: (p, c) => $.get("/api/ems/education/photoCollection/listColumn", p, c),
  },
  photoCollection_listForEducation: {
    /*
     * 教务列表
     */
    get: (p, c) =>
      $.get("/api/ems/education/photoCollection/listForEducation", p, c),
  },
  photoCollection_listForService: {
    /*
     * 服务列表
     */
    get: (p, c) =>
      $.get("/api/ems/education/photoCollection/listForService", p, c),
  },
  photoCollection_saveEduRemark: {
    /*
     * 添加教务备注
     * eduRemark 备注内容 (string query required)
     * id 照片采集id (integer query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/photoCollection/saveEduRemark", p, c),
  },
  photoCollection_saveHeaderTeacherRemark: {
    /*
     * 添加班主任备注
     * eduRemark 备注内容 (string query required)
     * id 照片采集id (integer query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/photoCollection/saveHeaderTeacherRemark", p, c),
  },
  photoCollection_searchForClient: {
    /*
     * C端查询我的图片采集信息
     */
    get: (p, c) =>
      $.get("/api/ems/education/photoCollection/searchForClient", p, c),
  },
  photoCollection_setAbleBatch: {
    /*
     * 设置启用批次
     * batches 启用批次 (array query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/photoCollection/setAbleBatch", p, c),
  },
  photoCollection_setCampusAndClassRoom: {
    /*
     * 设置拍摄校区和拍摄教室
     * classRoom 照片采集教室 (string query required)
     * id 照片采集id (integer query required)
     * photoCampus 拍摄校区 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/photoCollection/setCampusAndClassRoom", p, c),
  },
  photoCollection_syncSchoolRoll: {
    /*
     * 同步学籍
     */
    post: (p, c) =>
      $.post("/api/ems/education/photoCollection/syncSchoolRoll", p, c),
  },
  photoCollection_upload: {
    /*
     * 导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ems/education/photoCollection/upload", p, c),
  },
  photoCollection_uploadArrange: {
    /*
     * 导入采集安排
     * excelFile excelFile (file formData required)
     */
    post: (p, c) =>
      $.post("/api/ems/education/photoCollection/uploadArrange", p, c),
  },
  photoCollection_uploadChsiPhotoOrCode: {
    /*
     * 导入学信网截图或学信网采集码（前端批量导入，身份证命名）
     * batch 拍摄批次 (string query required)
     * idCardNo 身份证号码 (string query required)
     * type 类型： 1学信网截图 2学信网采集码 (integer query required)
     * url 照片地址 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/photoCollection/uploadChsiPhotoOrCode", p, c),
  },
  photoCollection_uploadPhoto: {
    /*
     * 上传照片
     * id 行id (integer query required)
     * photoUrl 照片地址 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/photoCollection/uploadPhoto", p, c),
  },
  photoCollection_uploadPhotoForClient: {
    /*
     * 上传照片
     * id 行id (integer query required)
     * photoUrl 照片地址 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/photoCollection/uploadPhotoForClient", p, c),
  },

  /**
   * 毕业管理
   */
  previewGraduate_auditGraduate: {
    /*
     * 毕业审核
     * wantGraduateDate 预计毕业时间（每月6月30号） (string query )
     */
    post: (p, c) =>
      $.post("/api/ems/education/previewGraduate/auditGraduate", p, c),
  },
  previewGraduate_checkGraduatePhoto: {
    /*
     * 刷新毕业照片
     * wantGraduateDate 预计毕业时间（每月6月30号） (string query )
     */
    post: (p, c) =>
      $.post("/api/ems/education/previewGraduate/checkGraduatePhoto", p, c),
  },
  previewGraduate_confirmStudyCertificateStatus: {
    /*
     * 批量确认学信网图像
     * ids 行ID列表 (array query required)
     */
    post: (p, c) =>
      $.post(
        "/api/ems/education/previewGraduate/confirmStudyCertificateStatus",
        p,
        c
      ),
  },
  previewGraduate_createGraduateInfo: {
    /*
     * 生成预毕业名单
     * wantGraduateDate 预计毕业时间（每月6月30号） (string query )
     */
    post: (p, c) =>
      $.post("/api/ems/education/previewGraduate/createGraduateInfo", p, c),
  },
  previewGraduate_downloadThesisScoreMould: {
    /*
     * 下载批量导入论文成绩模版
     */
    get: (p, c) =>
      $.get(
        "/api/ems/education/previewGraduate/downloadThesisScoreMould",
        p,
        c
      ),
  },
  previewGraduate_eduAudit: {
    /*
     * 教务审核毕业登记表信息
     * ids id列表 (array query required)
     * reason 通过则填写函授站意见， 拒绝填写理由 (string query )
     * type 类型 1通过 2拒绝 (integer query required)
     */
    put: (p, c) => $.put("/api/ems/education/previewGraduate/eduAudit", p, c),
  },
  previewGraduate_export: {
    /*
     * 毕业审核结论导出
     */
    get: (p, c) => $.get("/api/ems/education/previewGraduate/export", p, c),
  },
  previewGraduate_exportAppendInfo: {
    /*
     * 导出毕业登记表
     * ids 学籍ID列表 (array query required)
     */
    post: (p, c) =>
      $.post("/api/ems/education/previewGraduate/exportAppendInfo", p, c),
  },
  previewGraduate_exportAppendInfoForEnterSchool: {
    /*
     * 导出入学登记表
     * createTime 填表时间 (string query required)
     * ids 学籍ID列表 (array query required)
     */
    post: (p, c) =>
      $.post(
        "/api/ems/education/previewGraduate/exportAppendInfoForEnterSchool",
        p,
        c
      ),
  },
  previewGraduate_exportGraduateStudent: {
    /*
     * 导出毕业生名册
     * graduateStatus 毕业状态：全部（不传）|未知|预毕业|不毕业 (string query )
     * siteId 学校ID (integer query required)
     * wantGraduateDate 预计毕业时间（每年6月30号） (string query required)
     */
    get: (p, c) =>
      $.get("/api/ems/education/previewGraduate/exportGraduateStudent", p, c),
  },
  previewGraduate_getGraduateAppendInfo: {
    /*
     * 获取毕业登记表信息
     * schoolRollId schoolRollId (integer query )
     */
    get: (p, c) =>
      $.get("/api/ems/education/previewGraduate/getGraduateAppendInfo", p, c),
  },
  previewGraduate_getStudentBaseInfoChecking: {
    /*
     * 获取毕业生基本信息
     * schoolRollId 学籍id (integer query required)
     */
    get: (p, c) =>
      $.get(
        "/api/ems/education/previewGraduate/getStudentBaseInfoChecking",
        p,
        c
      ),
  },
  previewGraduate_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/education/previewGraduate/list", p, c),
  },
  previewGraduate_saveGraduateAppendInfoForClient: {
    /*
     * 添加毕业登记表
     * educationLevel 文化程度 (string query )
     * familyAddress 家庭住址 (string query )
     * familyPhone 家庭电话 (string query )
     * formerName 曾用名 (string query )
     * healthInfo 健康状况 (string query )
     * highestLevelTime 入学前最高学历(年月日) (string query )
     * joinPartyInfo 何时何地何人介绍参加共产党员或共青团 (string query )
     * joinWorkTime 参加工作时间 (string query )
     * mainSocialRelations 主要社会关系 (string query )
     * nativePlace 籍贯 (string query )
     * politicalStatus 政治面貌 (string query )
     * postcode 邮编 (string query )
     * rewards 奖惩情况 (string query )
     * selfAppraise 自我鉴定 (string query )
     * selfSignatureUrl 学生签名地址 (string query )
     * workDuty 现任职务、职称 (string query )
     * workPlace 工作单位 (string query )
     * workStatus 职业状况(在职或离职) (string query )
     */
    put: (p, c) =>
      $.put(
        "/api/ems/education/previewGraduate/saveGraduateAppendInfoForClient",
        p,
        c
      ),
  },
  previewGraduate_teacherAudit: {
    /*
     * 班主任审核毕业登记表
     * ids id列表 (array query required)
     * reason 通过则填写评语， 拒绝填写理由 (string query required)
     * signatureUrl 班主任签名地址 (string query )
     * type 类型 1通过 2拒绝 (integer query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/previewGraduate/teacherAudit", p, c),
  },
  previewGraduate_updateGraduateAppendInfo: {
    /*
     * 编辑毕业登记表
     * educationLevel 文化程度 (string query )
     * familyAddress 家庭住址 (string query )
     * familyPhone 家庭电话 (string query )
     * formerName 曾用名 (string query )
     * healthInfo 健康状况 (string query )
     * highestLevelTime 入学前最高学历(年月日) (string query )
     * id id (integer query required)
     * joinPartyInfo 何时何地何人介绍参加共产党员或共青团 (string query )
     * joinWorkTime 参加工作时间 (string query )
     * mainSocialRelations 主要社会关系 (string query )
     * nativePlace 籍贯 (string query )
     * politicalStatus 政治面貌 (string query )
     * postcode 邮编 (string query )
     * rewards 奖惩情况 (string query )
     * selfAppraise 自我鉴定 (string query )
     * selfSignatureUrl 学生签名地址 (string query )
     * workDuty 现任职务、职称 (string query )
     * workPlace 工作单位 (string query )
     * workStatus 职业状况(在职或离职) (string query )
     */
    put: (p, c) =>
      $.put(
        "/api/ems/education/previewGraduate/updateGraduateAppendInfo",
        p,
        c
      ),
  },
  previewGraduate_updateGraduateAppendInfoForClient: {
    /*
     * 编辑毕业登记表
     * educationLevel 文化程度 (string query )
     * familyAddress 家庭住址 (string query )
     * familyPhone 家庭电话 (string query )
     * formerName 曾用名 (string query )
     * healthInfo 健康状况 (string query )
     * highestLevelTime 入学前最高学历(年月日) (string query )
     * id id (integer query required)
     * joinPartyInfo 何时何地何人介绍参加共产党员或共青团 (string query )
     * joinWorkTime 参加工作时间 (string query )
     * mainSocialRelations 主要社会关系 (string query )
     * nativePlace 籍贯 (string query )
     * politicalStatus 政治面貌 (string query )
     * postcode 邮编 (string query )
     * rewards 奖惩情况 (string query )
     * selfAppraise 自我鉴定 (string query )
     * selfSignatureUrl 学生签名地址 (string query )
     * workDuty 现任职务、职称 (string query )
     * workPlace 工作单位 (string query )
     * workStatus 职业状况(在职或离职) (string query )
     */
    put: (p, c) =>
      $.put(
        "/api/ems/education/previewGraduate/updateGraduateAppendInfoForClient",
        p,
        c
      ),
  },
  previewGraduate_updateThesisScore: {
    /*
     * 批量修改论文成绩
     * ids 行ID列表 (array query required)
     * thesisScore 论文成绩（优秀良好不及格等） (string query )
     */
    post: (p, c) =>
      $.post("/api/ems/education/previewGraduate/updateThesisScore", p, c),
  },
  previewGraduate_uploadForExportDetailInfo: {
    /*
     * 按模板导出毕业登记表
     * excelFile excelFile (file formData required)
     */
    post: (p, c) =>
      $.post(
        "/api/ems/education/previewGraduate/uploadForExportDetailInfo",
        p,
        c
      ),
  },
  previewGraduate_uploadThesisScore: {
    /*
     * 批量导入论文成绩
     * excelFile excelFile (file formData required)
     */
    post: (p, c) =>
      $.post("/api/ems/education/previewGraduate/uploadThesisScore", p, c),
  },

  /**
   * 学生合同学籍表
   */
  schoolRoll_admission: {
    /*
     * 录取
     * contractId 所属站点id (integer query required)
     * id 合同id (integer query required)
     * studentNo 学号 (string query )
     */
    put: (p, c) => $.put("/api/ems/education/schoolRoll/admission", p, c),
  },
  schoolRoll_admissionOrGraduate: {
    /*
     * 录取毕业(人社证书)
     * id 合同id (integer query required)
     * schoolStatus 学籍状态（录取、毕业） (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/schoolRoll/admissionOrGraduate", p, c),
  },
  schoolRoll_assignAdminTeacher: {
    /*
     * 分配合同负责人
     * adminTeacherId 合同负责人 (integer query required)
     * id id (integer query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/schoolRoll/assignAdminTeacher", p, c),
  },
  schoolRoll_assignEnrollTeacher: {
    /*
     * 奖励业绩
     * enrollTeacherId 招生老师id (integer query required)
     * id id (integer query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/schoolRoll/assignEnrollTeacher", p, c),
  },
  schoolRoll_auditPaymentVoucher: {
    /*
     * 审核缴费截图（继教院）
     * auditStatus 审核状态：拒绝，通过 (string query required)
     * denyReason 拒绝理由 (string query )
     * id 合同id (integer query required)
     * version 版本号 (integer query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/schoolRoll/auditPaymentVoucher", p, c),
  },
  schoolRoll_batchEnrollEducationByContinue: {
    /*
     * 批量成教报名（继教院）
     * batch 批次 (string query required)
     * excelFile excelFile (file formData required)
     */
    post: (p, c) =>
      $.post(
        "/api/ems/education/schoolRoll/batchEnrollEducationByContinue",
        p,
        c
      ),
  },
  schoolRoll_batchUpdateEduRemark: {
    /*
     * 批量导入教务备注
     * excelFile excelFile (file formData required)
     */
    post: (p, c) =>
      $.post("/api/ems/education/schoolRoll/batchUpdateEduRemark", p, c),
  },
  schoolRoll_batchUpdateForSuspend: {
    /*
     * 批量编辑休学学生
     * excelFile excelFile (file formData required)
     */
    post: (p, c) =>
      $.post("/api/ems/education/schoolRoll/batchUpdateForSuspend", p, c),
  },
  schoolRoll_birthdayTodayForFront: {
    /*
     * 当天生日学生数量
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/birthdayTodayForFront", p, c),
  },
  schoolRoll_countForSuspendByEducation: {
    /*
     * 休学学生人数（教务）
     * type 类型:0即将逾期，1已逾期 (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/countForSuspendByEducation", p, c),
  },
  schoolRoll_countForSuspendByService: {
    /*
     * 休学学生人数（教务）
     * type 类型:0即将逾期，1已逾期 (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/countForSuspendByService", p, c),
  },
  schoolRoll_countOverdueForEnrollStatistics: {
    /*
     * 补齐资料逾期学生数量
     */
    get: (p, c) =>
      $.get(
        "/api/ems/education/schoolRoll/countOverdueForEnrollStatistics",
        p,
        c
      ),
  },
  schoolRoll_countUnFinishForEnrollStatistics: {
    /*
     * 未补齐资料学生数量
     */
    get: (p, c) =>
      $.get(
        "/api/ems/education/schoolRoll/countUnFinishForEnrollStatistics",
        p,
        c
      ),
  },
  schoolRoll_deny: {
    /*
     * 教务拒绝
     * id id (integer query required)
     * reason 拒绝理由 (string query required)
     */
    put: (p, c) => $.put("/api/ems/education/schoolRoll/deny", p, c),
  },
  schoolRoll_detailsForContinue: {
    /*
     * 继教院学籍合同详情
     * id id (integer query )
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/detailsForContinue", p, c),
  },
  schoolRoll_detailsForEducation: {
    /*
     * 教务学生详情
     * id 学籍id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/detailsForEducation", p, c),
  },
  schoolRoll_detailsForFront: {
    /*
     * 前端学籍合同详情（报名确认、招生统计、招办审核）
     * id id (integer query )
     */
    get: (p, c) => $.get("/api/ems/education/schoolRoll/detailsForFront", p, c),
  },
  schoolRoll_detailsForPaymentVoucher: {
    /*
     * 缴费截图详情（继教院）
     * id 合同id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/detailsForPaymentVoucher", p, c),
  },
  schoolRoll_detailsForSelfTaught: {
    /*
     * 自考学籍合同详情
     * id id (integer query )
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/detailsForSelfTaught", p, c),
  },
  schoolRoll_detailsForService: {
    /*
     * 学籍合同详情
     * id id (integer query )
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/detailsForService", p, c),
  },
  schoolRoll_divideEnrollTeacher: {
    /*
     * 划分业绩
     * enrollTeacherId 招生老师id (integer query required)
     * id id (integer query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/schoolRoll/divideEnrollTeacher", p, c),
  },
  schoolRoll_downloadAdmissionMould: {
    /*
     * 下载录取名单模版
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/downloadAdmissionMould", p, c),
  },
  schoolRoll_downloadEducationMould: {
    /*
     * 下载教务学生模版
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/downloadEducationMould", p, c),
  },
  schoolRoll_downloadMouldForCampusOrEnterprisePoint: {
    /*
     * 期末考区或校企学生期末考点导入模板
     */
    get: (p, c) =>
      $.get(
        "/api/ems/education/schoolRoll/downloadMouldForCampusOrEnterprisePoint",
        p,
        c
      ),
  },
  schoolRoll_downloadPassedStudentInfo: {
    /*
     * 已通过学生的资料下载
     * ids id (array query )
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/downloadPassedStudentInfo", p, c),
  },
  schoolRoll_downloadSchoolingMould: {
    /*
     * 下载批量导入在读名单模版
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/downloadSchoolingMould", p, c),
  },
  schoolRoll_enroll: {
    /*
     * 报名
     * batch 批次 (string query required)
     * consultWay 咨询方式 (string query )
     * enrollLevel 报名层次（包含专升本） (string query required)
     * extraFile 报名表，学校类型为继教院时显示, 选填 (string query )
     * idCardNo 身份证 (string query required)
     * majorId 专业id (integer query required)
     * name 真实姓名 (string query required)
     * originChannel 来源渠道 (string query )
     * originDetail 来源详情 (string query )
     * phone 手机号 (string query required)
     * referralDiscountType 推荐优惠类型(1新老200，2新400 (string query )
     * referralUserId 推荐人ID (integer query )
     * sourceFrom 来源详情：学校类型为继教院时显示：线上，线下 (string query )
     */
    post: (p, c) => $.post("/api/ems/education/schoolRoll/enroll", p, c),
  },
  schoolRoll_enrollEducationByArmy: {
    /*
     * 成教报名（中军）
     * area 成考考区 (string query )
     * batch 批次 (string query )
     * campus 期末考区 (string query )
     * city 成考地级市 (string query )
     * continueSchoolRollId 职教合同编号 (string query )
     * domicileArea 户籍地-市 (string query )
     * domicileCity 户籍地-县级市 (string query )
     * enrollLevel 层次 (string query )
     * enrollTeacherId 招生老师（中军报名用） (integer query )
     * major 专业 (string query )
     * majorId 专业id (integer query )
     * natives 是否省内 (string query )
     * phone 手机号（中军报名用） (string query )
     * site 学校 (string query )
     */
    post: (p, c) =>
      $.post("/api/ems/education/schoolRoll/enrollEducationByArmy", p, c),
  },
  schoolRoll_enrollEducationByContinue: {
    /*
     * 成教报名（继教院）
     * area 成考考区 (string query )
     * batch 批次 (string query )
     * campus 期末考区 (string query )
     * city 成考地级市 (string query )
     * continueSchoolRollId 职教合同编号 (string query )
     * domicileArea 户籍地-市 (string query )
     * domicileCity 户籍地-县级市 (string query )
     * enrollLevel 层次 (string query )
     * enrollTeacherId 招生老师（中军报名用） (integer query )
     * major 专业 (string query )
     * majorId 专业id (integer query )
     * natives 是否省内 (string query )
     * phone 手机号（中军报名用） (string query )
     * site 学校 (string query )
     */
    post: (p, c) =>
      $.post("/api/ems/education/schoolRoll/enrollEducationByContinue", p, c),
  },
  schoolRoll_enrollForClient: {
    /*
     * C端报名
     * batch 批次 (string query required)
     * consultWay 咨询方式 (string query )
     * enrollLevel 报名层次（包含本科二） (string query required)
     * enrollTeacherId 招生老师id (integer query required)
     * enterpriseQrCodeKey 企业招生二维码 (string query )
     * idCardNo 身份证 (string query required)
     * majorId 专业id (integer query required)
     * name 真实姓名 (string query required)
     * originChannel 来源渠道 (string query )
     * originDetail 来源详情 (string query )
     * phone 手机号 (string query required)
     */
    post: (p, c) =>
      $.post("/api/ems/education/schoolRoll/enrollForClient", p, c),
  },
  schoolRoll_exportCertificateForEducationConfirm: {
    /*
     * 资料审核列表导出
     */
    get: (p, c) =>
      $.get(
        "/api/ems/education/schoolRoll/exportCertificateForEducationConfirm",
        p,
        c
      ),
  },
  schoolRoll_exportContinueForEducationConfirm: {
    /*
     * 资料审核列表导出（继教院）
     */
    get: (p, c) =>
      $.get(
        "/api/ems/education/schoolRoll/exportContinueForEducationConfirm",
        p,
        c
      ),
  },
  schoolRoll_exportForDepartmentEnroll: {
    /*
     * 部门合同列表导出
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/exportForDepartmentEnroll", p, c),
  },
  schoolRoll_exportForEducation: {
    /*
     * 教务学生列表导出
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/exportForEducation", p, c),
  },
  schoolRoll_exportForEducationConfirm: {
    /*
     * 招办审核列表导出
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/exportForEducationConfirm", p, c),
  },
  schoolRoll_exportForEducationWarning: {
    /*
     * 学籍预警学生列表导出(教务)
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/exportForEducationWarning", p, c),
  },
  schoolRoll_exportForEnroll: {
    /*
     * 招办学生列表导出
     */
    get: (p, c) => $.get("/api/ems/education/schoolRoll/exportForEnroll", p, c),
  },
  schoolRoll_exportForEnrollStatistics: {
    /*
     * 招生统计列表导出
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/exportForEnrollStatistics", p, c),
  },
  schoolRoll_exportForNetClue: {
    /*
     * 网络合同列表导出
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/exportForNetClue", p, c),
  },
  schoolRoll_exportForServiceWarning: {
    /*
     * 学籍预警学生列表导出(服务)
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/exportForServiceWarning", p, c),
  },
  schoolRoll_exportForSuspend: {
    /*
     * 休学学生列表导出
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/exportForSuspend", p, c),
  },
  schoolRoll_exportSelfTaughtForEducationConfirm: {
    /*
     * 资料审核列表导出（自考）
     */
    get: (p, c) =>
      $.get(
        "/api/ems/education/schoolRoll/exportSelfTaughtForEducationConfirm",
        p,
        c
      ),
  },
  schoolRoll_getAdmissionLetterForClient: {
    /*
     * C端查询录取通知书-园丁
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/getAdmissionLetterForClient", p, c),
  },
  schoolRoll_getAgreementInfo: {
    /*
     * 获取正式报读须知内容
     * id id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/getAgreementInfo", p, c),
  },
  schoolRoll_getFrontAgreementInfo: {
    /*
     * 获取录取前报读须知内容
     * id id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/getFrontAgreementInfo", p, c),
  },
  schoolRoll_getOtherSchoolRollId: {
    /*
     * 查询其他合同数量列表
     * schoolRollId schoolRollId (integer query )
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/getOtherSchoolRollId", p, c),
  },
  schoolRoll_getSchoolRollIdByPhoneAndBatch: {
    /*
     * 根据手机号和批次获取学籍id
     * batch 批次 (string query required)
     * phone 手机号 (string query required)
     */
    get: (p, c) =>
      $.get(
        "/api/ems/education/schoolRoll/getSchoolRollIdByPhoneAndBatch",
        p,
        c
      ),
  },
  schoolRoll_listAdminRemark: {
    /*
     * 负责人备注列表
     * id 合同id (integer query required)
     */
    get: (p, c) => $.get("/api/ems/education/schoolRoll/listAdminRemark", p, c),
  },
  schoolRoll_listCertificateForEducationConfirm: {
    /*
     * 资料审核列表
     */
    get: (p, c) =>
      $.get(
        "/api/ems/education/schoolRoll/listCertificateForEducationConfirm",
        p,
        c
      ),
  },
  schoolRoll_listContinueForEducationConfirm: {
    /*
     * 资料审核列表（继教院）
     */
    get: (p, c) =>
      $.get(
        "/api/ems/education/schoolRoll/listContinueForEducationConfirm",
        p,
        c
      ),
  },
  schoolRoll_listEnrollInfo: {
    /*
     * 获取学生报名信息
     * idCardNo 身份证 (string query )
     * name 姓名 (string query )
     * phone 手机 (string query )
     */
    get: (p, c) => $.get("/api/ems/education/schoolRoll/listEnrollInfo", p, c),
  },
  schoolRoll_listForDepartmentEnroll: {
    /*
     * 部门合同列表
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/listForDepartmentEnroll", p, c),
  },
  schoolRoll_listForEducation: {
    /*
     * 教务学生列表
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/listForEducation", p, c),
  },
  schoolRoll_listForEducationConfirm: {
    /*
     * 招办审核列表
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/listForEducationConfirm", p, c),
  },
  schoolRoll_listForEducationWarning: {
    /*
     * 学籍预警学生列表(教务)
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/listForEducationWarning", p, c),
  },
  schoolRoll_listForEnroll: {
    /*
     * 招办学生列表
     */
    get: (p, c) => $.get("/api/ems/education/schoolRoll/listForEnroll", p, c),
  },
  schoolRoll_listForEnrollConfirm: {
    /*
     * 报名确认列表
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/listForEnrollConfirm", p, c),
  },
  schoolRoll_listForEnrollStatistics: {
    /*
     * 招生统计列表
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/listForEnrollStatistics", p, c),
  },
  schoolRoll_listForNetClue: {
    /*
     * 网络合同列表
     */
    get: (p, c) => $.get("/api/ems/education/schoolRoll/listForNetClue", p, c),
  },
  schoolRoll_listForServiceWarning: {
    /*
     * 学籍预警学生列表(服务)
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/listForServiceWarning", p, c),
  },
  schoolRoll_listForSuspendByEducation: {
    /*
     * 休学学生列表（教务）
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/listForSuspendByEducation", p, c),
  },
  schoolRoll_listForSuspendByService: {
    /*
     * 休学学生列表（服务）
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/listForSuspendByService", p, c),
  },
  schoolRoll_listOldStudent: {
    /*
     * 老生列表
     * name 名字 (string query required)
     */
    get: (p, c) => $.get("/api/ems/education/schoolRoll/listOldStudent", p, c),
  },
  schoolRoll_listSelfTaughtForEducationConfirm: {
    /*
     * 资料审核列表（自考）
     */
    get: (p, c) =>
      $.get(
        "/api/ems/education/schoolRoll/listSelfTaughtForEducationConfirm",
        p,
        c
      ),
  },
  schoolRoll_listSettleAccount: {
    /*
     * 结算方列表
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/listSettleAccount", p, c),
  },
  schoolRoll_listSimpleByUserId: {
    /*
     * 简易学籍列表
     * userId 用户id (integer query )
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/listSimpleByUserId", p, c),
  },
  schoolRoll_listSourceFrom: {
    /*
     * 来源详情列表
     */
    get: (p, c) => $.get("/api/ems/education/schoolRoll/listSourceFrom", p, c),
  },
  schoolRoll_passByEducation: {
    /*
     * 教务审核通过
     * headerTeacherId 班主任ID, 继教院审核时必填 (integer query )
     * id id (integer query required)
     */
    put: (p, c) => $.put("/api/ems/education/schoolRoll/passByEducation", p, c),
  },
  schoolRoll_passByEnrollTeacher: {
    /*
     * 招生老师通过
     * id id (integer query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/schoolRoll/passByEnrollTeacher", p, c),
  },
  schoolRoll_remarkByEnroll: {
    /*
     * 招生备注
     * id id (integer query required)
     * remark 备注 (string query required)
     */
    put: (p, c) => $.put("/api/ems/education/schoolRoll/remarkByEnroll", p, c),
  },
  schoolRoll_removeAgreement: {
    /*
     * 删除正式报读协议
     * id id (integer query required)
     */
    put: (p, c) => $.put("/api/ems/education/schoolRoll/removeAgreement", p, c),
  },
  schoolRoll_removeFrontAgreement: {
    /*
     * 删除报读须知
     * id id (integer query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/schoolRoll/removeFrontAgreement", p, c),
  },
  schoolRoll_saveAdminRemark: {
    /*
     * 添加备注（合同负责人）
     * content 内容 (string query required)
     * id 合同id (integer query required)
     */
    put: (p, c) => $.put("/api/ems/education/schoolRoll/saveAdminRemark", p, c),
  },
  schoolRoll_startSchoolRollByFirstFee: {
    /*
     * 合同开始（初次缴费成功）
     * id 学籍id (integer query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/schoolRoll/startSchoolRollByFirstFee", p, c),
  },
  schoolRoll_sumGmv: {
    /*
     * GMV-报名确认页面
     * end 结束时间 (string query )
     * start 开始时间 (string query )
     * timeType 时间类型 (string query )
     */
    get: (p, c) => $.get("/api/ems/education/schoolRoll/sumGmv", p, c),
  },
  schoolRoll_sumGmvForEnrollConfirm: {
    /*
     * 报名确认列表顶部gmv
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRoll/sumGmvForEnrollConfirm", p, c),
  },
  schoolRoll_testUpdateSchoolingStatus: {
    /*
     * 继教院学生更新在读状态-测试
     * schoolRollId 学籍id (integer query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/schoolRoll/testUpdateSchoolingStatus", p, c),
  },
  schoolRoll_transferAdminTeacher: {
    /*
     * 移交合同负责人
     * adminTeacherId 原合同负责人 (integer query required)
     * newAdminTeacherId 新合同负责人 (integer query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/schoolRoll/transferAdminTeacher", p, c),
  },
  schoolRoll_updateByEducation: {
    /*
     * 招办老师编辑
     * addScoreItem 加分项 (string query )
     * address 身份证地址 (string query )
     * adultExamArea 成考考区 (string query )
     * adultExamCity 成考地级市 (string query )
     * approvalFormPhoto 核准单照片 (string query )
     * campus 期末考区 (string query )
     * certificatePhoto 特殊证件 (string query )
     * companyAddress 单位地址 (string query )
     * companyName 单位名称 (string query )
     * contactPhone 联系电话 (string query )
     * degreeApply 学位申请 (string query )
     * diplomaNo 毕业证书编号 (string query )
     * diplomaPhoto 毕业证照片 (string query )
     * domicileArea 户籍所在地 (string query )
     * educationLevel 学历层次 (string query )
     * educationNetCertificateNo 备案表在线验证码 (string query )
     * educationNetCertificatePhoto 学历电子注册备案表（专升本必填） (string query )
     * emergencyContactName 紧急联系人 (string query )
     * emergencyContactPhone 紧急联系电话 (string query )
     * examRegister 考试院注册 (string query )
     * extraFile 报名表（国开选填，继教院必填，其他隐藏） (string query )
     * graduationMajor 毕业专业 (string query )
     * graduationSchool 毕业院校 (string query )
     * graduationTime 毕业时间 (string query )
     * id 学籍信息id (integer query )
     * idCardBackPhoto 身份证反面 (string query )
     * idCardFrontPhoto 身份证正面 (string query )
     * idCardNo 身份证号 (string query )
     * localType 成考所在地类型 (string query )
     * name 姓名 (string query )
     * nation 民族 (string query )
     * natives 是否省内（继教院隐藏） (string query )
     * notNeedExamItem 免试项 (string query )
     * originSource 学生来源 (string query )
     * otherCredentials 其他证件 (string query )
     * paymentVoucher 缴费凭证（继教院） (string query )
     * photo 个人照 (string query )
     * politicalStatus 政治面貌 (string query )
     * preDegreeStatus 前置学历状态 (string query )
     * preHeaderTeacher 前置学历班主任 (string query )
     * preHeaderTeacherPhone 前置学历班主任联系方式 (string query )
     * registerArea 注册地 (string query )
     * residencePermitFlag 已有居住证（是否） (string query )
     * residencePermitPreRemindTime 居住证提醒时间 (string query )
     * residencePermitUrl 居住证地址 (string query )
     * retiredAcceptAddress 退役安置接受地 (string query )
     * retirementCertificatePhoto 退役证照片 (string query )
     * shippingAddress 收件地址 (string query )
     * socialSecurityPhoto 社保缴纳证明照片 (string query )
     * sourceFrom 来源详情（招生老师角色包含同行招生老师或者招生老师部门包含电销） (string query )
     * userSecondPhoto 个人照2 (string query )
     */
    put: (p, c) =>
      $.put("/api/ems/education/schoolRoll/updateByEducation", p, c),
  },
  schoolRoll_updateByEnroll: {
    /*
     * 招生老师编辑
     * addScoreItem 加分项 (string query )
     * address 身份证地址 (string query )
     * adultExamArea 成考考区 (string query )
     * adultExamCity 成考地级市 (string query )
     * approvalFormPhoto 核准单照片 (string query )
     * campus 期末考区 (string query )
     * certificatePhoto 特殊证件 (string query )
     * companyAddress 单位地址 (string query )
     * companyName 单位名称 (string query )
     * contactPhone 联系电话 (string query )
     * degreeApply 学位申请 (string query )
     * diplomaNo 毕业证书编号 (string query )
     * diplomaPhoto 毕业证照片 (string query )
     * domicileArea 户籍所在地 (string query )
     * educationLevel 学历层次 (string query )
     * educationNetCertificateNo 备案表在线验证码 (string query )
     * educationNetCertificatePhoto 学历电子注册备案表（专升本必填） (string query )
     * emergencyContactName 紧急联系人 (string query )
     * emergencyContactPhone 紧急联系电话 (string query )
     * examRegister 考试院注册 (string query )
     * extraFile 报名表（国开选填，继教院必填，其他隐藏） (string query )
     * graduationMajor 毕业专业 (string query )
     * graduationSchool 毕业院校 (string query )
     * graduationTime 毕业时间 (string query )
     * id 学籍信息id (integer query )
     * idCardBackPhoto 身份证反面 (string query )
     * idCardFrontPhoto 身份证正面 (string query )
     * idCardNo 身份证号 (string query )
     * localType 成考所在地类型 (string query )
     * name 姓名 (string query )
     * nation 民族 (string query )
     * natives 是否省内（继教院隐藏） (string query )
     * notNeedExamItem 免试项 (string query )
     * otherCredentials 其他证件 (string query )
     * paymentVoucher 缴费凭证（继教院） (string query )
     * photo 个人照 (string query )
     * politicalStatus 政治面貌 (string query )
     * preDegreeStatus 前置学历状态 (string query )
     * preHeaderTeacher 前置学历班主任 (string query )
     * preHeaderTeacherPhone 前置学历班主任联系方式 (string query )
     * registerArea 注册地 (string query )
     * residencePermitFlag 已有居住证（是否） (string query )
     * residencePermitPreRemindTime 居住证提醒时间 (string query )
     * residencePermitUrl 居住证地址 (string query )
     * retiredAcceptAddress 退役安置接受地 (string query )
     * retirementCertificatePhoto 退役证照片 (string query )
     * shippingAddress 收件地址 (string query )
     * socialSecurityPhoto 社保缴纳证明照片 (string query )
     * sourceFrom 来源详情（招生老师角色包含同行招生老师或者招生老师部门包含电销） (string query )
     * userSecondPhoto 个人照2 (string query )
     */
    put: (p, c) => $.put("/api/ems/education/schoolRoll/updateByEnroll", p, c),
  },
  schoolRoll_updateByService: {
    /*
     * 服务老师编辑
     * awards 所获奖项 (string query )
     * awardsRemark 奖项备注 (string query )
     * billInfo 结算信息 (string query )
     * campus 考区 (string query )
     * companyAddress  (string query )
     * companyName  (string query )
     * contactPhone 联系电话 (string query )
     * degreeApply 是否申请学位 (string query )
     * driverCertificate 驾驶证培训证书 (string query )
     * driverEndTime 驾驶证培训结束时间 (string query )
     * driverStartTime 驾驶证培训开始时间 (string query )
     * driverStatus 驾驶证培训状态 (string query )
     * emergencyContactName 联系人 (string query )
     * emergencyContactPhone 联系人电话 (string query )
     * employmentStatus 就业状态 (string query )
     * employmentStatusRemark 就业状态备注 (string query )
     * endTime 培训结束时间 (string query )
     * enterpriseExamPoint 企业学生期末考点 (string query )
     * examMonth 考试月份 (integer query )
     * examRegister 考试院注册 (string query )
     * humanStatus 人社证书状态 (string query )
     * id 学籍信息id (integer query )
     * industry 就业行业 (string query )
     * industryRemark 行业备注 (string query )
     * onlineEnrollTime 线上报名时间 (string query )
     * photoCollection 毕业照片采集地 (string query )
     * qualifiedCertificate 合格证书 (string query )
     * registerArea 注册地 (string query )
     * repairCertificate 汽修培训证书 (string query )
     * repairEndTime 汽修培训结束时间 (string query )
     * repairStartTime 汽修培训开始时间 (string query )
     * repairStatus 汽修培训状态 (string query )
     * schoolAccount 学习平台账号 (string query )
     * schoolPassword 学习平台Miami (string query )
     * shippingAddress 收件地址 (string query )
     * socialSecurityPhoto 社保缴纳证明照片 (string query )
     * socialSecurityStatus 是否缴纳社保:是否 (string query )
     * startTime 培训开始时间 (string query )
     * trainingRemark 培训备注 (string query )
     * trainingStatus 培训状态 (string query )
     * tutorStatus 辅导情况 (string query )
     * unit 就业单位 (string query )
     * unitNature 单位性质 (string query )
     * unitNatureRemark 单位性质备注 (string query )
     * wenhuiOpen 文辉教育学习平台是否开通 (string query )
     * workingCertificatePhoto 工作证明 (string query )
     * workingCertificatePhotoType 工作证明等照片的具体类型说明 (string query )
     */
    put: (p, c) => $.put("/api/ems/education/schoolRoll/updateByService", p, c),
  },
  schoolRoll_updateEducation: {
    /*
     * 编辑教务学生
     * eduRemark 教务备注 (string query )
     * id 学籍id (integer query required)
     * realGraduateTime 毕业时间 (string query )
     * rollGrade 学籍年级 (string query )
     * studentExamNo 考生号 (string query )
     * studentNo 学号 (string query )
     */
    put: (p, c) => $.put("/api/ems/education/schoolRoll/updateEducation", p, c),
  },
  schoolRoll_updateEducationWarning: {
    /*
     * 更新学籍到期和预警时间
     * siteType 学校类型 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/schoolRoll/updateEducationWarning", p, c),
  },
  schoolRoll_updateForSuspend: {
    /*
     * 编辑单个休学学生
     * id 学籍id (integer query required)
     * suspendEnd 休学结束时间 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/schoolRoll/updateForSuspend", p, c),
  },
  schoolRoll_updateGmv: {
    /*
     * 更新gmv-人事平台用
     * end gmv结束时间 (string query required)
     * siteTypes 学校类型(多选) (array query )
     * start gmv开始时间 (string query required)
     */
    put: (p, c) => $.put("/api/ems/education/schoolRoll/updateGmv", p, c),
  },
  schoolRoll_uploadAdmission: {
    /*
     * 导入录取名单
     * excelFile excelFile (file formData required)
     */
    post: (p, c) =>
      $.post("/api/ems/education/schoolRoll/uploadAdmission", p, c),
  },
  schoolRoll_uploadAdmissionLetter: {
    /*
     * 导入录取通知书
     * batch 批次 (string query required)
     * idCardNo 身份证号 (string query required)
     * url 地址 (string query required)
     */
    post: (p, c) =>
      $.post("/api/ems/education/schoolRoll/uploadAdmissionLetter", p, c),
  },
  schoolRoll_uploadAgreement: {
    /*
     * 上传正式报读协议
     * agreementUrl 报读须知地址 (string query required)
     * id id (integer query required)
     */
    put: (p, c) => $.put("/api/ems/education/schoolRoll/uploadAgreement", p, c),
  },
  schoolRoll_uploadAgreementForClient: {
    /*
     * C端上传正式报读协议（学生签名）
     * id id (integer query required)
     * signData 签名图片，png文件，base64值 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/schoolRoll/uploadAgreementForClient", p, c),
  },
  schoolRoll_uploadCampusOrEnterprisePoint: {
    /*
     * 导入更新期末考区或校企学生期末考点
     * clearFlag 导入考区时是否清空企业学生期末考点 (string query )
     * excelFile excelFile (file formData required)
     * type 导入类型:期末考区|企业学生期末考点 (string query required)
     */
    post: (p, c) =>
      $.post(
        "/api/ems/education/schoolRoll/uploadCampusOrEnterprisePoint",
        p,
        c
      ),
  },
  schoolRoll_uploadEducation: {
    /*
     * 导入教务学生
     * excelFile excelFile (file formData required)
     */
    post: (p, c) =>
      $.post("/api/ems/education/schoolRoll/uploadEducation", p, c),
  },
  schoolRoll_uploadFrontAgreement: {
    /*
     * 上传录取前报读须知
     * agreementUrl 报读地址 (string query required)
     * id id (integer query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/schoolRoll/uploadFrontAgreement", p, c),
  },
  schoolRoll_uploadFrontAgreementForClient: {
    /*
     * C端上传录取前报读须知（学生签名）
     * id id (integer query required)
     * signData 签名图片，png文件，base64值 (string query required)
     */
    put: (p, c) =>
      $.put(
        "/api/ems/education/schoolRoll/uploadFrontAgreementForClient",
        p,
        c
      ),
  },
  schoolRoll_uploadPaymentVoucher: {
    /*
     * 上传缴费截图（继教院）
     * id 合同id (integer query required)
     * paymentVoucher 缴费截图 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/schoolRoll/uploadPaymentVoucher", p, c),
  },
  schoolRoll_uploadSchooling: {
    /*
     * 自考-批量导入在读名单
     * excelFile excelFile (file formData required)
     */
    post: (p, c) =>
      $.post("/api/ems/education/schoolRoll/uploadSchooling", p, c),
  },
  schoolRoll_uploadSyncEnrollInfo: {
    /*
     * 临时导入同步继教院合同部分信息到自考
     * excelFile excelFile (file formData required)
     */
    post: (p, c) =>
      $.post("/api/ems/education/schoolRoll/uploadSyncEnrollInfo", p, c),
  },
  schoolRoll_uploadSyncEnrollInfoForArmy: {
    /*
     * 临时同步军人合同信息
     * excelFile excelFile (file formData required)
     */
    post: (p, c) =>
      $.post("/api/ems/education/schoolRoll/uploadSyncEnrollInfoForArmy", p, c),
  },

  /**
   * 待异动库
   */
  schoolRollBatchTransaction_addSchoolRoll: {
    /*
     * 添加异动合同
     * id ID (integer query )
     * schoolRollId 合同编号 (integer query )
     */
    post: (p, c) =>
      $.post(
        "/api/ems/education/schoolRollBatchTransaction/addSchoolRoll",
        p,
        c
      ),
  },
  schoolRollBatchTransaction_downloadMould: {
    /*
     * 下载模板
     */
    get: (p, c) =>
      $.get(
        "/api/ems/education/schoolRollBatchTransaction/downloadMould",
        p,
        c
      ),
  },
  schoolRollBatchTransaction_export: {
    /*
     * 导出
     * adminTeacherId 合同负责人 (integer query )
     * batch 批次 (string query )
     * finishStatus 是否已异动， 是否 (string query )
     * id ID (integer query required)
     * name 学生姓名 (string query )
     * schoolRollId 合同编号 (integer query )
     * siteId 学校ID (integer query )
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRollBatchTransaction/export", p, c),
  },
  schoolRollBatchTransaction_list: {
    /*
     * 列表
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRollBatchTransaction/list", p, c),
  },
  schoolRollBatchTransaction_listForAdminTeacher: {
    /*
     * 招生管理-待异动库-列表
     */
    get: (p, c) =>
      $.get(
        "/api/ems/education/schoolRollBatchTransaction/listForAdminTeacher",
        p,
        c
      ),
  },
  schoolRollBatchTransaction_listRecord: {
    /*
     * 详情
     * adminTeacherId 合同负责人 (integer query )
     * batch 批次 (string query )
     * finishStatus 是否已异动， 是否 (string query )
     * id ID (integer query required)
     * name 学生姓名 (string query )
     * schoolRollId 合同编号 (integer query )
     * siteId 学校ID (integer query )
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRollBatchTransaction/listRecord", p, c),
  },
  schoolRollBatchTransaction_remove: {
    /*
     * 删除
     * id ID (integer query required)
     */
    delete: (p, c) =>
      $.delete("/api/ems/education/schoolRollBatchTransaction/remove", p, c),
  },
  schoolRollBatchTransaction_removeSchoolRoll: {
    /*
     * 删除异动合同
     * id ID (integer query required)
     */
    delete: (p, c) =>
      $.delete(
        "/api/ems/education/schoolRollBatchTransaction/removeSchoolRoll",
        p,
        c
      ),
  },
  schoolRollBatchTransaction_save: {
    /*
     * 添加编辑
     * deadline 截止时间 (string query required)
     * id ID (integer query )
     * name 名称 (string query required)
     */
    post: (p, c) =>
      $.post("/api/ems/education/schoolRollBatchTransaction/save", p, c),
  },
  schoolRollBatchTransaction_upload: {
    /*
     * 批量导入异动合同
     * excelFile excelFile (file formData required)
     * id 行ID (integer query )
     */
    post: (p, c) =>
      $.post("/api/ems/education/schoolRollBatchTransaction/upload", p, c),
  },

  /**
   * 前置学历待查
   */
  schoolRollChecking_eduAudit: {
    /*
     * 教务审核
     * id ID (integer query required)
     * refuseReason 拒绝理由 (string query )
     * type 类型 1通过 2拒绝 (integer query required)
     */
    post: (p, c) =>
      $.post("/api/ems/education/schoolRollChecking/eduAudit", p, c),
  },
  schoolRollChecking_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/education/schoolRollChecking/list", p, c),
  },
  schoolRollChecking_refresh: {
    /*
     * 刷新数据
     */
    post: (p, c) =>
      $.post("/api/ems/education/schoolRollChecking/refresh", p, c),
  },
  schoolRollChecking_saveFileUrl: {
    /*
     * 保存审核资料
     * extraFileUrl 资料压缩文件地址 (string query required)
     * id ID (integer query required)
     */
    post: (p, c) =>
      $.post("/api/ems/education/schoolRollChecking/saveFileUrl", p, c),
  },
  schoolRollChecking_saveReason: {
    /*
     * 保存待查原因
     * id ID (integer query required)
     * reason 待查原因(下拉 + 其他手填) (string query required)
     */
    post: (p, c) =>
      $.post("/api/ems/education/schoolRollChecking/saveReason", p, c),
  },
  schoolRollChecking_schoolAudit: {
    /*
     * 高校审核
     * id ID (integer query required)
     * refuseReason 拒绝理由 (string query )
     * type 类型 1通过 2拒绝 (integer query required)
     */
    post: (p, c) =>
      $.post("/api/ems/education/schoolRollChecking/schoolAudit", p, c),
  },

  /**
   * 学生信息勘误
   */
  schoolRollInfoModify_apply: {
    /*
     * 申请（服务-学生详情-在读状态）
     * newIdCardBackPhoto 新身份证背面照片 (string query )
     * newIdCardFrontPhoto 新身份证正面照片 (string query )
     * newIdCardNo 新身份证号 (string query )
     * newName 新学生姓名 (string query )
     * newNation 新民族 (string query )
     * newPoliticalStatus 新政治面貌 (string query )
     * remark 申请备注 (string query )
     * schoolRollId schoolRollId (integer query )
     */
    post: (p, c) =>
      $.post("/api/ems/education/schoolRollInfoModify/apply", p, c),
  },
  schoolRollInfoModify_audit: {
    /*
     * 审核
     * flowId 审批节点id (integer query required)
     * id id (integer query required)
     * remark 备注 (string query )
     * status 状态：通过，拒绝 (string query required)
     */
    put: (p, c) => $.put("/api/ems/education/schoolRollInfoModify/audit", p, c),
  },
  schoolRollInfoModify_details: {
    /*
     * 详情
     * id id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRollInfoModify/details", p, c),
  },
  schoolRollInfoModify_finish: {
    /*
     * 办结
     * id id (integer query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/schoolRollInfoModify/finish", p, c),
  },
  schoolRollInfoModify_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/education/schoolRollInfoModify/list", p, c),
  },
  schoolRollInfoModify_listSimple: {
    /*
     * 简易列表
     * schoolRollId 学籍id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRollInfoModify/listSimple", p, c),
  },
  schoolRollInfoModify_update: {
    /*
     * 编辑
     * id id (integer query required)
     * newIdCardBackPhoto 新身份证背面照片 (string query )
     * newIdCardFrontPhoto 新身份证正面照片 (string query )
     * newIdCardNo 新身份证号 (string query )
     * newName 新学生姓名 (string query )
     * newNation 新民族 (string query )
     * newPoliticalStatus 新政治面貌 (string query )
     * remark 申请备注 (string query )
     */
    put: (p, c) =>
      $.put("/api/ems/education/schoolRollInfoModify/update", p, c),
  },
  schoolRollInfoModify_withdraw: {
    /*
     * 撤回
     * id id (integer query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/schoolRollInfoModify/withdraw", p, c),
  },

  /**
   * 新生注册
   */
  schoolRollRegistry_confirmForClient: {
    /*
     * 学生确认或编辑（被打回）新生注册信息
     * chsiImage 学信网截图 (string query required)
     * confirmStatus 确认状态，已确认|反馈错误 (string query required)
     * id 新生注册信息返回的ID (integer query )
     * reportExtraUrl 反馈错误附件 (string query )
     * reportReason 反馈错误描述 (string query )
     */
    put: (p, c) =>
      $.put("/api/ems/education/schoolRollRegistry/confirmForClient", p, c),
  },
  schoolRollRegistry_confirmForManager: {
    /*
     * 班主任确认新生注册信息
     * chsiImage 学信网截图 (string query required)
     * confirmStatus 确认状态，已确认|反馈错误 (string query required)
     * id ID (integer query )
     * reportExtraUrl 反馈错误附件 (string query )
     * reportReason 反馈错误描述 (string query )
     * schoolRollId schoolRollId (integer query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/schoolRollRegistry/confirmForManager", p, c),
  },
  schoolRollRegistry_countUnFinishedForMainPage: {
    /*
     * 未进行学籍核对数量
     */
    get: (p, c) =>
      $.get(
        "/api/ems/education/schoolRollRegistry/countUnFinishedForMainPage",
        p,
        c
      ),
  },
  schoolRollRegistry_details: {
    /*
     * 详情
     * schoolRollId 合同编号 (integer query )
     */
    get: (p, c) => $.get("/api/ems/education/schoolRollRegistry/details", p, c),
  },
  schoolRollRegistry_eduDispose: {
    /*
     * 教务处理反馈错误
     * extraUrl 处理附件 (string query )
     * finishStatus 状态：未完结|已完结 (string query required)
     * id ID (integer query required)
     * resultContent 处理内容 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/schoolRollRegistry/eduDispose", p, c),
  },
  schoolRollRegistry_eduRefuse: {
    /*
     * 教务打回
     * id ID (integer query required)
     * refuseReason 打回理由 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/schoolRollRegistry/eduRefuse", p, c),
  },
  schoolRollRegistry_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ems/education/schoolRollRegistry/export", p, c),
  },
  schoolRollRegistry_infoForClient: {
    /*
     * 学生获取新生注册信息
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRollRegistry/infoForClient", p, c),
  },
  schoolRollRegistry_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/education/schoolRollRegistry/list", p, c),
  },
  schoolRollRegistry_listEduDispose: {
    /*
     * 教务处理记录
     * id ID (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRollRegistry/listEduDispose", p, c),
  },
  schoolRollRegistry_refuse: {
    /*
     * 班主任打回新生注册信息
     * id ID (integer query required)
     * refuseReason 打回理由 (string query required)
     */
    put: (p, c) => $.put("/api/ems/education/schoolRollRegistry/refuse", p, c),
  },

  /**
   * 合同（学籍）异动
   */
  schoolRollTransaction_applyForEducation: {
    /*
     * 教务异动申请
     * applyRemark 备注 (string query )
     * batch 新批次, 转批次才有 (string query )
     * dropoutsExtraFile 退学视频附件 (string query )
     * endTime 休学结束时间 (string query )
     * extraFile 附件 (string query )
     * majorId 专业id (integer query )
     * reason 异动理由 (string query )
     * rollGrade 学籍年级 (string query )
     * schoolRollId 学籍id (integer query required)
     * tradeType 异动类型：休学、退学、转专业、复学, 转批次(自考特有) (string query required)
     */
    post: (p, c) =>
      $.post(
        "/api/ems/education/schoolRollTransaction/applyForEducation",
        p,
        c
      ),
  },
  schoolRollTransaction_applyForEnroll: {
    /*
     * 招办异动申请
     * account 账号 (string query )
     * accountNick 账号昵称 (string query )
     * accountType 账号类型 (string query )
     * amount 不读退款金额 (number query )
     * applyRemark 备注 (string query )
     * batch 批次 (string query )
     * extraFile 批次 (string query )
     * majorId 专业id (integer query )
     * schoolRollId 学籍id (integer query required)
     * tradeType 异动类型：不读，转校转专业，转批次 (string query required)
     */
    post: (p, c) =>
      $.post("/api/ems/education/schoolRollTransaction/applyForEnroll", p, c),
  },
  schoolRollTransaction_auditByEducation: {
    /*
     * 教务审核
     * endTime 休学结束时间 (string query )
     * flowId 审批节点id (integer query required)
     * id id (integer query required)
     * remark 备注 (string query )
     * rollGrade 学籍年级 (string query )
     * status 状态：通过，拒绝 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/schoolRollTransaction/auditByEducation", p, c),
  },
  schoolRollTransaction_auditByLeader: {
    /*
     * 主管审核
     * flowId 审批节点id (integer query required)
     * id id (integer query required)
     * remark 备注 (string query )
     * status 状态：通过，拒绝 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/schoolRollTransaction/auditByLeader", p, c),
  },
  schoolRollTransaction_batchAuditByLeader: {
    /*
     * 批量主管审核
     * flowIds 审批节点id (array query required)
     * ids ids (array query required)
     * remark 备注 (string query )
     * status 状态：通过，拒绝 (string query required)
     */
    put: (p, c) =>
      $.put(
        "/api/ems/education/schoolRollTransaction/batchAuditByLeader",
        p,
        c
      ),
  },
  schoolRollTransaction_details: {
    /*
     * 详情
     * id id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRollTransaction/details", p, c),
  },
  schoolRollTransaction_downloadMould: {
    /*
     * 批量异动模版下载
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRollTransaction/downloadMould", p, c),
  },
  schoolRollTransaction_eduEdit: {
    /*
     * 编辑办结
     * id id (integer query required)
     * receivedPaper 是否收到纸质稿 (string query required)
     * schoolFinished 高校手续是否办结 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/schoolRollTransaction/eduEdit", p, c),
  },
  schoolRollTransaction_exportEducation: {
    /*
     * 教务异动列表导出-学历教育
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRollTransaction/exportEducation", p, c),
  },
  schoolRollTransaction_exportEducationForFullTime: {
    /*
     * 教务异动列表导出-继教院
     */
    get: (p, c) =>
      $.get(
        "/api/ems/education/schoolRollTransaction/exportEducationForFullTime",
        p,
        c
      ),
  },
  schoolRollTransaction_exportEducationForSelfTaught: {
    /*
     * 教务异动列表导出-自考
     */
    get: (p, c) =>
      $.get(
        "/api/ems/education/schoolRollTransaction/exportEducationForSelfTaught",
        p,
        c
      ),
  },
  schoolRollTransaction_exportEnroll: {
    /*
     * 招办异动列表导出-学历教育
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRollTransaction/exportEnroll", p, c),
  },
  schoolRollTransaction_exportEnrollForBasic: {
    /*
     * 招办异动列表导出-基础平台
     */
    get: (p, c) =>
      $.get(
        "/api/ems/education/schoolRollTransaction/exportEnrollForBasic",
        p,
        c
      ),
  },
  schoolRollTransaction_exportEnrollForFullTime: {
    /*
     * 招办异动列表-继教院
     */
    get: (p, c) =>
      $.get(
        "/api/ems/education/schoolRollTransaction/exportEnrollForFullTime",
        p,
        c
      ),
  },
  schoolRollTransaction_exportEnrollForSelfTaught: {
    /*
     * 招办异动列表-自考
     */
    get: (p, c) =>
      $.get(
        "/api/ems/education/schoolRollTransaction/exportEnrollForSelfTaught",
        p,
        c
      ),
  },
  schoolRollTransaction_listEducation: {
    /*
     * 教务异动列表-学历教育
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRollTransaction/listEducation", p, c),
  },
  schoolRollTransaction_listEducationForFullTime: {
    /*
     * 教务异动列表-继教院
     */
    get: (p, c) =>
      $.get(
        "/api/ems/education/schoolRollTransaction/listEducationForFullTime",
        p,
        c
      ),
  },
  schoolRollTransaction_listEducationForSelfTaught: {
    /*
     * 教务异动列表-自考
     */
    get: (p, c) =>
      $.get(
        "/api/ems/education/schoolRollTransaction/listEducationForSelfTaught",
        p,
        c
      ),
  },
  schoolRollTransaction_listEnroll: {
    /*
     * 招办异动列表-学历教育
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRollTransaction/listEnroll", p, c),
  },
  schoolRollTransaction_listEnrollForBasic: {
    /*
     * 招办异动列表-基础平台
     */
    get: (p, c) =>
      $.get(
        "/api/ems/education/schoolRollTransaction/listEnrollForBasic",
        p,
        c
      ),
  },
  schoolRollTransaction_listEnrollForFullTime: {
    /*
     * 招办异动列表-继教院
     */
    get: (p, c) =>
      $.get(
        "/api/ems/education/schoolRollTransaction/listEnrollForFullTime",
        p,
        c
      ),
  },
  schoolRollTransaction_listEnrollForSameIndustry: {
    /*
     * 招办异动列表-同行
     */
    get: (p, c) =>
      $.get(
        "/api/ems/education/schoolRollTransaction/listEnrollForSameIndustry",
        p,
        c
      ),
  },
  schoolRollTransaction_listEnrollForSelfTaught: {
    /*
     * 招办异动列表-自考
     */
    get: (p, c) =>
      $.get(
        "/api/ems/education/schoolRollTransaction/listEnrollForSelfTaught",
        p,
        c
      ),
  },
  schoolRollTransaction_listSimple: {
    /*
     * 简易异动列表
     * schoolRollId 学籍id (integer query required)
     * type 类型：招办异动，教务异动 (string query required)
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRollTransaction/listSimple", p, c),
  },
  schoolRollTransaction_sameIndustryApplyForEnroll: {
    /*
     * 招办异动申请-同行
     * applyRemark 备注 (string query )
     * batch 批次 (string query )
     * majorId 专业id (integer query )
     * schoolRollId 学籍id (integer query required)
     * tradeType 异动类型：不读，转校转专业，转批次 (string query required)
     */
    post: (p, c) =>
      $.post(
        "/api/ems/education/schoolRollTransaction/sameIndustryApplyForEnroll",
        p,
        c
      ),
  },
  schoolRollTransaction_updateForEducation: {
    /*
     * 教务异动编辑
     * applyRemark 备注 (string query )
     * batch 新批次, 转批次才有 (string query )
     * dropoutsExtraFile 退学视频附件 (string query )
     * endTime 休学结束时间 (string query )
     * extraFile 附件 (string query )
     * id id (integer query required)
     * majorId 专业id (integer query )
     * reason 异动理由 (string query )
     * rollGrade 学籍年级 (string query )
     */
    post: (p, c) =>
      $.post(
        "/api/ems/education/schoolRollTransaction/updateForEducation",
        p,
        c
      ),
  },
  schoolRollTransaction_updateForEnroll: {
    /*
     * 招办异动编辑
     * applyRemark 备注 (string query )
     * batch 批次 (string query )
     * extraFile 附件 (string query )
     * id id (integer query required)
     * majorId 专业id (integer query )
     */
    put: (p, c) =>
      $.put("/api/ems/education/schoolRollTransaction/updateForEnroll", p, c),
  },
  schoolRollTransaction_uploadEduFile: {
    /*
     * 教务上传退学时的文件
     * eduFile 退学时教务上传的文件 (string query required)
     * id id (integer query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/schoolRollTransaction/uploadEduFile", p, c),
  },
  schoolRollTransaction_withdraw: {
    /*
     * 撤回
     * id id (integer query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/schoolRollTransaction/withdraw", p, c),
  },

  /**
   * 增值服务
   */
  schoolRollVip_details: {
    /*
     * 详情
     * id id (integer query required)
     */
    get: (p, c) => $.get("/api/ems/education/schoolRollVip/details", p, c),
  },
  schoolRollVip_downloadMould: {
    /*
     * 下载批量更新状态模版
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRollVip/downloadMould", p, c),
  },
  schoolRollVip_downloadMouldVipStudent: {
    /*
     * 下载批量导入VIP服务学生模板
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRollVip/downloadMouldVipStudent", p, c),
  },
  schoolRollVip_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ems/education/schoolRollVip/export", p, c),
  },
  schoolRollVip_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/education/schoolRollVip/list", p, c),
  },
  schoolRollVip_listBySchoolRolId: {
    /*
     * 根据合同编号查询简单列表
     * schoolRollId 合同编号 (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/education/schoolRollVip/listBySchoolRolId", p, c),
  },
  schoolRollVip_listRemark: {
    /*
     * 备注记录列表
     * id 列表ID (integer query required)
     */
    get: (p, c) => $.get("/api/ems/education/schoolRollVip/listRemark", p, c),
  },
  schoolRollVip_save: {
    /*
     * 添加
     * actualAmount 实收金额 (number query required)
     * attach 附件 (string query required)
     * commitTime 提交时间 (string query required)
     * configIds 辅导配置ID (array query required)
     * contentDetail 具体内容 (string query )
     * endTime 结束时间 (string query )
     * payAmount 支付金额 (number query )
     * payTime 支付时间 (string query )
     * receiveTime 接收时间 (string query required)
     * remark 备注内容 (string query )
     * schoolRollId 合同编号 (integer query required)
     * status 状态 (string query required)
     */
    post: (p, c) => $.post("/api/ems/education/schoolRollVip/save", p, c),
  },
  schoolRollVip_saveRemark: {
    /*
     * 添加备注
     * id id (integer query required)
     * remark 具体内容 (string query )
     */
    put: (p, c) => $.put("/api/ems/education/schoolRollVip/saveRemark", p, c),
  },
  schoolRollVip_update: {
    /*
     * 编辑
     * actualAmount 实收金额 (number query required)
     * attach 附件） (string query required)
     * id id (integer query required)
     * payAmount 支付金额 (number query required)
     * payTime 支付时间 (string query required)
     * status 状态 (string query required)
     */
    put: (p, c) => $.put("/api/ems/education/schoolRollVip/update", p, c),
  },
  schoolRollVip_updateStatus: {
    /*
     * 批量更新状态
     * ids 列表ID (array query required)
     * status 状态 (string query required)
     */
    post: (p, c) =>
      $.post("/api/ems/education/schoolRollVip/updateStatus", p, c),
  },
  schoolRollVip_uploadUpdateStatus: {
    /*
     * 批量导入更新状态
     * excelFile excelFile (file formData required)
     */
    post: (p, c) =>
      $.post("/api/ems/education/schoolRollVip/uploadUpdateStatus", p, c),
  },
  schoolRollVip_uploadVipStudent: {
    /*
     * 批量导入VIP服务学生
     * excelFile excelFile (file formData required)
     */
    post: (p, c) =>
      $.post("/api/ems/education/schoolRollVip/uploadVipStudent", p, c),
  },

  /**
   * vip辅导配置
   */
  schoolRollVipConfig_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/education/schoolRollVipConfig/list", p, c),
  },
  schoolRollVipConfig_listConfigBySchoolRollId: {
    /*
     * 根据合同编号查询可选配置列表
     * schoolRollId 合同编号 (integer query required)
     */
    get: (p, c) =>
      $.get(
        "/api/ems/education/schoolRollVipConfig/listConfigBySchoolRollId",
        p,
        c
      ),
  },
  schoolRollVipConfig_save: {
    /*
     * 添加
     * ableStatus 启用状态 (string query required)
     * content 辅导内容 (string query required)
     * price 价格 (number query required)
     * siteIds 学校ID列表 (array query required)
     */
    post: (p, c) => $.post("/api/ems/education/schoolRollVipConfig/save", p, c),
  },
  schoolRollVipConfig_update: {
    /*
     * 编辑
     * ableStatus 启用状态 (string query required)
     * content 辅导内容 (string query required)
     * id id (integer query )
     * price 价格 (number query required)
     * siteId 学校ID (integer query required)
     */
    put: (p, c) => $.put("/api/ems/education/schoolRollVipConfig/update", p, c),
  },

  /**
   * 成教学生课程
   */
  studentCourse_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ems/education/studentCourse/export", p, c),
  },
  studentCourse_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/education/studentCourse/list", p, c),
  },

  /**
   * 学习记录
   */
  study_record_detail: {
    /*
     * 详情
     * courseId 课程ID (integer query required)
     * userId userId (integer query required)
     */
    get: (p, c) => $.get("/api/ems/education/study/record/detail", p, c),
  },
  study_record_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ems/education/study/record/export", p, c),
  },
  study_record_exportDetail: {
    /*
     * 详情导出
     * term 学期 (integer query )
     */
    get: (p, c) => $.get("/api/ems/education/study/record/exportDetail", p, c),
  },
  study_record_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/education/study/record/list", p, c),
  },
  study_record_updateStudyLengthTemp: {
    /*
     * 作弊方法
     * lowPer 最低完成比例 (number query required)
     * randomNum 随机增加范围 (integer query )
     * schoolRollId 合同编号 (integer query )
     * siteId 学校ID (integer query )
     * term 学期 (integer query )
     */
    put: (p, c) =>
      $.put("/api/ems/education/study/record/updateStudyLengthTemp", p, c),
  },

  /**
   * 探马接口
   */
  tanma_checkActivity: {
    /*
     * 测试探马-探活接口
     */
    get: (p, c) => $.get("/api/ems/education/tanma/checkActivity", p, c),
  },
  tanma_followDetail: {
    /*
     * 测试探马-取详细跟进记录内容
     * externalUserId externalUserId (string query required)
     */
    get: (p, c) => $.get("/api/ems/education/tanma/followDetail", p, c),
  },
  tanma_followRecord: {
    /*
     * 测试探马
     */
    get: (p, c) => $.get("/api/ems/education/tanma/followRecord", p, c),
  },
  tanma_getCustomerList: {
    /*
     * 测试探马-取员工客户列表
     * qwUserId externalUserId (string query required)
     */
    get: (p, c) => $.get("/api/ems/education/tanma/getCustomerList", p, c),
  },
  tanma_getFieldList: {
    /*
     * 测试探马-取客户字段列表
     */
    get: (p, c) => $.get("/api/ems/education/tanma/getFieldList", p, c),
  },
  tanma_importCustomer: {
    /*
     * 测试探马
     */
    get: (p, c) => $.get("/api/ems/education/tanma/importCustomer", p, c),
  },
  tanma_mappingField: {
    /*
     * 测试探马
     */
    get: (p, c) => $.get("/api/ems/education/tanma/mappingField", p, c),
  },
  tanma_mappingFieldAlias: {
    /*
     * 测试探马-映射字段别名
     * alias alias (string query required)
     * fieldId id (integer query required)
     */
    post: (p, c) => $.post("/api/ems/education/tanma/mappingFieldAlias", p, c),
  },
  tanma_syncChatRecord: {
    /*
     * 同步聊天记录
     * endTime endTime (string query )
     * startTime startTime (string query )
     */
    post: (p, c) => $.post("/api/ems/education/tanma/syncChatRecord", p, c),
  },
  tanma_syncContactRecordFromChat: {
    /*
     * 同步联系记录
     * endTime endTime (string query )
     * headerTeacherId headerTeacherId (integer query )
     * startTime startTime (string query )
     */
    post: (p, c) =>
      $.post("/api/ems/education/tanma/syncContactRecordFromChat", p, c),
  },
  tanma_syncCustomerFromTanma: {
    /*
     * 同步班主任企微学生
     * headerTeacherId headerTeacherId (integer query )
     */
    post: (p, c) =>
      $.post("/api/ems/education/tanma/syncCustomerFromTanma", p, c),
  },
  tanma_test: {
    /*
     * 测试json
     */
    post: (p, c) => $.post("/api/ems/education/tanma/test", p, c),
  },

  /**
   * 授课安排
   */
  teachPlan_assignAdultTeacher: {
    /*
     * 分配授课老师
     * adultTeacherId 成教老师id (integer query required)
     * ids 非列表ids (array query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/teachPlan/assignAdultTeacher", p, c),
  },
  teachPlan_batchRemove: {
    /*
     * 批量删除
     * ids id列表 (array query required)
     */
    delete: (p, c) =>
      $.delete("/api/ems/education/teachPlan/batchRemove", p, c),
  },
  teachPlan_detail_detail: {
    /*
     * 签到详情
     * id ID (integer query required)
     */
    get: (p, c) => $.get("/api/ems/education/teachPlan/detail/detail", p, c),
  },
  teachPlan_detail_downloadMould: {
    /*
     * 下载模板
     */
    get: (p, c) =>
      $.get("/api/ems/education/teachPlan/detail/downloadMould", p, c),
  },
  teachPlan_detail_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ems/education/teachPlan/detail/export", p, c),
  },
  teachPlan_detail_exportExcel: {
    /*
     * 导出签到表
     * season 春秋(下拉) (string query required)
     * teachPlanRecordIds 外层ID (array query required)
     * year 年度 (string query required)
     */
    get: (p, c) =>
      $.get("/api/ems/education/teachPlan/detail/exportExcel", p, c),
  },
  teachPlan_detail_getForClient: {
    /*
     * 学生端-我的列表
     */
    get: (p, c) =>
      $.get("/api/ems/education/teachPlan/detail/getForClient", p, c),
  },
  teachPlan_detail_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/education/teachPlan/detail/list", p, c),
  },
  teachPlan_detail_publish: {
    /*
     * 发布允许签到状态
     * ids IDs (array query required)
     * publishStatus 发布或未发布 (string query )
     */
    put: (p, c) => $.put("/api/ems/education/teachPlan/detail/publish", p, c),
  },
  teachPlan_detail_signatureForClient: {
    /*
     * 学生端-学生签到
     * id detail列表ID (integer query required)
     * signatureUrl 签到地址 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/teachPlan/detail/signatureForClient", p, c),
  },
  teachPlan_detail_update: {
    /*
     * 编辑
     * centreClassroomId 线下考点ID (integer query )
     * id ID (integer query required)
     * severalTimes 节数（数字） (integer query )
     * teachDate 上课日期 (string query )
     * timeFlag 上课时间（上午或下午） (string query )
     */
    put: (p, c) => $.put("/api/ems/education/teachPlan/detail/update", p, c),
  },
  teachPlan_detail_upload: {
    /*
     * 导入授课安排
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ems/education/teachPlan/detail/upload", p, c),
  },
  teachPlan_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/ems/education/teachPlan/export", p, c),
  },
  teachPlan_initTeachPlan: {
    /*
     * 生成授课计划
     * centreClassrooms 教室ID列表 (array query required)
     * rollGrade 学籍年级 (string query required)
     * siteId 学校ID (integer query required)
     * teachDates 上课日期列表 (string query )
     * term 学期 (integer query )
     */
    post: (p, c) => $.post("/api/ems/education/teachPlan/initTeachPlan", p, c),
  },
  teachPlan_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/education/teachPlan/list", p, c),
  },
  teachPlan_randomAssignAdultTeacher: {
    /*
     * 随机分配授课老师
     * rollGrade 学籍年级 (string query required)
     * siteId 学校ID (integer query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/teachPlan/randomAssignAdultTeacher", p, c),
  },
  teachPlan_update: {
    /*
     * 编辑
     * adultTeacherId 授课老师Id (integer query )
     * hours 课时（数字） (integer query )
     * id ID (integer query required)
     */
    put: (p, c) => $.put("/api/ems/education/teachPlan/update", p, c),
  },
  teachPlan_upload: {
    /*
     * 批量导入课时（模板为教学计划导出列表）
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ems/education/teachPlan/upload", p, c),
  },

  /**
   * 教学计划
   */
  teachingProgram: {
    /*
     * 添加
     * courseCategory 课程性质（公共课、专业课） (string query )
     * courseId 课程id (integer query )
     * credits 学分 (number query )
     * degreeCourseFlag 是否学位课（是否） (string query )
     * examType 考核方式（考试、考查） (string query )
     * id  (integer query )
     * majorId 专业id (integer query )
     * name 课程名字 (string query )
     * remark 备注 (string query )
     * rollGrade 学籍年级 (string query )
     * term 学期,0为考前辅导 (integer query )
     */
    post: (p, c) => $.post("/api/ems/education/teachingProgram", p, c),

    /*
     * 删除
     * id 教学计划id (integer query required)
     */
    delete: (p, c) => $.delete("/api/ems/education/teachingProgram", p, c),
  },
  teachingProgram_batchRemove: {
    /*
     * 批量删除教学计划
     * ids 教学计划id列表 (array query required)
     */
    delete: (p, c) =>
      $.delete("/api/ems/education/teachingProgram/batchRemove", p, c),
  },
  teachingProgram_changeCourse: {
    /*
     * 批量更换资源课程
     * courseId 资源课程id (integer query required)
     * ids 列表ids (array query required)
     */
    put: (p, c) =>
      $.put("/api/ems/education/teachingProgram/changeCourse", p, c),
  },
  teachingProgram_export: {
    /*
     * 教学计划导出
     */
    get: (p, c) => $.get("/api/ems/education/teachingProgram/export", p, c),
  },
  teachingProgram_list: {
    /*
     * 教学计划列表
     */
    get: (p, c) => $.get("/api/ems/education/teachingProgram/list", p, c),
  },
  teachingProgram_listCourseForCurrentTerm: {
    /*
     * 当前学期课程列表
     * currentTerm 当前学期 (integer query required)
     * majorId 专业id (integer query required)
     * rollGrade 学习年级 (string query required)
     */
    get: (p, c) =>
      $.get(
        "/api/ems/education/teachingProgram/listCourseForCurrentTerm",
        p,
        c
      ),
  },
  teachingProgram_listPlanByTerm: {
    /*
     * 每个学期的学习计划
     * majorId 专业id (integer query required)
     * rollGrade 学习年级 (string query required)
     * term 学期 (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/education/teachingProgram/listPlanByTerm", p, c),
  },
  teachingProgram_listSimple: {
    /*
     * 简易列表
     * majorId 专业id (integer query required)
     * rollGrade 学籍年级 (string query required)
     * term 当前学期 (integer query required)
     */
    get: (p, c) => $.get("/api/ems/education/teachingProgram/listSimple", p, c),
  },
  teachingProgram_listStudyRecordForCurrentTerm: {
    /*
     * 当前学期最近记录
     * currentTerm 当前学期 (integer query required)
     * majorId 专业id (integer query required)
     * rollGrade 学习年级 (string query required)
     */
    get: (p, c) =>
      $.get(
        "/api/ems/education/teachingProgram/listStudyRecordForCurrentTerm",
        p,
        c
      ),
  },
  teachingProgram_listTerm: {
    /*
     * 学期列表
     * currentTerm 当前学期 (integer query )
     * majorId 专业id (integer query required)
     * rollGrade 学籍年级 (string query required)
     */
    get: (p, c) => $.get("/api/ems/education/teachingProgram/listTerm", p, c),
  },
  teachingProgram_upload: {
    /*
     * 批量上传（如果名字改变视为新的教学计划）
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ems/education/teachingProgram/upload", p, c),
  },

  /**
   * 教材征订
   */
  textbookOrder_export: {
    /*
     * 教材征订单导出
     */
    get: (p, c) => $.get("/api/ems/education/textbookOrder/export", p, c),
  },
  textbookOrder_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/ems/education/textbookOrder/list", p, c),
  },
  textbookOrder_listByTextbookOrderDetail: {
    /*
     * 查看详情
     * id 行ID (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ems/education/textbookOrder/listByTextbookOrderDetail", p, c),
  },
  textbookOrder_listForBookSeller: {
    /*
     * 按照书商结算的教材征订结算单
     * bookSeller 书商 (string query )
     * rollGrade 学籍年级 (string query )
     * term 学期 (integer query )
     */
    get: (p, c) =>
      $.get("/api/ems/education/textbookOrder/listForBookSeller", p, c),
  },
  textbookOrder_listForEduPrint: {
    /*
     * 院校教材征订结算汇总单
     * majorId 专业id (integer query )
     * rollGrade 学籍年级 (string query )
     * term 学期 (integer query )
     */
    get: (p, c) =>
      $.get("/api/ems/education/textbookOrder/listForEduPrint", p, c),
  },
  textbookOrder_listForHeaderTeacherPrint: {
    /*
     * 班主任打印专业配书单
     * majorId 专业id (integer query )
     * rollGrade 学籍年级 (string query )
     * term 学期 (integer query )
     */
    get: (p, c) =>
      $.get("/api/ems/education/textbookOrder/listForHeaderTeacherPrint", p, c),
  },
  textbookOrder_save: {
    /*
     * 添加
     * rollGrade 学籍年级 (string query )
     * term 学期 (integer query )
     */
    put: (p, c) => $.put("/api/ems/education/textbookOrder/save", p, c),
  },
  textbookOrder_update: {
    /*
     * 编辑
     * bookName 教材名称 (string query )
     * bookNo 书号 (string query )
     * bookSeller 书商 (string query )
     * discount 折扣 (number query )
     * id 行ID (integer query required)
     * originPrice 原单价 (number query )
     * pressName 出版社 (string query )
     * teacherBookCount 老师教材数量 (integer query )
     */
    put: (p, c) => $.put("/api/ems/education/textbookOrder/update", p, c),
  },
  textbookOrder_upload: {
    /*
     * 导入征订单(教务)
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/ems/education/textbookOrder/upload", p, c),
  },
};
