import { $ } from "@/common/Axios";

export default {
  /**
   * 管理员
   */
  admin: {
    /*
     * 添加
     * ableStatus 启用状态 (string query )
     * account 账号 (string query )
     * avatar 头像 (string query )
     * broadcastBill 播报海报 (string query )
     * broadcastHash 播报hash (string query )
     * departmentId 部门id (integer query )
     * dingId 钉钉用户id (string query )
     * dingUnionId 钉钉unionId (string query )
     * employStatus 是否内部员工 (string query )
     * grade 员工等级 (string query )
     * id  (integer query )
     * name 员工名字 (string query )
     * parentId 上级领导id (integer query )
     * password 密码 (string query )
     * phone 手机号 (string query )
     * position 职位 (string query )
     * qwUserId 企微账号 (string query )
     * salesFirstPhone 企蜂云登录手机号 (string query )
     * workPhone 工作手机 (string query )
     * workWechat 工作微信（二维码地址） (string query )
     */
    post: (p, c) => $.post("/api/bms/system/admin", p, c),
  },
  admin_changeAdmin: {
    /*
     * 切换管理员
     * adminId 管理员id (integer query required)
     */
    put: (p, c) => $.put("/api/bms/system/admin/changeAdmin", p, c),
  },
  admin_changeDepartment: {
    /*
     * 切换管理部门
     * departmentId 部门id (integer query required)
     */
    put: (p, c) => $.put("/api/bms/system/admin/changeDepartment", p, c),
  },
  admin_changePlatform: {
    /*
     * 切换平台
     * platform 平台 (string query required)
     */
    put: (p, c) => $.put("/api/bms/system/admin/changePlatform", p, c),
  },
  admin_checkLeaveInfo: {
    /*
     * 查看离职人员情况
     * adminId 管理员id (integer query required)
     */
    get: (p, c) => $.get("/api/bms/system/admin/checkLeaveInfo", p, c),
  },
  admin_dealEducation: {
    /*
     * 学历合同成交
     * adminId 管理员id (integer query required)
     */
    post: (p, c) => $.post("/api/bms/system/admin/dealEducation", p, c),
  },
  admin_downloadQwUserMould: {
    /*
     * 下载模版
     */
    get: (p, c) => $.get("/api/bms/system/admin/downloadQwUserMould", p, c),
  },
  admin_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/bms/system/admin/export", p, c),
  },
  admin_getAdminInfo: {
    /*
     * 获取管理员编辑信息
     */
    get: (p, c) => $.get("/api/bms/system/admin/getAdminInfo", p, c),
  },
  admin_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/bms/system/admin/list", p, c),
  },
  admin_listAdmin: {
    /*
     * 管理员列表
     * ableStatus 禁用启用 (string query )
     * departmentId 部门 (integer query )
     * position 职位 (string query )
     */
    get: (p, c) => $.get("/api/bms/system/admin/listAdmin", p, c),
  },
  admin_listAdminByIds: {
    /*
     * 根据id获取管理员列表
     * ids 管理员id (array query required)
     */
    get: (p, c) => $.get("/api/bms/system/admin/listAdminByIds", p, c),
  },
  admin_listByFirstGradeDepartment: {
    /*
     * 根据一级部门获取管理列表
     * ableStatus 启用状态 (string query )
     * department 一级部门名称 (string query required)
     */
    get: (p, c) =>
      $.get("/api/bms/system/admin/listByFirstGradeDepartment", p, c),
  },
  admin_listChargeAdmin: {
    /*
     * 所负责管理员列表
     * ableStatus 禁用启用 (string query )
     */
    get: (p, c) => $.get("/api/bms/system/admin/listChargeAdmin", p, c),
  },
  admin_listPosition: {
    /*
     * 职位列表
     * employStatus 是否内部员工 (string query )
     */
    get: (p, c) => $.get("/api/bms/system/admin/listPosition", p, c),
  },
  admin_resetPassword: {
    /*
     * 重置密码
     * id id (integer query required)
     */
    put: (p, c) => $.put("/api/bms/system/admin/resetPassword", p, c),
  },
  admin_signIn: {
    /*
     * 登录
     * avatarCode PC点击头像免登码 (string query )
     * mPCode 小程序免登码 (string query )
     * password 密码 (string query )
     * pcCode PC免登码 (string query )
     * phone 手机号 (string query )
     */
    post: (p, c) => $.post("/api/bms/system/admin/signIn", p, c),
  },
  admin_signOut: {
    /*
     * 退出
     */
    delete: (p, c) => $.delete("/api/bms/system/admin/signOut", p, c),
  },
  admin_updatePassword: {
    /*
     * 修改密码
     * oldPassword 旧密码 (string query required)
     * password 新密码 (string query required)
     */
    put: (p, c) => $.put("/api/bms/system/admin/updatePassword", p, c),
  },
  admin_updateSelf: {
    /*
     * 管理员自己修改信息，目前可修改工作手机和工作微信
     * qwUserId 企微账号 (string query )
     * salesFirstPhone 话务外呼手机号1 (string query )
     * workPhone 工作手机 (string query )
     * workWechat 工作微信 (string query )
     */
    put: (p, c) => $.put("/api/bms/system/admin/updateSelf", p, c),
  },
  admin_upload: {
    /*
     * 批量导入修改企微账号
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/bms/system/admin/upload", p, c),
  },

  /**
   * 管理员模块查看情况
   */
  adminModuleCheck: {
    /*
     * 添加
     * adminId 管理员id (integer query )
     * id  (integer query )
     * module 模块名称 (string query )
     * platform 平台 (string query )
     * submodule 子模块名称 (string query )
     */
    post: (p, c) => $.post("/api/bms/system/adminModuleCheck", p, c),
  },

  /**
   * 第三方管理员信息
   */
  adminThirdParty_sync: {
    /*
     * 同步
     */
    put: (p, c) => $.put("/api/bms/system/adminThirdParty/sync", p, c),
  },

  /**
   * 管理员视图
   */
  adminView: {
    /*
     * 添加
     * adminId 管理员id (integer query )
     * ascText 升序参数 (string query )
     * columnText 列表参数 (string query )
     * descText 降序参数 (string query )
     * id  (integer query )
     * module 模块名称 (string query )
     * name 视图名字 (string query )
     * pageText 分页参数 (string query )
     * platform 平台 (string query )
     * selectText 筛选参数 (string query )
     * submodule 子模块名称 (string query )
     * type 类型,0非默认视图1默认视图 (integer query )
     */
    post: (p, c) => $.post("/api/bms/system/adminView", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/bms/system/adminView", p, c),
  },
  adminView_list: {
    /*
     * 列表
     * adminId 管理员id (integer query )
     * ascText 升序参数 (string query )
     * columnText 列表参数 (string query )
     * descText 降序参数 (string query )
     * id  (integer query )
     * module 模块名称 (string query )
     * name 视图名字 (string query )
     * pageText 分页参数 (string query )
     * platform 平台 (string query )
     * selectText 筛选参数 (string query )
     * submodule 子模块名称 (string query )
     * type 类型,0非默认视图1默认视图 (integer query )
     */
    get: (p, c) => $.get("/api/bms/system/adminView/list", p, c),
  },

  /**
   * 审批配置
   */
  approvalConfig: {
    /*
     * 添加
     * adminId 管理员id (integer query )
     * approvalType 审批类型：审核、抄送 (string query )
     * baseType 基于 (string query )
     * denyStep 拒绝后回到哪里：如果是0，则回到最开始申请的人 (integer query )
     * endLevel 结束部门等级 (string query )
     * flowType 流程类型：指定人、招办、学籍、考务、教学、高校对接人、部门流程、上级 (string query )
     * id  (integer query )
     * jumpIfSame 如果相同是否跳过 (string query )
     * mapKey 键 (string query )
     * name 审批流名称 (string query )
     * notifyContent 通知内容 (string query )
     * notifyDenyContent 通知拒绝内容 (string query )
     * notifyDenyMpUrl 通知拒绝跳转地址（小程序） (string query )
     * notifyDenyPcUrl 通知拒绝跳转地址（PC） (string query )
     * notifyDenyTitle 通知拒绝标题 (string query )
     * notifyMpUrl 通知跳转地址（小程序） (string query )
     * notifyPassContent 通知通过内容 (string query )
     * notifyPassMpUrl 通知拒绝跳转地址（小程序） (string query )
     * notifyPassPcUrl 通知通过跳转地址（PC） (string query )
     * notifyPassTitle 通知通过标题 (string query )
     * notifyPcUrl 通知跳转地址（PC） (string query )
     * notifyTitle 通知标题 (string query )
     * step 第几步 (integer query )
     */
    post: (p, c) => $.post("/api/bms/system/approvalConfig", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/bms/system/approvalConfig", p, c),
  },
  approvalConfig_copy: {
    /*
     * 复制
     * destName 目标审批名 (string query required)
     * sourceName 源审批名 (string query required)
     */
    post: (p, c) => $.post("/api/bms/system/approvalConfig/copy", p, c),
  },
  approvalConfig_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/bms/system/approvalConfig/list", p, c),
  },
  approvalConfig_listByName: {
    /*
     * 根据名字获取配置列表
     * name 审批名字 (string query required)
     */
    get: (p, c) => $.get("/api/bms/system/approvalConfig/listByName", p, c),
  },
  approvalConfig_listName: {
    /*
     * 审批名字列表
     */
    get: (p, c) => $.get("/api/bms/system/approvalConfig/listName", p, c),
  },
  approvalConfig_sort: {
    /*
     * 排序
     * configsText 排序好的列表 (string query required)
     */
    put: (p, c) => $.put("/api/bms/system/approvalConfig/sort", p, c),
  },

  /**
   * 审批流
   */
  approvalFlow_auditApprovalFlow: {
    /*
     * 审批流审核，审批后返回整个审批的状态
     * auditStatus 审核状态 (string query required)
     * comments 审批意见 (string query required)
     * flowId 审批流id (integer query required)
     * mapText 键值对 (string query )
     * requirementExtraInfo 需求管理审批需要的字段 (string query )
     */
    post: (p, c) =>
      $.post("/api/bms/system/approvalFlow/auditApprovalFlow", p, c),
  },
  approvalFlow_batchAuditApprovalFlow: {
    /*
     * 批量审核
     * auditStatus 审核状态 (string query required)
     * comments 审批意见 (string query required)
     * flowIds 审批流id (array query required)
     * mapText 键值对 (string query )
     * requirementExtraInfo 需求管理审批需要的字段 (string query )
     */
    post: (p, c) =>
      $.post("/api/bms/system/approvalFlow/batchAuditApprovalFlow", p, c),
  },
  approvalFlow_changeAdmin: {
    /*
     * 审批流批量移交
     * adminId 新管理员 (integer query required)
     * originAdminId 审批流原来管理员 (integer query required)
     */
    put: (p, c) => $.put("/api/bms/system/approvalFlow/changeAdmin", p, c),
  },
  approvalFlow_list: {
    /*
     * 列表
     * approvalId 审批id (integer query required)
     */
    get: (p, c) => $.get("/api/bms/system/approvalFlow/list", p, c),
  },
  approvalFlow_testAutoConfirm: {
    /*
     * 测试核心工作自动确认
     */
    post: (p, c) =>
      $.post("/api/bms/system/approvalFlow/testAutoConfirm", p, c),
  },
  approvalFlow_withdrawApproval: {
    /*
     * 撤回审批
     * approvalId 审批实例id (integer query required)
     */
    post: (p, c) =>
      $.post("/api/bms/system/approvalFlow/withdrawApproval", p, c),
  },

  /**
   * 考勤日期
   */
  attendanceDate_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/bms/system/attendanceDate/export", p, c),
  },
  attendanceDate_upload: {
    /*
     * 导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/bms/system/attendanceDate/upload", p, c),
  },

  /**
   * 考勤日期距离
   */
  attendanceDateDistance_sync: {
    /*
     * 导出
     */
    post: (p, c) => $.post("/api/bms/system/attendanceDateDistance/sync", p, c),
  },

  /**
   * 部门
   */
  department: {
    /*
     * 添加
     * ableStatus 启用禁用 (string query )
     * adminId 部门负责人id (integer query )
     * biDepartmentId bi部门id (integer query )
     * businessDepartmentId 事业部id (integer query )
     * dingId 钉钉id (integer query )
     * firstGradeId 一级部门id (integer query )
     * grade 部门等级：公司级、一级、二级 (string query )
     * id  (integer query )
     * name 部门名称 (string query )
     * parentId 上级部门id (integer query )
     * point 招生点 (string query )
     */
    post: (p, c) => $.post("/api/bms/system/department", p, c),
  },
  department_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/bms/system/department/list", p, c),
  },
  department_listAdmins: {
    /*
     * 部门负责人列表
     * departmentId 部门id (integer query required)
     */
    get: (p, c) => $.get("/api/bms/system/department/listAdmins", p, c),
  },
  department_listBusiness: {
    /*
     * 事业部级部门列表
     */
    get: (p, c) => $.get("/api/bms/system/department/listBusiness", p, c),
  },
  department_listChargeDepartment: {
    /*
     * 所负责部门列表
     */
    get: (p, c) =>
      $.get("/api/bms/system/department/listChargeDepartment", p, c),
  },
  department_listCurrentChargeSubDepartment: {
    /*
     * 当前负责部门列表
     */
    get: (p, c) =>
      $.get("/api/bms/system/department/listCurrentChargeSubDepartment", p, c),
  },
  department_listDepartment: {
    /*
     * 部门列表
     * ableStatus 禁用启用 (string query )
     */
    get: (p, c) => $.get("/api/bms/system/department/listDepartment", p, c),
  },
  department_listFirstGrade: {
    /*
     * 一级部门列表
     */
    get: (p, c) => $.get("/api/bms/system/department/listFirstGrade", p, c),
  },
  department_listPoint: {
    /*
     * 招生点列表
     */
    get: (p, c) => $.get("/api/bms/system/department/listPoint", p, c),
  },
  department_listRDepartments: {
    /*
     * 递归获取下级部门
     * id 部门id (integer query required)
     */
    get: (p, c) => $.get("/api/bms/system/department/listRDepartments", p, c),
  },
  department_listRIds: {
    /*
     * 递归获取下级部门id
     * id 部门id (integer query required)
     */
    get: (p, c) => $.get("/api/bms/system/department/listRIds", p, c),
  },
  department_updateAdmin: {
    /*
     * 编辑负责人
     * adminIds 管理员id (array query required)
     * departmentId 部门id (integer query required)
     */
    put: (p, c) => $.put("/api/bms/system/department/updateAdmin", p, c),
  },

  /**
   * 部门临时负责人
   */
  departmentTemporaryAdmin: {
    /*
     * 添加
     * adminId 管理员id (integer query )
     * departmentId 部门 (integer query )
     * id  (integer query )
     */
    post: (p, c) => $.post("/api/bms/system/departmentTemporaryAdmin", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) =>
      $.delete("/api/bms/system/departmentTemporaryAdmin", p, c),
  },
  departmentTemporaryAdmin_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/bms/system/departmentTemporaryAdmin/list", p, c),
  },

  /**
   * 钉钉部门
   */
  dingDepartment_listIds: {
    /*
     * 根据管理员id获取列表
     * adminId 管理员id (integer query required)
     */
    get: (p, c) => $.get("/api/bms/system/dingDepartment/listIds", p, c),
  },
  dingDepartment_listSimple: {
    /*
     * 根据登录管理员id获取列表
     */
    get: (p, c) => $.get("/api/bms/system/dingDepartment/listSimple", p, c),
  },
  dingDepartment_sync: {
    /*
     * 同步
     */
    post: (p, c) => $.post("/api/bms/system/dingDepartment/sync", p, c),
  },

  /**
   * 模块收藏
   */
  moduleCollection: {
    /*
     * 添加
     * adminId 管理员id (integer query )
     * id  (integer query )
     * module 模块 (string query )
     * platform 平台 (string query )
     * sort 排序 (integer query )
     * submodule 子模块 (string query )
     */
    post: (p, c) => $.post("/api/bms/system/moduleCollection", p, c),

    /*
     * 删除
     * module 主模块 (string query required)
     * platform 平台 (string query required)
     * submodule 子模块 (string query required)
     */
    delete: (p, c) => $.delete("/api/bms/system/moduleCollection", p, c),
  },
  moduleCollection_getDefaultModule: {
    /*
     * 获取默认模块收藏情况
     * platform 平台 (string query required)
     */
    get: (p, c) =>
      $.get("/api/bms/system/moduleCollection/getDefaultModule", p, c),
  },
  moduleCollection_listByPlatform: {
    /*
     * 根据平台获取收藏列表
     * platform 平台 (string query required)
     */
    get: (p, c) =>
      $.get("/api/bms/system/moduleCollection/listByPlatform", p, c),
  },
  moduleCollection_listSelectByPlatform: {
    /*
     * 根据平台获取收藏情况
     * platform 平台 (string query required)
     */
    get: (p, c) =>
      $.get("/api/bms/system/moduleCollection/listSelectByPlatform", p, c),
  },
  moduleCollection_putDefaultModule: {
    /*
     * 设置默认模块收藏情况
     * modules 模块 (array query required)
     * platform 平台 (string query required)
     * submodules 子模块 (array query required)
     */
    post: (p, c) =>
      $.post("/api/bms/system/moduleCollection/putDefaultModule", p, c),
  },

  /**
   * 帮助模块
   */
  moduleHelper_detail: {
    /*
     * 内容详情
     * id id (integer query required)
     */
    get: (p, c) => $.get("/api/bms/system/moduleHelper/detail", p, c),
  },
  moduleHelper_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/bms/system/moduleHelper/list", p, c),
  },
  moduleHelper_listDetail: {
    /*
     * 详情列表
     * module 模块 (string query required)
     * platform 平台 (string query required)
     * submodule 子模块 (string query required)
     */
    get: (p, c) => $.get("/api/bms/system/moduleHelper/listDetail", p, c),
  },
  moduleHelper_listMyHelper: {
    /*
     * 我的模块帮助文件列表
     * module 模块 (string query required)
     * platform 平台 (string query required)
     * submodule 子模块 (string query required)
     * versionNumber 版本号 (integer query )
     */
    get: (p, c) => $.get("/api/bms/system/moduleHelper/listMyHelper", p, c),
  },
  moduleHelper_listSimpleHelperAdmin: {
    /*
     * 已读列表
     * id id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/bms/system/moduleHelper/listSimpleHelperAdmin", p, c),
  },
  moduleHelper_myDetail: {
    /*
     * 我的详情
     * id 我的模块帮助文件列表id (integer query required)
     */
    get: (p, c) => $.get("/api/bms/system/moduleHelper/myDetail", p, c),
  },
  moduleHelper_publish: {
    /*
     * 发布
     * id id,编辑传入 (integer query )
     * publishStatus 发布状态：已发布、未发布 (string query )
     */
    put: (p, c) => $.put("/api/bms/system/moduleHelper/publish", p, c),
  },
  moduleHelper_remove: {
    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/bms/system/moduleHelper/remove", p, c),
  },
  moduleHelper_save: {
    /*
     * 添加编辑
     * content 内容 (string query )
     * id id,编辑传入 (integer query )
     * module 模块 (string query required)
     * platform 平台 (string query required)
     * submodule 子模块 (string query required)
     * type 类型：富文本、视频、文档 (string query required)
     */
    put: (p, c) => $.put("/api/bms/system/moduleHelper/save", p, c),
  },

  /**
   * 权限
   */
  permission: {
    /*
     * 添加
     * id  (integer query )
     * module 模块 (string query )
     * moduleSort 模块排序 (integer query )
     * operate 操作 (string query )
     * platform 平台 (string query )
     * requestMethod 请求方法 (string query )
     * requestPath 请求路径(不包含域名) (string query )
     * saveLog 保存日志：0不保存，保存C端日志，保存B端日志 (integer query )
     * signInType 登录类型：1C端登录2B端登录3B端登录且需要校验权限 (integer query )
     * submodule 子模块 (string query )
     * submoduleSort 子模块排序 (integer query )
     */
    post: (p, c) => $.post("/api/bms/system/permission", p, c),
  },
  permission_listModule: {
    /*
     * 简单模块列表
     * platform 平台 (string query )
     */
    get: (p, c) => $.get("/api/bms/system/permission/listModule", p, c),
  },
  permission_listNewModule: {
    /*
     * 简单模块列表
     * platform 平台 (string query required)
     */
    get: (p, c) => $.get("/api/bms/system/permission/listNewModule", p, c),
  },
  permission_listPlatform: {
    /*
     * 简单平台列表
     */
    get: (p, c) => $.get("/api/bms/system/permission/listPlatform", p, c),
  },
  permission_listSimple: {
    /*
     * 简单模块列表
     * module 模块 (string query required)
     * platform 平台 (string query required)
     * submodule 子模块 (string query required)
     */
    get: (p, c) => $.get("/api/bms/system/permission/listSimple", p, c),
  },
  permission_listSubmodule: {
    /*
     * 简单模块列表
     * module 模块 (string query )
     * platform 平台 (string query )
     */
    get: (p, c) => $.get("/api/bms/system/permission/listSubmodule", p, c),
  },
  permission_refreshRedis: {
    /*
     * 刷新缓存
     */
    get: (p, c) => $.get("/api/bms/system/permission/refreshRedis", p, c),
  },

  /**
   * 职位权限
   */
  positionPermission_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/bms/system/positionPermission/list", p, c),
  },
  positionPermission_listModuleByParams: {
    /*
     * 根据角色查询平台或模块或子模块
     * module 模块 (string query )
     * platform 模块 (string query )
     * positions 职位, 多选 (array query required)
     * searchType 查询内容:平台|模块|子模块 (string query required)
     * submodule 子模块 (string query )
     */
    get: (p, c) =>
      $.get("/api/bms/system/positionPermission/listModuleByParams", p, c),
  },
  positionPermission_listOut: {
    /*
     * 外部职位列表
     */
    get: (p, c) => $.get("/api/bms/system/positionPermission/listOut", p, c),
  },
  positionPermission_manage: {
    /*
     * 权限
     * employStatus 职位 (string query required)
     * platform 平台 (string query required)
     * position 职位 (string query required)
     */
    get: (p, c) => $.get("/api/bms/system/positionPermission/manage", p, c),
  },
  positionPermission_operates: {
    /*
     * 当前角色在当前平台下，当前模块的操作列表
     * module 主模块 (string query required)
     * platform 平台 (string query required)
     * submodule 子模块 (string query required)
     */
    get: (p, c) => $.get("/api/bms/system/positionPermission/operates", p, c),
  },
  positionPermission_permissions: {
    /*
     * 当前角色在某平台下的权限列表（菜单）
     * platform 平台 (string query required)
     */
    get: (p, c) =>
      $.get("/api/bms/system/positionPermission/permissions", p, c),
  },
  positionPermission_permissionsBySumModule: {
    /*
     * 当前角色模糊查询
     * submodule 子模块 (string query required)
     */
    get: (p, c) =>
      $.get("/api/bms/system/positionPermission/permissionsBySumModule", p, c),
  },
  positionPermission_platforms: {
    /*
     * 当前角色有的平台
     */
    get: (p, c) => $.get("/api/bms/system/positionPermission/platforms", p, c),
  },
  positionPermission_updatePermission: {
    /*
     * 编辑权限
     * permissionIds 权限id (array query required)
     * platform 平台 (string query required)
     * position 职位 (string query required)
     */
    put: (p, c) =>
      $.put("/api/bms/system/positionPermission/updatePermission", p, c),
  },

  /**
   * 临时管理员
   */
  temporaryAdmin: {
    /*
     * 添加
     * adminId 管理员id (integer query )
     * departmentId 部门id (integer query )
     * id  (integer query )
     * parentId 上级领导id (integer query )
     */
    post: (p, c) => $.post("/api/bms/system/temporaryAdmin", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/bms/system/temporaryAdmin", p, c),
  },
  temporaryAdmin_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/bms/system/temporaryAdmin/list", p, c),
  },

  /**
   * 待办事项
   */
  toDoMatter: {
    /*
     * 添加
     * ableStatus 启用状态 (string query )
     * backgroundColor 背景颜色 (string query )
     * icon 图标 (string query )
     * id  (integer query )
     * interfaceUrl 接口请求地址 (string query )
     * jumpPath 钉钉跳转路径 (string query )
     * jumpUrl 跳转地址 (string query )
     * name 代办事项名字 (string query )
     * permissionId 权限id (integer query )
     * shadeColor 阴影颜色 (string query )
     * sort 排序 (integer query )
     * tipsIcon 提示图标 (string query )
     */
    post: (p, c) => $.post("/api/bms/system/toDoMatter", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/bms/system/toDoMatter", p, c),
  },
  toDoMatter_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/bms/system/toDoMatter/list", p, c),
  },
  toDoMatter_listByPosition: {
    /*
     * 根据登录管理员的角色获取待办事项列表
     * platform 平台 (string query )
     */
    get: (p, c) => $.get("/api/bms/system/toDoMatter/listByPosition", p, c),
  },
};
