import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import api from "./api";
import Tools from "./common/Tools";
import Menu from "./common/Menu";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueAnimateNumber from "vue-animate-number";

Vue.config.productionTip = false;
Vue.prototype.$api = api;
Vue.prototype.$Tools = Tools;
Vue.use(VueAnimateNumber);

Vue.use(ElementUI);

(async function () {
  // 初始化菜单
  await Menu.init();
  // 导航拦截器
  router.beforeEach(async (to, from, next) => {
    // 设置导航索引
    if (Object.prototype.hasOwnProperty.call(to.meta, "naveInd")) {
      store.dispatch("naveIndSet", to.meta.naveInd);
    } else {
      store.dispatch("naveIndSet", "");
    }
    next();
    window.scrollTo(0, 0);
  });

  // 初始化App
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
})();
