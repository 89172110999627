import { $ } from "@/common/Axios";

export default {
  /**
   * 校企学生
   */
  employee: {
    /*
     * 添加
     * enterpriseId 企业ID (integer query )
     * entryTime 入职时间 (string query )
     * id  (integer query )
     * operatingPost 工作岗位 (string query )
     * schoolRollId 合同id (integer query )
     * userId 用户ID (integer query )
     * workPhoto 工作证明 (string query )
     */
    post: (p, c) => $.post("/api/ums/enterprise/employee", p, c),
  },
  employee_getBySchoolRollId: {
    /*
     * 根据合同编号获取校企学生信息
     * schoolRollId 合同id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ums/enterprise/employee/getBySchoolRollId", p, c),
  },
  employee_list: {
    /*
     * 校企学生列表
     */
    get: (p, c) => $.get("/api/ums/enterprise/employee/list", p, c),
  },
  employee_restore: {
    /*
     * 修复
     */
    put: (p, c) => $.put("/api/ums/enterprise/employee/restore", p, c),
  },

  /**
   * 校企
   */
  enterpriseCompany: {
    /*
     * 添加
     * contractPhoto 合同证明 (string query )
     * createId 创建人 (integer query )
     * developTime 开发时间 (string query )
     * docking 对接部门 总经办、工会、人事部、财务部、其他 (string query )
     * id  (integer query )
     * logo 企业logo (string query )
     * name 企业名称 (string query )
     * preferentialFee 优惠金额 (number query )
     * scale 企业规模 50以下，100-300，300-500,500-1000,1000-5000,5000以上 (string query )
     * simpleName 简称 (string query )
     */
    post: (p, c) => $.post("/api/ums/enterprise/enterpriseCompany", p, c),
  },
  enterpriseCompany_changeCreate: {
    /*
     * 更换创建人
     * adminFlag 原创建人是否为负责人:是否 (string query required)
     * createId 创建人 (integer query required)
     * enterpriseId 企业ID (integer query required)
     */
    put: (p, c) =>
      $.put("/api/ums/enterprise/enterpriseCompany/changeCreate", p, c),
  },
  enterpriseCompany_createQrCode: {
    /*
     * 生成企业优惠二维码
     * batch 批次 (string query required)
     * days 有效期（天） (integer query required)
     * enterpriseId 企业ID (integer query required)
     * extraInfos 自定义Json值 (string query required)
     * showTeacher 是否显示招生老师姓名:是、否 (string query required)
     */
    post: (p, c) =>
      $.post("/api/ums/enterprise/enterpriseCompany/createQrCode", p, c),
  },
  enterpriseCompany_getQrCodeValue: {
    /*
     * 根据企业优惠二维码查询value
     * key key (string query required)
     */
    get: (p, c) =>
      $.get("/api/ums/enterprise/enterpriseCompany/getQrCodeValue", p, c),
  },
  enterpriseCompany_list: {
    /*
     * 企业列表
     */
    get: (p, c) => $.get("/api/ums/enterprise/enterpriseCompany/list", p, c),
  },
  enterpriseCompany_listSimple: {
    /*
     * 简易企业列表
     */
    get: (p, c) =>
      $.get("/api/ums/enterprise/enterpriseCompany/listSimple", p, c),
  },
  enterpriseCompany_remove: {
    /*
     * 删除
     * id 企业ID (integer query required)
     */
    delete: (p, c) =>
      $.delete("/api/ums/enterprise/enterpriseCompany/remove", p, c),
  },
  enterpriseCompany_transferCreate: {
    /*
     * 移交创建人
     * adminFlag 原创建人是否为负责人:是否 (string query required)
     * createId 原创建人 (integer query required)
     * newCreateId 新创建人 (integer query required)
     */
    put: (p, c) =>
      $.put("/api/ums/enterprise/enterpriseCompany/transferCreate", p, c),
  },

  /**
   * 校企优惠
   */
  enterpriseCompanyDiscount_apply: {
    /*
     * 申请
     * enterpriseId 企业ID (integer query required)
     * preferentialFee 优惠 (number query required)
     */
    post: (p, c) =>
      $.post("/api/ums/enterprise/enterpriseCompanyDiscount/apply", p, c),
  },
  enterpriseCompanyDiscount_audit: {
    /*
     * 企业优惠审核
     * auditStatus 审核状态 2通过 3拒绝 (string query required)
     * discountId 优惠id (integer query required)
     * flowId 节点id (integer query required)
     * remark 备注 (string query required)
     */
    put: (p, c) =>
      $.put("/api/ums/enterprise/enterpriseCompanyDiscount/audit", p, c),
  },
  enterpriseCompanyDiscount_list: {
    /*
     * 根据企业ID查询优惠金额记录
     * enterpriseId 企业ID (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ums/enterprise/enterpriseCompanyDiscount/list", p, c),
  },

  /**
   * 校企负责人
   */
  enterpriseCompanyLeader_listLeaderId: {
    /*
     * 负责人id列表
     * enterpriseId 企业ID (integer query required)
     */
    get: (p, c) =>
      $.get("/api/ums/enterprise/enterpriseCompanyLeader/listLeaderId", p, c),
  },
  enterpriseCompanyLeader_updateLeader: {
    /*
     * 更新负责人
     * enterpriseId 企业ID (integer query required)
     * leaderIds 负责人IDs (array query required)
     */
    post: (p, c) =>
      $.post("/api/ums/enterprise/enterpriseCompanyLeader/updateLeader", p, c),
  },
};
