import api from "../api";
import store from "../store";
import routers from "../router";

export default class {
  static init() {
    return new Promise((resolve) => {
      api.cms.web.siteMenu_listForClient
        .get({
          platform: "公司官网",
        })
        .then(async (res) => {
          // 设置路由
          for (let item of res.data) {
            for (let item2 of item.subMenus) {
              let path = "/";
              let openType = item2.sortType === "s-0-2" ? 1 : 0; // 0 当前页面打开 1 新页面打开

              if (item2.contentType === "c-0") {
                // 地址
                const menuInfo = await this.getMenuInfo(item2.id);
                if (menuInfo) {
                  path = menuInfo.url;
                }
              } else {
                // 模版
                const route = this.getRouter({
                  contentType: item2.contentType,
                  sortType: item2.sortType,
                });
                if (route) {
                  path = route.path.replace(":menuId", item2.id);
                }
                // 单页特殊处理
                if (item2.contentType === "c-1") {
                  path = path + "?pType=one";
                }
              }

              item2.path = path;
              item2.openType = openType;
            }

            // 隐藏的模块
            item.status =
              ["配置", "底部菜单", "隐藏菜单"].indexOf(item.name) === -1;
            item.on = false;
          }
          store.commit("menuSet", {
            list: res.data,
          });
          resolve();
        });
    });
  }

  // 获取菜单内容
  static getMenuInfo(id) {
    return new Promise((resolve) => {
      api.cms.web.siteContent_listForClient
        .get({
          menuId: id,
        })
        .then((res) => {
          resolve(res.data.pageList.length ? res.data.pageList[0] : "");
        });
    });
  }

  // 路由改变
  static routerChange(to) {
    const routeIndObj = this.getRouteInd(to.fullPath);
    store.commit("menuSet", {
      ind: routeIndObj.ind,
      subInd: routeIndObj.subInd,
      title: routeIndObj.title,
      subTitle: routeIndObj.subTitle,
    });
  }

  // 获取当前路由下标
  static getRouteInd(path) {
    const list = store.state.main.menu.list;
    let ind = "";
    let subInd = "";
    let title = "";
    let subTitle = "";

    list.forEach((item, index) => {
      item.subMenus.forEach((item2, index2) => {
        if (item2.path === path) {
          ind = index;
          subInd = index2;
          title = item.name;
          subTitle = item2.name;
        }
      });
    });
    return {
      ind,
      subInd,
      title,
      subTitle,
    };
  }

  // 获取指定类型的路由
  static getRouter({
    contentType = "",
    sortType = "",
    routes = routers.options.routes,
  }) {
    for (let route of routes) {
      if (
        route.meta &&
        route.meta.contentType === contentType &&
        route.meta.sortType === sortType
      ) {
        return route;
      } else if (route.children && route.children.length) {
        const res = this.getRouter({
          contentType,
          sortType,
          routes: route.children,
        });
        if (res) return res;
      }
    }
  }
}
