import { $ } from "@/common/Axios";

export default {
  /**
   * 军人区县
   */
  armyCounty: {
    /*
     * 添加
     * adminId 管理员id (integer query )
     * city 市 (string query )
     * cover 封面 (string query )
     * district 区 (string query )
     * id  (integer query )
     * province 省 (string query )
     * title 标题 (string query )
     * type 类型 (string query )
     * year 年份 (string query )
     */
    post: (p, c) => $.post("/api/cms/resource/armyCounty", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/cms/resource/armyCounty", p, c),
  },
  armyCounty_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/cms/resource/armyCounty/export", p, c),
  },
  armyCounty_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/cms/resource/armyCounty/list", p, c),
  },
  armyCounty_listCity: {
    /*
     * 市列表
     * province 省 (string query required)
     */
    get: (p, c) => $.get("/api/cms/resource/armyCounty/listCity", p, c),
  },
  armyCounty_listDistrict: {
    /*
     * 区列表
     * city 市 (string query required)
     * province 省 (string query required)
     */
    get: (p, c) => $.get("/api/cms/resource/armyCounty/listDistrict", p, c),
  },
  armyCounty_listForClient: {
    /*
     * 客户端列表
     * type 军人区县类型 (string query required)
     */
    get: (p, c) => $.get("/api/cms/resource/armyCounty/listForClient", p, c),
  },
  armyCounty_listProvince: {
    /*
     * 省份列表
     */
    get: (p, c) => $.get("/api/cms/resource/armyCounty/listProvince", p, c),
  },
  armyCounty_listYear: {
    /*
     * 年份列表
     */
    get: (p, c) => $.get("/api/cms/resource/armyCounty/listYear", p, c),
  },
  armyCounty_upload: {
    /*
     * 导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/cms/resource/armyCounty/upload", p, c),
  },

  /**
   * 军人区县课程
   */
  armyCountyCourse_listCourseIdByCounty: {
    /*
     * 根据区县获取课程列表Id
     * countyId id (integer query required)
     * courseType 课程类型 (string query required)
     */
    get: (p, c) =>
      $.get("/api/cms/resource/armyCountyCourse/listCourseIdByCounty", p, c),
  },
  armyCountyCourse_updateCourse: {
    /*
     * 编辑课程
     * countyId 区县id (integer query required)
     * courseIds 课程id (array query required)
     * courseType 课程类型 (string query required)
     */
    put: (p, c) =>
      $.put("/api/cms/resource/armyCountyCourse/updateCourse", p, c),
  },

  /**
   * 课程
   */
  course: {
    /*
     * 添加
     * ableStatus 启用状态 (string query )
     * code 课程代码 (string query )
     * content 课程内容 (string query )
     * cover 封面 (string query )
     * id  (integer query )
     * name 课程名字 (string query )
     * province 省份 (string query )
     * subtitle 副标题 (string query )
     * teacherAvatar 授课老师头像 (string query )
     * teacherName 授课老师 (string query )
     * teacherSummary 授课老师简介 (string query )
     * title 主标题 (string query )
     * type 类型：成教，成考，专升本 (string query )
     */
    post: (p, c) => $.post("/api/cms/resource/course", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/cms/resource/course", p, c),
  },
  course_details: {
    /*
     * 简易课程列表
     * id id (integer query required)
     */
    get: (p, c) => $.get("/api/cms/resource/course/details", p, c),
  },
  course_detailsAdultExamCourseForClient: {
    /*
     * C端成考课程详情
     * id 课程id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/cms/resource/course/detailsAdultExamCourseForClient", p, c),
  },
  course_exportSelfTaughtCourse: {
    /*
     * 导出自考课程
     */
    get: (p, c) =>
      $.get("/api/cms/resource/course/exportSelfTaughtCourse", p, c),
  },
  course_getArmyCourseStudyTimes: {
    /*
     * C端军人适应性培训已学习时长
     * countyId 区县id (integer query required)
     * courseType 课程类型 (string query required)
     */
    get: (p, c) =>
      $.get("/api/cms/resource/course/getArmyCourseStudyTimes", p, c),
  },
  course_getIdByName: {
    /*
     * 通过名字获取id
     * name 课程名字 (string query required)
     * province 省份-全部 (string query )
     * type 类型：成教，成考，专升本，军人适应性培训，军人职业技能性培训 (string query required)
     */
    get: (p, c) => $.get("/api/cms/resource/course/getIdByName", p, c),
  },
  course_listAdultEducationCourse: {
    /*
     * 成教课程列表
     */
    get: (p, c) =>
      $.get("/api/cms/resource/course/listAdultEducationCourse", p, c),
  },
  course_listAdultExamCourse: {
    /*
     * 成考课程列表
     */
    get: (p, c) => $.get("/api/cms/resource/course/listAdultExamCourse", p, c),
  },
  course_listAdultExamCourseForClient: {
    /*
     * C端成考课程列表
     */
    get: (p, c) =>
      $.get("/api/cms/resource/course/listAdultExamCourseForClient", p, c),
  },
  course_listArmyCourse: {
    /*
     * 军人适应性培训课程列表
     */
    get: (p, c) => $.get("/api/cms/resource/course/listArmyCourse", p, c),
  },
  course_listArmyCourseByCountyAndLabel: {
    /*
     * C端军人适应性培训课程列表
     * countyId 区县id (integer query required)
     * courseType 课程 (string query required)
     * labelId 标签id (integer query )
     */
    get: (p, c) =>
      $.get("/api/cms/resource/course/listArmyCourseByCountyAndLabel", p, c),
  },
  course_listArmyProfessionCourse: {
    /*
     * 军人职业技能培训课程列表
     */
    get: (p, c) =>
      $.get("/api/cms/resource/course/listArmyProfessionCourse", p, c),
  },
  course_listDiscipline: {
    /*
     * 科类列表
     * id id (integer query required)
     * level 层次 (string query )
     */
    get: (p, c) => $.get("/api/cms/resource/course/listDiscipline", p, c),
  },
  course_listFormerYearsArmyCourseByCountyAndLabel: {
    /*
     * C端军人适应性培训课程往年列表
     * countyId 区县id (integer query required)
     * courseType 课程类型 (string query required)
     * labelId 标签id (integer query )
     */
    get: (p, c) =>
      $.get(
        "/api/cms/resource/course/listFormerYearsArmyCourseByCountyAndLabel",
        p,
        c
      ),
  },
  course_listSelfTaughtCourse: {
    /*
     * 自考课程列表
     */
    get: (p, c) => $.get("/api/cms/resource/course/listSelfTaughtCourse", p, c),
  },
  course_listSimpleAdultExamCourseForClient: {
    /*
     * C端简易成考课程列表
     */
    get: (p, c) =>
      $.get(
        "/api/cms/resource/course/listSimpleAdultExamCourseForClient",
        p,
        c
      ),
  },
  course_listSimpleArmyCourse: {
    /*
     * 军人适应性培训简易课程列表
     */
    get: (p, c) => $.get("/api/cms/resource/course/listSimpleArmyCourse", p, c),
  },
  course_listSimpleArmyProfessionCourse: {
    /*
     * 军人职业技能培训简易课程列表
     */
    get: (p, c) =>
      $.get("/api/cms/resource/course/listSimpleArmyProfessionCourse", p, c),
  },
  course_listSimpleCourse: {
    /*
     * 简易课程列表
     * ableStatus 启用状态 (string query )
     * type 课程类型：成教，成考，专升本 (string query required)
     */
    get: (p, c) => $.get("/api/cms/resource/course/listSimpleCourse", p, c),
  },
  course_listSimpleSelfTaughtCourse: {
    /*
     * 自考简易课程列表
     * ableStatus 启用禁用 (string query )
     */
    get: (p, c) =>
      $.get("/api/cms/resource/course/listSimpleSelfTaughtCourse", p, c),
  },
  course_listZsbCourse: {
    /*
     * 专升本课程列表
     */
    get: (p, c) => $.get("/api/cms/resource/course/listZsbCourse", p, c),
  },
  course_updateDiscipline: {
    /*
     * 编辑科类
     * disciplines 科类列表 (array query required)
     * id id (integer query required)
     * level 层次 (string query )
     */
    put: (p, c) => $.put("/api/cms/resource/course/updateDiscipline", p, c),
  },

  /**
   * 课程标签
   */
  courseLabel_listLabelIdByCourseId: {
    /*
     * 根据课程id获取标签id
     * courseId 课程id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/cms/resource/courseLabel/listLabelIdByCourseId", p, c),
  },
  courseLabel_updateLabel: {
    /*
     * 编辑标签
     * courseId 课程id (integer query required)
     * labelIds 标签id (array query required)
     */
    put: (p, c) => $.put("/api/cms/resource/courseLabel/updateLabel", p, c),
  },

  /**
   * 课程类别
   */
  courseType_listTypeByCourseId: {
    /*
     * 根据课程id获取列表
     * courseId 课程id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/cms/resource/courseType/listTypeByCourseId", p, c),
  },
  courseType_updateType: {
    /*
     * 编辑类别
     * courseId 课程id (integer query required)
     * types 类别 (array query required)
     */
    put: (p, c) => $.put("/api/cms/resource/courseType/updateType", p, c),
  },

  /**
   * 期末考试
   */
  exam_downloadMould: {
    /*
     * 下载模版
     */
    get: (p, c) => $.get("/api/cms/resource/exam/downloadMould", p, c),
  },
  exam_downloadMouldExcelSubject: {
    /*
     * 下载Excel试卷模版
     */
    get: (p, c) =>
      $.get("/api/cms/resource/exam/downloadMouldExcelSubject", p, c),
  },
  exam_downloadMouldExcelSubjectOnlyAnswer: {
    /*
     * 下载答案导入模版
     */
    get: (p, c) =>
      $.get("/api/cms/resource/exam/downloadMouldExcelSubjectOnlyAnswer", p, c),
  },
  exam_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/cms/resource/exam/export", p, c),
  },
  exam_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/cms/resource/exam/list", p, c),
  },
  exam_modifyExamTime: {
    /*
     * 批量修改考试时间
     * commitEndTime 交卷结束时间 (string query )
     * commitStartTime 交卷开始时间 (string query )
     * endTime 考试结束时间 (string query required)
     * ids 考试id (array query required)
     * startTime 考试开始时间 (string query required)
     */
    post: (p, c) => $.post("/api/cms/resource/exam/modifyExamTime", p, c),
  },
  exam_previewPaper: {
    /*
     * 预览试卷
     * id 考试id (integer query required)
     */
    get: (p, c) => $.get("/api/cms/resource/exam/previewPaper", p, c),
  },
  exam_publish: {
    /*
     * 发布考试成绩
     * ids 考试id (array query required)
     */
    post: (p, c) => $.post("/api/cms/resource/exam/publish", p, c),
  },
  exam_remove: {
    /*
     * 删除考试
     * id 考试id (integer query required)
     */
    delete: (p, c) => $.delete("/api/cms/resource/exam/remove", p, c),
  },
  exam_save: {
    /*
     * 添加编辑
     * category 考试模式（仅限普通模式、答题卡模式） (string query required)
     * courseName 课程名称 (string query required)
     * duration 本次考试时长（分） (integer query required)
     * endTime 考试结束时间 (string query required)
     * examYear 考试年度 (string query required)
     * id id (integer query )
     * majorId 专业ID (integer query required)
     * paperUrl 学生试卷 (string query )
     * rollGrade 学籍年级 (string query required)
     * startTime 考试开始时间 (string query required)
     * type 类型 正考 补考 (string query required)
     */
    post: (p, c) => $.post("/api/cms/resource/exam/save", p, c),
  },
  exam_upload: {
    /*
     * 导入考试
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/cms/resource/exam/upload", p, c),
  },
  exam_uploadSubject: {
    /*
     * 导入试卷
     * attachUrl 试卷源文件地址 (string query required)
     * file file (file formData required)
     * id id (integer query required)
     */
    post: (p, c) => $.post("/api/cms/resource/exam/uploadSubject", p, c),
  },
  exam_uploadSubjectByExcel: {
    /*
     * 导入Excel试卷
     * attachUrl 试卷源文件地址 (string query required)
     * excelFile excelFile (file formData required)
     * id id (integer query required)
     */
    post: (p, c) => $.post("/api/cms/resource/exam/uploadSubjectByExcel", p, c),
  },
  exam_uploadSubjectExcelOnlyAnswer: {
    /*
     * 导入答案，仅限考试模式为答题卡模式
     * attachUrl 试卷源文件地址 (string query required)
     * excelFile excelFile (file formData required)
     * id id (integer query required)
     */
    post: (p, c) =>
      $.post("/api/cms/resource/exam/uploadSubjectExcelOnlyAnswer", p, c),
  },

  /**
   * 知识点
   */
  knowledge: {
    /*
     * 添加
     * ableStatus 启用，禁用 (string query )
     * canTry 是否可以试看 (string query )
     * courseId 课程id (integer query )
     * docUrl 文档地址 (string query )
     * duration 视频时长（秒） (integer query )
     * id  (integer query )
     * name 知识点（节名称） (string query )
     * section 大章名称 (string query )
     * sort 排序 (integer query )
     * type 类型 (string query )
     * videoUrl 视频地址 (string query )
     */
    post: (p, c) => $.post("/api/cms/resource/knowledge", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/cms/resource/knowledge", p, c),
  },
  knowledge_batchAble: {
    /*
     * 批量启用禁用
     * ableStatus 启用状态：启用，禁用 (string query required)
     * ids 知识点id (array query required)
     */
    put: (p, c) => $.put("/api/cms/resource/knowledge/batchAble", p, c),
  },
  knowledge_batchRemove: {
    /*
     * 批量删除
     * ids 知识点id (array query required)
     */
    delete: (p, c) => $.delete("/api/cms/resource/knowledge/batchRemove", p, c),
  },
  knowledge_exportAdultEducationKnowledge: {
    /*
     * 批量导出成教知识点
     */
    get: (p, c) =>
      $.get("/api/cms/resource/knowledge/exportAdultEducationKnowledge", p, c),
  },
  knowledge_listAdultEducationKnowledge: {
    /*
     * 成教知识点列表
     */
    get: (p, c) =>
      $.get("/api/cms/resource/knowledge/listAdultEducationKnowledge", p, c),
  },
  knowledge_listAdultEducationKnowledgeForClient: {
    /*
     * C端课程课件列表
     * courseId 课程id (integer query required)
     */
    get: (p, c) =>
      $.get(
        "/api/cms/resource/knowledge/listAdultEducationKnowledgeForClient",
        p,
        c
      ),
  },
  knowledge_listAdultExamKnowledge: {
    /*
     * 成考知识点列表
     */
    get: (p, c) =>
      $.get("/api/cms/resource/knowledge/listAdultExamKnowledge", p, c),
  },
  knowledge_listArmyKnowledge: {
    /*
     * 军人适应性培训知识点列表
     */
    get: (p, c) => $.get("/api/cms/resource/knowledge/listArmyKnowledge", p, c),
  },
  knowledge_listArmyProfessionKnowledge: {
    /*
     * 军人职业技能培训知识点列表
     */
    get: (p, c) =>
      $.get("/api/cms/resource/knowledge/listArmyProfessionKnowledge", p, c),
  },
  knowledge_listCourseKnowledgeForClient: {
    /*
     * 学生成考知识点小结列表
     * courseId 课程id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/cms/resource/knowledge/listCourseKnowledgeForClient", p, c),
  },
  knowledge_listKnowledgeForClient: {
    /*
     * C端课程课件列表
     * courseId 课程id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/cms/resource/knowledge/listKnowledgeForClient", p, c),
  },
  knowledge_listPurchasedKnowledge: {
    /*
     * 已购知识点列表（需要登录）
     */
    get: (p, c) =>
      $.get("/api/cms/resource/knowledge/listPurchasedKnowledge", p, c),
  },
  knowledge_listSection: {
    /*
     * 简易大章列表
     * courseId 课程id (integer query required)
     */
    get: (p, c) => $.get("/api/cms/resource/knowledge/listSection", p, c),
  },
  knowledge_listSectionForClient: {
    /*
     * C端大章列表（不需要登录）
     * courseId 课程id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/cms/resource/knowledge/listSectionForClient", p, c),
  },
  knowledge_listSelfSubjectKnowledge: {
    /*
     * 自考习题章节列表
     */
    get: (p, c) =>
      $.get("/api/cms/resource/knowledge/listSelfSubjectKnowledge", p, c),
  },
  knowledge_listSelfSubjectKnowledgeForClient: {
    /*
     * 自考习题章节C端口列表
     * courseId 课程id (integer query required)
     */
    get: (p, c) =>
      $.get(
        "/api/cms/resource/knowledge/listSelfSubjectKnowledgeForClient",
        p,
        c
      ),
  },
  knowledge_listSelfTaughtCourseForClient: {
    /*
     * 自考精品课C端口列表
     */
    get: (p, c) =>
      $.get("/api/cms/resource/knowledge/listSelfTaughtCourseForClient", p, c),
  },
  knowledge_listSelfTaughtVideoForClient: {
    /*
     * 自考视频C端口列表
     * courseId 课程Id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/cms/resource/knowledge/listSelfTaughtVideoForClient", p, c),
  },
  knowledge_listSelfTaughtVideoKnowledge: {
    /*
     * 自考课程章节(视频)列表
     */
    get: (p, c) =>
      $.get("/api/cms/resource/knowledge/listSelfTaughtVideoKnowledge", p, c),
  },
  knowledge_listSimpleAdultExamCourseKnowledgeForClient: {
    /*
     * 学生成考知识点小结列表
     */
    get: (p, c) =>
      $.get(
        "/api/cms/resource/knowledge/listSimpleAdultExamCourseKnowledgeForClient",
        p,
        c
      ),
  },
  knowledge_listSimpleSelfTaughtVideoKnowledge: {
    /*
     * 自考简易章节(视频)列表
     * ableStatus 启用禁用 (string query )
     * courseId 课程id (integer query required)
     */
    get: (p, c) =>
      $.get(
        "/api/cms/resource/knowledge/listSimpleSelfTaughtVideoKnowledge",
        p,
        c
      ),
  },
  knowledge_listStudentKnowledgeSectionForClient: {
    /*
     * 学生知识点大章列表（需要登录）
     * courseId 课程id (integer query required)
     */
    get: (p, c) =>
      $.get(
        "/api/cms/resource/knowledge/listStudentKnowledgeSectionForClient",
        p,
        c
      ),
  },
  knowledge_uploadSelfTaughtVideo: {
    /*
     * 导入自考章节视频
     * course 课程名称 (string query required)
     * duration 时长 (integer query required)
     * name 章节名称 (string query required)
     * sort 排序 (integer query required)
     * videoUrl 视频地址 (string query required)
     */
    post: (p, c) =>
      $.post("/api/cms/resource/knowledge/uploadSelfTaughtVideo", p, c),
  },

  /**
   * 标签
   */
  label: {
    /*
     * 添加
     * courseType 课程类型 (string query )
     * id  (integer query )
     * name 标签名 (string query )
     */
    post: (p, c) => $.post("/api/cms/resource/label", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/cms/resource/label", p, c),
  },
  label_listArmy: {
    /*
     * 军人适应性培训标签列表
     */
    get: (p, c) => $.get("/api/cms/resource/label/listArmy", p, c),
  },
  label_listArmyByCounty: {
    /*
     * C端根据区县获取军人适应性培训标签列表
     * countyId 区县id (integer query required)
     */
    get: (p, c) => $.get("/api/cms/resource/label/listArmyByCounty", p, c),
  },
  label_listSimple: {
    /*
     * 简易列表
     * courseType 课程类型 (string query required)
     */
    get: (p, c) => $.get("/api/cms/resource/label/listSimple", p, c),
  },

  /**
   * 学习资料
   */
  learningFile: {
    /*
     * 添加
     * courseId 课程id (integer query )
     * cover 封面 (string query )
     * id  (integer query )
     * payUrl 购买地址 (string query )
     * price 价格 (number query )
     * title 标题 (string query )
     * type 类型：成考通关资料 (string query )
     */
    post: (p, c) => $.post("/api/cms/resource/learningFile", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/cms/resource/learningFile", p, c),
  },
  learningFile_listAdultExamLearningFile: {
    /*
     * 成考通关资料列表
     */
    get: (p, c) =>
      $.get("/api/cms/resource/learningFile/listAdultExamLearningFile", p, c),
  },
  learningFile_listLearningFileForClient: {
    /*
     * 学习资料列表
     * type 资料类型：成考通关资料 (string query required)
     */
    get: (p, c) =>
      $.get("/api/cms/resource/learningFile/listLearningFileForClient", p, c),
  },

  /**
   * 成考直播课
   */
  liveCourse: {
    /*
     * 添加
     * ableStatus 启用状态 (string query )
     * courseId 课程id (integer query )
     * cover 封面 (string query )
     * homeShow 是否主页展示 (string query )
     * id  (integer query )
     * note 注意事项 (string query )
     * teacher 老师 (string query )
     * teacherAvatar 老师头像 (string query )
     * teacherSummary 老师简介 (string query )
     * title 标题 (string query )
     * type 类型：公开课等等 (string query )
     */
    post: (p, c) => $.post("/api/cms/resource/liveCourse", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/cms/resource/liveCourse", p, c),
  },
  liveCourse_detailsAdultExamLiveCourseForClient: {
    /*
     * C端成考课程详情
     * id 直播课程id (integer query required)
     */
    get: (p, c) =>
      $.get(
        "/api/cms/resource/liveCourse/detailsAdultExamLiveCourseForClient",
        p,
        c
      ),
  },
  liveCourse_listAdultExamLiveCourse: {
    /*
     * 成考直播课程列表
     */
    get: (p, c) =>
      $.get("/api/cms/resource/liveCourse/listAdultExamLiveCourse", p, c),
  },
  liveCourse_listAdultExamLiveCourseForClient: {
    /*
     * C端成考直播课程列表
     * type 类别：公开课 (string query required)
     */
    get: (p, c) =>
      $.get(
        "/api/cms/resource/liveCourse/listAdultExamLiveCourseForClient",
        p,
        c
      ),
  },
  liveCourse_listAdultExamLiveCoursePlaybackForClient: {
    /*
     * 根据课程id获取成考直播课程
     * type 类别：公开课 (string query required)
     */
    get: (p, c) =>
      $.get(
        "/api/cms/resource/liveCourse/listAdultExamLiveCoursePlaybackForClient",
        p,
        c
      ),
  },
  liveCourse_listSelfTaughtLiveCourse: {
    /*
     * 自考直播课程列表
     */
    get: (p, c) =>
      $.get("/api/cms/resource/liveCourse/listSelfTaughtLiveCourse", p, c),
  },
  liveCourse_listSelfTaughtLiveCourseForClient: {
    /*
     * 自考直播课程列表-C端
     * homeShow 是否主页展示(是为主页列表, 不传为回放列表) (string query )
     */
    get: (p, c) =>
      $.get(
        "/api/cms/resource/liveCourse/listSelfTaughtLiveCourseForClient",
        p,
        c
      ),
  },
  liveCourse_listSimple: {
    /*
     * 直播课简易列表
     * courseType 课程类别 (string query required)
     * liveCourseType 类别：公开课 (string query required)
     */
    get: (p, c) => $.get("/api/cms/resource/liveCourse/listSimple", p, c),
  },
  liveCourse_removeSelfTaught: {
    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) =>
      $.delete("/api/cms/resource/liveCourse/removeSelfTaught", p, c),
  },
  liveCourse_saveSelfTaught: {
    /*
     * 添加编辑
     * ableStatus 启用状态|全部||字符串下拉|api/bms/develop/config/listSelect?key=启用状态 (string query )
     * course 课程名称|列表||| (string query )
     * courseId 课程id|筛选||ID下拉|api/cms/resource/course/listSimpleCourse?type=自考 (integer query )
     * cover 封面|列表|图片|| (string query )
     * docUrl 文档地址|列表|附件|| (string query )
     * duration 视频时长（秒）|列表||| (integer query )
     * endLive 结束直播时间|全部|时间|时间| (string query )
     * homeShow 是否主页展示|全部||字符串下拉|api/bms/develop/config/listSelect?key=是否 (string query )
     * id 直播课id|无||| (integer query )
     * liveCourseChapterId 章节ID, 反馈问题用|无||| (integer query )
     * note 注意事项|列表||| (string query )
     * startLive 开始直播时间|全部|时间|时间| (string query )
     * teacher 老师|列表||| (string query )
     * teacherAvatar 老师头像|列表|图片|| (string query )
     * teacherSummary 老师简介|列表||| (string query )
     * title 标题|全部||| (string query )
     * type 类型|无||| (string query )
     * videoUrl 视频地址|列表|视频|| (string query )
     */
    post: (p, c) => $.post("/api/cms/resource/liveCourse/saveSelfTaught", p, c),
  },

  /**
   * 成考直播章节
   */
  liveCourseChapter: {
    /*
     * 添加
     * docUrl 文档地址 (string query )
     * duration 视频时长（秒） (integer query )
     * endLive 结束直播时间 (string query )
     * id  (integer query )
     * liveCourseId 直播课id (integer query )
     * name 章节名 (string query )
     * section 大章名称 (string query )
     * sort 排序 (integer query )
     * startLive 开始直播时间 (string query )
     * videoUrl 视频地址 (string query )
     */
    post: (p, c) => $.post("/api/cms/resource/liveCourseChapter", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/cms/resource/liveCourseChapter", p, c),
  },
  liveCourseChapter_listAdultExamLiveCourseChapter: {
    /*
     * 成考直播章节列表
     */
    get: (p, c) =>
      $.get(
        "/api/cms/resource/liveCourseChapter/listAdultExamLiveCourseChapter",
        p,
        c
      ),
  },
  liveCourseChapter_listPassedForClient: {
    /*
     * 回放列表（需要登录）
     * liveCourseId 直播课程id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/cms/resource/liveCourseChapter/listPassedForClient", p, c),
  },
  liveCourseChapter_listSection: {
    /*
     * 简易大章列表
     * liveCourseId 直播课程id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/cms/resource/liveCourseChapter/listSection", p, c),
  },
  liveCourseChapter_listSimple: {
    /*
     * 简易列表
     * liveCourseId 直播课程id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/cms/resource/liveCourseChapter/listSimple", p, c),
  },

  /**
   * 试卷
   */
  paper: {
    /*
     * 添加
     * ableStatus 启用状态 (string query )
     * attach 试卷附件 (string query )
     * courseId 课程id (integer query )
     * duration 建议答题时长 (string query )
     * endTime 结束时间 (string query )
     * id  (integer query )
     * name 名字 (string query )
     * scoreStatus 成绩发布状态：未发布，已发布 (string query )
     * startTime 开始时间 (string query )
     * type 试卷类型:历年真题，模拟测试，摸底考试 (string query )
     */
    post: (p, c) => $.post("/api/cms/resource/paper", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/cms/resource/paper", p, c),
  },
  paper_listHabitTestPaper: {
    /*
     * 摸底考试列表
     */
    get: (p, c) => $.get("/api/cms/resource/paper/listHabitTestPaper", p, c),
  },
  paper_listMockTestPaper: {
    /*
     * 模拟测试列表
     */
    get: (p, c) => $.get("/api/cms/resource/paper/listMockTestPaper", p, c),
  },
  paper_listPaper: {
    /*
     * 试卷(历年真题和模拟测试)列表
     * courseId 课程id (integer query required)
     * type 试卷类型：历年真题，模拟测试，摸底考试，社会生摸底考试 (string query required)
     */
    get: (p, c) => $.get("/api/cms/resource/paper/listPaper", p, c),
  },
  paper_listPaperForClient: {
    /*
     * C端试卷(历年真题,模拟测试,摸底考试，社会生摸底考试，顺序练习)列表
     * courseId 课程id (integer query )
     * type 试卷类型：历年真题，模拟测试，摸底考试，社会生摸底考试，顺序练习 (string query required)
     */
    get: (p, c) => $.get("/api/cms/resource/paper/listPaperForClient", p, c),
  },
  paper_listPaperForMainPage: {
    /*
     * 首页试卷(历年真题,模拟测试,摸底考试)列表
     * type 试卷类型：历年真题，模拟测试，摸底考试 (string query required)
     */
    get: (p, c) => $.get("/api/cms/resource/paper/listPaperForMainPage", p, c),
  },
  paper_listPastRealPaper: {
    /*
     * 历年真题列表
     */
    get: (p, c) => $.get("/api/cms/resource/paper/listPastRealPaper", p, c),
  },
  paper_listSimple: {
    /*
     * 试卷简易列表
     * courseId 课程id (integer query required)
     * type 试卷类型：历年真题，模拟测试，摸底考试 (string query required)
     */
    get: (p, c) => $.get("/api/cms/resource/paper/listSimple", p, c),
  },
  paper_listSocietyTestPaper: {
    /*
     * 社会生摸底考试
     */
    get: (p, c) => $.get("/api/cms/resource/paper/listSocietyTestPaper", p, c),
  },
  paper_uploadSortSubject: {
    /*
     * 上传顺序练习习题
     * courseId 课程id (integer query required)
     * file file (file formData required)
     * paperName 顺序练习试卷名称 (string query required)
     */
    post: (p, c) => $.post("/api/cms/resource/paper/uploadSortSubject", p, c),
  },
  paper_uploadSortSubjectByExcel: {
    /*
     * 上传顺序练习习题-Excel模式
     * courseId 课程id (integer query required)
     * file file (file formData required)
     * paperName 顺序练习试卷名称 (string query required)
     */
    post: (p, c) =>
      $.post("/api/cms/resource/paper/uploadSortSubjectByExcel", p, c),
  },
  paper_uploadSubject: {
    /*
     * 上传试卷习题
     * attach 附件地址 (string query required)
     * file file (file formData required)
     * id 试卷id (integer query required)
     */
    post: (p, c) => $.post("/api/cms/resource/paper/uploadSubject", p, c),
  },
  paper_uploadSubjectByExcel: {
    /*
     * 上传试卷习题-Excel模式
     * attachUrl 试卷源文件地址 (string query required)
     * excelFile excelFile (file formData required)
     * id id (integer query required)
     */
    post: (p, c) =>
      $.post("/api/cms/resource/paper/uploadSubjectByExcel", p, c),
  },

  /**
   * 考务管理-试卷管理
   */
  paperRecord_downloadPapers: {
    /*
     * 下载试卷
     * courseIds 课程ID列表, 多选 (array query )
     * id 记录ID，单个下载 (integer query )
     */
    get: (p, c) => $.get("/api/cms/resource/paperRecord/downloadPapers", p, c),
  },
  paperRecord_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/cms/resource/paperRecord/list", p, c),
  },
  paperRecord_listConfig: {
    /*
     * 组卷策略列表
     * id 列表ID (integer query required)
     */
    get: (p, c) => $.get("/api/cms/resource/paperRecord/listConfig", p, c),
  },
  paperRecord_listRandomSubject: {
    /*
     * 根据课程ID和组卷策略获取随机试题
     * id 列表ID (integer query required)
     */
    get: (p, c) =>
      $.get("/api/cms/resource/paperRecord/listRandomSubject", p, c),
  },
  paperRecord_listRecord: {
    /*
     * 随机组卷记录列表
     * id 列表ID (integer query required)
     */
    get: (p, c) => $.get("/api/cms/resource/paperRecord/listRecord", p, c),
  },
  paperRecord_removeConfig: {
    /*
     * 删除组卷策略
     * id 策略列表ID，非主列表ID (integer query required)
     */
    delete: (p, c) =>
      $.delete("/api/cms/resource/paperRecord/removeConfig", p, c),
  },
  paperRecord_removeRandomPaper: {
    /*
     * 删除组卷记录
     * id 组卷记录列表ID，非主列表ID (integer query required)
     */
    delete: (p, c) =>
      $.delete("/api/cms/resource/paperRecord/removeRandomPaper", p, c),
  },
  paperRecord_saveConfig: {
    /*
     * 添加编辑组卷策略
     * applyAnswerCountList 该题型提供答案的题数 (array query required)
     * countList 该题型题数 (array query required)
     * courseId 课程ID (integer query required)
     * scoreList 该题型总数 (array query required)
     * sortingList 该题型顺序 (array query required)
     * typeList 习题类型名称 (array query required)
     */
    put: (p, c) => $.put("/api/cms/resource/paperRecord/saveConfig", p, c),
  },
  paperRecord_saveConfigList: {
    /*
     * 添加编辑组卷策略
     * configListStr 策略列表 (string query required)
     * courseId 课程ID (integer query required)
     */
    put: (p, c) => $.put("/api/cms/resource/paperRecord/saveConfigList", p, c),
  },
  paperRecord_saveRandomPaper: {
    /*
     * 随机组卷
     * courseId 列表ID (integer query required)
     * level 层次 (string query )
     * major 专业 (string query )
     * paperType A/B卷 (string query )
     * point 教学点 (string query )
     * site 院校 (string query )
     * siteId 学校ID (integer query )
     * term 学期 (string query )
     * time 考试时间 (string query )
     * type 考试形式 (string query )
     * year 学年 (string query )
     */
    put: (p, c) => $.put("/api/cms/resource/paperRecord/saveRandomPaper", p, c),
  },

  /**
   * 学生试卷
   */
  studentPaper_createPaper: {
    /*
     * 创建试卷
     * contentNum 由题干文章数量 (integer query required)
     * noContentNum 无题干题目数量 (integer query required)
     * paperId 顺序练习习题类型id (integer query required)
     */
    post: (p, c) => $.post("/api/cms/resource/studentPaper/createPaper", p, c),
  },
  studentPaper_listForClient: {
    /*
     * 试卷列表
     * paperId 顺序练习习题类型id (integer query required)
     */
    get: (p, c) => $.get("/api/cms/resource/studentPaper/listForClient", p, c),
  },

  /**
   * 习题
   */
  subject: {
    /*
     * 添加
     * ableStatus 启用状态：启用，禁用 (string query )
     * analysis 解析 (string query )
     * answer 正确答案 (string query )
     * answerA 答案A (string query )
     * answerB 答案B (string query )
     * answerC 答案C (string query )
     * answerD 答案D (string query )
     * answerE 答案E (string query )
     * answerF 答案F (string query )
     * answerG 答案G (string query )
     * answerH 答案H (string query )
     * answerI 答案I (string query )
     * belongId 归属id,如果是章节练习，则为知识点id,其他为试卷id (integer query )
     * content 题目 (string query )
     * contentId 内容id (integer query )
     * contentType 内容类型：单选，多选，判断等 (string query )
     * id  (integer query )
     * level 难度:简单,一般,难 (string query )
     * logicType 逻辑类型：单选，多选，判断，填空 (string query )
     * originId 原习题id，顺序练习特有 (integer query )
     * score 分值 (number query )
     * sort 习题序号，针对同一个contentId的习题，入股contentId为0，则序号也为0 (integer query )
     * type 习题类型：章节练习，直播课课后练习，模拟测试，历年真题，摸底考试，社会生摸底考试，顺序练习 (string query )
     */
    post: (p, c) => $.post("/api/cms/resource/subject", p, c),
  },
  subject_countSortSubject: {
    /*
     * 顺序练习习题数量
     * paperId 顺序练习试卷id (integer query required)
     * type 类型： 有内容、无内容 (string query required)
     */
    get: (p, c) => $.get("/api/cms/resource/subject/countSortSubject", p, c),
  },
  subject_detailChapterSubject: {
    /*
     * 章节习题详情
     * id 习题id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/cms/resource/subject/detailChapterSubject", p, c),
  },
  subject_detailsByPaperForClient: {
    /*
     * C端试卷习题详情
     * exerciseId 练习id,解析的时候一定要传 (integer query )
     * paperId 试卷id (integer query required)
     * paperType 试卷类型：章节练习，直播课课后练习，历年真题，模拟测试，摸底考试，社会生摸底考试，顺序练习 (string query required)
     * type 类型：测试，考试，解析 (string query required)
     */
    get: (p, c) =>
      $.get("/api/cms/resource/subject/detailsByPaperForClient", p, c),
  },
  subject_detailsByPaperId: {
    /*
     * 试卷习题详情
     * exerciseId 练习id (integer query )
     * paperId 试卷id (integer query required)
     * paperType 试卷类型：历年真题，模拟测试，摸底考试 (string query required)
     * userId 用户id (integer query )
     */
    get: (p, c) => $.get("/api/cms/resource/subject/detailsByPaperId", p, c),
  },
  subject_detailsSelfByBelongForClient: {
    /*
     * C端根据归属获取自考简易习题概览详情（不包含习题内容）
     * belongId 归属id (integer query required)
     * exerciseId 练习id (integer query )
     * paperType 试卷类型：历年真题，模拟测试，摸底考试 (string query required)
     * type 类型：测试，考试，解析 (string query required)
     */
    get: (p, c) =>
      $.get("/api/cms/resource/subject/detailsSelfByBelongForClient", p, c),
  },
  subject_detailsSelfBySubjectForClient: {
    /*
     * C端自考习题详情某一个题的
     * exerciseId 练习id (integer query )
     * subjectId 习题id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/cms/resource/subject/detailsSelfBySubjectForClient", p, c),
  },
  subject_downloadMouldExcelSubject: {
    /*
     * 下载Excel习题模版
     */
    get: (p, c) =>
      $.get("/api/cms/resource/subject/downloadMouldExcelSubject", p, c),
  },
  subject_getContentByContentId: {
    /*
     * 根据内容id获取内容
     * id 内容id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/cms/resource/subject/getContentByContentId", p, c),
  },
  subject_getOrderSubjectForClient: {
    /*
     * 获取顺序习题
     * exerciseId 练习id,解析的时候一定要传 (integer query )
     * id 习题id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/cms/resource/subject/getOrderSubjectForClient", p, c),
  },
  subject_list: {
    /*
     * 章节练习习题列表
     * knowledgeId 知识点id (integer query required)
     */
    get: (p, c) => $.get("/api/cms/resource/subject/list", p, c),
  },
  subject_listCardSubjectForClient: {
    /*
     * 答题卡习题列表
     * exerciseId 练习id,解析的时候一定要传 (integer query )
     * paperId 试卷id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/cms/resource/subject/listCardSubjectForClient", p, c),
  },
  subject_listFinalExamSubject: {
    /*
     * 根据考试id获取试题列表（整理后）
     * examId 考试id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/cms/resource/subject/listFinalExamSubject", p, c),
  },
  subject_listForRandomPaper: {
    /*
     * 考务管理-习题管理列表
     */
    get: (p, c) => $.get("/api/cms/resource/subject/listForRandomPaper", p, c),
  },
  subject_listSelfForDailyExercise: {
    /*
     * 每日一练习题
     * courseId 课程id (integer query required)
     * knowledgeIds 章节id (array query )
     */
    get: (p, c) =>
      $.get("/api/cms/resource/subject/listSelfForDailyExercise", p, c),
  },
  subject_listSortSubject: {
    /*
     * 顺序练习习题列表
     */
    get: (p, c) => $.get("/api/cms/resource/subject/listSortSubject", p, c),
  },
  subject_refreshOrderSubject: {
    /*
     * 刷新顺序练习
     */
    post: (p, c) =>
      $.post("/api/cms/resource/subject/refreshOrderSubject", p, c),
  },
  subject_sortOrderSubject: {
    /*
     * 顺序练习重新排序
     */
    post: (p, c) => $.post("/api/cms/resource/subject/sortOrderSubject", p, c),
  },
  subject_updateChapterSubject: {
    /*
     * 编辑章节习题
     * ableStatus 启用状态|全部||字符串下拉|api/bms/develop/config/listSelect?key=启用状态 (string query )
     * analysis 解析|列表||| (string query )
     * answer 正确答案|列表||| (string query )
     * answerA 答案A|列表||| (string query )
     * answerB 答案B|列表||| (string query )
     * answerC 答案C|列表||| (string query )
     * answerD 答案D|列表||| (string query )
     * answerE 答案E|列表||| (string query )
     * answerF 答案F|列表||| (string query )
     * answerG 答案G|列表||| (string query )
     * answerH 答案H|列表||| (string query )
     * answerI 答案I|列表||| (string query )
     * content 题目|列表||| (string query )
     * contentContent 内容|列表||| (string query )
     * contentId 内容id|无||| (integer query )
     * contentType 内容类型|列表||| (string query )
     * id 习题id|无||| (integer query )
     * level 难度|列表||| (string query )
     * logicType 逻辑类型|列表||| (string query )
     * sort 习题序号|列表||| (integer query )
     */
    put: (p, c) =>
      $.put("/api/cms/resource/subject/updateChapterSubject", p, c),
  },
  subject_uploadChapterSubject: {
    /*
     * 上传章节习题
     * belongId 知识点id (integer query required)
     * file file (file formData required)
     * type 类型：章节练习，直播课课后练习 (string query required)
     */
    post: (p, c) =>
      $.post("/api/cms/resource/subject/uploadChapterSubject", p, c),
  },
  subject_uploadSubjectByExcel: {
    /*
     * 上传习题
     * belongId 知识点id (integer query required)
     * file file (file formData required)
     * type 类型：自考章节练习 (string query required)
     */
    post: (p, c) =>
      $.post("/api/cms/resource/subject/uploadSubjectByExcel", p, c),
  },
};
