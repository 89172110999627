<template>
  <div class="com-foot">
    <footer class="footer">
      <div class="t1">让学习成为一种习惯</div>
      <div class="t2">
        华博特教育学习分享平台汇集海量优秀资源，志在打造优秀的在线学历教育和能力提升平台
      </div>
    </footer>
    <div class="copyright-con">
      Copyright © 2021 杭州课房科技有限公司版权所有
      <a href="https://beian.miit.gov.cn/" target="_blank">
        浙ICP备14043930号-1
      </a>
    </div>
  </div>
</template>

<script>
import page_mx from "@/mixin/page_mx";

export default {
  mixins: [page_mx],
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.com-foot {
}
.footer {
  height: 240px;
  color: #fff;
  background-color: #272727;
  text-align: center;
  box-sizing: border-box;
  .t1 {
    padding-top: 60px;
    height: 50px;
    line-height: 50px;
    font-size: 36px;
  }
  .t2 {
    padding-top: 20px;
    font-size: 20px;
  }
}

.copyright-con {
  color: #fff;
  font-size: 12px;
  height: 40px;
  line-height: 40px;
  background: #333333;
  text-align: center;
}
.copyright-con a {
  color: #fff;
}
</style>
