import { $ } from "@/common/Axios";

export default {
  /**
   * 优秀员工
   */
  coworker: {
    /*
     * 添加
     * ableStatus 是否启用 (string query )
     * constellation 星座 (string query )
     * cover 封面 (string query )
     * experiences 个人经历 (string query )
     * hireDate 入职时间 (string query )
     * hobby 爱好 (string query )
     * home 家乡 (string query )
     * id  (integer query )
     * image 图片 (string query )
     * interview 采访 (string query )
     * name 姓名 (string query )
     * school 院校 (string query )
     * sort 排序 (integer query )
     * station 岗位 (string query )
     * stature 身高 (string query )
     * title 标题 (string query )
     */
    post: (p, c) => $.post("/api/cms/web/coworker", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/cms/web/coworker", p, c),
  },
  coworker_details: {
    /*
     * 详情
     * id id (integer query required)
     */
    get: (p, c) => $.get("/api/cms/web/coworker/details", p, c),
  },
  coworker_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/cms/web/coworker/list", p, c),
  },
  coworker_listForClient: {
    /*
     * C端列表
     */
    get: (p, c) => $.get("/api/cms/web/coworker/listForClient", p, c),
  },

  /**
   * 招聘岗位
   */
  jobPosition: {
    /*
     * 添加
     * ableStatus 是否启用 (string query )
     * address 工作地点 (string query )
     * department 所属部门 (string query )
     * id  (integer query )
     * name 岗位名称 (string query )
     * requirement 任职要求 (string query )
     * responsibility 岗位职责 (string query )
     * sort 排序 (integer query )
     */
    post: (p, c) => $.post("/api/cms/web/jobPosition", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/cms/web/jobPosition", p, c),
  },
  jobPosition_details: {
    /*
     * 详情
     * id id (integer query required)
     */
    get: (p, c) => $.get("/api/cms/web/jobPosition/details", p, c),
  },
  jobPosition_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/cms/web/jobPosition/list", p, c),
  },
  jobPosition_listForClient: {
    /*
     * C端列表
     */
    get: (p, c) => $.get("/api/cms/web/jobPosition/listForClient", p, c),
  },

  /**
   * 页面类型
   */
  pageType: {
    /*
     * 添加
     * clickType 点击详情类型 (string query )
     * contentTips 内容提示 (string query )
     * contentType 内容类型：无、文本、JSON、富文本、多图 (string query )
     * coverTips 封面提示 (string query )
     * coverType 封面类型：是|否 (string query )
     * id  (integer query )
     * name 名字 (string query )
     * publishTimeType 发布时间：是|否 (string query )
     * subtitleType 副标题类型：是|否 (string query )
     * tipsType 提示类型：是|否 (string query )
     * titleType 标题类型：是|否 (string query )
     * urlType 链接类型：是|否 (string query )
     * videoType 视频类型：是|否 (string query )
     */
    post: (p, c) => $.post("/api/cms/web/pageType", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/cms/web/pageType", p, c),
  },
  pageType_getByName: {
    /*
     * 根据名字获取页面类型
     * name name (string query required)
     */
    get: (p, c) => $.get("/api/cms/web/pageType/getByName", p, c),
  },
  pageType_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/cms/web/pageType/list", p, c),
  },
  pageType_listSimple: {
    /*
     * 简易列表
     */
    get: (p, c) => $.get("/api/cms/web/pageType/listSimple", p, c),
  },

  /**
   * 官网Banner
   */
  siteBanner: {
    /*
     * 添加
     * ableStatus 启用状态 (string query )
     * id  (integer query )
     * image 图片 (string query )
     * platform 平台 (string query )
     * sort 排序 (integer query )
     * title 标题 (string query )
     * url 地址 (string query )
     * video 视频 (string query )
     */
    post: (p, c) => $.post("/api/cms/web/siteBanner", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/cms/web/siteBanner", p, c),
  },
  siteBanner_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/cms/web/siteBanner/list", p, c),
  },
  siteBanner_listForClient: {
    /*
     * C端列表
     * platform 平台 (string query required)
     */
    get: (p, c) => $.get("/api/cms/web/siteBanner/listForClient", p, c),
  },

  /**
   * 官网内容
   */
  siteContent: {
    /*
     * 添加
     * ableStatus 启用禁用 (string query )
     * content 内容 (string query )
     * contentType 内容类型 (string query )
     * id  (integer query )
     * image 图片 (string query )
     * menuId 菜单id (integer query )
     * publishTime 发布时间 (string query )
     * sort 排序,数字越大，排序越考前 (integer query )
     * subtitle 副标题 (string query )
     * tips 提示 (string query )
     * title 标题 (string query )
     * url 地址 (string query )
     * video 视频 (string query )
     */
    post: (p, c) => $.post("/api/cms/web/siteContent", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/cms/web/siteContent", p, c),
  },
  siteContent_detailsForClient: {
    /*
     * C端详情
     * id id (integer query required)
     */
    get: (p, c) => $.get("/api/cms/web/siteContent/detailsForClient", p, c),
  },
  siteContent_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/cms/web/siteContent/list", p, c),
  },
  siteContent_listCorrelation: {
    /*
     * 根据详情获取相关内容
     * id 内容id (integer query required)
     */
    get: (p, c) => $.get("/api/cms/web/siteContent/listCorrelation", p, c),
  },
  siteContent_listForArmy: {
    /*
     * 中军职达列表
     */
    get: (p, c) => $.get("/api/cms/web/siteContent/listForArmy", p, c),
  },
  siteContent_listForClient: {
    /*
     * C端列表
     * menuId 菜单id (integer query required)
     */
    get: (p, c) => $.get("/api/cms/web/siteContent/listForClient", p, c),
  },
  siteContent_listForClientOrderByPublishTime: {
    /*
     * C端列表(发布时间倒序排序)
     * menuId 菜单id (integer query required)
     */
    get: (p, c) =>
      $.get("/api/cms/web/siteContent/listForClientOrderByPublishTime", p, c),
  },
  siteContent_migration: {
    /*
     * 内容迁移
     * newMenuId 新菜单 (integer query required)
     * oldMenuId 原菜单 (integer query required)
     */
    put: (p, c) => $.put("/api/cms/web/siteContent/migration", p, c),
  },

  /**
   * 官网菜单
   */
  siteMenu: {
    /*
     * 添加
     * ableStatus 启用禁用 (string query )
     * contentType 内容类型 (string query )
     * correlationMenuId 关联菜单id (integer query )
     * cover 封面 (string query )
     * fatherId 父菜单id (integer query )
     * home 首页样式，为空字符串则不放在首页 (string query )
     * icon 图标 (string query )
     * id  (integer query )
     * name 菜单名 (string query )
     * pageTypeId 页面类型 (integer query )
     * platform 平台 (string query )
     * sort 排序 (integer query )
     * sortType 排列形式 (string query )
     * tips 提示 (string query )
     */
    post: (p, c) => $.post("/api/cms/web/siteMenu", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/cms/web/siteMenu", p, c),
  },
  siteMenu_details: {
    /*
     * details
     * id id (integer query required)
     */
    get: (p, c) => $.get("/api/cms/web/siteMenu/details", p, c),
  },
  siteMenu_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/cms/web/siteMenu/list", p, c),
  },
  siteMenu_listForArmy: {
    /*
     * 中军职达列表
     */
    get: (p, c) => $.get("/api/cms/web/siteMenu/listForArmy", p, c),
  },
  siteMenu_listForClient: {
    /*
     * C端菜单列表
     * platform 平台 (string query required)
     */
    get: (p, c) => $.get("/api/cms/web/siteMenu/listForClient", p, c),
  },
  siteMenu_listPlatform: {
    /*
     * 根据配置获取负责的平台
     */
    get: (p, c) => $.get("/api/cms/web/siteMenu/listPlatform", p, c),
  },
  siteMenu_listSimple: {
    /*
     * 一级菜单列表
     * fatherId 父id（为0的时候表示一级菜单） (integer query required)
     * platform 平台 (string query required)
     */
    get: (p, c) => $.get("/api/cms/web/siteMenu/listSimple", p, c),
  },

  /**
   * 官网留言
   */
  siteMessage: {
    /*
     * 添加
     * content 留言内容 (string query )
     * id  (integer query )
     * name 姓名 (string query )
     * phone 手机号 (string query )
     * platform 平台 (string query )
     */
    post: (p, c) => $.post("/api/cms/web/siteMessage", p, c),
  },
  siteMessage_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/cms/web/siteMessage/list", p, c),
  },
};
