import { Message, MessageBox, Loading } from "element-ui";
import TransformToChinese from "./TransformToChinese";
import { stringify } from "qs";
import ClipboardJS from "clipboard";

/*
   时间戳转换
   new Date.format('yyyy-MM-dd h:m:s');
 */
window.Date.prototype.format = function (format) {
  let date = {
    "M+": this.getMonth() + 1,
    "d+": this.getDate(),
    "h+": this.getHours(),
    "m+": this.getMinutes(),
    "s+": this.getSeconds(),
    "q+": Math.floor((this.getMonth() + 3) / 3),
    "S+": this.getMilliseconds(),
  };
  if (/(y+)/i.test(format)) {
    format = format.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (let k in date) {
    if (new RegExp("(" + k + ")").test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length === 1
          ? date[k]
          : ("00" + date[k]).substr(("" + date[k]).length)
      );
    }
  }
  return format;
};

let obj = {
  //提取URL参数
  getQueryString(name, url = window.location.search) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    let r = url.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
  },
  //修改URL参数
  changeURLPar(url, arg, arg_val) {
    const pattern = arg + "=([^&]*)";
    const replaceText = arg + "=" + arg_val;
    if (url.match(pattern)) {
      let tmp = "/(" + arg + "=)([^&]*)/gi";
      tmp = url.replace(eval(tmp), replaceText);
      return tmp;
    } else {
      if (url.match("[?]")) {
        return url + "&" + replaceText;
      } else {
        return url + "?" + replaceText;
      }
    }
  },
  historyPush(url) {
    history.pushState(null, null, url);
  },
  codeExN(res, num) {
    let code = res ? res.toString(2) : "";

    if (num >= code.length) {
      return 0;
    } else {
      return Math.floor(code.substr(code.length - 1 - num, 1));
    }
  },
  table: {
    time(val1, val2, val3) {
      if (
        val3 &&
        new Date(val3).getTime() &&
        new Date(val3).getTime() !== -28800000
      ) {
        return new Date(val3).format("yyyy-MM-dd hh:mm:ss");
      }
    },
    timeSmall(val1, val2, val3) {
      if (
        val3 &&
        new Date(val3).getTime() &&
        new Date(val3).getTime() !== -28800000
      ) {
        return new Date(val3).format("yyyy-MM-dd");
      }
    },
    //秒=>时分秒
    h(val1, val2, val3) {
      return obj.time_h(val3);
    },
  },
  //秒=>时分秒
  time_h(val) {
    if (val) {
      let result = Math.abs(val);
      let h =
        Math.floor(result / 3600) < 10
          ? "0" + Math.floor(result / 3600)
          : Math.floor(result / 3600);
      let m =
        Math.floor((result / 60) % 60) < 10
          ? "0" + Math.floor((result / 60) % 60)
          : Math.floor((result / 60) % 60);
      let s =
        Math.floor(result % 60) < 10
          ? "0" + Math.floor(result % 60)
          : Math.floor(result % 60);
      if (h === "00") {
        result = m + ":" + s;
      } else {
        result = h + ":" + m + ":" + s;
      }
      return val < 0 ? "-" + result : result;
    } else {
      return "00:00";
    }
  },
  downTime(second_time) {
    let time = parseInt(second_time) + "秒";

    if (parseInt(second_time) > 60) {
      let second = parseInt(second_time) % 60;
      let min = parseInt(second_time / 60);
      time = min + "分" + second + "秒";

      if (min > 60) {
        min = parseInt(second_time / 60) % 60;
        let hour = parseInt(parseInt(second_time / 60) / 60);
        time = hour + "小时" + min + "分" + second + "秒";

        if (hour > 24) {
          hour = parseInt(parseInt(second_time / 60) / 60) % 24;
          let day = parseInt(parseInt(parseInt(second_time / 60) / 60) / 24);
          time = day + "天" + hour + "小时" + min + "分" + second + "秒";
        }
      }
    }

    return time;
  },
  time(val, format = "yyyy-MM-dd hh:mm:ss") {
    if (
      val &&
      new Date(val).getTime() &&
      new Date(val).getTime() !== -28800000
    ) {
      return new Date(val).format(format);
    }
  },
  timeSmall(val) {
    if (
      val &&
      new Date(val).getTime() &&
      new Date(val).getTime() !== -28800000
    ) {
      return new Date(val).format("yyyy-MM-dd");
    }
  },
  //删除空的参数
  signDel(res) {
    for (let key in res) {
      let item = res[key];
      if (item === null || item === undefined || item === "") {
        delete res[key];
      }
    }
    return res;
  },
  match: {
    manyFloat(res) {
      if (res) {
        res = String(res);
        //先把非数字的都替换掉，除了数字和.
        res = res.replace(/[^\d.]/g, "");
        //保证只有出现一个.而没有多个.
        res = res.replace(/\.{2,}/g, ".");
        //必须保证第一个为数字而不是.
        res = res.replace(/^\./g, "");
        //保证.只出现一次，而不能出现两次以上
        res = res.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
        //只能填写两个小数
        res = res.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3");

        return res;
      }
    },
  },
  urlEnJson(res) {
    return encodeURIComponent(JSON.stringify(res));
  },
  urlDeJson(res) {
    return JSON.parse(decodeURIComponent(res));
  },
  // 获取身份证号码
  getIdNo(str) {
    if (str && str.length >= 18) {
      for (let i = 0; i <= str.length - 18; i++) {
        let temStr = str.substring(i, i + 18);
        let reg = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9X]$/;
        let factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
        let parity = [1, 0, "X", 9, 8, 7, 6, 5, 4, 3, 2];
        let code = temStr.substring(17);
        if (reg.test(temStr)) {
          let sum = 0;
          for (let i = 0; i < 17; i++) {
            sum += temStr[i] * factor[i];
          }
          if (String(parity[sum % 11]) === code.toUpperCase()) {
            return temStr;
          }
        }
      }
    }
  },
  alert(text, title = "提示") {
    return MessageBox.alert(text, title, {
      confirmButtonText: "确定",
      type: "warning",
    });
  },
  confirm(text, title = "提示") {
    return MessageBox.confirm(text, title, {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    });
  },
  prompt(text, title = "提示") {
    return MessageBox.prompt(text, title, {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      inputErrorMessage: "请填写内容",
      inputValidator: (val) => {
        return !!(val ? val.trim() : val);
      },
    });
  },
  loading: {
    show(background = "rgba(255, 255, 255, 0.4)") {
      this.loading = Loading.service({
        background,
      });
    },
    hide() {
      if (this.loading) this.loading.close();
    },
  },
  TransformToChinese,
  /**
   获取学生批次
   type: 1 成教，2 远程，3 所有
   all: true 所有，false 当前年往后推两年
   order: desc 降序，asc 升序
   备注：远程 03 09， 成教 03
  **/
  studentBatch({ type = 3, all = true, order = "desc" } = {}) {
    let model_1 = ["03"]; // 成教
    let model_2 = ["03", "09"]; // 远程
    let yearList = [];
    let dateList = [];
    let nowYear = Number(new Date().format("yyyy"));
    let start = all ? 2010 : nowYear;
    let end = nowYear + 2;

    for (let i = start; i <= end; i++) {
      yearList.push(i);
    }

    if ([1, 3].indexOf(type) > -1)
      model_1.map((item) => {
        yearList.map((item2) => {
          dateList.push(item2 + item);
        });
      });
    if ([2, 3].indexOf(type) > -1)
      model_2.map((item) => {
        yearList.map((item2) => {
          dateList.push(item2 + item);
        });
      });

    // 去重
    let list = [];
    dateList.map((item) => {
      if (list.indexOf(item) === -1) list.push(item);
    });
    // 排序
    list.sort((a, b) => (order === "asc" ? a - b : b - a));
    return list;
  },
  photoDown(imgSrc, name) {
    let image = new Image();

    // 解决跨域 Canvas 污染问题
    image.setAttribute("crossOrigin", "anonymous");
    image.onload = () => {
      let canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;

      let context = canvas.getContext("2d");
      context.drawImage(image, 0, 0, image.width, image.height);

      canvas.toBlob((blob) => {
        // 生成一个a元素
        let a = document.createElement("a");
        // 创建一个单击事件
        let event = new MouseEvent("click");

        // 将a的download属性设置为我们想要下载的图片名称，若name不存在则使用‘下载图片名称’作为默认名称
        a.download = name || "下载图片名称";
        // 将生成的URL设置为a.href属性
        a.href = window.URL.createObjectURL(blob);

        // 触发a的单击事件
        a.dispatchEvent(event);
      }, "image/jpeg");
    };

    image.src = imgSrc;
  },
  pageSize: [15, 30, 60, 100, 200, 400, 600, 800, 1000],
  // 现代浏览器全屏
  fullScreen() {
    let element = document.documentElement;
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    }
  },
  // 现代浏览器退出
  exitScreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  },
  stringify(opt = {}) {
    return stringify(opt);
  },
  copyText(text) {
    const temEl = document.createElement("button");
    const temClass = document.createAttribute("class");
    const temText = document.createAttribute("data-clipboard-text");

    temClass.value = "copyBtn";
    temText.value = text;

    temEl.setAttributeNode(temClass);
    temEl.setAttributeNode(temText);
    document.body.appendChild(temEl);

    const clipboard = new ClipboardJS(".copyBtn");

    clipboard.on("success", () => {
      Message.success("复制成功!");
      document.body.removeChild(temEl);
      clipboard.destroy();
    });

    clipboard.on("error", () => {
      MessageBox.alert(text, "请手动复制", {
        showConfirmButton: false,
      });
      document.body.removeChild(temEl);
      clipboard.destroy();
    });

    document.querySelector(".copyBtn").click();
  },
  async printPageHtml(selStr) {
    this.loading.show();
    let selEl = document.querySelector(selStr).cloneNode(true);

    // 提取内联样式
    let styleArr = document.querySelectorAll("style");
    let styleStr = "";
    if (styleArr) {
      styleArr.forEach((item) => {
        styleStr = styleStr + item.innerText;
      });
    }

    // 提取外联样式
    let linkArr = document.querySelectorAll("link");
    if (linkArr) {
      let linkHrefArr = [];
      linkArr.forEach((item) => {
        if (item.href && item.href.indexOf(".css") > -1) {
          linkHrefArr.push(item.href);
        }
      });
      // 下载样式表
      if (linkHrefArr.length) {
        let downHref = () => {
          return new Promise((resolve) => {
            let i = 0;
            let _downFun = async () => {
              let text = await this.downFile(linkHrefArr[i], "text");
              styleStr = styleStr + text;
              i++;
              if (i < linkHrefArr.length) {
                await _downFun();
              } else {
                resolve();
              }
            };
            _downFun();
          });
        };
        await downHref();
      }
    }

    // 添加 iframe
    const iframeTemp = document.createElement("iframe");
    iframeTemp.classList.add("printIframe");
    document.body.appendChild(iframeTemp);
    const iframe = document.querySelector(".printIframe");
    const iframeWin = iframe.contentWindow;

    // 添加内联样式
    if (styleStr) {
      const styleTemp = document.createElement("style");
      styleTemp.innerHTML = styleStr;
      iframeWin.document.head.appendChild(styleTemp);
    }

    // 添加内容
    iframeWin.document.body.appendChild(selEl);

    iframeWin.print();
    this.loading.hide();

    // 移除标签
    document.body.removeChild(iframeTemp);
  },
  // 下载文件
  downFile(fileUrl, responseType = "blob") {
    return new Promise((resolve) => {
      let xhr = new XMLHttpRequest();
      xhr.onload = () => {
        switch (responseType) {
          case "blob":
            resolve(URL.createObjectURL(xhr.response));
            break;
          default:
            resolve(xhr.response);
            break;
        }
      };
      xhr.onabort = () => {
        resolve("");
      };
      xhr.onerror = () => {
        resolve("");
      };
      xhr.open("GET", fileUrl, true);
      xhr.responseType = responseType;
      xhr.send();
    });
  },
  undToCap(path) {
    path = path.replace("/", "");
    let ind1 = path.indexOf("_");
    if (ind1 !== -1) {
      let str1 = path.substring(ind1 + 1, ind1 + 2);
      let tab1 = "_" + str1;
      let tab2 = str1.toLocaleUpperCase();

      path = path.replace(tab1, tab2);
    }
    return path;
  },
  objCopy(obj) {
    return obj ? JSON.parse(JSON.stringify(obj)) : obj;
  },
  // 刷新页面
  refresh() {
    document.querySelector(".m-mainBtn.n-refresh").click();
  },
  // 获取百分比
  getPercentage(finishCount, targetCount, { p = 100, f = 2 } = {}) {
    if (finishCount && targetCount) {
      return Math.fround((finishCount / targetCount) * p).toFixed(f);
    } else {
      return 0;
    }
  },
  // 获取中文数字
  getNumStr(ind) {
    const arr = ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];
    return arr[ind - 1];
  },
  // 获取图片扩展名
  getImgExtension(str) {
    const imgArr = ["jpg", "jpeg", "png", "gif"];
    let ext = imgArr[0];

    if (str) {
      const tempArr = str.split(".");
      if (tempArr.length && imgArr.indexOf(tempArr[tempArr.length - 1]) > -1) {
        ext = tempArr[tempArr.length - 1];
      }
    }
    return ext;
  },
  // 获取后缀名
  getExt(str) {
    if (str && str.indexOf(".") > -1) {
      const arr = str.split(".");
      return arr[arr.length - 1].toLowerCase();
    } else {
      return "";
    }
  },
  // 获取跳转登录的参数
  getLoginQuery() {},
  // 是否为空
  isEmpty(val) {
    if (val) {
      if (val instanceof Array) {
        return !val.length;
      } else if (typeof val === "object") {
        return !Object.keys(val).length;
      }
      return false;
    } else {
      return true;
    }
  },
  // 去除空字段
  removeEmptyFields(obj) {
    // 去除空字段
    for (let key in obj) {
      const item = obj[key];
      if (item === "" || item === null || item === undefined) {
        delete obj[key];
      }
    }
    return obj;
  },
  // 提取接口地址
  getApiPath(_api) {
    let urlStr = _api.toString();
    const reg = /\("[\s\S]*?"/g;
    urlStr = urlStr.match(reg);

    // 提取地址
    if (urlStr) {
      urlStr = urlStr[0].replace(/\("/g, "");
      urlStr = urlStr.replace(/"/g, "");
    }
    return urlStr;
  },
  // 延时
  asyncTime(time) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, time);
    });
  },
};

export default obj;
