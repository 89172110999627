import develop from "./develop";
import extension from "./extension";
import page from "./page";
import statistics from "./statistics";
import system from "./system";

/**
 * 基础微服务
 */
export default {
  develop,
  extension,
  page,
  statistics,
  system,
};
