import enterprise from "./enterprise";
import promote from "./promote";
import user from "./user";

/**
 * 用户微服务
 */
export default {
  enterprise,
  promote,
  user,
};
