import { $ } from "@/common/Axios";

export default {
  /**
   * 成考专业
   */
  crgkMajor: {
    /*
     * 添加
     * ableStatus 启用状态 (string query )
     * discipline 科类 (string query )
     * id  (integer query )
     * label 标签 (string query )
     * level 层次 (string query )
     * name 专业名称 (string query )
     * openType 开放类型：华博特学生1，社会生0 (integer query )
     * yearSystem 学制 (string query )
     */
    post: (p, c) => $.post("/api/cms/content/crgkMajor", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/cms/content/crgkMajor", p, c),
  },
  crgkMajor_detailsForClient: {
    /*
     * C端专业详情
     * id 专业id (integer query required)
     */
    get: (p, c) => $.get("/api/cms/content/crgkMajor/detailsForClient", p, c),
  },
  crgkMajor_downloadMouldForHabit: {
    /*
     * 下载华博特专业模版
     */
    get: (p, c) =>
      $.get("/api/cms/content/crgkMajor/downloadMouldForHabit", p, c),
  },
  crgkMajor_downloadMouldForSociety: {
    /*
     * 下载社会生专业模版
     */
    get: (p, c) =>
      $.get("/api/cms/content/crgkMajor/downloadMouldForSociety", p, c),
  },
  crgkMajor_exportForHabit: {
    /*
     * 导出华博特专业列表
     */
    get: (p, c) => $.get("/api/cms/content/crgkMajor/exportForHabit", p, c),
  },
  crgkMajor_exportForSociety: {
    /*
     * 导出社会生专业列表
     */
    get: (p, c) => $.get("/api/cms/content/crgkMajor/exportForSociety", p, c),
  },
  crgkMajor_listDiscipline: {
    /*
     * 科类列表
     * ableStatus 启用状态 (string query )
     * level 层次 (string query )
     * openType 启用状态 (integer query )
     */
    get: (p, c) => $.get("/api/cms/content/crgkMajor/listDiscipline", p, c),
  },
  crgkMajor_listForClient: {
    /*
     * C端专业列表
     * label 标签 (string query )
     * name 专业名字 (string query )
     */
    get: (p, c) => $.get("/api/cms/content/crgkMajor/listForClient", p, c),
  },
  crgkMajor_listForHabit: {
    /*
     * 华博特专业列表
     */
    get: (p, c) => $.get("/api/cms/content/crgkMajor/listForHabit", p, c),
  },
  crgkMajor_listForSociety: {
    /*
     * 社会生专业列表
     */
    get: (p, c) => $.get("/api/cms/content/crgkMajor/listForSociety", p, c),
  },
  crgkMajor_listLabel: {
    /*
     * 标签列表
     * ableStatus 启用状态 (string query )
     * openType 开放类型：1华博特0社会生 (integer query )
     */
    get: (p, c) => $.get("/api/cms/content/crgkMajor/listLabel", p, c),
  },
  crgkMajor_listLabelMajorForClient: {
    /*
     * C端标签专业列表
     */
    get: (p, c) =>
      $.get("/api/cms/content/crgkMajor/listLabelMajorForClient", p, c),
  },
  crgkMajor_uploadForHabit: {
    /*
     * 导入华博特专业
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/cms/content/crgkMajor/uploadForHabit", p, c),
  },
  crgkMajor_uploadForSociety: {
    /*
     * 导入社会生专业
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/cms/content/crgkMajor/uploadForSociety", p, c),
  },

  /**
   * 成考院校
   */
  crgkSite: {
    /*
     * 添加
     * ableStatus 启用状态 (string query )
     * content 内容 (string query )
     * id  (integer query )
     * logo 学校logo (string query )
     * name 名称 (string query )
     * openType 开放类型：华博特学生1，社会生0 (integer query )
     * province 省份 (string query )
     * recruitment 招生简章地址 (string query )
     * sort 排序 (integer query )
     * type 类型 (string query )
     */
    post: (p, c) => $.post("/api/cms/content/crgkSite", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/cms/content/crgkSite", p, c),
  },
  crgkSite_detailsForClient: {
    /*
     * C端学校详情
     * id 学校id (integer query required)
     */
    get: (p, c) => $.get("/api/cms/content/crgkSite/detailsForClient", p, c),
  },
  crgkSite_downloadMould: {
    /*
     * 下载模版
     */
    get: (p, c) => $.get("/api/cms/content/crgkSite/downloadMould", p, c),
  },
  crgkSite_exportForHabit: {
    /*
     * 导出院校
     */
    get: (p, c) => $.get("/api/cms/content/crgkSite/exportForHabit", p, c),
  },
  crgkSite_exportForSociety: {
    /*
     * 导出社会生院校
     */
    get: (p, c) => $.get("/api/cms/content/crgkSite/exportForSociety", p, c),
  },
  crgkSite_listForHabit: {
    /*
     * 华博特院校列表
     */
    get: (p, c) => $.get("/api/cms/content/crgkSite/listForHabit", p, c),
  },
  crgkSite_listForSociety: {
    /*
     * 社会生院校列表
     */
    get: (p, c) => $.get("/api/cms/content/crgkSite/listForSociety", p, c),
  },
  crgkSite_listMajors: {
    /*
     * 学校专业选择情况列表
     * level 层次 (string query required)
     * openType 开放类型：1华博特0社会生 (integer query required)
     * siteId 学校id (integer query required)
     */
    get: (p, c) => $.get("/api/cms/content/crgkSite/listMajors", p, c),
  },
  crgkSite_listProvince: {
    /*
     * 省份列表
     */
    get: (p, c) => $.get("/api/cms/content/crgkSite/listProvince", p, c),
  },
  crgkSite_listSiteForClient: {
    /*
     * C端学校列表
     * name 学校名称 (string query )
     */
    get: (p, c) => $.get("/api/cms/content/crgkSite/listSiteForClient", p, c),
  },
  crgkSite_updateMajor: {
    /*
     * 编辑专业
     * majorIds 专业id (array query required)
     * siteId 学校id (integer query required)
     */
    put: (p, c) => $.put("/api/cms/content/crgkSite/updateMajor", p, c),
  },
  crgkSite_uploadForHabit: {
    /*
     * 导入华博特院校
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/cms/content/crgkSite/uploadForHabit", p, c),
  },
  crgkSite_uploadForSociety: {
    /*
     * 导入社会生院校
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/cms/content/crgkSite/uploadForSociety", p, c),
  },

  /**
   * 直播课表科类
   */
  liveCourseDiscipline_listSelect: {
    /*
     * 成考宝典C端直播回放列表
     * course 科目 (string query )
     * level 层次 (string query )
     */
    get: (p, c) =>
      $.get("/api/cms/content/liveCourseDiscipline/listSelect", p, c),
  },
  liveCourseDiscipline_updateDiscipline: {
    /*
     * 编辑科类
     * course 科目 (string query required)
     * disciplineText 科类选择情况的json字符串 (string query required)
     * level 层次 (string query required)
     */
    put: (p, c) =>
      $.put("/api/cms/content/liveCourseDiscipline/updateDiscipline", p, c),
  },

  /**
   * 直播课资料
   */
  liveCourseInformation: {
    /*
     * 添加
     * course 科目 (string query )
     * id  (integer query )
     * level 层次 (string query )
     * url 直播课 (string query )
     * year 成考年度 (string query )
     */
    post: (p, c) => $.post("/api/cms/content/liveCourseInformation", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/cms/content/liveCourseInformation", p, c),
  },
  liveCourseInformation_list: {
    /*
     * 直播列表
     */
    get: (p, c) => $.get("/api/cms/content/liveCourseInformation/list", p, c),
  },
  liveCourseInformation_listForClient: {
    /*
     * 成考宝典C端直播列表
     */
    get: (p, c) =>
      $.get("/api/cms/content/liveCourseInformation/listForClient", p, c),
  },

  /**
   * 直播课表
   */
  liveCourseSchedule: {
    /*
     * 添加
     * course 科目 (string query )
     * docHref 文档链接 (string query )
     * duration 时长 (integer query )
     * id  (integer query )
     * latestFlag 是否最近 (string query )
     * level 层次 (string query )
     * liveEnd 直播开始时间 (string query )
     * liveHref 直播链接 (string query )
     * liveStart 直播开始时间 (string query )
     * meetingHref 会议链接 (string query )
     * platform 平台 (string query )
     * playbackHref 直播回放链接 (string query )
     */
    post: (p, c) => $.post("/api/cms/content/liveCourseSchedule", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/cms/content/liveCourseSchedule", p, c),
  },
  liveCourseSchedule_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/cms/content/liveCourseSchedule/export", p, c),
  },
  liveCourseSchedule_list: {
    /*
     * 直播列表
     */
    get: (p, c) => $.get("/api/cms/content/liveCourseSchedule/list", p, c),
  },
  liveCourseSchedule_listCkbdLiveForClient: {
    /*
     * 成考宝典C端直播列表
     * end 开始时间，如果是当天直播，传给我当天结束时间 (string query )
     */
    get: (p, c) =>
      $.get("/api/cms/content/liveCourseSchedule/listCkbdLiveForClient", p, c),
  },
  liveCourseSchedule_listCkbdPlaybackForClient: {
    /*
     * 成考宝典C端直播回放列表
     * course 课程 (string query )
     * month 月份:1,2,3... (integer query )
     */
    get: (p, c) =>
      $.get(
        "/api/cms/content/liveCourseSchedule/listCkbdPlaybackForClient",
        p,
        c
      ),
  },
  liveCourseSchedule_listCourse: {
    /*
     * 课程列表
     * level 层次 (string query required)
     */
    get: (p, c) =>
      $.get("/api/cms/content/liveCourseSchedule/listCourse", p, c),
  },
  liveCourseSchedule_listForCkbd: {
    /*
     * listForCrgk
     */
    get: (p, c) =>
      $.get("/api/cms/content/liveCourseSchedule/listForCkbd", p, c),
  },
  liveCourseSchedule_listForClient: {
    /*
     * 直播C端列表
     */
    get: (p, c) =>
      $.get("/api/cms/content/liveCourseSchedule/listForClient", p, c),
  },
  liveCourseSchedule_listLevel: {
    /*
     * 层次列表
     */
    get: (p, c) => $.get("/api/cms/content/liveCourseSchedule/listLevel", p, c),
  },
  liveCourseSchedule_listStudentLive: {
    /*
     * 成考学生直播学习情况列表
     */
    get: (p, c) =>
      $.get("/api/cms/content/liveCourseSchedule/listStudentLive", p, c),
  },
  liveCourseSchedule_listStudentLiveStatistics: {
    /*
     * 成考学生直播学习统计列表
     * year 成考年度 (string query required)
     */
    get: (p, c) =>
      $.get(
        "/api/cms/content/liveCourseSchedule/listStudentLiveStatistics",
        p,
        c
      ),
  },
  liveCourseSchedule_refreshLatestFlag: {
    /*
     * 刷新直播最新标识
     */
    put: (p, c) =>
      $.put("/api/cms/content/liveCourseSchedule/refreshLatestFlag", p, c),
  },
  liveCourseSchedule_upload: {
    /*
     * 导入
     * excelFile excelFile (file formData required)
     * platform 平台 (string query required)
     */
    post: (p, c) => $.post("/api/cms/content/liveCourseSchedule/upload", p, c),
  },

  /**
   * 宣传内容
   */
  publicity: {
    /*
     * 添加
     * ableStatus 启用状态 (string query )
     * content 内容，富文本的时候 (string query )
     * contentType 内容类型：超链接，富文本，视频，图片 (string query )
     * cover 封面 (string query )
     * id  (integer query )
     * label 标签 (string query )
     * openType 开放类型：华博特学生1，社会生0 (integer query )
     * platform 平台 (string query )
     * province 省份 (string query )
     * publishDate 发布日期 (string query )
     * showSide 展示端：PC端，移动端 (string query )
     * sort 排序 (integer query )
     * subtitle 副标题 (string query )
     * title 标题 (string query )
     * type 类型：首页Banner，成考资讯等 (string query )
     * url 链接 (string query )
     */
    post: (p, c) => $.post("/api/cms/content/publicity", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/cms/content/publicity", p, c),
  },
  publicity_details: {
    /*
     * 详情
     * id id (integer query required)
     */
    get: (p, c) => $.get("/api/cms/content/publicity/details", p, c),
  },
  publicity_detailsForClient: {
    /*
     * C端详情
     * id id (integer query required)
     */
    get: (p, c) => $.get("/api/cms/content/publicity/detailsForClient", p, c),
  },
  publicity_listAdultExamInformationForHabit: {
    /*
     * 华博特成考资讯列表
     */
    get: (p, c) =>
      $.get(
        "/api/cms/content/publicity/listAdultExamInformationForHabit",
        p,
        c
      ),
  },
  publicity_listAdultExamInformationForSociety: {
    /*
     * 社会生成考资讯列表
     */
    get: (p, c) =>
      $.get(
        "/api/cms/content/publicity/listAdultExamInformationForSociety",
        p,
        c
      ),
  },
  publicity_listAdultExamProcessForHabit: {
    /*
     * 华博特成考流程列表
     */
    get: (p, c) =>
      $.get("/api/cms/content/publicity/listAdultExamProcessForHabit", p, c),
  },
  publicity_listAdultExamProcessForSociety: {
    /*
     * 社会生成考流程列表
     */
    get: (p, c) =>
      $.get("/api/cms/content/publicity/listAdultExamProcessForSociety", p, c),
  },
  publicity_listCourseBanner: {
    /*
     * 精品课Banner列表
     */
    get: (p, c) => $.get("/api/cms/content/publicity/listCourseBanner", p, c),
  },
  publicity_listHomeBanner: {
    /*
     * 首页Banner列表
     */
    get: (p, c) => $.get("/api/cms/content/publicity/listHomeBanner", p, c),
  },
  publicity_listInformationBanner: {
    /*
     * 资讯Banner列表
     */
    get: (p, c) =>
      $.get("/api/cms/content/publicity/listInformationBanner", p, c),
  },
  publicity_listLabel: {
    /*
     * 标签
     * openType 开放类型 (integer query )
     * platform 平台 (string query required)
     * type 类型：成考资讯，成考流程 (string query required)
     */
    get: (p, c) => $.get("/api/cms/content/publicity/listLabel", p, c),
  },
  publicity_listPublicityForClient: {
    /*
     * C端宣传内容列表
     * label 标签 (string query )
     * platform 平台 (string query required)
     * province 省份 (string query )
     * showSide 展示端：PC端，移动端 (string query required)
     * type 类型：首页Banner，成考资讯，成考流程，直播课表 (string query required)
     */
    get: (p, c) =>
      $.get("/api/cms/content/publicity/listPublicityForClient", p, c),
  },
  publicity_listZsbNews: {
    /*
     * 新闻资讯列表
     */
    get: (p, c) => $.get("/api/cms/content/publicity/listZsbNews", p, c),
  },

  /**
   * 内容喜欢
   */
  publicityLike_like: {
    /*
     * 喜欢|不喜欢
     * id id (integer query required)
     */
    post: (p, c) => $.post("/api/cms/content/publicityLike/like", p, c),
  },

  /**
   * 资源地址
   */
  resourceUrl: {
    /*
     * 添加
     * id  (integer query )
     * platform 平台 (string query )
     * title 标题 (string query )
     * url 链接 (string query )
     */
    post: (p, c) => $.post("/api/cms/content/resourceUrl", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/cms/content/resourceUrl", p, c),
  },
  resourceUrl_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/cms/content/resourceUrl/list", p, c),
  },

  /**
   * 专升本学校专业
   */
  zsbMajor: {
    /*
     * 添加
     * discipline 科类 (string query )
     * id  (integer query )
     * major 专业 (string query )
     * province 省份 (string query )
     * site 学校 (string query )
     */
    post: (p, c) => $.post("/api/cms/content/zsbMajor", p, c),

    /*
     * 删除
     * id id (integer query required)
     */
    delete: (p, c) => $.delete("/api/cms/content/zsbMajor", p, c),
  },
  zsbMajor_downloadMould: {
    /*
     * 下载模版
     */
    get: (p, c) => $.get("/api/cms/content/zsbMajor/downloadMould", p, c),
  },
  zsbMajor_export: {
    /*
     * 导出
     */
    get: (p, c) => $.get("/api/cms/content/zsbMajor/export", p, c),
  },
  zsbMajor_list: {
    /*
     * 列表
     */
    get: (p, c) => $.get("/api/cms/content/zsbMajor/list", p, c),
  },
  zsbMajor_listDiscipline: {
    /*
     * 科类列表
     * province 省份 (string query )
     */
    get: (p, c) => $.get("/api/cms/content/zsbMajor/listDiscipline", p, c),
  },
  zsbMajor_listMajor: {
    /*
     * 专业列表
     * site 学校 (string query )
     */
    get: (p, c) => $.get("/api/cms/content/zsbMajor/listMajor", p, c),
  },
  zsbMajor_listSite: {
    /*
     * 学校
     * province 省份 (string query )
     */
    get: (p, c) => $.get("/api/cms/content/zsbMajor/listSite", p, c),
  },
  zsbMajor_upload: {
    /*
     * 导入
     * excelFile excelFile (file formData required)
     */
    post: (p, c) => $.post("/api/cms/content/zsbMajor/upload", p, c),
  },
};
